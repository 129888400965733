<template>
  <div>
    <div v-if="isMobile===false">
      <el-dialog
          :visible.sync="dialogVisible"
          width="600px"
          :before-close="handleClose"
          top="20vh"
      >
        <div>
          <div class="title1"><span style="color:#2F6EFF">成为合作伙伴</span></div>
          <div class="title2">加入油气通合作伙伴网络</div>
          <div style="margin-top: 30px">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
              <el-form-item label="企业名称" prop="companyName" >
                <el-input v-model="ruleForm.companyName" placeholder="请输入您的企业名称"></el-input>
              </el-form-item>
              <el-form-item label="联系姓名" prop="userName" >
                <el-input v-model="ruleForm.userName" placeholder="请填写您的联系姓名"></el-input>
              </el-form-item>
              <el-form-item label="联系方式" prop="contact">
                <el-input v-model="ruleForm.contact"  placeholder="请输入联系手机号码或者邮箱"></el-input>
              </el-form-item>
              <el-form-item label="合作内容" prop="content" >
                <el-input type="textarea" v-model="ruleForm.content" placeholder="您可针对合作内容/产品进行详细描述"></el-input>
              </el-form-item>
              <el-form-item style="text-align: center">
              </el-form-item>
            </el-form>
          </div>
          <div style="text-align: center">  <el-button type="primary" @click="submitForm(ruleForm)">立即提交</el-button></div>
        </div>
      </el-dialog>
    </div>
    <div v-if="isMobile===true" style="font-size: 14px" class="phone">
      <el-dialog
          :visible.sync="dialogVisible"
          width="88vw"
          :before-close="handleClose"
          top="15vh"
      >
        <div>
          <div class="title1"><span style="color:#2F6EFF">成为合作伙伴</span></div>
          <div class="title2">加入油气通合作伙伴网络</div>
          <div style="margin-top: 30px">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
              <el-form-item label="企业名称" prop="companyName" >
                <el-input v-model="ruleForm.companyName" placeholder="请输入您的企业名称"></el-input>
              </el-form-item>
              <div class="span"></div>
              <el-form-item label="联系姓名" prop="userName" >
                <el-input v-model="ruleForm.userName" placeholder="请填写您的联系姓名"></el-input>
              </el-form-item>
              <div class="span"></div>
              <el-form-item label="联系方式" prop="contact">
                <el-input v-model="ruleForm.contact"  placeholder="请输入联系手机号码或者邮箱"></el-input>
              </el-form-item>
              <div class="span"></div>
              <el-form-item label="合作内容" prop="content" >
                <el-input type="textarea" v-model="ruleForm.content" placeholder="您可针对合作内容进行详细描述"></el-input>
              </el-form-item>
              <el-form-item style="text-align: center">

              </el-form-item>

              <div class="span"></div>
            </el-form>
          </div>
          <div style="text-align: center">  <el-button type="primary" @click="submitForm(ruleForm)">立即提交</el-button></div>
        </div>
      </el-dialog>
    </div>
    <successTip
      :dialogVisible="dialogconsult"
      @hideDialog="hide"
  ></successTip>

  </div>

</template>
<script>
import successTip from "@/components/successTip.vue";
export default {
  components: {successTip},
  props: {
    dialogVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data(){
    return{
      dialogconsult:false,
      token:localStorage.getItem("access_token"),
      isMobile:false,
      languageSelect:'',
      userId:localStorage.getItem("userId"),
      complete:localStorage.getItem("infoComplete"),
      ruleForm: {
        companyName: '',
        userName: '',
        contact: '',
        content: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      rules: {
        companyName: [
          { required: true, message: '请输入企业名称', trigger: 'blur' },
        ],
        userName: [
          { required: true, message: '请输入您的姓名', trigger: 'blur' }
        ],
        contact: [
          { required: true, message: '请输入您的联系方式', trigger: 'blur' }
        ],

      }
    }
  },
  mounted() {
    // Check if the device width is less than 768px (example for mobile)
    this.isMobile = window.matchMedia('(max-width: 767px)').matches;
  },
  created() {
    console.log(this.userId)
  },
  methods: {
    hideDialogin() {
      this.localDialogVisible=false
      // location.reload()
      this.$forceUpdate()
    },
    hide() {
      this.dialogconsult = false;
    },
    hideComplete(){
      // this.dialogComplete = false;
      // this.localDialogVisible=false
      // location.reload()
      this.$forceUpdate()
    },
    handleClose() {
      this.ruleForm.companyName=""
      this.ruleForm.userName=""
      this.ruleForm.contact=""
      this.ruleForm.content=""
      this.$emit('hideDialog')
    },
    submitForm(formName) {
      formName.consultUrl=window.location.href
      // console.log(formName)
      // this.dialogComplete = true;
      if(formName.companyName===""){
        this.$message('请输入您的企业名称');
      }
      else if(formName.userName===""){
        this.$message('请输入您的联系姓名');
      }
      else if(formName.contact===""){
        this.$message('请输入您的联系方式');
      }
      else{
        this.$axios.post("/api/consult/add" ,{
          companyName: formName.companyName,
          userName: formName.userName,
          contact: formName.contact,
          content: formName.content,
          consultType:2,
          consultUrl:formName.consultUrl,
        }, {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token       },
        })
            .then((res) => {
              if (res.data.code === 200) {
                this.dialogconsult = true
                this.handleClose()
                this.resetForm(formName)
              } else {
                this.$message('提交失败，请重新提交试试');
              }

            }).catch(error=>{
          console.log(error)
        })

      }


    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  },
  watch: {
    "$store.state.userId"(newValue) {
      this.userId = newValue;
    },
    // dialogVisible() {
    //   this.localDialogVisible = false;
    //   this.dialogComplete = false;
    //
    //   //
    //   // if (newVal&&!this.userId) {
    //   //   // 如果 dialogVisible 为 true 且 userId 不存在，设置 localDialogVisible 为 true
    //   //   this.dialogVisibleLogin=true
    //   //   this.localDialogVisible = false;
    //   // }
    //   // else if(newVal&&this.userId){
    //   //   if(this.complete==1){
    //   //     this.dialogComplete = false;
    //   //     this.localDialogVisible = true;
    //   //   }
    //   //   else{
    //   //     this.dialogComplete = true;
    //   //     this.localDialogVisible = false;
    //   //   }
    //   // }
    //   // else{
    //   //   this.localDialogVisible = false;
    //   //   this.dialogComplete = false;
    //   // }
    // },
  },
};
</script>
<style scoped lang="scss">

.title1{
  text-align: center;
  width: 100%;
  font-size: 25px;
  margin-top: -35px;
}
.title2{
  color:gray;
  text-align: center;
  width: 100%;
  margin-top: 15px;
}
.phone{
  position: fixed;
  z-index: 9999999999;
  .title1{
    text-align: center;
    width: 100%;
    font-size:16px;
    margin-top: -35px;
  }
  ::v-deep .el-form-item__error{
    left: 15px !important;
    top:80%;
  }
  ::v-deep .el-textarea__inner{
    border: none !important;
    margin-top: 5px;
    height: 35px;
    font-size: 14px;
    font-family: Sarial, sans-serif, "Microsoft Yahei";

  }
  .el-form-item {
    margin-bottom: 10px !important;
  }
  .span{
    height: 1px;
    background-color: #e1d7d7;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  .title2{
    color:gray;
    text-align: center;
    width: 100%;
    margin-top: 15px;
  }
  ::v-deep {
    .el-dialog__body {
      padding: 30px 20px 30px 20px;
      color: #606266;
      font-size: 14px;
      word-break: break-all;
    }
    .el-dialog__header {
      .el-dialog__title {
        font-weight: bold;
        font-size: 14px;
      }
    }

    .el-dialog__headerbtn .el-dialog__close {
      color:#2F6EFF;;
      border: #2F6EFF 1px solid;
      border-radius: 50%;
    }
    .el-input__inner {
      font-size: 14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";

      border:none !important;

    }
    .el-textarea {
      border-radius: 4px;
      line-height: 20px;
      border: none !important;
      position: relative;
      font-size: 14px;
    }
    .el-form-item__label {
      font-size: 14px;
    }
    .el-button {
      width: fit-content;
      height: fit-content;
      padding: 15px 25px;
      margin-top: 25px;
      line-height: 35px;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
      color: #2F6EFF;
      a {
        display: block;
      }
    }
    .el-button--primary {
      border: 1px solid #2F6EFF;
      background-color: #2F6EFF !important;
      color: #fff !important;
      margin-bottom: 10px !important;
    }

  }
  ::v-deep {
    .el-dialog{
      margin:auto !important;
      font-size: 14px;

      top: 150px;
    }
    .el-dialog__header {
      padding:5px;
      .el-dialog__title {
        font-weight: bold;
        padding:15px;
        font-size: 14px;

      }

    }


    .el-button {
      width: fit-content;
      height: fit-content;
      font-size: 14px;
      padding:10px  15px;
      line-height:25px;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
      a {
        display: block;
      }
    }

  }

}
::v-deep {
  .el-dialog__body {
    padding: 30px 50px 30px 40px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
  }
  .el-dialog__header {
    .el-dialog__title {
      font-weight: bold;
      font-size: 16px;
    }
  }

  .el-dialog__headerbtn .el-dialog__close {

    color:#2F6EFF;;
    border: #2F6EFF 1px solid;
    border-radius: 50%;
  }
  .el-input__inner {
    font-size: 16px;
    font-family: Sarial, sans-serif, "Microsoft Yahei";

    border-radius: 5px !important;
    border: 1px solid #9b9a9a !important;

  }
  .el-textarea {
    line-height: 40px;
    border-radius: 5px !important;
    border: 1px solid #9b9a9a !important;
    position: relative;
    font-size: 14px;
  }
  .el-form-item__label {
    font-size: 16px;
  }
  .el-button {
    border: 1px solid #2F6EFF;
    a {
      display: block;
    }
  }
  .el-button--primary {
    border: 1px solid #2F6EFF;
    background: #2F6EFF;
    color: #fff;
  }
  .el-textarea__inner {

    font-size: 16px;
    font-family: Sarial, sans-serif, "Microsoft Yahei";

  }


}
</style>
