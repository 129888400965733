<template>
  <div>
    <div v-if="model===false">
      <div ref="total" class="total">
        <div :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed'}"
             class="topback">
          <headtip :changeVisable="changeVisable" @showDialog="showPartner"></headtip>
        </div>
        <div @click="changeheadVisable">
          <div class="screen1">
            <div class="tooltip"
                 v-if="showTooltip"
                 style="width: 15vh;height: 15vh;position: fixed;right: 3.5vw;top: 60vh;">
              <img src="../assets/wechat.jpg"/>
            </div>

            <!--      logo  you气通GPT社区 合作伙伴  登录-->
            <!--          <div class="up" v-if="scrollTrue===false" @click="uptop"></div>-->
            <div class="up" style="z-index: 9999">
              <div class="uptop" @click="uptop"></div>
              <div style="height: 1px;background-color: rgba(128,128,128,0.3);width: 100%"></div>
              <div style="padding: 1vh 0;" @click="upkefu">
                <div class="upkefu"></div>
                <div style="font-size: 0.6vw;text-align: center;margin-top: 0.5vh;color: white"> 在线<br>客服</div>
              </div>
              <div style="height: 1px;background-color: rgba(128,128,128,0.3);width: 100%"></div>
              <div class="feedback" @click="feedback">服务<br>反馈</div>
              <div style="height: 1px;background-color: rgba(128,128,128,0.3);width: 100%"></div>
              <div class="wechaticon" @mouseenter="showTooltip=true"
                   @mouseleave="showTooltip=false" style="height: 6vh;width: 3vw;display: flex;justify-content: center;text-align: center;padding:1vh 1vw 0.5vh 0.3vw">
                <svg t="1727166040965" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3334" width="100%" height="30">
                  <path d="M1010.8 628c0-141.2-141.3-256.2-299.9-256.2-168 0-300.3 115.1-300.3 256.2 0 141.4 132.3 256.2 300.3 256.2 35.2 0 70.7-8.9 106-17.7l96.8 53-26.6-88.2c70.9-53.2 123.7-123.7 123.7-203.3zM618 588.8c-22.1 0-40-17.9-40-40s17.9-40 40-40 40 17.9 40 40c0 22-17.9 40-40 40z m194.3-0.3c-22.1 0-40-17.9-40-40s17.9-40 40-40 40 17.9 40 40-17.9 40-40 40z" fill="#00C800" p-id="3335"></path><path d="M366.3 106.9c-194.1 0-353.1 132.3-353.1 300.3 0 97 52.9 176.6 141.3 238.4l-35.3 106.2 123.4-61.9c44.2 8.7 79.6 17.7 123.7 17.7 11.1 0 22.1-0.5 33-1.4-6.9-23.6-10.9-48.3-10.9-74 0-154.3 132.5-279.5 300.2-279.5 11.5 0 22.8 0.8 34 2.1C692 212.6 539.9 106.9 366.3 106.9zM247.7 349.2c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48z m246.6 0c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48z" fill="#00C800" p-id="3336"></path></svg>
              </div>

            </div>
            <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1"  >油气通</div>
              <div class="title2" >全球油气行业专业、开放的人工智能云平台</div>
              <div>
                <transition appear name="run">
                  <div class="title3">
                    <div  class="hoverTitleup"  @click="position1">
                      <div style="display: flex;align-items: center">
                        <div style="height: 2vw;width: 2vw;margin-right:0.5vw"><img loading="lazy"
                                                                                    src="@/assets/banner1.png">
                        </div>
                        油气资讯GPTs
                      </div>

                      <div class="light"> 免费为用户提供基于AI大模型技术的油气资讯服务。</div>
                    </div>
                    <div class="hoverTitleup" @click="position7">
                      <div style="display: flex;align-items: center">
                        <div style="height: 2vw;width: 2.5vw;margin-right: 0.5vw"><img loading="lazy"
                                                                                             src="@/assets/banner2.png">
                        </div>
                        油气专业GPTs
                      </div>
                      <div class="light">油气专业GPTs人工智能模型的建立主要是基于安东等工程技术企业的应用，面向业界企业开放分享。</div>

                    </div>
                    <div class="hoverTitleup" @click="position5">
                      <div style="display: flex;align-items: center">
                        <div style="height: 2vw;width: 2vw;margin-right: 1vw"><img loading="lazy"
                                                                                           src="@/assets/banner3.png">
                        </div>
                        企业管理GPTs
                      </div>
                      <div class="light">企业管理GPTs人工智能是基于特定的企业环境训练。</div>

                    </div>
                    <div class="hoverTitleup" style="margin-right: 0" @click="position2">
                      <div style="display: flex;align-items: center">
                        <div style="height: 2vw;width: 2vw;margin-right: 0.5vw"><img loading="lazy"
                                                                                             src="@/assets/banner4.png">
                        </div>
                        通用类GPTs
                      </div>
                      <div class="light">线上通用性GPTs，基于AI大模型能力，辅助用户改进工作效率。</div>

                    </div>

                    <div class="hoverTitleup" @click="position8">
                      <div style="display: flex;align-items: center">
                        <div style="height: 2vw;width: 2vw;margin-right: 0.5vw"><img loading="lazy"
                                                                                     src="@/assets/banner5.png">
                        </div>
                        油气开发作业GPT应用
                      </div>
                      <div class="light">通过GPT辅助油气开发现场作业。</div>


                    </div>
                    <div class="hoverTitleup" @click="position6">
                      <div style="display: flex;align-items: center">
                        <div style="height: 2vw;width: 2vw;margin-right: 0.5vw"><img loading="lazy"
                                                                                             src="@/assets/banner6.png">
                        </div>
                        数智油田GPT应用
                      </div>
                      <div class="light">利用人工智能技术，建设与地质、工程相结合的数智油田全场景解决方案，赋能数智油田全面升级。</div>

                    </div>
                    <div class="hoverTitleup" @click="position9">
                      <div style="display: flex;align-items: center">
                        <div style="height: 2vw;width: 2vw;margin-right: 0.5vw"><img loading="lazy"
                                                                                             src="@/assets/banner7.png">
                        </div>
                        智能多媒体
                      </div>
                      <div class="light">帮助企业客户提供油气行业智能多媒体服务，让油气世界更加有声有型。提供科学可视化、营销推广及品牌优化服务，满足客户技术宣传、品牌推广、培训、竟赛和科研汇报等方面的多媒体需求。</div>

                    </div>
                    <div class="hoverTitleup" @click="position3">
                      <div style="display: flex;align-items: center">
                        <div style="height: 2vw;width: 2vw;margin-right: 0.5vw"><img loading="lazy"
                                                                                             src="@/assets/banner8.png">
                        </div>
                        人工智能培训及转型咨询
                      </div>
                      <div class="light">帮助客户企业全员可以使用在线的、开放的大模型人工智能工具，成为人工智能的驾驶员。帮助客户实现人工转型规划制定、项目方案设计、专家咨询服务，提升客户企业竞争力。</div>

                    </div>
                    <div class="hoverTitleup" @click="position10">
                      <div style="display: flex;align-items: center">
                        <div style="height: 2vw;width: 2vw;margin-right: 0.5vw"><img loading="lazy"
                                                                                             src="@/assets/banner9.png">
                        </div>
                        油气企业自建GPT云平台
                      </div>
                      <div class="light">帮助企业客户建设私有环境下的人工智能云平台，积累各应用场景的模型成果，实现模型的最大化共享和算力资源的充分使用。</div>

                    </div>
                  </div>
                </transition>
              </div>

            </div>
            <!--      平台大图-->
            <div class="layer3">
              <!--            ce-->
              <transition appear name="rundown">
                <AIAnswer :input="input" @setTop="uptop" @skipping="skippcom"></AIAnswer>
              </transition>
            </div>
          </div>
          <!--              在线互动    -->
          <div class="screen3" style="background-color: white;display: flex;padding-left: 13vw">
              <div v-sliden-in="{duration:600}"  style="padding:8vh 0;height:20vh;text-align: center;line-height: 19vh;font-size: 5vw">
                联系
                <br>
                <span v-sliden-in="{duration:600}"  style="font-size: 7vw">我们</span></div>
            <div class="internew">
              <!--        智能-->
                <div v-zoom-in="{duration:600}" class="grand2" @click="upkefu2()">
                  <div class="back">
                    <div class="photo">
                      <img loading="lazy" src="@/assets/kefu.png"/>
                    </div>
                    <div class="mengban">
                      <div class="title1" style="color: white;font-size: 2.5vw;">
                        在线客服
                      </div>
                      <div class="title1" style="margin-top: 5vh">
                        <img style="width: 3vw;height: 2vh" src="@/assets/rightArrow.png">
                      </div>
                    </div>
                  </div>
                </div>
              <!--        油藏-->

                <div v-zoom-in="{duration:800}" class="grand2" @click="feedback()">
                  <div class="back">
                    <div class="photo">
                      <img loading="lazy" src="@/assets/communicate.png"/>
                    </div>
                    <div class="mengban">
                      <div class="title1" style="color: white;font-size: 2.5vw;">
                        服务反馈
                      </div>
                      <div class="title1" style="margin-top: 5vh">
                        <img style="width: 3vw;height: 2vh" src="@/assets/rightArrow.png">
                      </div>
                    </div>
                  </div>
                </div>

              <!--        智能-->

              <div v-zoom-in="{duration:1000}" class="grand2" @click="showPartner()">
                <div class="back">
                  <div class="photo">
                    <img loading="lazy" src="@/assets/hand.png"/>
                  </div>
                  <div class="mengban">
                    <div class="title1" style="color: white;font-size: 2.5vw;">
                      合作交流
                    </div>
                    <div class="title1" style="margin-top: 5vh">
                      <img style="width: 3vw;height: 2vh" src="@/assets/rightArrow.png">
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>

          <!--        油气资讯GPTs-->
          <div ref="oilZixun" class="screenConsult" style="padding-top: 0">
            <div v-sliden-in="{duration:600}" class="title" style="text-align: center">油气资讯GPTs</div>
            <div v-sliden-in="{duration:600}" class="light">
              免费为用户提供基于AI大模型技术的油气资讯服务。 <span class="light2"><br>因AI模型无法做到完全精准，且受算力限制，如想获得更好的使用效果，请<span
                style="color: #2F6EFF;cursor: pointer" @click="clickConsult('油气资讯GPTs')">点击咨询</span>。</span>
            </div>
            <div class="pro2">
              <!--        知识-->
              <div v-zoom-in="{duration:600}" class="grand2"  @click="info">
                <div class="back">
                  <div class="photo">
                    <img class="image" src="@/assets/information.png"/>
                    <div class="mengban" >
                      <div class="title1" style=" left: 4vh;">
                        油气知识
                      </div>
                      <div class="title1" style="right: 1vh ;font-weight: unset;font-size: 1.21vw ">
                        更多 &nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                      </div>
                    </div>
                  </div>
                  <!--                  <div class="title1">Oil & Gas Information</div>-->
                  <!--                  <div class="title2">-->
                  <!--                    Oil & Gas Information covers comprehensive industry information, serving as your intelligent assistant.-->
                  <!--                  </div>-->
                  <!--                  <div class="consult" @click="info">View Details<i-->
                  <!--                      class="el-icon-right" style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>-->
                  <!--                  </div>-->
                </div>
              </div>
              <!--            展馆-->
              <div v-zoom-in="{duration:800}" class="grand2" @click="expo">
                <div class="back">
                  <div class="photo">
                    <img class="image" src="@/assets/expo.png"/>
                    <div class="mengban" >
                      <div class="title1" style=" left: 4vh;">
                        油气展馆
                      </div>
                      <div class="title1" style="right: 1vh ;font-weight: unset;font-size: 1.21vw ">
                        更多 &nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                      </div>
                    </div>
                  </div>
                  <!--                  <div class="title1">Oil & Gas Exposition</div>-->
                  <!--                  <div class="title2">-->
                  <!--                    Oil & Gas Exposition provides online promotion and exhibition services for companies in the global Oil & Gas development ecosystem.-->
                  <!--                  </div>-->
                  <!--                  <div class="consult" @click="expo">View Details<i-->
                  <!--                      class="el-icon-right" style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>-->
                  <!--                  </div>-->
                </div>
              </div>
              <!--        人才-->
              <div v-zoom-in="{duration:1000}" class="grand2"  @click="job">
                <div class="back">
                  <div class="photo" style="overflow: hidden;">
                    <img class="image" src="@/assets/job.png"/>
                    <div class="mengban" >
                      <div class="title1" style=" left: 4vh;">
                        油气人才
                      </div>
                      <div class="title1" style="right: 1vh ;font-weight: unset;font-size: 1.21vw ">
                        更多 &nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                      </div>
                    </div>
                  </div>
                  <!--                  <div class="title1">Oil & Gas JobAI</div>-->
                  <!--                  <div class="title2">-->
                  <!--                    Oil & Gas JobAI is committed to becoming a global job hunting and recruitment platform for professionals in the Oil & Gas industry.-->
                  <!--                  </div>-->
                  <!--                  <div class="consult" @click="job">View Details<i class="el-icon-right"-->
                  <!--                                                                   style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>-->
                  <!--                  </div>-->
                </div>
              </div>
            </div>
            <!--            <div class="pro2">-->
            <!--              &lt;!&ndash;        知识&ndash;&gt;-->
            <!--              <div class="grand2" @click="info">-->
            <!--                <div class="back">-->
            <!--                  <div class="photo">-->
            <!--                    <img loading="lazy" src="@/assets/icon03.png"/>-->
            <!--                  </div>-->
            <!--                  <div class="title1">油气知识</div>-->
            <!--                  <div class="title2">-->
            <!--                    油气知识覆盖全方位⾏业资讯，涵盖科技解释、学术报告、商机洞察等；借助AI 大模型，创造智能化、个性化⽤⼾体验，成为您的智能助手。-->
            <!--                  </div>-->
            <!--                  <div class="consult" @click="info">查看详情<i-->
            <!--                      class="el-icon-right" style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              &lt;!&ndash;            展馆&ndash;&gt;-->
            <!--              <div class="grand2" @click="expo">-->
            <!--                <div class="back">-->
            <!--                  <div class="photo"><img loading="lazy" src="@/assets/icon01.png"/></div>-->
            <!--                  <div class="title1">油气展馆</div>-->
            <!--                  <div class="title2">-->
            <!--                    油气展馆为全球油气开发生态圈企业提供线上宣传展示服务，对油气开发客户形成强大的吸引力，为参展商带来巨大的客户流量和品牌推广力，实现线上引流，线下交易。-->
            <!--                  </div>-->
            <!--                  <div class="consult" @click="expo">查看详情<i-->
            <!--                      class="el-icon-right" style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              &lt;!&ndash;        人才&ndash;&gt;-->
            <!--              <div class="grand2" @click="job">-->
            <!--                <div class="back">-->
            <!--                  <div class="photo">-->
            <!--                    <img loading="lazy" src="@/assets/icon02.png"/>-->
            <!--                  </div>-->
            <!--                  <div class="title1">油气人才</div>-->
            <!--                  <div class="title2">-->
            <!--                    油气人才是全球油气行业专业人才求职招聘平台，为求职者提供精准职位匹配，AI简历优化和在线沟通等服务！为招聘方提供高效招聘服务，优质油气人才，精准匹配。-->
            <!--                  </div>-->
            <!--                  <div class="consult" @click="job">查看详情<i class="el-icon-right"-->
            <!--                                                               style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->

          </div>

          <!--    油气专业GPTs    -->
          <div ref="professional" class="screen3" style="background-color: #1d3b72;color: white">
            <div v-sliden-in="{duration:600}" class="title">油气专业GPTs</div>
            <div v-sliden-in="{duration:600}" class="light" style="width: fit-content;color:white">
              油气专业GPTs人工智能模型的建立主要是基于安东等工程技术企业的应用，面向业界企业开放分享。
              <span class="light2"><br>因对其他应用场景的泛化能力不足，如需更精准的专业应用，请<span
                  style="color: #2F6EFF;cursor: pointer" @click="clickConsult('油气专业GPTs')">点击咨询</span>。</span>
            </div>
            <div class="pro2">
              <!--        油藏-->
            <a href="/gptDesign" target="_blank">
              <div  v-zoom-in="{duration:600}" class="grand2"  style="box-shadow: none">
                <!--              <div v-zoom-in="{duration:600}" class="grand2" @click="oilDesign()" style="box-shadow: none">-->
                <div class="back" style="position: relative">
                  <div class="photo">
                    <img class="image" src="@/assets/pro1.jpg" style="border-radius: 9px"/>
                    <div class="mengban" >
                      <div class="title1" style=" left: 4vh;">
                        设计类GPTs
                      </div>
                      <div class="title1" style="right: 1vh ;font-weight: unset;font-size: 1.21vw ">
                        更多 &nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                      </div>
                    </div>
                  </div>
                  <!--                  <div style="margin-left: 15px;">-->
                  <!--                    <div class="title1">Design-oriented GPTs</div>-->
                  <!--                    <div class="title2">-->
                  <!--                      Design-oriented GPTs taking into account various factors in the target region, a design plan is formulated to achieve rational development of Oil & Gas resources.-->
                  <!--                    </div>-->
                  <!--                  </div>-->

                </div>
              </div>
            </a>
              <!--        智能-->
              <a href="/gptTranslate" target="_blank">
              <div v-zoom-in="{duration:800}" class="grand2" >
                <div class="back" style="position: relative">
                  <div class="photo">
                    <img class="image" src="@/assets/pro7.jpg" style="border-radius: 9px"/>
                    <div class="mengban" >
                      <div class="title1" style=" left: 4vh;">
                        解释类GPTs
                      </div>
                      <div class="title1" style="right: 1vh ;font-weight: unset;font-size: 1.21vw ">
                        更多 &nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                      </div>
                    </div>
                  </div>
                  <!--                  <div style="margin-left: 15px;">-->
                  <!--                    <div class="title1"> Explanatory GPTs</div>-->
                  <!--                    <div class="title2">-->
                  <!--                      Explanatory GPTs based on the explanation of Oil & Gas development objectives, it not only facilitates employees to grasp operational information in real-time but also provides robust support for the development process.-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                </div>
              </div>
              </a>
              <!--        智能-->
              <a href="/gptTechnology" target="_blank">
              <div v-zoom-in="{duration:1000}" class="grand2" >
                <div class="back" style="position: relative">
                  <div class="photo">
                    <img class="image" src="@/assets/pro2.jpg" style="border-radius: 9px"/>
                    <div class="mengban" >
                      <div class="title1" style=" left: 4vh;">
                        工艺类GPTs
                      </div>
                      <div class="title1" style="right: 1vh ;font-weight: unset;font-size: 1.21vw ">
                        更多 &nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                      </div>
                    </div>
                  </div>
                  <!--                  <div style="margin-left: 15px;">-->
                  <!--                    <div class="title1">Process-related GPTs</div>-->
                  <!--                    <div class="title2">-->
                  <!--                      Process-related GPTs,not only helps improve development efficiency but also reduces risk losses, ensuring stable development.-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                </div>
              </div>
              </a>
            </div>
            <!--            <div class="pro2">-->
            <!--              &lt;!&ndash;        油藏&ndash;&gt;-->
            <!--              <div class="grand2" @click="oilDesign()">-->
            <!--                <div class="back">-->
            <!--                  <div class="photo">-->
            <!--                    <img loading="lazy" src="@/assets/pro11.png"-->
            <!--                         style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>-->
            <!--                  </div>-->
            <!--&lt;!&ndash;                  <div style="margin-left: 15px;">&ndash;&gt;-->
            <!--&lt;!&ndash;                    <div class="title1">设计类GPTs</div>&ndash;&gt;-->
            <!--&lt;!&ndash;                    <div class="title2">&ndash;&gt;-->
            <!--&lt;!&ndash;                      设计类GPTs，基于目标区域的地质条件、地层结构、工程要求、产量目标等多方面因素，制定出科学、合理、可行的设计方案，以实现油气资源的高效、安全、经济开发。&ndash;&gt;-->
            <!--&lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--&lt;!&ndash;                  </div>&ndash;&gt;-->

            <!--                </div>-->
            <!--              </div>-->
            <!--              &lt;!&ndash;        智能&ndash;&gt;-->
            <!--              <div class="grand2" @click="oilTranslate()">-->
            <!--                <div class="back">-->
            <!--                  <div class="photo">-->
            <!--                    <img loading="lazy" src="@/assets/pro12.png"-->
            <!--                         style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>-->
            <!--                  </div>-->
            <!--&lt;!&ndash;                  <div style="margin-left: 15px;">&ndash;&gt;-->
            <!--&lt;!&ndash;                    <div class="title1">解释类GPTs</div>&ndash;&gt;-->
            <!--&lt;!&ndash;                    <div class="title2">&ndash;&gt;-->
            <!--&lt;!&ndash;                      解释类GPTs，基于油气开发目标解释，不仅有助于工程作业人员实时掌握油气藏地质地质条件以及工程作业情况，还为开发过程中的决策和调整提供了有力支持。&ndash;&gt;-->
            <!--&lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--&lt;!&ndash;                  </div>&ndash;&gt;-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              &lt;!&ndash;        智能&ndash;&gt;-->
            <!--              <div class="grand2" @click="oilTechnology()">-->
            <!--                <div class="back">-->
            <!--                  <div class="photo">-->
            <!--                    <img loading="lazy" src="@/assets/pro13.png"-->
            <!--                         style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>-->
            <!--                  </div>-->
            <!--&lt;!&ndash;                  <div style="margin-left: 15px;">&ndash;&gt;-->
            <!--&lt;!&ndash;                    <div class="title1">工艺类GPTs</div>&ndash;&gt;-->
            <!--&lt;!&ndash;                    <div class="title2">&ndash;&gt;-->
            <!--&lt;!&ndash;                      工艺类GPTs，不仅有助于提高开发效果和采收率，同时降低开发过程中的风险损失，确保油气田的安全稳定开发。&ndash;&gt;-->
            <!--&lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--&lt;!&ndash;                  </div>&ndash;&gt;-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->

          </div>




          <!--    企业管理GPTs-->
          <div ref="menageService" class="screenGeneral" style="background-color: white">

            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:0 auto;text-align: center">
                <div v-sliden-in="{duration:600}" class="title">&nbsp;企业管理GPTs</div>
              </div>
            </div>
            <div v-sliden-in="{duration:600}" class="light" style="width: fit-content">
              企业管理GPTs人工智能是基于特定的企业环境训练。
              <span
                  class="light2"><br>因泛化能力不足，仅提供案例和展示服务，如需进一步交流，请<span
                  style="color: #2F6EFF;cursor: pointer" @click="clickConsult('企业管理GPTs')">点击咨询</span>。</span>
            </div>

            <div class="pro2" style="display: grid;grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);overflow-y: hidden;grid-column-gap: 1vw;grid-row-gap: 0.5vw">
<!--              <div ref="myswiperPC" class="swiper-container-pc">-->
<!--                <div class="swiper-wrapper"   >-->
<!--                  <div class="swiper-slide" @click="knowledge">-->
              <a href="/gptKnowledge" target="_blank">
                    <div v-zoom-in="{duration:600}" class="grand1" style="position: relative;height: fit-content" >
                      <div class="back">
                        <div class="photo">
                          <img class="image" style="border-radius: 9px" src="@/assets/knowledgenew1.jpg">
                        </div>
                      </div>
                      <div class="back1">
                        <div class="title1"> 企业内部管理类<br>知识GPT开发</div>
                        <div class="title2">
                          更多&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">

                        </div>
                      </div>
                    </div>
              </a>
<!--                  </div>-->
<!--                  <div class="swiper-slide" @click="datgptDataa">-->
                    <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
              <a href="/gptData" target="_blank">
                    <div v-zoom-in="{duration:800}" class="grand1" style="position: relative;height: fit-content" >
                      <div class="back">
                        <div class="photo">
                          <img class="image" style="border-radius: 9px" src="@/assets/knowledgenew2.png">
                        </div>
                      </div>
                      <div class="back1">
                        <div class="title1">企业管理数智化<br>整体解决方案</div>
                        <div class="title2">
                          更多&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                        </div>
                      </div>
                    </div>
              </a>
<!--                  </div>-->
<!--                  <div class="swiper-slide" @click="wisdom">-->
                    <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
              <a href="/wisdom" target="_blank">
                    <div v-zoom-in="{duration:1000}" class="grand1" style="position: relative;height: fit-content" >
                      <div class="back">
                        <div class="photo">
                          <img class="image" style="border-radius: 9px" src="@/assets/knowledgenew3.jpg">
                        </div>
                      </div>
                      <div class="back1">
                        <div class="title1">数据驱动智慧决策平台<br>设计及建设</div>
                        <div class="title2">
                          更多&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                        </div>
                      </div>
                    </div>
              </a>
<!--                  </div>-->
<!--                  <div class="swiper-slide" @click="design">-->
                    <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
              <a href="/design" target="_blank">
                    <div v-zoom-in="{duration:600}" class="grand1" style="position: relative;height: fit-content" >
                      <div class="back">
                        <div class="photo">
                          <img class="image" style="border-radius: 9px" src="@/assets/knowledgenew4.png">
                        </div>
                      </div>
                      <div class="back1">
                        <div class="title1"> 智慧员工社区<br>设计及建设</div>
                        <div class="title2">
                          更多&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">

                        </div>
                      </div>
                    </div>
              </a>
<!--                  </div>-->
<!--                  <div class="swiper-slide" @click="plat">-->
                    <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
              <a href="plat" target="_blank">
                    <div v-zoom-in="{duration:800}" class="grand1" style="position: relative;height: fit-content" >
                      <div class="back">
                        <div class="photo">
                          <img class="image" style="border-radius: 9px" src="@/assets/knowledgenew5.png">
                        </div>
                      </div>
                      <div class="back1">
                        <div class="title1"> 智慧电商平台设计<br>建设及运营</div>
                        <div class="title2">
                          更多&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">

                        </div>
                      </div>
                    </div>
              </a>
<!--                  </div>-->
<!--                  <div class="swiper-slide" @click="automation">-->
                    <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
              <a href="/automation" target="_blank">
                    <div v-zoom-in="{duration:1000}" class="grand1" style="position: relative;height: fit-content" >
                      <div class="back">
                        <div class="photo">
                          <img class="image" style="border-radius: 9px" src="@/assets/knowledgenew6.png">
                        </div>
                      </div>
                      <div class="back1">
                        <div class="title1"> 管理流程自动化平台<br>设计及建设</div>
                        <div class="title2">
                          更多&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                        </div>
                      </div>
                    </div>
              </a>
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="swiper-pagination"></div>-->
<!--              </div>-->
            </div>
            <!--            <div class="pro3">-->
            <!--              &lt;!&ndash;        油藏&ndash;&gt;-->

            <!--              &lt;!&ndash;        人力&ndash;&gt;-->
            <!--              <div class="grand2" @click="knowledge">-->
            <!--                <div class="back">-->
            <!--                  <div class="color">-->
            <!--                    <img loading="lazy" src="@/assets/qiye.png"/>-->
            <!--                  </div>-->
            <!--                  <div class="title1">-->
            <!--                    企业内部管理类知识GPT开发服务-->
            <!--                  </div>-->
            <!--                  <div class="title2">-->
            <!--                    为客户企业基于内部管理知识，构建知识类大语言模型，让员工快速便捷获取知识。-->
            <!--                  </div>-->
            <!--                  <div class="consult">产品咨询<i-->
            <!--                      class="el-icon-right" style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              &lt;!&ndash;        qhse&ndash;&gt;-->
            <!--              <div class="grand2" @click="data">-->
            <!--                <div class="back">-->
            <!--                  <div class="color">-->
            <!--                    <img loading="lazy" src="@/assets/zhushihua.png"/>-->
            <!--                  </div>-->
            <!--                  <div class="title1">企业管理数智化整体解决方案服务</div>-->
            <!--                  <div class="title2">-->
            <!--                    为客户企业提供基于业务的管理体系数智化架构的咨询、设计及规划，帮助客户进行重点项目的规划和方案设计。-->
            <!--                  </div>-->
            <!--                  <div class="consult" @click="data">产品咨询<i-->
            <!--                      class="el-icon-right" style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->

            <!--              &lt;!&ndash;法律&ndash;&gt;-->
            <!--              <div class="grand2" @click="wisdom">-->
            <!--                <div class="back">-->
            <!--                  <div class="color"><img loading="lazy" src="@/assets/zhihui.png"/>-->
            <!--                  </div>-->
            <!--                  <div class="title1"> 数据驱动智慧决策平台设计及建设服务</div>-->
            <!--                  <div class="title2">-->
            <!--                    帮助客户企业基于数据和人工智能模型规则，可视化的提前预警，智能预判，支持决策。-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                <div class="consult" @click="wisdom">产品咨询<i-->
            <!--                    class="el-icon-right" style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              &lt;!&ndash;        技术&ndash;&gt;-->
            <!--              <div class="grand1" @click="design">-->
            <!--                <div class="back">-->
            <!--                  <div class="color">-->
            <!--                    <img loading="lazy" src="@/assets/yuangong.png"/>-->
            <!--                  </div>-->
            <!--                  <div class="title1">智慧员工社区设计及建设服务</div>-->
            <!--                  <div class="title2">-->
            <!--                    帮助客户企业建设智慧化的员工社区，帮助员工利用各类人工智能办公工具，提升组织工作效率。-->
            <!--                  </div>-->
            <!--                  <div class="consult">产品咨询<i-->
            <!--                      class="el-icon-right" style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              &lt;!&ndash;        计划&ndash;&gt;-->
            <!--              <div class="grand1" @click="plat">-->
            <!--                <div class="back">-->
            <!--                  <div class="color">-->
            <!--                    <img loading="lazy" src="@/assets/dianshang.png"/>-->
            <!--                  </div>-->
            <!--                  <div class="title1">智慧电商平台设计、建设及运营服务</div>-->
            <!--                  <div class="title2">-->
            <!--                    帮助客户企业构建智能化的、对外的电子商务平台，支持客户开展线上的品牌和产品快速推广。-->
            <!--                  </div>-->
            <!--                  &lt;!&ndash;                <div class="consult">产品咨询<i&ndash;&gt;-->
            <!--                  &lt;!&ndash;                    class="el-icon-right" style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>&ndash;&gt;-->
            <!--                  &lt;!&ndash;                </div>&ndash;&gt;-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              &lt;!&ndash;        财务&ndash;&gt;-->
            <!--              <div class="grand1" @click="automation">-->
            <!--                <div class="back">-->
            <!--                  <div class="color">-->
            <!--                    <img loading="lazy" src="@/assets/liucheng.png"/>-->
            <!--                  </div>-->
            <!--                  <div class="title1">管理流程自动化平台设计及建设服务</div>-->
            <!--                  <div class="title2">-->
            <!--                    帮助客户企业实现企业流程不间断的、智能化的自动识别、核验、自动处理等服务。-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->

            <!--              &lt;!&ndash;            &lt;!&ndash;数智&ndash;&gt;&ndash;&gt;-->
            <!--              &lt;!&ndash;            <div class="grand1" @click="dialogVisible = true">&ndash;&gt;-->
            <!--              &lt;!&ndash;              <div class="back">&ndash;&gt;-->
            <!--              &lt;!&ndash;                <div class="photo">&ndash;&gt;-->
            <!--              &lt;!&ndash;                  <img loading="lazy" src="@/assets/icon13.png"/>&ndash;&gt;-->
            <!--              &lt;!&ndash;                </div>&ndash;&gt;-->
            <!--              &lt;!&ndash;                <div class="title1"> 数智技术GPTs</div>&ndash;&gt;-->
            <!--              &lt;!&ndash;                <div class="title2">&ndash;&gt;-->
            <!--              &lt;!&ndash;                  数智技术GPTs，集团数智化技术问答平台。基于集团数智化管理办法及SOP、OKR等智能化平台建设维护，准确解答相关数智化问题，提升办公效率。&ndash;&gt;-->
            <!--              &lt;!&ndash;                </div>&ndash;&gt;-->
            <!--              &lt;!&ndash;                <div class="consult" @click="dialogVisible = true">产品咨询<i&ndash;&gt;-->
            <!--              &lt;!&ndash;                    class="el-icon-right" style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>&ndash;&gt;-->
            <!--              &lt;!&ndash;                </div>&ndash;&gt;-->
            <!--              &lt;!&ndash;              </div>&ndash;&gt;-->
            <!--              &lt;!&ndash;            </div>&ndash;&gt;-->


            <!--              &lt;!&ndash;            &lt;!&ndash;多媒体&ndash;&gt;&ndash;&gt;-->
            <!--              &lt;!&ndash;            <div class="grand1" @click="dialogVisible = true">&ndash;&gt;-->
            <!--              &lt;!&ndash;              <div class="back">&ndash;&gt;-->
            <!--              &lt;!&ndash;                <div class="photo">&ndash;&gt;-->
            <!--              &lt;!&ndash;                  <img loading="lazy" src="@/assets/icon14.png"/>&ndash;&gt;-->
            <!--              &lt;!&ndash;                </div>&ndash;&gt;-->
            <!--              &lt;!&ndash;                <div class="title1">多媒体设计GPTs</div>&ndash;&gt;-->
            <!--              &lt;!&ndash;                <div class="title2">&ndash;&gt;-->
            <!--              &lt;!&ndash;                  多媒体设计GPTs，基于文本数据、图片数据、音频数据和视频数据，建立油气领域多媒体设计、营销、动画仿真等业务能力，提供多媒体营销领域的智能设计服务。&ndash;&gt;-->
            <!--              &lt;!&ndash;                </div>&ndash;&gt;-->
            <!--              &lt;!&ndash;                <div class="consult" @click="dialogVisible = true">产品咨询<i&ndash;&gt;-->
            <!--              &lt;!&ndash;                    class="el-icon-right" style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>&ndash;&gt;-->
            <!--              &lt;!&ndash;                </div>&ndash;&gt;-->
            <!--              &lt;!&ndash;              </div>&ndash;&gt;-->
            <!--              &lt;!&ndash;            </div>&ndash;&gt;-->
            <!--            </div>-->


          </div>

          <!--        通用类-->
          <div ref="generalMove"  class="screenConsultnew" style="background-color: white;padding-top: 0">

            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
                <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
                <!--              </div>-->
                <div v-sliden-in="{duration:600}" class="title">&nbsp;通用类GPTs</div>
              </div>
            </div>
            <div  v-sliden-in="{duration:600}" class="light">
              线上通用性GPTs，基于AI大模型能力，辅助用户改进工作效率。
              <span class="light2"><br>如想基于开放的通用性GPTs开展定制化的使用培训和开发，请<span
                  style="color: #2F6EFF;cursor: pointer" @click="clickConsult('通用类GPTs')">点击咨询</span>。</span>

            </div>
            <div class="pro4">

              <!--油田-->
              <div v-sliden-in="{duration:600}" class="grand2" @click="wen">
                <div class="back">
                  <div class="photo">
                    <img src="@/assets/wen.png"/>
                  </div>
                  <div class="title1">文心一言</div>
                  <!--                  <div class="title2">-->
                  <!--                    ERNIE Bot is Baidu's new generation of knowledge-enhanced large language models that can dialog and interact with people, answer questions, assist in creation, and efficiently and conveniently help people access information, knowledge, and inspiration.-->
                  <!--                  </div>-->
                </div>
                <div class="consult">
                  了解详情<i class="el-icon-right" style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>
                </div>
              </div>
              <!--        油藏-->
              <div v-sliden-in="{duration:1000}" class="grand2" @click="dou">
                <div class="back">
                  <div class="photo"><img src="@/assets/dou.png"/></div>
                  <div class="title1">豆包AI</div>
                  <!--                  <div class="title2">-->
                  <!--                    Grace is an AI tool developed by ByteDance based on the Skylark model, offering features such as a chatbot, a writing assistant, and an English learning assistant, which can help people access information by answering a variety of questions through dialog.-->
                  <!--                  </div>-->
                  <div class="consult">了解详情<i class="el-icon-right"
                                                  style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>
                  </div>
                </div>
              </div>

              <!--        智能-->
              <div v-sliden-in="{duration:1400}" class="grand2" @click="tong">
                <div class="back">
                  <div class="photo">
                    <img src="@/assets/tong.png"/>
                  </div>
                  <div class="title1">通义千问</div>
                  <!--                  <div class="title2">-->
                  <!--                    Tongyi Qianwen is a large language model independently developed by Aliyun, which can provide users with services and assistance in different fields and tasks through natural language understanding and semantic analysis on the basis of users' natural language input.-->
                  <!--                  </div>-->
                  <div class="consult">了解详情<i class="el-icon-right"
                                                  style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>
                  </div>
                </div>
              </div>
              <!--采油气-->
              <div v-sliden-in="{duration:1800}" class="grand2" @click="zhi">
                <div class="back">
                  <div class="photo">
                    <img src="@/assets/zhi.png"/>
                  </div>
                  <div class="title1">智谱清言</div>
                  <!--                  <div class="title2">-->
                  <!--                    The first generative AI assistant launched by Beijing Zhi Spectrum Huazhang Technology Co., Ltd, which is based on Zhi Spectrum AI's self-developed Chinese-English bilingual dialogue model ChatGLM2, pre-trained with trillions of characters of text and code, to provide users with intelligent services.-->
                  <!--                  </div>-->
                  <div class="consult">了解详情<i class="el-icon-right"
                                                  style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>
                  </div>
                </div>
              </div>

              <!--        智能-->
              <div v-sliden-in="{duration:2200}" class="grand2" @click="xun">
                <div class="back">
                  <div class="photo">
                    <img src="@/assets/xun.png"/>
                  </div>
                  <div class="title1">讯飞星火</div>
                  <!--                  <div class="title2">-->
                  <!--                    The new generation of cognitive intelligence big model launched by KU Xunfei has cross-domain knowledge and language understanding ability, which can understand and perform tasks based on natural dialog way, providing language understanding, knowledge question and answer and other abilities.-->
                  <!--                  </div>-->
                  <div class="consult">查看详情<i class="el-icon-right"
                                                  style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>
                  </div>
                </div>
              </div>

              <!--采油气-->
              <div v-sliden-in="{duration:600}" class="grand2" @click="teng">
                <div class="back">
                  <div class="photo">
                    <img src="@/assets/teng.png"/>
                  </div>
                  <div class="title1">腾讯混元</div>
                  <!--                  <div class="title2">-->
                  <!--                    Tencent Hunyuan is a big language model developed by Tencent, with cross-domain knowledge and natural language understanding capabilities, to achieve based on human-computer natural language dialogue, understand user instructions and perform tasks, to help users realize the human access to information, knowledge and inspiration.-->
                  <!--                  </div>-->
                  <div class="consult">查看详情<i class="el-icon-right"
                                                  style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>
                  </div>
                </div>
              </div>

              <!--采油气-->
              <div v-sliden-in="{duration:1000}" class="grand2" @click="tian">
                <div class="back">
                  <div class="photo">
                    <img src="@/assets/tian.png"/>
                  </div>
                  <div class="title1">天工AI</div>
                  <!--                  <div class="title2">-->
                  <!--                    Tiangong AI is able to realize a variety of application scenarios such as personalized AI search, intelligent Q&A, chat interaction, etc., and has a rich knowledge base covering the fields of science, technology, culture, art, history and so on.-->
                  <!--                  </div>-->
                  <div class="consult">查看详情<i class="el-icon-right"
                                                  style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>
                  </div>
                </div>
              </div>
              <!--采油气-->
              <div v-sliden-in="{duration:1400}" class="grand2" @click="ge">
                <div class="back">
                  <div class="photo">
                    <img src="@/assets/ge.png"/>
                  </div>
                  <div class="title1">文心一格</div>
                  <!--                  <div class="title2">-->
                  <!--                    Wenxin Yige is launched by Baidu relying on its Wenxin big model and flying paddle technology of text to generate pictures of A1 art and creative assistance platform, as the first echelon of the domestic AI picture generation tools and platforms.-->
                  <!--                  </div>-->
                  <div class="consult">查看详情<i class="el-icon-right"
                                                  style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>
                  </div>
                </div>
              </div>

              <!--采油气-->
              <div v-sliden-in="{duration:1800}" class="grand2" @click="wan">
                <div class="back">
                  <div class="photo">
                    <img src="@/assets/wan.png"/>
                  </div>
                  <div class="title1">万彩AI</div>
                  <!--                  <div class="title2">-->
                  <!--                    WanCai AI is a powerful collection of AI content creation tools. In addition to providing AI intelligent writing support, it also integrates powerful AI-generated content features such as AI face change, photo digital person creation and AI short video creation, which further expands the field of AI creation and gives you unlimited possibilities for your creation.-->
                  <!--                  </div>-->
                  <div class="consult">查看详情<i class="el-icon-right"
                                                  style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>
                  </div>
                </div>
              </div>

              <!--采油气-->
              <div v-sliden-in="{duration:2200}" class="grand2" @click="yi">
                <div class="back">
                  <div class="photo">
                    <img src="@/assets/yi.png"/>
                  </div>
                  <div class="title1">一帧秒创</div>
                  <!--                  <div class="title2">-->
                  <!--                    Yizhen TV is an intelligent AI content generation platform, which collects tools such as AI digital person, AI help writing, AI video, AI painting, etc. It supports multi-platform article one-key-to-video and digital person broadcasting video, and helps enterprises and self-media to create efficiently.-->
                  <!--                  </div>-->
                  <div class="consult">查看详情<i class="el-icon-right"
                                                  style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>
                  </div>
                </div>
              </div>

            </div>
            <!--            <div class="pro3">-->

            <!--              &lt;!&ndash;油田&ndash;&gt;-->
            <!--              <div class="grand2" @click="wen">-->
            <!--                <div class="back">-->
            <!--                  <div class="photo">-->
            <!--                    <img loading="lazy" src="@/assets/wen.png"/>-->
            <!--                  </div>-->
            <!--                  <div class="title1">文心一言</div>-->
            <!--                  <div class="title2">-->
            <!--                    文心一言是百度全新一代知识增强大语言模型，能够与人对话互动，回答问题，协助创作，高效便捷地帮助人们获取信息、知识和灵感。-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                <div class="consult">查看详情<i class="el-icon-right"-->
            <!--                                                style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              &lt;!&ndash;        油藏&ndash;&gt;-->
            <!--              <div class="grand2" @click="dou">-->
            <!--                <div class="back">-->
            <!--                  <div class="photo"><img loading="lazy" src="@/assets/dou.png"/></div>-->
            <!--                  <div class="title1">豆包AI</div>-->
            <!--                  <div class="title2">-->
            <!--                    豆包是字节跳动公司基于云雀模型开发的 AI 工具，提供聊天机器人、写作助手以及英语学习助手等功能，它可以回答各种问题并进行对话，帮助人们获取信息。-->
            <!--                  </div>-->
            <!--                  <div class="consult">查看详情<i class="el-icon-right"-->
            <!--                                                  style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->

            <!--              &lt;!&ndash;        智能&ndash;&gt;-->
            <!--              <div class="grand2" @click="tong">-->
            <!--                <div class="back">-->
            <!--                  <div class="photo">-->
            <!--                    <img loading="lazy" src="@/assets/tong.png"/>-->
            <!--                  </div>-->
            <!--                  <div class="title1">通义千问</div>-->
            <!--                  <div class="title2">-->
            <!--                    通义千问是阿里云自主研发的大语言模型，能够在用户自然语言输入的基础上，通过自然语言理解和语义分析，在不同领域、任务内为用户提供服务和帮助。-->
            <!--                  </div>-->
            <!--                  <div class="consult">查看详情<i class="el-icon-right"-->
            <!--                                                  style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              &lt;!&ndash;采油气&ndash;&gt;-->
            <!--              <div class="grand2" @click="zhi">-->
            <!--                <div class="back">-->
            <!--                  <div class="photo">-->
            <!--                    <img loading="lazy" src="@/assets/zhi.png"/>-->
            <!--                  </div>-->
            <!--                  <div class="title1">智谱清言</div>-->
            <!--                  <div class="title2">-->
            <!--                    由北京智谱华章科技有限公司推出的首款生成式AI助手，该助手基于智谱AI自主研发的中英双语对话模型ChatGLM2，经过万亿字符的文本与代码预训练，为用户提供智能化服务。-->
            <!--                  </div>-->
            <!--                  <div class="consult">查看详情<i class="el-icon-right"-->
            <!--                                                  style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->

            <!--              &lt;!&ndash;        智能&ndash;&gt;-->
            <!--              <div class="grand2" @click="xun">-->
            <!--                <div class="back">-->
            <!--                  <div class="photo">-->
            <!--                    <img loading="lazy" src="@/assets/xun.png"/>-->
            <!--                  </div>-->
            <!--                  <div class="title1">讯飞星火</div>-->
            <!--                  <div class="title2">-->
            <!--                    由科大讯飞推出的新一代认知智能大模型，拥有跨领域的知识和语言理解能力，能够基于自然对话方式理解与执行任务，提供语言理解、知识问答、逻辑推理、数学题解答等能力。-->
            <!--                  </div>-->
            <!--                  <div class="consult">查看详情<i class="el-icon-right"-->
            <!--                                                  style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->

            <!--              &lt;!&ndash;采油气&ndash;&gt;-->
            <!--              <div class="grand2" @click="teng">-->
            <!--                <div class="back">-->
            <!--                  <div class="photo">-->
            <!--                    <img loading="lazy" src="@/assets/teng.png"/>-->
            <!--                  </div>-->
            <!--                  <div class="title1">腾讯混元</div>-->
            <!--                  <div class="title2">-->
            <!--                    腾讯混元大模型是由腾讯研发的大语言模型，具备跨领域知识和自然语言理解能力，实现基于人机自然语言对话的方式，理解用户指令并执行任务，帮助用户实现人获取信息，知识和灵感。-->
            <!--                  </div>-->
            <!--                  <div class="consult">查看详情<i class="el-icon-right"-->
            <!--                                                  style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->

            <!--              &lt;!&ndash;采油气&ndash;&gt;-->
            <!--              <div class="grand2" @click="tian">-->
            <!--                <div class="back">-->
            <!--                  <div class="photo">-->
            <!--                    <img loading="lazy" src="@/assets/tian.png"/>-->
            <!--                  </div>-->
            <!--                  <div class="title1">天工AI</div>-->
            <!--                  <div class="title2">-->
            <!--                    天工AI能够实现个性化AI搜索、智能问答、聊天互动、文本生成、编写代码、语言翻译等多种应用场景，并且具有丰富的知识储备，涵盖科学、技术、文化、艺术、历史等领域。-->
            <!--                  </div>-->
            <!--                  <div class="consult">查看详情<i class="el-icon-right"-->
            <!--                                                  style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              &lt;!&ndash;采油气&ndash;&gt;-->
            <!--              <div class="grand2" @click="ge">-->
            <!--                <div class="back">-->
            <!--                  <div class="photo">-->
            <!--                    <img loading="lazy" src="@/assets/wen.png"/>-->
            <!--                  </div>-->
            <!--                  <div class="title1">文心一格</div>-->
            <!--                  <div class="title2">-->
            <!--                    文心一格是由百度推出的依托其文心大模型和飞桨技术的文本生成图片的 A1艺术和创意辅助平台，作为国内第一梯队的AI图片生成的工具和平台。-->
            <!--                  </div>-->
            <!--                  <div class="consult">查看详情<i class="el-icon-right"-->
            <!--                                                  style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->

            <!--              &lt;!&ndash;采油气&ndash;&gt;-->
            <!--              <div class="grand2" @click="wan">-->
            <!--                <div class="back">-->
            <!--                  <div class="photo">-->
            <!--                    <img loading="lazy" src="@/assets/wan.png"/>-->
            <!--                  </div>-->
            <!--                  <div class="title1">万彩AI</div>-->
            <!--                  <div class="title2">-->
            <!--                    万彩AI是一款强大的AI内容创作工具合集，除了提供AI智能写作支持之外，还集成了AI换脸、照片数字人制作和AI短视频制作等强大的AI生成内容功能，进一步扩展了AI的创作领域，使您的创作具有无限可能。-->
            <!--                  </div>-->
            <!--                  <div class="consult">查看详情<i class="el-icon-right"-->
            <!--                                                  style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->

            <!--              &lt;!&ndash;采油气&ndash;&gt;-->
            <!--              <div class="grand2" @click="yi">-->
            <!--                <div class="back">-->
            <!--                  <div class="photo">-->
            <!--                    <img loading="lazy" src="@/assets/yi.png"/>-->
            <!--                  </div>-->
            <!--                  <div class="title1">一帧秒创</div>-->
            <!--                  <div class="title2">-->
            <!--                    一帧秒创是智能AI内容生成平台，集合AI数字人、AI帮写、AI视频、AI作画等工具，支持多平台文章一键转视频和数字人播报视频，助力企业及自媒体高效创作。-->
            <!--                  </div>-->
            <!--                  <div class="consult">查看详情<i class="el-icon-right"-->
            <!--                                                  style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->

            <!--            </div>-->
          </div>

          <!--    油气开发作业GPT应用服务-->
          <div ref="moveProduct" class="screenConsult1" style="background-color: #22396f;color: white">

            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:0 auto;text-align: center">
                <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_second.png">-->
                <!--              </div>-->
                <div v-sliden-in="{duration:600}" class="title" style="">&nbsp;油气开发作业GPT应用服务</div>
              </div>
            </div>
            <div v-sliden-in="{duration:600}" class="light" style="width: fit-content;color: white">
              通过GPT辅助油气开发现场作业。
              <span class="light2"><br>因泛化能力有限，如需提升在现场作业过程中的使用效果，快速落地，请<span
                  style="color: #2F6EFF;cursor: pointer"
                  @click="clickConsult('油气开发作业GPT应用')">点击咨询</span>。</span>
            </div>
            <div class="pro12" style="display: grid;grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);overflow-y: hidden;grid-column-gap: 1vw;grid-row-gap: 0.5vw">
<!--              <div class="swiper-container-pc">-->
<!--                <div class="swiper-wrapper">-->
<!--                  <div class="swiper-slide" @click="ground">-->
              <a href="groundOil" target="_blank">
                    <div v-zoom-in="{duration:600}" class="grand1" style="position: relative;height: fit-content" >
                      <div class="back">
                        <div class="photo">
                          <img class="image" src="@/assets/pro1.png">
                        </div>
                      </div>
                      <div class="back1">
                        <div class="title1"> 智能油藏地质</div>
                        <div class="title2">
                          更多&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">

                        </div>
                      </div>
                    </div>
              </a>
<!--                  </div>-->
<!--                  <div class="swiper-slide" @click="hole">-->
                    <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
              <a href="/makeHole" target="_blank">
                    <div v-zoom-in="{duration:800}" class="grand1" style="position: relative;height: fit-content" >
                      <div class="back">
                        <div class="photo">
                          <img class="image" src="@/assets/pro2.png">
                        </div>
                      </div>
                      <div class="back1">
                        <div class="title1"> 智能钻井</div>
                        <div class="title2">
                          更多&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                        </div>
                      </div>
                    </div>
              </a>
<!--                  </div>-->
<!--                  <div class="swiper-slide" @click="frack">-->
                    <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
              <a href="/frackOil" target="_blank">
                    <div v-zoom-in="{duration:1000}" class="grand1" style="position: relative;height: fit-content" >
                      <div class="back">
                        <div class="photo">
                          <img class="image" src="@/assets/pro3.png">
                        </div>
                      </div>
                      <div class="back1">
                        <div class="title1"> 智能压裂</div>
                        <div class="title2">
                          更多&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                        </div>
                      </div>
                    </div>
              </a>
<!--                  </div>-->
<!--                  <div class="swiper-slide" @click="getOil">-->
                    <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
              <a href="getOil" target="_blank">
                    <div v-zoom-in="{duration:600}" class="grand1" style="position: relative;height: fit-content" >
                      <div class="back">
                        <div class="photo">
                          <img class="image" src="@/assets/pro4.png">
                        </div>
                      </div>
                      <div class="back1">
                        <div class="title1"> 智能采油气</div>
                        <div class="title2">
                          更多&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">

                        </div>
                      </div>
                    </div>
              </a>
<!--                  </div>-->
<!--                  <div class="swiper-slide" @click="monitor">-->
                    <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
              <a href="/monitorOil" target="_blank">
                    <div v-zoom-in="{duration:800}" class="grand1" style="position: relative;height: fit-content" >
                      <div class="back">
                        <div class="photo">
                          <img class="image" src="@/assets/pro7.png">
                        </div>
                      </div>
                      <div class="back1">
                        <div class="title1"> 地层监测</div>
                        <div class="title2">
                          更多&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">

                        </div>
                      </div>
                    </div>
              </a>
<!--                  </div>-->
<!--                  <div class="swiper-slide" @click="equipment">-->
                    <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
              <a href="/monitorEqu" target="_blank">
                    <div v-zoom-in="{duration:1000}" class="grand1" style="position: relative;height: fit-content" >
                      <div class="back">
                        <div class="photo">
                          <img class="image"  src="@/assets/pro6.png">
                        </div>
                      </div>
                      <div class="back1">
                        <div class="title1">设备设施监测</div>
                        <div class="title2">
                          更多&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                        </div>
                      </div>
                    </div>
              </a>
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="swiper-pagination"></div>-->
<!--              </div>-->
            </div>
            <!--            <div class="pro1">-->
            <!--              &lt;!&ndash;        油藏&ndash;&gt;-->
            <!--              <div class="grand1" @click="ground">-->
            <!--                <div class="back">-->
            <!--                  <div class="photo">-->
            <!--                    <img loading="lazy" src="@/assets/pro1.png"-->
            <!--                         style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>-->
            <!--                  </div>-->
            <!--                  <div class="title1">智能油藏地质<br>解决方案</div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              &lt;!&ndash;        智能&ndash;&gt;-->
            <!--              <div class="grand1" @click="hole">-->
            <!--                <div class="back">-->
            <!--                  <div class="photo">-->
            <!--                    <img loading="lazy" src="@/assets/pro2.png"-->
            <!--                         style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>-->
            <!--                  </div>-->
            <!--                  <div class="title1">智能钻井<br>解决方案</div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              &lt;!&ndash;        智能&ndash;&gt;-->
            <!--              <div class="grand1" @click="frack">-->
            <!--                <div class="back">-->
            <!--                  <div class="photo">-->
            <!--                    <img loading="lazy" src="@/assets/pro3.png"-->
            <!--                         style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>-->
            <!--                  </div>-->
            <!--                  <div class="title1">智能压裂<br>解决方案</div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              &lt;!&ndash;采油气&ndash;&gt;-->
            <!--              <div class="grand1" @click="getOil">-->
            <!--                <div class="back">-->
            <!--                  <div class="photo">-->
            <!--                    <img loading="lazy" src="@/assets/pro4.png"-->
            <!--                         style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>-->
            <!--                  </div>-->
            <!--                  <div class="title1">-->
            <!--                    智能采油气<br>解决方案-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              &lt;!&ndash;油田&ndash;&gt;-->
            <!--              &lt;!&ndash;            <div class="grand1" @click="menage">&ndash;&gt;-->
            <!--              &lt;!&ndash;              <div class="back">&ndash;&gt;-->
            <!--              &lt;!&ndash;                <div class="photo">&ndash;&gt;-->
            <!--              &lt;!&ndash;                  <img loading="lazy" style="border-top-right-radius: 9px;border-top-left-radius: 9px" src="@/assets/pro5.png" />&ndash;&gt;-->
            <!--              &lt;!&ndash;                </div>&ndash;&gt;-->
            <!--              &lt;!&ndash;                <div class="title1"> 智能油田<br>解决方案</div>&ndash;&gt;-->
            <!--              &lt;!&ndash;              </div>&ndash;&gt;-->
            <!--              &lt;!&ndash;            </div>&ndash;&gt;-->
            <!--              &lt;!&ndash;地层&ndash;&gt;-->
            <!--              <div class="grand1" @click="monitor">-->
            <!--                <div class="back">-->
            <!--                  <div class="photo">-->
            <!--                    <img loading="lazy" src="@/assets/pro7.png"-->
            <!--                         style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>-->
            <!--                  </div>-->
            <!--                  <div class="title1"> 地层监测<br>解决方案</div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              &lt;!&ndash;监测&ndash;&gt;-->
            <!--              <div class="grand1" @click="equipment">-->
            <!--                <div class="back">-->
            <!--                  <div class="photo">-->
            <!--                    <img loading="lazy" src="@/assets/pro6.png"-->
            <!--                         style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>-->
            <!--                  </div>-->
            <!--                  <div class="title1">-->
            <!--                    设备设施监测<br>解决方案-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
          <!--    数智油田解决方案GPT应用服务-->
          <div ref="consultMove" class="screenConsult1" style="background-color: white">

            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:0 auto;text-align: center">
                <!--              <div style="height:2.2vw;text-align: right;"><img loading="lazy"-->
                <!--                  src="@/assets/icon_second.png"></div>-->
                <div v-sliden-in="{duration:600}" class="title">数智油田解决方案GPT应用服务</div>
              </div>
            </div>
            <div v-sliden-in="{duration:600}" class="light" style="width:fit-content">
              利用人工智能技术，建设与地质、工程相结合的数智油田全场景解决方案，赋能数智油田全面升级。 <span
                class="light2">
              <br>如您想进行产品方案展示及交流，请<span style="color: #2F6EFF;cursor: pointer"
                                                       @click="clickConsult('数智油田解决方案GPT应用')">点击咨询</span>。
          </span>
            </div>
            <div class="pro3" style="grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);display: grid;overflow-y: hidden;grid-column-gap: 1vw;grid-row-gap: 0.5vw">
<!--              <div id="swiper-container" class="swiper-container-pc">-->
<!--                <div class="swiper-wrapper">-->
<!--                  <div class="swiper-slide" style="display: block">-->
              <a href="/station" target="_blank">
                    <div v-zoom-in="{duration:600}" class="grand1" style="position: relative;height: fit-content" >
                      <div class="back">
                        <div class="photo">
                          <img class="image" style="border-radius: 9px" src="@/assets/home1.png">
                        </div>
                      </div>
                      <div class="back1">
                        <div class="title1">智慧场站</div>
                        <div class="title2">
                          更多&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                        </div>
                      </div>
                    </div>
              </a>
              <a href="/security" target="_blank">
                    <div v-zoom-in="{duration:800}" class="grand1" style="position: relative;height: fit-content" >
                      <div class="back">
                        <div class="photo">
                          <img class="image" style="border-radius: 9px"  src="@/assets/home2.png">
                        </div>
                      </div>
                      <div class="back1">
                        <div class="title1">智慧安防</div>
                        <div class="title2">
                          更多&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                        </div>
                      </div>
                    </div>
              </a>
<!--                  </div>-->
<!--                  <div class="swiper-slide" style="display: block">-->
                    <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
              <a href="/product" target="_blank">
                    <div v-zoom-in="{duration:1000}" class="grand1" style="position: relative;height: fit-content" >
                      <div class="back">
                        <div class="photo">
                          <img class="image" style="border-radius: 9px"  src="@/assets/home3.png">
                        </div>
                      </div>
                      <div class="back1">
                        <div class="title1"> 油气生产智能管控</div>
                        <div class="title2">
                          更多&nbsp;<img style="width: 0.6vw;object-fit: contain;border-radius: 9px" src="@/assets/more_btn_icon.png">
                        </div>
                      </div>
                    </div>
              </a>

              <a href="/equipmentAnxun" target="_blank">
                    <div v-zoom-in="{duration:600}" class="grand1" style="position: relative;height: fit-content" >
                      <div class="back">
                        <div class="photo">
                          <img class="image" style="border-radius: 9px"  src="@/assets/home4.png">
                        </div>
                      </div>
                      <div class="back1">
                        <div class="title1">设备设施智能监测</div>
                        <div class="title2">
                          更多&nbsp;<img style="width: 0.6vw;object-fit: contain;border-radius: 9px" src="@/assets/more_btn_icon.png">
                        </div>
                      </div>
                    </div>
              </a>
<!--                  </div>-->
<!--                  <div class="swiper-slide" style="display: block">-->
                    <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
              <a href="/technology" target="_blank">
                    <div v-zoom-in="{duration:800}" class="grand1" style="position: relative;height: fit-content" >
                      <div class="back">
                        <div class="photo">
                          <img class="image" style="border-radius: 9px"   src="@/assets/home5.png">
                        </div>
                      </div>
                      <div class="back1">
                        <div class="title1"> 工艺大数据分析系统</div>
                        <div class="title2">
                          更多&nbsp;<img style="width: 0.6vw;object-fit: contain;border-radius: 9px" src="@/assets/more_btn_icon.png">
                        </div>
                      </div>
                    </div>
              </a>
              <a href="/monitorAnxun" target="_blank">
                    <div v-zoom-in="{duration:1000}" class="grand1" style="position: relative;height: fit-content" >
                      <div class="back">
                        <div class="photo">
                          <img class="image" style="border-radius: 9px"  src="@/assets/home6.png">
                        </div>
                      </div>
                      <div class="back1">
                        <div class="title1">石油工程远程监控指挥平台</div>
                        <div class="title2">
                          更多&nbsp;<img style="width: 0.6vw;object-fit: contain;border-radius: 9px" src="@/assets/more_btn_icon.png">
                        </div>
                      </div>
                    </div>
              </a>
<!--                  </div>-->
<!--                  <div class="swiper-slide" style="display: block">-->
                    <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
              <a href="/pipeline" target="_blank">
                    <div v-zoom-in="{duration:600}"  class="grand1" style="position: relative;height: fit-content" >
                      <div class="back">
                        <div class="photo">
                          <img class="image" style="border-radius: 9px"  src="@/assets/home7.png">
                        </div>
                      </div>
                      <div class="back1">
                        <div class="title1"> 智慧管网</div>
                        <div class="title2">
                          更多&nbsp;<img style="width: 0.6vw;object-fit: contain;border-radius: 9px" src="@/assets/more_btn_icon.png">
                        </div>
                      </div>
                    </div>
              </a>
              <a href="/basic" target="_blank">
                    <div v-zoom-in="{duration:800}" class="grand1" style="position: relative;height: fit-content" >
                      <div class="back">
                        <div class="photo">
                          <img class="image" style="border-radius: 9px"  src="@/assets/home8.png">
                        </div>
                      </div>
                      <div class="back1">
                        <div class="title1"> 智慧油气基础设施建设</div>
                        <div class="title2">
                          更多&nbsp;<img style="width: 0.6vw;object-fit: contain;border-radius: 9px" src="@/assets/more_btn_icon.png">
                        </div>
                      </div>
                    </div>
              </a>
<!--                  </div>-->
<!--                  <div class="swiper-slide" style="display: block">-->
                    <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
              <a href="/setting" target="_blank">
                    <div v-zoom-in="{duration:1000}" class="grand1" style="position: relative;height: fit-content" >
                      <div class="back">
                        <div class="photo">
                          <img class="image" style="border-radius: 9px"  src="@/assets/home9.png">
                        </div>
                      </div>
                      <div class="back1">
                        <div class="title1">油气数智化全场景<br>伴随式运维</div>
                        <div class="title2">
                          更多&nbsp;<img style="width: 0.6vw;object-fit: contain;border-radius: 9px" src="@/assets/more_btn_icon.png">

                        </div>
                      </div>
                    </div>
              </a>
              <a href="/storage" target="_blank">
                    <div v-zoom-in="{duration:600}" class="grand1" style="position: relative;height: fit-content" >
                      <div class="back">
                        <div class="photo">
                          <img class="image" style="border-radius: 9px"  src="@/assets/storage1.png">
                        </div>
                      </div>
                      <div class="back1">
                        <div class="title1">智慧储气库</div>
                        <div class="title2">
                          更多&nbsp;<img style="width: 0.6vw;object-fit: contain;border-radius: 9px" src="@/assets/more_btn_icon.png">

                        </div>
                      </div>
                    </div>
              </a>
<!--                  </div>-->
<!--                  <div class="swiper-slide" style="display: block">-->
                    <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
              <a href="/training" target="_blank">
                    <div v-zoom-in="{duration:800}" class="grand1" style="position: relative;height: fit-content" >
                      <div class="back">
                        <div class="photo">
                          <img class="image" src="@/assets/home11.png">
                        </div>
                      </div>
                      <div class="back1">
                        <div class="title1">智慧培训与应急<br>模拟演练平台</div>
                        <div class="title2">
                          更多&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                        </div>
                      </div>
                    </div>
              </a>
              <a href="/build" target="_blank">
                    <div v-zoom-in="{duration:1000}" class="grand1" style="position: relative;height: fit-content" >
                      <div class="back">
                        <div class="photo">
                          <img class="image" src="@/assets/home8.png">
                        </div>
                      </div>
                      <div class="back1">
                        <div class="title1">智能油气生产优化及<br>AI资讯建设
                        </div>
                        <div class="title2">
                          更多&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                        </div>
                      </div>
                    </div>
              </a>
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="swiper-pagination"></div>-->
<!--              </div>-->
            </div>
            <!--            <div class="pro2">-->
            <!--              &lt;!&ndash;        智能&ndash;&gt;-->
            <!--              <div class="grand1" @click="station">-->
            <!--                <div class="back">-->
            <!--                  <div class="photo">-->
            <!--                    <img loading="lazy" src="@/assets/home1.png" style="border-radius: 9px"/>-->
            <!--                  </div>-->
            <!--                  <div style="width: 34vw">-->
            <!--                    <div class="title1">智慧场站解决方案</div>-->
            <!--                    <div class="title2">-->
            <!--                      构建数智孪生场站系统，实现智能化生产管理，覆盖工艺、安全、巡检-->
            <!--                      、作业和设备等各环节。人机智能交互，确保生产安全，提升管控效率和作业品质。-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->

            <!--              </div>-->
            <!--              &lt;!&ndash;采油气&ndash;&gt;-->
            <!--              <div class="grand1" @click="security">-->
            <!--                <div class="back">-->
            <!--                  <div class="photo">-->
            <!--                    <img loading="lazy" src="@/assets/home2.png" style="border-radius: 9px"/>-->
            <!--                  </div>-->
            <!--                  <div style="width: 34vw">-->
            <!--                    <div class="title1">智慧安防解决方案</div>-->
            <!--                    <div class="title2">-->
            <!--                      从油田 HSSE 管理角度出发，设计全方位管理支持、全面安全控制和全面安全保障，涵盖安全教育、风险管控、隐患治理、视频智能安防、智能巡检、智慧消防等。-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              &lt;!&ndash;油田&ndash;&gt;-->
            <!--              <div class="grand1" @click="productANxun">-->
            <!--                <div class="back">-->
            <!--                  <div class="photo">-->
            <!--                    <img loading="lazy" src="@/assets/home3.png" style="border-radius: 9px"/>-->
            <!--                  </div>-->
            <!--                  <div style="width: 34vw">-->
            <!--                    <el-popover-->
            <!--                        content="油气生产智能管控解决方案"-->
            <!--                        placement="bottom-start"-->
            <!--                        trigger="hover"-->
            <!--                        width="260">-->
            <!--                      <div slot="reference" class="title1">油气生产智能管控解决方案</div>-->
            <!--                    </el-popover>-->
            <!--                    <div class="title2">-->
            <!--                      该平台以井站数据自动化采集、视频监控为依托，充分利用云计算、大数据等新技术，-->
            <!--                      实现油气生产动态实时感知、油气生产全流程监控、运行指挥精准高效等功能。-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->

            <!--              </div>-->
            <!--              &lt;!&ndash;        油藏&ndash;&gt;-->
            <!--              <div class="grand1" @click="equipmentAnxun">-->
            <!--                <div class="back">-->
            <!--                  <div class="photo">-->
            <!--                    <img loading="lazy" src="@/assets/home4.png" style="border-radius: 9px"/>-->
            <!--                  </div>-->
            <!--                  <div style="width: 32vw">-->
            <!--                    <el-popover-->
            <!--                        content="设备设施智能监测解决方案"-->
            <!--                        placement="bottom-start"-->
            <!--                        trigger="hover"-->
            <!--                        width="260">-->
            <!--                      <div slot="reference" class="title1">设备设施智能监测解决方案</div>-->
            <!--                    </el-popover>-->
            <!--                    <div class="title2">-->
            <!--                      利用工业物联网技术，监控设备数据，保障生产安全。预警规则助力监测、诊断和检修，确保设备安全。-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->

            <!--              </div>-->
            <!--              &lt;!&ndash;        智能&ndash;&gt;-->
            <!--              <div class="grand1" @click="technologyAnxun">-->
            <!--                <div class="back">-->
            <!--                  <div class="photo">-->
            <!--                    <img loading="lazy" src="@/assets/home5.png" style="border-radius: 9px"/>-->
            <!--                  </div>-->
            <!--                  <div style="width: 34vw">-->
            <!--                    <el-popover-->
            <!--                        content="工艺大数据分析系统解决方案"-->
            <!--                        placement="bottom-start"-->
            <!--                        trigger="hover"-->
            <!--                        width="260">-->
            <!--                      <div slot="reference" class="title1">工艺大数据分析系统解决方案</div>-->
            <!--                    </el-popover>-->
            <!--                    <div class="title2">-->
            <!--                      以实时数据和LIMS数据为核心的工艺管理平台，为油气场站生产建设提升盈利能力，实现效益最大化，提升企业核心竞争力。-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->

            <!--              </div>-->
            <!--              &lt;!&ndash;        智能&ndash;&gt;-->
            <!--              <div class="grand1" @click="monitorAnxun">-->
            <!--                <div class="back">-->
            <!--                  <div class="photo">-->
            <!--                    <img loading="lazy" src="@/assets/home6.png" style="border-radius: 9px"/>-->
            <!--                  </div>-->
            <!--                  <div style="width: 34vw">-->
            <!--                    <el-popover-->
            <!--                        content="石油工程远程监控指挥解决方案"-->
            <!--                        placement="bottom-start"-->
            <!--                        trigger="hover"-->
            <!--                        width="260">-->
            <!--                      <div slot="reference" class="title1">石油工程远程监控指挥解决方案</div>-->
            <!--                    </el-popover>-->
            <!--                    <div class="title2">-->
            <!--                      提供全新的石油工程平台一体化解决方案，实现作业现场全方位数据采集，监控现场设备状态及运行情况，包括钻井、录井、定向、随钻测井、压裂、完井等。-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->

            <!--              </div>-->
            <!--              &lt;!&ndash;采油气&ndash;&gt;-->
            <!--              <div class="grand1" @click="networkAnxun">-->
            <!--                <div class="back">-->
            <!--                  <div class="photo">-->
            <!--                    <img loading="lazy" src="@/assets/home7.png" style="border-radius: 9px"/>-->
            <!--                  </div>-->
            <!--                  <div style="width: 34vw">-->
            <!--                    <div class="title1">智慧管网解决方案</div>-->
            <!--                    <div class="title2">-->
            <!--                      建设数字基础设施，实现自动控制、数据分析和实时监控。创建数字平台和智能应用，管理管网全生命周期。增强移动性和安全性，提升管理便利性和管网安全性。-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->

            <!--              </div>-->
            <!--              &lt;!&ndash;油田&ndash;&gt;-->
            <!--              <div class="grand1" @click="basic">-->
            <!--                <div class="back">-->
            <!--                  <div class="photo">-->
            <!--                    <img loading="lazy" src="@/assets/home8.png" style="border-radius: 9px"/>-->
            <!--                  </div>-->
            <!--                  <div style="width: 34vw">-->
            <!--                    <div class="title1">智慧油气基础设施建设</div>-->
            <!--                    <div class="title2">-->
            <!--                      设施建设涵盖机房、数据中心等，提供自主软硬件产品，并专注系统集成与实施，以及维护和运维服务。-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->

            <!--              </div>-->
            <!--              &lt;!&ndash;        油藏&ndash;&gt;-->
            <!--              <div class="grand1" @click="setting">-->
            <!--                <div class="back">-->
            <!--                  <div class="photo">-->
            <!--                    <img loading="lazy" src="@/assets/home9.png" style="border-radius: 9px"/>-->
            <!--                  </div>-->
            <!--                  <div style="width: 34vw">-->
            <!--                    <el-popover-->
            <!--                        content="油气数智化全场景伴随式运维服务"-->
            <!--                        placement="bottom-start"-->
            <!--                        trigger="hover"-->
            <!--                        width="260">-->
            <!--                      <div slot="reference" class="title1">油气数智化全场景伴随式运维服务</div>-->
            <!--                    </el-popover>-->

            <!--                    <div class="title2">-->
            <!--                      专业化的服务交付团队，依据油气行业特性，管理需求，提供个性化、定制化、伴随式的数字化运维服务。-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->

            <!--              </div>-->
            <!--            &lt;!&ndash;        油藏&ndash;&gt;-->
            <!--            <div class="grand1" @click="storage">-->
            <!--              <div class="back">-->
            <!--                <div class="photo">-->
            <!--                  <img loading="lazy" src="@/assets/storage1.png" style="border-radius: 9px"/>-->
            <!--                </div>-->
            <!--                <div style="width: 34vw">-->
            <!--                  <el-popover-->
            <!--                      content="智慧储气库解决方案"-->
            <!--                      placement="bottom-start"-->
            <!--                      trigger="hover"-->
            <!--                      width="260">-->
            <!--                    <div slot="reference" class="title1">智慧储气库解决方案</div>-->
            <!--                  </el-popover>-->

            <!--                  <div class="title2">-->
            <!--                    通过整合先进的传感器技术、自动化控制系统和智能分析算法，实现对储气库全生命周期的智能化管理，降低运营风险，增强竞争力。-->
            <!--                  </div>-->
            <!--                </div>-->

            <!--              </div>-->
            <!--            </div>-->
            <!--            &lt;!&ndash;        油藏&ndash;&gt;-->
            <!--            <div class="grand1" @click="training">-->
            <!--              <div class="back">-->
            <!--                <div class="photo">-->
            <!--                  <img loading="lazy" src="@/assets/training1.png" style="border-radius: 9px"/>-->
            <!--                </div>-->
            <!--                <div style="width: 34vw">-->
            <!--                  <el-popover-->
            <!--                      content="智慧培训与应急模拟演练平台"-->
            <!--                      placement="bottom-start"-->
            <!--                      trigger="hover"-->
            <!--                      width="260">-->
            <!--                    <div slot="reference" class="title1">智慧培训与应急模拟演练平台</div>-->
            <!--                  </el-popover>-->

            <!--                  <div class="title2">-->
            <!--                    通过先进的虚拟现实（VR）、人工智能和数据建模技术，为客户提供一个全面、灵活且安全的培训环境。-->

            <!--                  </div>-->
            <!--                </div>-->

            <!--              </div>-->
            <!--            </div>-->
            <!--              &lt;!&ndash;        油藏&ndash;&gt;-->
            <!--              <div class="grand1" @click="build">-->
            <!--                <div class="back">-->
            <!--                  <div class="photo">-->
            <!--                    <img loading="lazy" src="@/assets/home11.png" style="border-radius: 9px;object-fit:contain"/>-->
            <!--                  </div>-->
            <!--                  <div style="width: 34vw">-->
            <!--                    <el-popover-->
            <!--                        content="智能油气生产优化及AI资讯建设服务"-->
            <!--                        placement="bottom-start"-->
            <!--                        trigger="hover"-->
            <!--                        width="260">-->
            <!--                      <div slot="reference" class="title1">智能油气生产优化及AI资讯建设服务</div>-->
            <!--                    </el-popover>-->

            <!--                    <div class="title2">-->
            <!--                      依托人工智能、大数据分析和工业物联网等先进技术，提供全流程生产优化和智能决策支持，帮助企业提升生产效率、降低运营成本，灵活应对多变的市场环境，为决策者和技术人员提供精准的行业资讯，助力科学决策和持续创新。-->
            <!--                    </div>-->
            <!--                  </div>-->

            <!--                </div>-->


            <!--              </div>-->


            <!--            </div>-->
          </div>
          <!--        多媒体-->
          <div ref="mediaMove" class="screen4" style="background-color: #26376d;color: white">
            <div v-sliden-in="{duration:600}" class="title">智能多媒体服务</div>
            <div v-sliden-in="{duration:600}" class="light" style="color:white">
              帮助企业客户提供油气行业智能多媒体服务，让油气世界更加有声有型。提供科学可视化、营销推广及品牌优化服务，满足客户技术宣传、品牌推广、培训、竟赛和科研汇报等方面的多媒体需求。
              <span class="light2">
            <br>如您想进行产品方案展示及交流，请<span style="color: #2F6EFF;cursor: pointer"
                                                     @click="clickConsult('智能多媒体')">点击咨询</span>。

            </span>
            </div>
            <div class="pro2">
              <!--        油藏-->
              <div v-zoom-in="{duration:600}" class="grand2" @click="meitiProduct()">
                <div class="back">
                  <div class="photo" style="height: 40vh">
                    <img class="image" loading="lazy" src="@/assets/meiti1.png"
                         style="border-radius: 9px;"/>
                    <div class="mengban">
                      <div class="title1" style=" left: 4vh;">
                        宣传片制作
                      </div>
                      <div class="title1" style="right: 1vh ;font-weight: unset;font-size: 1.21vw ">
                        更多 &nbsp;<img style="width: 0.6vw;object-fit: contain;height: unset" src="@/assets/more_btn_icon.png">
                      </div>
                    </div>
                  </div>
                  <!--                  <div style="margin-left: 15px;">-->
                  <!--                    <div class="title1">Promotional Videos</div>-->
                  <!--                    <div class="title2">Dig deeper into your brand story-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                </div>
              </div>
              <!--        智能-->
              <div v-zoom-in="{duration:800}" class="grand2" @click="meiti3D()">
                <div class="back">
                  <div class="photo" style="height: 40vh">
                    <img class="image" loading="lazy" src="@/assets/meiti2.png"
                         style="border-radius: 9px;"/>
                    <div class="mengban">
                      <div class="title1" style=" left: 4vh;">
                        三维动画
                      </div>
                      <div class="title1" style="right: 1vh ;font-weight: unset;font-size: 1.21vw ">
                        更多&nbsp;<img style="width: 0.6vw;object-fit: contain;height: unset" src="@/assets/more_btn_icon.png">
                      </div>
                    </div>
                  </div>
                  <!--                  <div style="margin-left: 15px;">-->
                  <!--                    <div class="title1">3D Animation</div>-->
                  <!--                    <div class="title2">-->
                  <!--                      Let your technology and products be presented in three dimensions</div>-->
                  <!--                  </div>-->
                </div>
              </div>
              <!--        智能-->
              <div v-zoom-in="{duration:1000}" class="grand2" @click="meitiDesign()">
                <div class="back">
                  <div class="photo" style="height: 40vh">
                    <img class="image" loading="lazy" src="@/assets/meiti3.jpg"
                         style="border-radius: 9px;"/>
                    <div class="mengban">
                      <div class="title1" style=" left: 4vh;">
                        平面设计
                      </div>
                      <div class="title1" style="right: 1vh ;font-weight: unset;font-size: 1.21vw ">
                        更多&nbsp;<img style="width: 0.6vw;object-fit: contain;height: unset" src="@/assets/more_btn_icon.png">
                      </div>
                    </div>
                  </div>
                  <!--                  <div style="margin-left: 15px;">-->
                  <!--                    <div class="title1">Graphic Design</div>-->
                  <!--                    <div class="title2">-->
                  <!--                      Brightly color the Oil & Gas industry with aesthetically pleasing visual designs</div>-->
                  <!--                  </div>-->
                </div>
              </div>
            </div>

            <!--              <div class="pro2">-->
            <!--                &lt;!&ndash;        油藏&ndash;&gt;-->
            <!--                <div class="grand2" @click="meitiProduct()">-->
            <!--                  <div class="back">-->
            <!--                    <div class="photo" style="height: 32vh">-->
            <!--                      <img loading="lazy" src="@/assets/meiti1.png"-->
            <!--                           style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>-->
            <!--                    </div>-->
            <!--                    <div style="margin-left: 15px;">-->
            <!--                      <div class="title1">宣传片制作</div>-->
            <!--                      <div class="title2">深入挖掘您的品牌故事-->
            <!--                      </div>-->
            <!--                    </div>-->

            <!--                  </div>-->
            <!--                </div>-->
            <!--                &lt;!&ndash;        智能&ndash;&gt;-->
            <!--                <div class="grand2" @click="meiti3D()">-->
            <!--                  <div class="back">-->
            <!--                    <div class="photo" style="height: 32vh">-->
            <!--                      <img loading="lazy" src="@/assets/meiti2.png"-->
            <!--                           style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>-->
            <!--                    </div>-->
            <!--                    <div style="margin-left: 15px;">-->
            <!--                      <div class="title1">三维动画</div>-->
            <!--                      <div class="title2">-->
            <!--                        让您的技术和产品立体呈现-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                &lt;!&ndash;        智能&ndash;&gt;-->
            <!--                <div class="grand2" @click="meitiDesign()">-->
            <!--                  <div class="back">-->
            <!--                    <div class="photo" style="height: 32vh">-->
            <!--                      <img loading="lazy" src="@/assets/meiti3.jpg"-->
            <!--                           style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>-->
            <!--                    </div>-->
            <!--                    <div style="margin-left: 15px;">-->
            <!--                      <div class="title1">平面设计</div>-->
            <!--                      <div class="title2">-->
            <!--                        用极具美感的可视化设计为油气行业鲜艳上色-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->


          </div>

          <!--人工智能全员普及培训及转型咨询服务-->
          <div ref="aiPeixun" class=" screen8" style="background-color: white">
            <div v-sliden-in="{duration:600}" class="title">人工智能全员普及培训及转型咨询服务</div>
            <div v-sliden-in="{duration:600}" class="light" style="text-align: justify">
              帮助客户企业全员可以使用在线的、开放的大模型人工智能工具，成为人工智能的驾驶员。帮助客户实现人工转型规划制定、项目方案设计、专家咨询服务，提升客户企业竞争力。
              <span class="light2"><br>如您有培训或咨询需求，请<span
                  style="color: #2F6EFF;cursor: pointer"
                  @click="clickConsult('人工智能普及培训及转型服务')">点击咨询</span>。</span></div>
            <div class="pro2">
              <!--        油藏-->
              <a href="/traning" target="_blank">
                  <div v-zoom-in="{duration:600}" class="grand2" >
                <div class="back">
                  <div class="photo" style="height: 50.123vh">
                    <img class="image" loading="lazy" src="@/assets/puji2.jpg"
                         style="border-radius: 9px;"/>
                    <div class="mengban">
                      <div class="title1" style=" left: 1vh;">
                        人工智能全员普及应用培训
                      </div>
                      <div class="title1" style="right: 1vh;font-weight: unset;font-size: 1.21vw; ">
                        更多 &nbsp;<img style="width: 0.6vw;object-fit: contain;height: unset" src="@/assets/more_btn_icon.png">
                      </div>
                    </div>
                  </div>
                  <!--                  <div style="margin-left: 15px;">-->
                  <!--                    <div class="title1">Promotional Videos</div>-->
                  <!--                    <div class="title2">Dig deeper into your brand story-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                </div>
              </div>
              </a>
              <!--        智能-->
              <a href="/consult" target="_blank">
              <div v-zoom-in="{duration:800}" class="grand2" >
                <div class="back">
                  <div class="photo" style="height: 50.123vh">
                    <img class="image" loading="lazy" src="@/assets/puji1.png"
                         style="border-radius: 9px;"/>
                    <div class="mengban">
                      <div class="title1" style=" left: 1vh;">
                        企业人工智能转型咨询
                      </div>
                      <div class="title1" style="right: 1vh;font-weight: unset;font-size:  1.21vw; ">
                        更多 &nbsp;<img style="width: 0.6vw;object-fit: contain;height: unset" src="@/assets/more_btn_icon.png">
                      </div>
                    </div>
                  </div>
                  <!--                  <div style="margin-left: 15px;">-->
                  <!--                    <div class="title1">3D Animation</div>-->
                  <!--                    <div class="title2">-->
                  <!--                      Let your technology and products be presented in three dimensions</div>-->
                  <!--                  </div>-->
                </div>
              </div>
              </a>
            </div>
            <!--              <div style="margin:auto;width: 100%;height:45vh;;display: flex">-->
            <!--                <div class="AIimg" style="width: 50%;height: 100%">-->
            <!--                  <img src="@/assets/puji2.png" style="border-radius: 9px" height="100%">-->
            <!--                </div>-->
            <!--                <div class="AIpoj" style="width: 50%;height: 100%">-->
            <!--                  <div class="poj" style="" @click="traningAi">-->
            <!--                    <div style="font-size: 1vw;font-weight: bold;position: absolute;top:14%;left:5%">-->
            <!--                      人工智能全员普及应用培训-->
            <!--                    </div>-->
            <!--                    <div class="pojimg" style="font-size: 1vw;font-weight: bold;position: absolute;bottom:14%;right:5%">-->
            <!--                      更多&nbsp;<img class="imgs" style="width: 1.2vw;object-fit: contain;color: black"-->
            <!--                                     src="@/assets/more_btn_icon.png_black.png"/>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                  <div class="poj" style="" @click="consult">-->
            <!--                    <div style="font-size: 1vw;font-weight: bold;position: absolute;top:14%;left:5%">-->
            <!--                      企业人工智能转型咨询-->
            <!--                    </div>-->
            <!--                    <div class="pojimg" style="font-size: 1vw;font-weight: bold;position: absolute;bottom:14%;right:5%">-->
            <!--                      更多&nbsp;<img class="imgs" style="width: 1.2vw;object-fit: contain"-->
            <!--                                     src="@/assets/more_btn_icon.png_black.png"/>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->


            <!--              <div class="pro2">-->

            <!--                &lt;!&ndash;        智能&ndash;&gt;-->
            <!--                <div ref="station" class="grand3" @click="traningAi">-->
            <!--                  <div class="back">-->
            <!--                    <div class="photo">-->
            <!--                      <img loading="lazy" src="@/assets/puji2.png"-->
            <!--                           style="border-top-left-radius: 6px;border-bottom-left-radius: 6px"/>-->
            <!--                    </div>-->
            <!--                    <div style="width: 100%;background-color: white;border-radius: 9px">-->
            <!--                      <div class="title1">-->
            <!--                        人工智能全员普及应用培训-->
            <!--                      </div>-->
            <!--                      <div class="title2">-->
            <!--                        帮助客户企业全员全面提升使用人工智能技术、工具开展工作的技能。-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                &lt;!&ndash;采油气&ndash;&gt;-->
            <!--                <div class="grand3" @click="consult">-->
            <!--                  <div class="back">-->
            <!--                    <div class="photo">-->
            <!--                      <img loading="lazy" src="@/assets/puji1.png"-->
            <!--                           style="border-top-left-radius: 6px;border-bottom-left-radius: 6px"/>-->
            <!--                    </div>-->
            <!--                    <div style="width: 100%;background-color: white;border-radius: 9px">-->
            <!--                      <div class="title1">企业人工智能转型咨询</div>-->
            <!--                      <div class="title2">-->
            <!--                        帮助客户企业整体人工智能转型规划、方案制定，梳理人工智能转型全场景，从而支持客户人工智能转型全面落地。-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                  </div>-->

            <!--                </div>-->


            <!--              </div>-->

          </div>
          <!--油气企业自建GPT云平台服务-->
          <div ref="companyZijian" class=" screen10" style="padding-top: 0">
            <div v-sliden-in="{duration:600}" class="title">油气企业自建GPT云平台服务</div>
            <div v-sliden-in="{duration:600}" class="light">
              帮助企业客户建设私有环境下的人工智能云平台，积累各应用场景的模型成果，实现模型的最大化共享和算力资源的充分使用。
              <span class="light2"><br>如您想在私有环境下自建GPT云平台，请<span
                  style="color: #2F6EFF;cursor: pointer" @click="clickConsult('企业自建GPT')">点击咨询</span>。</span>
            </div>

            <div class="pro12" style="display: grid;grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr);overflow-y: hidden">
<!--              <div class="swiper-container-pc">-->
<!--                <div class="swiper-wrapper">-->
<!--                  <div class="swiper-slide" @click="constructPlat">-->
              <a href="/construct" target="_blank">
                    <div v-zoom-in="{duration:600}" class="grand1" style="position: relative;height: fit-content" >
                      <div class="back">
                        <div class="photo">
                          <img class="image" src="@/assets/zijian1.png">
                        </div>
                      </div>
                      <div class="back1">
                        <div class="title1"> 油气企业大模型云平台规划及建设</div>
                        <div class="title2">
                          更多&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">

                        </div>
                      </div>
                    </div>
              </a>
<!--                  </div>-->
<!--                  <div class="swiper-slide" @click="business">-->
                    <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
              <a href="/business" target="_blank">
                    <div v-zoom-in="{duration:800}" class="grand1" style="position: relative;height: fit-content" >
                      <div class="back">
                        <div class="photo">
                          <img class="image" src="@/assets/zijian2.png">
                        </div>
                      </div>
                      <div class="back1">
                        <div class="title1">业务GPTs项目服务</div>
                        <div class="title2">
                          更多&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                        </div>
                      </div>
                    </div>
              </a>
<!--                  </div>-->
<!--                  <div class="swiper-slide" @click="company">-->
                    <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
              <a href="/company" target="_blank">
                    <div v-zoom-in="{duration:600}" class="grand1" style="position: relative;height: fit-content" >
                      <div class="back">
                        <div class="photo">
                          <img class="image" src="@/assets/zijian3.png">
                        </div>
                      </div>
                      <div class="back1">
                        <div class="title1">  油气企业数据（知识）治理</div>
                        <div class="title2">
                          更多&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                        </div>
                      </div>
                    </div>
              </a>
<!--                  </div>-->
<!--                  <div class="swiper-slide" @click="computingPower">-->
                    <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
              <a href="/computingPower" target="_blank">
                    <div v-zoom-in="{duration:800}" class="grand1" style="position: relative;height: fit-content" >
                      <div class="back">
                        <div class="photo">
                          <img class="image" src="@/assets/zijian4.png">
                        </div>
                      </div>
                      <div class="back1">
                        <div class="title1"> 基础算力优化</div>
                        <div class="title2">
                          更多&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">

                        </div>
                      </div>
                    </div>
              </a>
<!--                  </div>-->

<!--                </div>-->
<!--                <div class="swiper-pagination"></div>-->
<!--              </div>-->
            </div>

            <!--              <div class="pro1">-->
            <!--                <div class="card">-->
            <!--                  <div class="back" @click="constructPlat">-->
            <!--                    <div class="self-build1" style="margin: 10% auto;height: 14vh;width: fit-content;">-->
            <!--                      <img src="@/assets/bigmodel.png">-->
            <!--                    </div>-->
            <!--                    <div style="font-size: 1vw;font-weight: bold;margin: 0 auto 5% auto;text-align: center;height: 20%;display: flex;width: fit-content">-->
            <!--                      油气企业大模型云平台规划<br>及建设服务-->
            <!--                    </div>-->
            <!--                    <div style="font-size: 1vw;font-weight: bold;text-align: center;margin-bottom:10%">-->
            <!--                      更多 <img class="self-build11" style="width: 1.2vw;object-fit: contain;color: black"-->
            <!--                                src="@/assets/more_btn_icon.png_black.png"/>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                <div class="card" style="height: 100%;">-->
            <!--                  <div class="back" @click="business">-->
            <!--                    <div class="self-build2" style="margin: 10% auto;height: 14vh;width: fit-content;">-->
            <!--                      <img src="@/assets/business.png">-->
            <!--                    </div>-->
            <!--                    <div style="width:fit-content;align-content: center;;font-size: 1vw;font-weight: bold;margin: 0 auto 5% auto ;text-align: center;height: 20%;display: flex;-->
            <!--    align-items: center;">-->
            <!--                      业务GPTs项目服务-->
            <!--                    </div>-->
            <!--                    <div style="font-size: 1vw;font-weight: bold;text-align: center;margin-bottom:10%">-->
            <!--                      更多 <img class="self-build11" style="width: 1.2vw;object-fit: contain;color: black"-->
            <!--                                src="@/assets/more_btn_icon.png_black.png"/>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                <div class="card" style="height: 100%;">-->
            <!--                  <div class="back" @click="company">-->
            <!--                    <div class="self-build3" style="margin: 10% auto;height: 14vh;width: fit-content;">-->
            <!--                      <img src="@/assets/knowledgeicon.png">-->
            <!--                    </div>-->
            <!--                    <div style="align-content: center;font-size: 1vw;font-weight: bold;margin: 0 auto 5% ;text-align: center;height: 20%;display: flex;-->
            <!--    align-items: center;width: fit-content">-->
            <!--                      油气企业数据（知识）治理服务-->
            <!--                    </div>-->
            <!--                    <div style="font-size: 1vw;font-weight: bold;text-align: center;margin-bottom:10%">-->
            <!--                      更多 <img class="self-build11" style="width: 1.2vw;object-fit: contain;color: black"-->
            <!--                                src="@/assets/more_btn_icon.png_black.png"/>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                <div class="card" style="height: 100%;">-->
            <!--                  <div class="back" @click="computingPower">-->
            <!--                    <div class="self-build4" style="margin: 10% auto;height: 14vh;width: fit-content;">-->
            <!--                      <img src="@/assets/CPU.png">-->
            <!--                    </div>-->
            <!--                    <div style="font-size: 1vw;font-weight: bold;margin: 0 auto 5% ;text-align: center;height: 20%;align-content: center;display: flex;-->
            <!--    align-items: center;width: fit-content">-->
            <!--                      基础算力优化服务-->
            <!--                    </div>-->
            <!--                    <div style="font-size: 1vw;font-weight: bold;text-align: center;margin-bottom:10%">-->
            <!--                      更多 <img class="self-build11" style="width: 1.2vw;object-fit: contain;color: black"-->
            <!--                                src="@/assets/more_btn_icon.png_black.png"/>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <div class="pro2">-->

            <!--                &lt;!&ndash;        智能&ndash;&gt;-->
            <!--                <div ref="station" class="grand3" @click="constructPlat">-->
            <!--                  <div class="back">-->
            <!--                    <div class="photo">-->
            <!--                      <img loading="lazy" src="@/assets/zijian1.png"-->
            <!--                           style="border-top-left-radius: 6px;border-top-right-radius: 6px"/>-->
            <!--                    </div>-->
            <!--                    <div>-->
            <!--                      <div class="title1">-->
            <!--                        油气企业大模型云平台<br>规划及建设服务-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                  </div>-->

            <!--                </div>-->
            <!--                &lt;!&ndash;采油气&ndash;&gt;-->
            <!--                <div class="grand2" @click="business">-->
            <!--                  <div class="back">-->
            <!--                    <div class="photo">-->
            <!--                      <img loading="lazy" src="@/assets/zijian2.png"-->
            <!--                           style="border-top-left-radius: 6px;border-top-right-radius: 6px"/>-->
            <!--                    </div>-->
            <!--                    <div>-->
            <!--                      <div class="title1">业务GPTs项目服务</div>-->
            <!--                    </div>-->
            <!--                  </div>-->

            <!--                </div>-->
            <!--                &lt;!&ndash;油田&ndash;&gt;-->
            <!--                <div class="grand1" @click="company">-->
            <!--                  <div class="back">-->
            <!--                    <div class="photo">-->
            <!--                      <img loading="lazy" src="@/assets/zijian3.png"-->
            <!--                           style="border-top-left-radius: 6px;border-top-right-radius: 6px"/>-->
            <!--                    </div>-->
            <!--                    <div>-->
            <!--                      <div class="title1">油气企业数据<br>（知识）治理服务</div>-->
            <!--                    </div>-->
            <!--                  </div>-->

            <!--                </div>-->
            <!--                &lt;!&ndash;        油藏&ndash;&gt;-->
            <!--                <div class="grand3" @click="computingPower">-->
            <!--                  <div class="back">-->
            <!--                    <div class="photo">-->
            <!--                      <img loading="lazy" src="@/assets/zijian4.png"-->
            <!--                           style="border-top-left-radius: 6px;border-top-right-radius: 6px"/>-->
            <!--                    </div>-->
            <!--                    <div>-->
            <!--                      <div class="title1">基础算力优化服务</div>-->
            <!--                    </div>-->
            <!--                  </div>-->

            <!--                </div>-->


            <!--                &lt;!&ndash;            &lt;!&ndash;        智能&ndash;&gt;&ndash;&gt;-->
            <!--                &lt;!&ndash;            <div class="grand2" @click="application" ref="technology">&ndash;&gt;-->
            <!--                &lt;!&ndash;              <div class="back">&ndash;&gt;-->
            <!--                &lt;!&ndash;&lt;!&ndash;                <div class="photo">&ndash;&gt;&ndash;&gt;-->
            <!--                &lt;!&ndash;&lt;!&ndash;                  <img loading="lazy" src="@/assets/shuzhi.png" style="border-radius: 6px"/>&ndash;&gt;&ndash;&gt;-->
            <!--                &lt;!&ndash;&lt;!&ndash;                </div>&ndash;&gt;&ndash;&gt;-->
            <!--                &lt;!&ndash;                <div  >&ndash;&gt;-->
            <!--                &lt;!&ndash;                  <div class="title1">数智油田GPT开发应用项目服务</div>&ndash;&gt;-->
            <!--                &lt;!&ndash;                  <div class="title2">利用人工智能技术，建设与地质、工程相结合的数智油田全场景解决方案，赋能数智油田全面升级。</div>&ndash;&gt;-->
            <!--                &lt;!&ndash;                </div>&ndash;&gt;-->
            <!--                &lt;!&ndash;                <div class="details">查看详情<i class="el-icon-right" style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>&ndash;&gt;-->
            <!--                &lt;!&ndash;                </div>&ndash;&gt;-->
            <!--                &lt;!&ndash;              </div>&ndash;&gt;-->

            <!--                &lt;!&ndash;            </div>&ndash;&gt;-->
            <!--                &lt;!&ndash;            &lt;!&ndash;        智能&ndash;&gt;&ndash;&gt;-->
            <!--                &lt;!&ndash;            <div class="grand1" @click="oilApplication">&ndash;&gt;-->
            <!--                &lt;!&ndash;              <div class="back">&ndash;&gt;-->
            <!--                &lt;!&ndash;&lt;!&ndash;                <div class="photo">&ndash;&gt;&ndash;&gt;-->
            <!--                &lt;!&ndash;&lt;!&ndash;                  <img loading="lazy" src="@/assets/kaifa.png" style="border-radius: 6px"/>&ndash;&gt;&ndash;&gt;-->
            <!--                &lt;!&ndash;&lt;!&ndash;                </div>&ndash;&gt;&ndash;&gt;-->
            <!--                &lt;!&ndash;                <div  >&ndash;&gt;-->
            <!--                &lt;!&ndash;                  <div class="title1">油气开发作业GPT应用服务</div>&ndash;&gt;-->
            <!--                &lt;!&ndash;                  <div class="title2">帮助企业客户打造油气开发作业GPT应用服务，借助AI、大数据、IoT等技术，通过GPT与边缘智能融合，实现油藏地质、钻井、压裂、采油气、地层监测和设备设施监测的一体化智能解决方案，助力油气企业优化施工，改进工艺及流程，提高开发经济效益。</div>&ndash;&gt;-->
            <!--                &lt;!&ndash;                </div>&ndash;&gt;-->
            <!--                &lt;!&ndash;                <div class="details">查看详情<i class="el-icon-right" style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>&ndash;&gt;-->
            <!--                &lt;!&ndash;                </div>&ndash;&gt;-->
            <!--                &lt;!&ndash;              </div>&ndash;&gt;-->

            <!--                &lt;!&ndash;            </div>&ndash;&gt;-->


            <!--              </div>-->

          </div>

          <div ref="companyskip"  style="background-color: #29356b;color:white"  class="screen6">
            <div class="pro3">
              <div  v-sliden-in="{duration:600}"  class="title" style="color:white">安迅数智服务</div>
              <div  v-sliden-in="{duration:600}"  class="title2" style="margin:19px 15px;color:white">
                油气通是由安迅数智倾力打造并运营，秉承服务于油气行业各方，让油田数智化简单易行。
                <span class="AnvisionMore" style="color: #fb6400;font-weight: bolder;cursor: pointer" v-if="!this.AnvisionMore"
                      @click="handleClick">更多 >>
                </span>
                <div v-if="AnvisionMore">
                  安迅数智作为安东集团旗下的行业领先的油田数智化技术服务公司，以客户为中心，通过数智化技术与油气技术完美结合，立足于油气田现场伴随式服务，以最简单方式、最低成本、最快周期、最易落地为特点，提供油气行业的全栈式解决方案。
                  <span class="AnvisionMore" style="color: #f96400;width: fit-content;font-weight: bold;cursor: pointer"
                        @click="()=>{this.AnvisionMore=false}"><i style="font-weight: bold"
                                                                  class="el-icon-arrow-up"></i>
                  </span></div>
              </div>
              <div class="foot">
                <div  v-sliden-in="{duration:600}"  class="left1">
                  <div class="line"></div>
                  <div @click="hidden3"  style="display: flex;cursor: pointer">
                    <span class="title1" :style="hid2?{ color: '#fd6400' } : { color: 'white' }">安迅数智</span>
                    <span  v-if="hid2===0" style="width:fit-content;margin:15px 0 0 auto;float: right;color: white;cursor: pointer"><i style="font-size: 1.5vw" class="el-icon-arrow-up"></i> </span>
                    <span  v-if="hid2===1" style="width:fit-content;margin:15px 0 0 auto;float: right;color: white;cursor: pointer"><i style="font-size: 1.5vw" class="el-icon-arrow-down"></i> </span>
                  </div>

                  <div v-if="hid2===0"   class="title2">
                    安迅数智立足油气田现场各类应用场景，以最可靠的项目落地、最快的项目周期、最低的成本，提供专业的伴随式服务，满足油气田数智化刚性需求。<br>服务涵盖智慧油田综合解决方案、油气田全场景数智服务。

                  </div>
                  <div v-if="hid2===0"    :style="{ WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', backgroundColor: bgColor, backgroundImage: textGradient }"
                       class="title3">
                    油气数智，简单易行
                  </div>
                </div>

                <div  v-sliden-in="{duration:600}"  class="left1">
                  <div class="line"></div>
                  <div @click="hidden" style="display: flex;cursor: pointer">
                    <span class="title1" :style="hid?{ color: '#fd6400' } : { color: 'white' }">安迅油气通智能</span>
                    <span  v-if="hid===0" style="width:fit-content;margin:15px 0 0 auto;float: right;color: white;cursor: pointer"><i style="font-size: 1.5vw" class="el-icon-arrow-up"></i> </span>
                    <span v-if="hid===1" style="width:fit-content;margin:15px 0 0 auto;float: right;color: white;cursor: pointer"><i  style="font-size: 1.5vw" class="el-icon-arrow-down"></i> </span>
                  </div>

                  <div v-if="hid===0" class="title2">
                    安迅油气通智能技术有限公司专注为石油行业提供人工智能技术解决方案，致力于将人工智能技术深度融入石油业务中。<br>我们的专业团队拥有丰富的行业经验和先进的人工智能技术，能够为石油企业提供高效、精准的解决方案，助力企业实现数字化转型和智能化升级。

                  </div>
                  <div v-if="hid===0"    :style="{ WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', backgroundColor: bgColor, backgroundImage: textGradient }"
                       class="title3"> 油气“通”智能</div>
                </div>
                <div  v-sliden-in="{duration:600}"  class="left1">
                  <div class="line"></div>
                  <div @click="hidden1" style="display: flex;cursor:pointer;">
                    <span class="title1" :style="hid1?{ color: '#fd6400' } : { color: 'white' }">石同多媒体</span>
                    <span v-if="hid1===0" style="width:fit-content;margin:15px 0 0 auto;float: right;color: white;cursor: pointer"><i style="font-size: 1.5vw" class="el-icon-arrow-up"></i> </span>
                    <span  v-if="hid1===1" style="width:fit-content;margin:15px 0 0 auto;float: right;color: white;cursor: pointer"><i style="font-size: 1.5vw" class="el-icon-arrow-down"></i> </span>
                  </div>

                  <div v-if="hid1===0" class="title2">
                    石同多媒体致力于服务油气行业多媒体需求，让油气世界更加有声有型。<br>提供科学可视化、营销推广及品牌优化服务，满足客户技术宣传、品牌推广、培训、竞赛和科研汇报等方面的多媒体需求。

                  </div>
                  <div v-if="hid1===0"   :style="{ WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', backgroundColor: bgColor, backgroundImage: textGradient }"  style="padding: 0 2vw 0 2vw;"
                       class="title3">
                    视通油气
                  </div>

                </div>
              </div>
              <!--              <div class="companyIntroduce">-->

              <!--                &lt;!&ndash;        智能&ndash;&gt;-->
              <!--                <div class="grand2">-->
              <!--                  <div class="back">-->

              <!--                    <div class="photoHidden" style="opacity: 0.1;left:50%;transform:translateX(-50%);height: 100%;">-->
              <!--                      <img loading="lazy" src="@/assets/shuzhiback.png"/>-->
              <!--                    </div>-->
              <!--                    <div class="title">-->
              <!--                      <div class="title1">-->
              <!--                        <img loading="lazy" src="@/assets/anxunCompang.png"/></div>-->
              <!--                      <div class="title2">-->
              <!--                        安迅数智立足油气田现场各类应用场景，以最可靠的项目落地、最快的项目周期、最低的成本，提供专业的伴随式服务，满足油气田数智化刚性需求。服务涵盖智慧油田综合解决方案、油气田全场景数智服务。-->
              <!--                      </div>-->
              <!--                      <div class="title3">-->
              <!--                        <img loading="lazy" src="@/assets/anxunSlogn.png"/></div>-->
              <!--                    </div>-->
              <!--                  </div>-->

              <!--                </div>-->
              <!--                <div class="grand3">-->
              <!--                  <div class="back">-->

              <!--                    <div class="photoHidden" style="opacity: 0.1;left:50%;transform:translateX(-50%);height: 25%">-->

              <!--                      <img loading="lazy" src="@/assets/anxun.png"/>-->
              <!--                    </div>-->
              <!--                    <div class="title">-->
              <!--                      <div class="title1">-->
              <!--                        <img loading="lazy" src="@/assets/oilCompany.png"/></div>-->
              <!--                      <div class="title2">-->
              <!--                        安迅油气通智能技术有限公司专注为石油行业提供人工智能技术解决方案，致力于将人工智能技术深度融入石油业务中。我们的专业团队拥有丰富的行业经验和先进的人工智能技术，能够为石油企业提供高效、精准的解决方案，助力企业实现数字化转型和智能化升级。-->
              <!--                      </div>-->
              <!--                      <div class="title3">-->
              <!--                        <img loading="lazy" src="@/assets/oilSlogin.png"/></div>-->
              <!--                    </div>-->
              <!--                  </div>-->

              <!--                </div>-->
              <!--                <div class="grand4" style="padding-bottom: 0">-->
              <!--                  <div class="back">-->
              <!--                    <div class="photoHidden" style="opacity: 0.1;left:50%;transform:translateX(-50%);height: 100%">-->
              <!--                      <img loading="lazy" src="@/assets/mediaCompany.png"/>-->
              <!--                    </div>-->
              <!--                    <div class="title">-->
              <!--                      <div class="title1">-->
              <!--                        <img loading="lazy" src="@/assets/mediaCom.png"/></div>-->
              <!--                      <div class="title2">-->
              <!--                        石同多媒体致力于服务油气行业多媒体需求，让油气世界更加有声有型。提供科学可视化、营销推广及品牌优化服务，满足客户技术宣传、品牌推广、培训、竞赛和科研汇报等方面的多媒体需求。-->
              <!--                      </div>-->
              <!--                      <div class="title3">-->
              <!--                        <img loading="lazy" src="@/assets/mediaSlogin.png"/></div>-->
              <!--                    </div>-->
              <!--                  </div>-->

              <!--                </div>-->


              <!--              </div>-->


              <!--            <div class="banner">-->
              <!--              <div class="back1">-->
              <!--                <div class="backTitle1"> 油气智能化全场景覆盖</div>-->
              <!--                <div class="span"></div>-->
              <!--                <div class="backTitle2">-->
              <!--                  我们拥有从地质勘探、钻井、完井等全流程智能化场景覆盖。-->
              <!--                </div>-->
              <!--              </div>-->

              <!--              <div class="back1">-->
              <!--                <div class="backTitle1">油田现场验证</div>-->
              <!--                <div class="span"></div>-->
              <!--                <div class="backTitle2">-->
              <!--                  已经实现在安东多个交付的工程项目中率先落地投入使用，率先通过智能化手段实现降本增效。-->
              <!--                </div>-->
              <!--              </div>-->

              <!--              <div class="back1">-->
              <!--                <div class="backTitle1">油气知识积累</div>-->
              <!--                <div class="span"></div>-->
              <!--                <div class="backTitle2">-->
              <!--                  我们基于安东多年积累的大量油田工程技术知识，用于智能化模型训练并持续优化。-->
              <!--                </div>-->
              <!--              </div>-->
              <!--              <div class="back1">-->
              <!--                <div class="backTitle1">技术生态伙伴</div>-->
              <!--                <div class="span"></div>-->
              <!--                <div class="backTitle2">-->
              <!--                  积累人工智能技术伙伴资源，涵盖数据治理、AI大模型、算力服务、应用开发等多领域技术伙伴。-->
              <!--                </div>-->
              <!--              </div>-->
              <!--            </div>-->
            </div>
          </div>
          <!--      服务优势-->
<!--          <div ref="companyskip" class=" screen6" style="background-color: #29356b;color:white">-->
<!--            <div class="pro3">-->
<!--              <div class="title">安迅数智服务</div>-->
<!--              <div class="title2" style="color:white">油气通是由安迅数智倾力打造并运营，秉承服务于油气行业各方，让油田数智化简单易行。-->
<!--                <br>安迅数智作为安东集团旗下的行业领先的油田数智化技术服务公司，以客户为中心，通过数智化技术与油气技术完美结合，立足于油气田现场伴随式服务，以最简单方式、最低成本、最快周期、最易落地为特点，提供油气行业的全栈式解决方案。-->
<!--              </div>-->
<!--              <div class="companyIntroduce">-->

<!--                &lt;!&ndash;        智能&ndash;&gt;-->
<!--                <div class="grand2">-->
<!--                  <div class="back">-->
<!--                    <div class="photoHidden"-->
<!--                         style="top:0;opacity: 0.1;left:50%;transform:translateX(-50%);height: 100%">-->
<!--                      <img loading="lazy" src="@/assets/shuzhiback.png"/>-->
<!--                    </div>-->
<!--                    <div class="title">-->
<!--                      <div class="title1">-->
<!--                        <img style="object-fit: cover" loading="lazy" src="@/assets/anxunCompang.png"/></div>-->
<!--                      <div class="title2">-->
<!--                        安迅数智立足油气田现场各类应用场景，以最可靠的项目落地、最快的项目周期、最低的成本，提供专业的伴随式服务，满足油气田数智化刚性需求。服务涵盖智慧油田综合解决方案、油气田全场景数智服务。-->
<!--                      </div>-->
<!--                      <div class="title3">-->
<!--                        <img loading="lazy" src="@/assets/anxunSlogn.png"/></div>-->
<!--                    </div>-->
<!--                  </div>-->

<!--                </div>-->
<!--                <div class="grand3">-->
<!--                  <div class="back">-->

<!--                    <div class="photoHidden" style="opacity: 0.1;left:50%;transform:translateX(-50%);width: 100%">-->

<!--                      <img loading="lazy" src="@/assets/anxun.png"/>-->
<!--                    </div>-->
<!--                    <div class="title">-->
<!--                      <div class="title1">-->
<!--                        <img loading="lazy" src="@/assets/oilCompany.png"/></div>-->
<!--                      <div class="title2">-->
<!--                        安迅油气通智能技术有限公司专注为石油行业提供人工智能技术解决方案，致力于将人工智能技术深度融入石油业务中。我们的专业团队拥有丰富的行业经验和先进的人工智能技术，能够为石油企业提供高效、精准的解决方案，助力企业实现数字化转型和智能化升级。-->
<!--                      </div>-->
<!--                      <div class="title3">-->
<!--                        <img loading="lazy" src="@/assets/oilSlogin.png"/></div>-->
<!--                    </div>-->
<!--                  </div>-->

<!--                </div>-->

<!--                <div class="grand4">-->
<!--                  <div class="back">-->
<!--                    <div class="photoHidden" style="opacity: 0.1;left:50%;transform:translateX(-50%);height: 90%">-->
<!--                      <img loading="lazy" src="@/assets/mediaCompany.png"/>-->
<!--                    </div>-->
<!--                    <div class="title">-->
<!--                      <div class="title1">-->
<!--                        <img loading="lazy" src="@/assets/mediaCom.png"/></div>-->
<!--                      <div class="title2">-->
<!--                        石同多媒体致力于服务油气行业多媒体需求，让油气世界更加有声有型。提供科学可视化、营销推广及品牌优化服务，满足客户技术宣传、品牌推广、培训、竞赛和科研汇报等方面的多媒体需求。-->
<!--                      </div>-->
<!--                      <div class="title3">-->
<!--                        <img loading="lazy" src="@/assets/mediaSlogin.png"/></div>-->
<!--                    </div>-->
<!--                  </div>-->

<!--                </div>-->


<!--              </div>-->

<!--              &lt;!&ndash;            <div class="banner">&ndash;&gt;-->
<!--              &lt;!&ndash;              <div class="back1">&ndash;&gt;-->
<!--              &lt;!&ndash;                <div class="background"></div>&ndash;&gt;-->
<!--              &lt;!&ndash;                <div class="backTitle1">油气智能化全场景覆盖</div><div class="backTitle2">&ndash;&gt;-->
<!--              &lt;!&ndash;                  我们拥有从地质勘探、钻井、完井等全流程智能化场景覆盖。&ndash;&gt;-->
<!--              &lt;!&ndash;                </div>&ndash;&gt;-->
<!--              &lt;!&ndash;              </div>&ndash;&gt;-->
<!--              &lt;!&ndash;              <div class="back3">&ndash;&gt;-->
<!--              &lt;!&ndash;                <div class="background"></div>&ndash;&gt;-->
<!--              &lt;!&ndash;                <div class="backTitle1">油田现场验证</div>&ndash;&gt;-->
<!--              &lt;!&ndash;                <div class="backTitle2">&ndash;&gt;-->
<!--              &lt;!&ndash;                  已经实现在安东多个交付的工程项目中率先落地投入使用，率先通过智能化手段实现降本增效。&ndash;&gt;-->
<!--              &lt;!&ndash;                </div>&ndash;&gt;-->
<!--              &lt;!&ndash;              </div>&ndash;&gt;-->

<!--              &lt;!&ndash;              <div class="back4">&ndash;&gt;-->
<!--              &lt;!&ndash;                <div class="background"></div>&ndash;&gt;-->
<!--              &lt;!&ndash;                <div class="backTitle1">油气知识积累</div>&ndash;&gt;-->
<!--              &lt;!&ndash;                <div class="backTitle2">&ndash;&gt;-->
<!--              &lt;!&ndash;                  我们基于安东多年积累的大量油田工程技术知识，用于智能化模型训练并持续优化。&ndash;&gt;-->
<!--              &lt;!&ndash;                </div>&ndash;&gt;-->
<!--              &lt;!&ndash;              </div>&ndash;&gt;-->
<!--              &lt;!&ndash;              <div class="back2">&ndash;&gt;-->
<!--              &lt;!&ndash;                <div class="background"></div>&ndash;&gt;-->
<!--              &lt;!&ndash;                <div class="backTitle1">技术生态伙伴</div>&ndash;&gt;-->
<!--              &lt;!&ndash;                <div class="backTitle2">&ndash;&gt;-->
<!--              &lt;!&ndash;                  积累人工智能技术伙伴资源，涵盖数据治理、AI大模型、算力服务、应用开发等多领域技术伙伴。&ndash;&gt;-->
<!--              &lt;!&ndash;                </div>&ndash;&gt;-->
<!--              &lt;!&ndash;              </div>&ndash;&gt;-->
<!--              &lt;!&ndash;            </div>&ndash;&gt;-->

<!--            </div>-->


<!--          </div>-->
          <!--底部-->
          <div class="screen7">
            <bottomTip></bottomTip>
          </div>
        </div>

        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
        <partnerTip :dialogVisible="dialogconsult" @hideDialog="hide"></partnerTip>
      </div>
    </div>
    <div v-else >

      <div id="totalModel"  ref="totalModel" class="totalModel">
        <div class="screen1" style="" @click="showTooltip=false" :style="screendata ? { height: '100vh' } : { height: '100vh' }" id="phonescreen1">
          <div v-if="focus">
            <el-button  :style="focusno ? { top: '120px' } : { top: '120px' }"  class="title3img" @click="focus=0;"></el-button>
          </div>
          <headOther :focus="focus" @showDialog="showPartner" @toptop="uptop" @homeSkip="skipp2"></headOther>

          <div class="layer3"    :style="focus ? { height: '80vh',margin:'35px 0 0 0 ' } : { height: '45vh' }"
               ref="layer3">
            <!--              <el-input v-model="newMessage"></el-input>-->
            <AIAnswerMobile @skipping="skippcom2" :focusone="focus" @screenmodel="screenmodel" @setTop="uptop2" @setTopno="uptopno"></AIAnswerMobile>
            <!--              &lt;!&ndash;        <div class="communicityGpt" @click="gpts">GPT社区</div>&ndash;&gt;-->
            <!--      logo  you气通GPT社区 合作伙伴  登录-->
          </div>
          <div  v-if="!focus||focusno" style="position: absolute;bottom:5px;left:50%;transform: translateX(-50%);width: 15px;height: 15px">
<!--            <el-button class="title4img" src=""></el-button>-->
            <img src="@/assets/arrow.png" class="animated-element" style="width: 3vw;height: 3vh;position: absolute">
            <img src="@/assets/arrow.png" class="animated-element2" style="width: 3vw;height: 3vh;position: absolute">
            <img src="@/assets/arrow.png" class="animated-element3" style="width: 3vw;height: 3vh;position: absolute">
            <img src="@/assets/arrow.png" class="animated-element4" style="width: 3vw;height: 3vh;position: absolute">
          </div>
        </div>          <!--        <kefuMobile @topMobile="topTopmobile"></kefuMobile>-->
        <div class="up">
          <div class="tooltip"
               v-if="showTooltip"
               style="width:105px;height: 105px;position: fixed;right: 35px;top:45%;z-index: 999">
            <img src="../assets/wechat.jpg"/>
          </div>
          <div class="uptop" @click="uptop"></div>
          <div style="height: 1px;background-color: rgba(128,128,128,0.3);;width: 100%"></div>
          <div style="padding: 1vh 0;" @click="upkefu2">
            <div class="upkefu"></div>
            <div style="font-size: 8px;text-align: center;margin-top:5px;color:white"> 在线<br>客服</div>
          </div>
          <div style="height: 1px;background-color: rgba(128,128,128,0.3);width: 100%"></div>

          <div class="feedback" @click="feedback">服务<br>反馈</div>
          <div style="height: 1px;background-color: rgba(128,128,128,0.3);width: 100%"></div>
          <div class="wechaticon" @click="showTooltip=true" style="height: 20px;width:20px;display: flex;justify-content: center;text-align: center;padding:10px 0 15px 2.5px;background-color: transparent">
            <svg t="1727166040965" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3334" width="15" height="15">
              <path d="M1010.8 628c0-141.2-141.3-256.2-299.9-256.2-168 0-300.3 115.1-300.3 256.2 0 141.4 132.3 256.2 300.3 256.2 35.2 0 70.7-8.9 106-17.7l96.8 53-26.6-88.2c70.9-53.2 123.7-123.7 123.7-203.3zM618 588.8c-22.1 0-40-17.9-40-40s17.9-40 40-40 40 17.9 40 40c0 22-17.9 40-40 40z m194.3-0.3c-22.1 0-40-17.9-40-40s17.9-40 40-40 40 17.9 40 40-17.9 40-40 40z" fill="#00C800" p-id="3335"></path><path d="M366.3 106.9c-194.1 0-353.1 132.3-353.1 300.3 0 97 52.9 176.6 141.3 238.4l-35.3 106.2 123.4-61.9c44.2 8.7 79.6 17.7 123.7 17.7 11.1 0 22.1-0.5 33-1.4-6.9-23.6-10.9-48.3-10.9-74 0-154.3 132.5-279.5 300.2-279.5 11.5 0 22.8 0.8 34 2.1C692 212.6 539.9 106.9 366.3 106.9zM247.7 349.2c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48z m246.6 0c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48z" fill="#00C800" p-id="3336"></path></svg>
          </div>
        </div>
        <div v-if="scrollTrue===false" class="up" @click="uptop"></div>
        <div @click="showTooltip=false">
          <div class="screen3">
              <div class="pro3">
              <div>
                <div class="grand1" style="">

                  <div v-sliden-in="{duration:800}" class="cards"
                       style=" border-radius: 9px;height: 200px;font-family: 'Times New Roman';text-align: center;justify-content: center;display: flex;align-content: center">
                    <div style="margin:auto">

                      <div style="font-size: 40px;font-weight: bolder">联系</div>

                        <div style="font-size: 65px;font-weight: bolder">我们</div>

                    </div>
                  </div>

                  <div v-sliden-in="{duration:1200}" class="cards" style="background-color: #015477;border-radius: 9px;height: 200px"
                       @click="upkefu2()">
                    <div style="text-align: center;padding: 25px 0 20px 0">
                      <img src="@/assets/kefu.png" style="height: 60px;width: 60px">
                    </div>
                    <div style="font-family: 'Times New Roman';color: #ade0e1;font-size: 25px;text-align: center">
                      在线客服
                    </div>
                    <div style="margin: 10px auto;text-align: center">
                      <!--                      <i class="el-icon-right" style="color:white;font-size: 37px;"></i>-->
                      <img src="@/assets/rightArrow.png" style="height: 30px;width: 30px">
                    </div>

                  </div>
                  <div v-sliden-in="{duration:800}" class="cards" style="background-color: #015477;border-radius: 9px;height: 200px"
                       @click="feedback1()">
                    <div style="text-align: center;padding: 25px 0 20px 0">
                      <img src="@/assets/communicate.png" style="height: 60px;width: 60px">
                    </div>
                    <div style="font-family: 'Times New Roman';color: #acdfe0;font-size: 25px;text-align: center">
                      服务反馈
                    </div>
                    <div style="margin: 10px auto;text-align: center">
                      <!--                      <i class="el-icon-right" style="color:white;font-size: 37px;"></i>-->
                      <img src="@/assets/rightArrow.png" style="height: 30px;width: 30px">
                    </div>

                    </div>

                  <div  v-sliden-in="{duration:800}" class="cards" style="background-color: #015477;border-radius: 9px;height: 200px"
                        @click="showPartner()">
                    <div style="text-align: center;padding: 25px 0 20px 0">
                      <img src="@/assets/hand.png" style="height: 60px;width: 60px">
                    </div>
                    <div style="font-family: 'Times New Roman';color: #ade0e1;font-size: 25px;text-align: center">
                      合作交流
                    </div>
                    <div style="margin: 10px auto;text-align: center">
                      <!--                      <i class="el-icon-right" style="color:white;font-size: 37px;"></i>-->
                      <img src="@/assets/rightArrow.png" style="height: 30px;width: 30px">
                    </div>

                    </div>

                </div>
              </div>
            </div>

          </div>

          <!--    油气资讯GPTss-->
          <div ref="zixun" class="screen13" style="background-color: transparent">

            <div v-sliden-in="{duration:600}"  class="title" style="color:#371e76">&nbsp;油气资讯GPTs</div>
            <div v-sliden-in="{duration:600}" class="light" style="margin:15px auto">
              免费为用户提供基于AI大模型技术的油气资讯服务。
              <span
                  class="light2"> <br>因AI模型无法做到完全精准，且受算力限制，如想获得更好的使用效果，请<span
                  style="color: #2F6EFF;cursor: pointer" @click="clickConsult('油气资讯GPTs')">点击咨询</span>。</span>

            </div>
            <div class="pro1">
              <!--采油气-->

              <!--                  <div v-sliden-in="{duration:600}"  @click="oilgpt1" >-->


              <div v-sliden-in="{duration:600}"  @click="info1" key="yqzx_002"  >

                <div class="grand1" style="position: relative;height: fit-content;padding:0" >
                  <div class="back">
                    <div class="photo">
                      <img src="@/assets/information.png">
                    </div>
                  </div>
                  <div class="back1">
                    <div class="title1" >油气知识 </div>
                    <div class="title2" >
                      更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:600}" @click="expo1" key="yqzx_001">
                <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                <div class="grand1" style="position: relative;height: fit-content" >
                  <div class="back">
                    <div class="photo">
                      <img src="@/assets/expo.png">
                    </div>
                  </div>
                  <div class="back1">
                    <div class="title1"> 油气展馆</div>
                    <div class="title2" >
                      更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:600}" @click="job1"  key="yqzx_003">
                <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                <div class="grand1" style="position: relative;height: fit-content"  >
                  <div class="back">
                    <div class="photo">
                      <img src="@/assets/job.png">
                    </div>
                  </div>
                  <div class="back1">
                    <div class="title1"> 油气人才</div>
                    <div class="title2" >
                      更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>

            </div>


          </div>

          <!--    油气专业GPTs-->
          <div ref="professional1" style="background-color: white" class="screen3">

            <div v-sliden-in="{duration:600}" class="title" style="color:#371e76">油气专业GPTs</div>
            <div v-sliden-in="{duration:600}" class="light" >
              油气专业GPTs人工智能模型的建立主要是基于安东等工程技术企业的应用，面向业界企业开放分享。
              <span class="light2"><br>因对其他应用场景的泛化能力不足，如需更精准的专业应用，请<span
                  style="color: #2F6EFF;cursor: pointer" @click="clickConsult('油气专业GPTs')">点击咨询</span>。</span>

            </div>
            <div class="pro1">

              <div v-sliden-in="{duration:600}" key="yqzy1" @click="oilgpt1" >

                <div class="grand1" style="position: relative;height: fit-content;padding: 0" >
                  <div class="back">
                    <div class="photo">
                      <img class="image" style="border-radius: 9px" src="@/assets/pro1.jpg">
                    </div>
                  </div>
                  <div class="back1" style=" box-shadow: none">
                    <div class="title1" > 设计类GPTs</div>
                    <div class="title2" >
                      更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:600}"  key="yqzy2" @click="oilgpt21">
                <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                <div class="grand1" style="position: relative;height: fit-content" >
                  <div class="back">
                    <div class="photo">
                      <img class="image" style="border-radius: 9px" src="@/assets/pro7.jpg">
                    </div>
                  </div>
                  <div class="back1" style=" box-shadow: none">
                    <div class="title1"> 解释类GPTs</div>
                    <div class="title2" >
                      更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:600}" key="yqzy3" @click="oilgpt31">
                <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                <div class="grand1" style="position: relative;height: fit-content"  >
                  <div class="back">
                    <div class="photo">
                      <img class="image" style="border-radius: 9px" src="@/assets/pro2.jpg">
                    </div>
                  </div>
                  <div class="back1" style=" box-shadow: none">
                    <div class="title1"> 工艺类GPTs</div>
                    <div class="title2" >
                      更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <!--    企业管理GPTs-->
          <div ref="menageServices" class="screenConsult1" style="background-color: transparent">
            <div v-sliden-in="{duration:600}"  class="title" style="color:#371e76" >企业管理GPTs</div>
            <div v-sliden-in="{duration:600}"  class="light">
              企业管理GPTs人工智能是基于特定的企业环境训练。
              <span
                  class="light2"><br>因泛化能力不足，仅提供案例和展示服务，如需进一步交流，请<span
                  style="color: #2F6EFF;cursor: pointer" @click="clickConsult('企业管理GPTs')">点击咨询</span>。</span>

            </div>
            <div class="pro4">


                <div v-sliden-in="{duration:600}" key="qygl_001"  @click="knowledge1" >
                  <div class="grand1" style="position: relative;height: fit-content;padding: 0" >
                    <div class="back">
                      <div class="photo">
                        <img class="image" style="border-radius: 9px" src="@/assets/knowledgenew1.jpg">
                      </div>
                    </div>
                    <div class="back1" style=" box-shadow: none">
                      <div class="title1" >企业内部管理类<br>知识GPT开发</div>
                      <div class="title2" >
                        更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                      </div>
                    </div>
                  </div>
                </div>
                  <div v-sliden-in="{duration:600}" key="qygl_002"  @click="data1">
                    <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                    <div class="grand1" style="position: relative;height: fit-content" >
                      <div class="back">
                        <div class="photo">
                          <img class="image" style="border-radius: 9px" src="@/assets/knowledgenew2.png">
                        </div>
                      </div>
                      <div class="back1" style=" box-shadow: none">
                        <div class="title1"> 企业管理数智化<br>整体解决方案</div>
                        <div class="title2" >
                          更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-sliden-in="{duration:600}" key="qygl_003" @click="wisdom1">
                    <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                    <div class="grand1" style="position: relative;height: fit-content"  >
                      <div class="back">
                        <div class="photo">
                          <img class="image" style="border-radius: 9px" src="@/assets/knowledgenew3.jpg">
                        </div>
                      </div>
                      <div class="back1" style=" box-shadow: none">
                        <div class="title1"> 数据驱动智慧决策<br>平台设计及建设</div>
                        <div class="title2" >
                          更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-sliden-in="{duration:600}" key="qygl_004" @click="design1">

                    <div class="grand1" style="position: relative;height: fit-content" >
                      <div class="back">
                        <div class="photo">
                          <img class="image" style="border-radius: 9px" src="@/assets/knowledgenew4.png">
                        </div>
                      </div>
                      <div class="back1" style=" box-shadow: none">
                        <div class="title1" >智慧员工社区<br>设计及建设</div>
                        <div class="title2" >
                          更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-sliden-in="{duration:600}" key="qygl_005"  @click="plat1">
                    <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                    <div class="grand1" style="position: relative;height: fit-content" >
                      <div class="back">
                        <div class="photo">
                          <img class="image" style="border-radius: 9px" src="@/assets/knowledgenew5.png">
                        </div>
                      </div>
                      <div class="back1" style=" box-shadow: none">
                        <div class="title1"> 智慧电商平台设计、<br>建设及运营</div>
                        <div class="title2" >
                          更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-sliden-in="{duration:600}" key="qygl_006" @click="automation1">
                    <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                    <div class="grand1" style="position: relative;height: fit-content"  >
                      <div class="back">
                        <div class="photo">
                          <img class="image" style="border-radius: 9px" src="@/assets/knowledgenew6.png">
                        </div>
                      </div>
                      <div class="back1" style=" box-shadow: none">
                        <div class="title1"> 管理流程自动化平台<br>设计及建设</div>
                        <div class="title2" >
                          更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                        </div>
                      </div>
                    </div>
                  </div>


            </div>

          </div>

          <!--    通用类GPTs-->
          <div class="screenGeneral" style="background-color:white ">


            <div ref="general" style="display: flex;margin:0 auto 0 auto;background-color:white">
              <div style="display: flex;margin:0 auto;text-align: center">
                <!--              <div style="height:22px;width:21px;text-align: right;margin-top:18px;"><img loading="lazy"-->
                <!--                                                                                          src="@/assets/icon_first.png">-->
                <!--              </div>-->
                <div v-sliden-in="{duration:800}" class="title" style="color:#371e76">&nbsp;通用类GPTs</div>
              </div>
            </div>
            <div v-sliden-in="{duration:800}" class="light">
              线上通用性GPTs，基于AI大模型能力，辅助用户改进工作效率。
              <span class="light2"><br>如想基于开放的通用性GPTs开展定制化的使用培训和开发，请<span
                  style="color: #2F6EFF;cursor: pointer" @click="clickConsult('通用类GPTs')">点击咨询</span>。</span>

            </div>

            <div class="pro1">
              <!--油田-->
              <div v-sliden-in="{duration:800}" key="ty1" class="grand2" @click="wen1">
                <div class="back">
                  <div class="photo">
                    <img loading="lazy" src="@/assets/wen.png"/>
                  </div>
                  <div>
                    <div class="title1">文心一言</div>

                  </div>

                </div>
              </div>
              <!--        油藏-->
              <div v-sliden-in="{duration:1000}" key="ty2" class="grand2" @click="dou1">
                <div class="back">
                  <div class="photo"><img loading="lazy" src="@/assets/dou.png"/></div>
                  <div>
                    <div class="title1">豆包AI</div>

                  </div>
                </div>
              </div>

              <!--        智能-->
              <div v-sliden-in="{duration:1200}" key="ty3" class="grand2" @click="tong1">
                <div class="back">
                  <div class="photo">
                    <img loading="lazy" src="@/assets/tong.png" style="height: 85%;margin-top: 7.5%"/>
                  </div>
                  <div>
                    <div class="title1">通义千问</div>

                  </div>
                </div>
              </div>
              <!--采油气-->
              <div v-sliden-in="{duration:800}" key="ty4" class="grand2" @click="zhi1">
                <div class="back">
                  <div class="photo">
                    <img loading="lazy" src="@/assets/zhi.png" style="height: 75%;margin-top: 10%"/>
                  </div>
                  <div>
                    <div class="title1">智谱清言</div>

                  </div>
                </div>
              </div>

              <!--        智能-->
              <div v-sliden-in="{duration:1000}" key="ty5" class="grand2" @click="xun1">
                <div class="back">
                  <div class="photo">
                    <img loading="lazy" src="@/assets/xun.png" style="height:85%;margin-top: 5%"/>
                  </div>
                  <div>
                    <div class="title1">讯飞星火</div>

                  </div>
                </div>
              </div>

              <!--采油气-->
              <div v-sliden-in="{duration:1200}" key="ty6" class="grand2" @click="teng1">
                <div class="back">
                  <div class="photo">
                    <img loading="lazy" src="@/assets/teng.png" style="height: 90%;margin-top: 5%"/>
                  </div>
                  <div>
                    <div class="title1">腾讯混元</div>

                  </div>
                </div>
              </div>

              <!--采油气-->
              <div v-sliden-in="{duration:800}" key="ty7" class="grand2" @click="tian1">
                <div class="back">
                  <div class="photo">
                    <img loading="lazy" src="@/assets/tian.png"/>
                  </div>
                  <div>
                    <div class="title1">天工AI</div>

                  </div>
                </div>
              </div>
              <!--采油气-->
              <div v-sliden-in="{duration:1200}" key="ty8" class="grand2" @click="ge1">
                <div class="back">
                  <div class="photo">
                    <img loading="lazy" src="@/assets/wen.png"/>
                  </div>
                  <div>
                    <div class="title1">文心一格</div>

                  </div>
                </div>
              </div>

              <!--采油气-->
              <div v-sliden-in="{duration:1200}" key="ty9" class="grand2" @click="wan1">
                <div class="back">
                  <div class="photo">
                    <img loading="lazy" src="@/assets/wan.png" style="height: 90%;margin-top: 5%"/>
                  </div>
                  <div>
                    <div class="title1">万彩AI</div>

                  </div>
                </div>
              </div>

              <!--采油气-->
              <!--      <div class="grand2" @click="yi1">-->
              <!--        <div class="back">-->
              <!--          <div class="photo">-->
              <!--            <img loading="lazy" src="@/assets/yi.png" style="height: 85%;margin-top: 10%"/>-->
              <!--          </div>-->
              <!--          <div>-->
              <!--            <div class="title1">一帧秒创</div>-->

              <!--          </div>-->
              <!--        </div>-->
              <!--      </div>-->

            </div>

          </div>

          <!--    油气开发作业GPT应用服务-->
          <div class="screenConsult" style="background-color: transparent">
            <div ref="upload" style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:0 auto;text-align: center">
                <!--              <div style="height:22px;width:21px;text-align: right;margin-top:18px;"><img loading="lazy"-->
                <!--                                                                                          src="@/assets/icon_second.png">-->
                <!--              </div>-->
                <div v-sliden-in="{duration:1200}" class="title" style="margin-top: 15px;color:#371e76">&nbsp;油气开发作业GPT应用服务</div>
              </div>
            </div>
            <div v-sliden-in="{duration:1200}" class="light">
              通过GPT辅助油气开发现场作业。
              <span class="light2"><br>因泛化能力有限，如需提升在现场作业过程中的使用效果，快速落地，请<span
                  style="color: #2F6EFF;cursor: pointer"
                  @click="clickConsult('油气开发作业GPT应用')">点击咨询</span>。</span>
            </div>
            <div class="pro2">

              <div v-sliden-in="{duration:1200}" key="yqkf1"   @click="ground1">

                    <div class="grand1" style="position: relative;height: fit-content;padding: 0" >
                      <div class="back">
                        <div class="photo">
                          <img style="border-radius: 9px;" src="@/assets/pro1.png">
                        </div>
                      </div>
                      <div class="back1" style="background-color: transparent">
                        <div class="title1"  style="width: fit-content">智能油藏地质  </div>
                        <div class="title2" >
                          更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:1200}"  key="yqkf2" @click="hole1">
                <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                <div class="grand1" style="position: relative;height: fit-content" >
                  <div class="back">
                    <div class="photo">
                      <img  style="border-radius: 9px;"  src="@/assets/pro2.png">
                    </div>
                  </div>
                  <div class="back1"  style="background-color: transparent">
                    <div class="title1" style="width: fit-content"> 智能钻井  </div>
                    <div class="title2" >
                      更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>
              <div  v-sliden-in="{duration:1200}" key="yqkf3" @click="frack1">
                <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                <div class="grand1" style="position: relative;height: fit-content"  >
                  <div class="back">
                    <div class="photo">
                      <img  style="border-radius: 9px;"  src="@/assets/pro3.png">
                    </div>
                  </div>
                  <div class="back1"  style="background-color: transparent">
                    <div class="title1" style="width: fit-content"> 智能压裂  </div>
                    <div class="title2" >
                      更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>
              <div   v-sliden-in="{duration:1200}" key="yqkf4" @click="getOil1">

                    <div class="grand1" style="position: relative;height: fit-content" >
                      <div class="back">
                        <div class="photo">
                          <img style="border-radius: 9px;"  src="@/assets/pro4.png">
                        </div>
                      </div>
                      <div class="back1"  style="background-color: transparent">
                        <div class="title1"  style="width: fit-content">智能采油气   </div>
                        <div class="title2" >
                          更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:1200}" key="yqkf5"  @click="monitor1">
                <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                <div class="grand1" style="position: relative;height: fit-content" >
                  <div class="back">
                    <div class="photo">
                      <img style="border-radius: 9px;"  src="@/assets/pro5.png">
                    </div>
                  </div>
                  <div class="back1"  style="background-color: transparent">
                    <div class="title1" style="width: fit-content"> 地层监测  </div>
                    <div class="title2" >
                      更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:1200}" key="yqkf6" @click="equipment1">
                <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                <div class="grand1" style="position: relative;height: fit-content"  >
                  <div class="back">
                    <div class="photo">
                      <img style="border-radius: 9px;"  src="@/assets/pro6.png">
                    </div>
                  </div>
                  <div class="back1"  style="background-color: transparent">
                    <div class="title1" style="width: fit-content">设备设施监测  </div>
                    <div class="title2" >
                      更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                        </div>
                      </div>
                    </div>
                  </div>


            </div>


          </div>


          <!--    数智油田解决方案GPT应用服务-->
          <div ref="station" class="screenConsult" style="background-color: white">

            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:0 auto;text-align: center">
                <!--              <div style="height:22px;width:22px;text-align: right;margin-top:19px;"><img loading="lazy"-->
                <!--                  src="@/assets/icon_second.png"></div>-->
                <div v-sliden-in="{duration:1200}" class="title" style="margin-top: 15px;color:#371e76">数智油田解决方案GPT应用服务</div>
              </div>
            </div>

            <div v-sliden-in="{duration:1200}" class="light">利用人工智能技术，建设与地质、工程相结合的数智油田全场景解决方案，赋能数智油田全面升级。
              <span class="light2">
              <br>如您想进行产品方案展示及交流，请<span style="color: #2F6EFF;cursor: pointer"
                                                       @click="clickConsult('数智油田解决方案GPT应用')">点击咨询</span>。
          </span></div>
            <div class="pro2">


              <div v-sliden-in="{duration:1200}" key="jjfa1" @click="station1">

                      <div class="grand1" style="position: relative;height: fit-content;padding: 0" >
                        <div class="back">
                          <div class="photo">
                            <img style="border-radius: 9px;" src="@/assets/home1.png">
                          </div>
                        </div>
                        <div class="back1">
                          <div class="title1" >
                            智慧场站</div>
                          <div class="title2" >
                            更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:1200}" key="jjfa2" @click="security1">
                <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                <div class="grand1" style="position: relative;height: fit-content" >
                  <div class="back">
                    <div class="photo">
                      <img  style="border-radius: 9px;"  src="@/assets/home2.png">
                    </div>
                  </div>
                  <div class="back1">
                    <div class="title1"> 智慧安防 </div>
                    <div class="title2" >
                      更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:1200}" key="jjfa3" @click="productANxun1">
                <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                <div class="grand1" style="position: relative;height: fit-content"  >
                  <div class="back">
                    <div class="photo">
                      <img  style="border-radius: 9px;"  src="@/assets/home3.png">
                    </div>
                  </div>
                  <div class="back1">
                    <div class="title1">油气生产智能管控 </div>
                    <div class="title2" >
                      更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>
              <div  v-sliden-in="{duration:1200}" key="jjfa4" @click="equipmentAnxun1">

                      <div class="grand1" style="position: relative;height: fit-content" >
                        <div class="back">
                          <div class="photo">
                            <img style="border-radius: 9px;"  src="@/assets/home4.png">
                          </div>
                        </div>
                        <div class="back1">
                          <div class="title1" >设备设施智能监测 </div>
                          <div class="title2" >
                            更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:1200}" key="jjfa5"  @click="technologyAnxun1">
                <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                <div class="grand1" style="position: relative;height: fit-content" >
                  <div class="back">
                    <div class="photo">
                      <img style="border-radius: 9px;"  src="@/assets/home5.png">
                    </div>
                  </div>
                  <div class="back1">
                    <div class="title1">工艺大数据<br>分析系统 </div>
                    <div class="title2" >
                      更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:1200}" key="jjfa6" @click="monitorAnxun1">
                <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                <div class="grand1" style="position: relative;height: fit-content"  >
                  <div class="back">
                    <div class="photo">
                      <img style="border-radius: 9px;"  src="@/assets/home6.png">
                    </div>
                  </div>
                  <div class="back1">
                    <div class="title1"> 石油工程远程<br>监控指挥平台 </div>
                    <div class="title2" >
                      更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:1200}" key="jjfa7"  @click="networkAnxun1">
                <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                <div class="grand1" style="position: relative;height: fit-content" >
                  <div class="back">
                    <div class="photo">
                      <img style="border-radius: 9px;"  src="@/assets/home7.png">
                    </div>
                  </div>
                  <div class="back1">
                    <div class="title1"> 智慧管网 </div>
                    <div class="title2" >
                      更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>
              <div  v-sliden-in="{duration:1200}" key="jjfa8" @click="basic1">
                <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                <div class="grand1" style="position: relative;height: fit-content"  >
                  <div class="back">
                    <div class="photo">
                      <img style="border-radius: 9px;"  src="@/assets/home8.png">
                    </div>
                  </div>
                  <div class="back1">
                    <div class="title1"> 智慧油气基础<br>设施建设</div>
                    <div class="title2" >
                      更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                          </div>
                        </div>
                      </div>
                    </div>

              <div v-sliden-in="{duration:1200}" key="jjfa9"   @click="setting1">
                <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                <div class="grand1" style="position: relative;height: fit-content" >
                  <div class="back">
                    <div class="photo">
                      <img style="border-radius: 9px;"  src="@/assets/home9.png">
                    </div>
                  </div>
                  <div class="back1">
                    <div class="title1"> 油气数智化全场景<br>伴随式运维服务</div>
                    <div class="title2" >
                      更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:1200}" key="jjfa10" @click="storage1">
                <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                <div class="grand1" style="position: relative;height: fit-content"  >
                  <div class="back">
                    <div class="photo">
                      <img style="border-radius: 9px;"  src="@/assets/storage1.png">
                    </div>
                  </div>
                  <div class="back1">
                    <div class="title1">智慧储气库 </div>
                    <div class="title2" >
                      更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                          </div>
                        </div>
                      </div>
                    </div>

              <div v-sliden-in="{duration:1200}" key="jjfa11"  @click="training1">
                <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                <div class="grand1" style="position: relative;height: fit-content" >
                  <div class="back">
                    <div class="photo">
                      <img style="border-radius: 9px;"  src="@/assets/home11.png">
                    </div>
                  </div>
                  <div class="back1">
                    <div class="title1">智慧培训与应急<br>模拟演练平台</div>
                    <div class="title2" >
                      更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>
              <div  v-sliden-in="{duration:1200}" key="jjfa12" @click="build1">
                <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                <div class="grand1" style="position: relative;height: fit-content"  >
                  <div class="back">
                    <div class="photo">
                      <img style="border-radius: 9px;"  src="@/assets/home8.png">
                    </div>
                  </div>
                  <div class="back1">
                    <div class="title1"> 智能油气生产优化及AI资讯建设服务</div>
                    <div class="title2" >
                      更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                          </div>
                        </div>
                      </div>
                    </div>


            </div>


          </div>

          <!--    多媒体服务-->
          <div ref="media" class="screen3" style="background-color: transparent">

            <div v-sliden-in="{duration:1200}" class="title" style="color:#371e76">智能多媒体服务</div>
            <div v-sliden-in="{duration:1200}" class="light">
              帮助企业客户提供油气行业智能多媒体服务，让油气世界更加有声有型。提供科学可视化、营销推广及品牌优化服务，满足客户技术宣传、品牌推广、培训、竟赛和科研汇报等方面多媒体需求。
              <span class="light2">
            <br>如您想进行产品方案展示及交流，请<span style="color: #2F6EFF;cursor: pointer"
                                                     @click="clickConsult('智能多媒体')">点击咨询</span>。
          </span>
            </div>
            <div class="pro11">
              <!-- 设计类-->

              <div v-sliden-in="{duration:1200}"  key="dmt1" @click="meitiProduct1">


                    <div class="grand1" style="position: relative;height: fit-content;padding: 0" >
                      <div class="back">
                        <div class="photo">
                          <img class="image" style="border-radius: 9px" src="@/assets/meiti1.png">
                        </div>
                      </div>
                      <div class="back1" style=" box-shadow: none">
                        <div class="title1" > 宣传片制作</div>
                        <div class="title2" >
                          更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:1200}"  key="dmt2" @click="meiti3D1">
                <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                <div class="grand1" style="position: relative;height: fit-content" >
                  <div class="back">
                    <div class="photo">
                      <img class="image" style="border-radius: 9px" src="@/assets/meiti22.png">
                    </div>
                  </div>
                  <div class="back1" style=" box-shadow: none">
                    <div class="title1"> 三维动画</div>
                    <div class="title2" >
                      更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>
              <div  v-sliden-in="{duration:1200}" key="dmt3" @click="meitiDesign1">
                <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                <div class="grand1" style="position: relative;height: fit-content"  >
                  <div class="back">
                    <div class="photo">
                      <img class="image" style="border-radius: 9px" src="@/assets/meiti33.png">
                    </div>
                  </div>
                  <div class="back1" style=" box-shadow: none">
                    <div class="title1"> 平面设计</div>
                    <div class="title2" >
                      更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                        </div>
                      </div>
                    </div>
                  </div>

            </div>

          </div>


          <!--    人工智能全员普及培训人工智能全员普及培训-->
          <div ref="traningConsult" style="background-color: white" class="screen3">

            <div v-sliden-in="{duration:1200}" class="title" style="color:#371e76">人工智能全员普及培训及转型咨询服务</div>
            <div v-sliden-in="{duration:1200}" class="light">
              帮助客户企业全员可以使用在线的、开放的大模型人工智能工具，成为人工智能的驾驶员。帮助客户实现人工转型规划制定、项目方案设计、专家咨询服务，提升客户企业竞争力。
              <span class="light2"><br>如您有培训或咨询需求，请<span style="color: #2F6EFF;cursor: pointer"
                                                                    @click="clickConsult('人工智能普及培训及转型服务')">点击咨询</span>。</span>
            </div>
            <div class="pro1">

              <div v-sliden-in="{duration:1200}" key="peixun1" @click="traningAi1">

                    <div class="grand1" style="position: relative;height: fit-content;padding: 0" >
                      <div class="back">
                        <div class="photo">
                          <img class="image" style="border-radius: 9px" src="@/assets/puji2.jpg">
                        </div>
                      </div>
                      <div class="back1" style=" box-shadow: none">
                        <div class="title1" > 人工智能全员<br>普及应用培训</div>
                        <div class="title2"  style="margin:auto 0 0 auto;">
                          更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:1200}" key="peixun2"  @click="consult1">
                <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                <div class="grand1" style="position: relative;height: fit-content" >
                  <div class="back">
                    <div class="photo">
                      <img class="image" style="border-radius: 9px" src="@/assets/puji1.png">
                    </div>
                  </div>
                  <div class="back1" style=" box-shadow: none">
                    <div class="title1"> 企业人工智能转型咨询</div>
                    <div class="title2" >
                      更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                        </div>
                      </div>
                    </div>
                  </div>


            </div>


          </div>


          <!--    油气企业自建GPT云平台服务-->
          <div ref="companyConduct"  style="background-color: transparent"  class="screen3">
            <div v-sliden-in="{duration:1200}" class="title" style="color:#371e76">油气企业自建GPT云平台服务</div>
            <div v-sliden-in="{duration:1200}" class="light">
              帮助企业客户建设私有环境下的人工智能云平台，积累各应用场景的模型成果，实现模型的最大化共享和算力资源的充分使用。
              <span class="light2"><br>如您想在私有环境下自建GPT云平台，请<span
                  style="color: #2F6EFF;cursor: pointer" @click="clickConsult('企业自建GPT')">点击咨询</span>。</span>
            </div>
            <div class="pro1">
              <div v-sliden-in="{duration:1200}" key="zijian1" @click="constructPlat1">

                    <div class="grand1" style="position: relative;height: fit-content;padding: 0" >
                      <div class="back">
                        <div class="photo">
                          <img class="image" style="border-radius: 9px" src="@/assets/zijian1.png">
                        </div>
                      </div>
                      <div class="back1" style=" box-shadow: none">
                        <div class="title1" >  企业大模型云平台<br>规划及建设</div>
                        <div class="title2" style="margin:auto 0 0 auto">
                          更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:1200}" key="zijian2" @click="business1">
                <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                <div class="grand1" style="position: relative;height: fit-content" >
                  <div class="back">
                    <div class="photo">
                      <img class="image" style="border-radius: 9px" src="@/assets/zijian2.png">
                    </div>
                  </div>
                  <div class="back1" style=" box-shadow: none">
                    <div class="title1"> 业务GPTs项目服务</div>
                    <div class="title2" >
                      更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>
              <div  v-sliden-in="{duration:1200}" key="zijian3" @click="company1">

                    <div class="grand1" style="position: relative;height: fit-content" >
                      <div class="back">
                        <div class="photo">
                          <img class="image" style="border-radius: 9px" src="@/assets/zijian3.png">
                        </div>
                      </div>
                      <div class="back1" style=" box-shadow: none">
                        <div class="title1" > 油气企业数据<br>
                          （知识）治理</div>
                        <div class="title2" style="margin:auto 0 0 auto">
                          更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>
              <div  v-sliden-in="{duration:1200}" key="zijian4" @click="computingPower1">
                <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                <div class="grand1" style="position: relative;height: fit-content" >
                  <div class="back">
                    <div class="photo">
                      <img class="image" style="border-radius: 9px" src="@/assets/zijian4.png">
                    </div>
                  </div>
                  <div class="back1" style=" box-shadow: none">
                    <div class="title1"> 基础算力优化</div>
                    <div class="title2" >
                      更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                        </div>
                      </div>
                    </div>
                  </div>

            </div>
          </div>


          <div ref="companyskip"  style="background-color: white"  class="screen6">
<!--            <div>123123123</div><div>123123123</div>-->

            <div v-sliden-in="{duration:1200}"  class="title"  style="color:#371e76">安迅数智服务</div>
            <div   v-sliden-in="{duration:1200}" class="title2"  style="margin:19px 0;line-height: 22px">
              油气通是由安迅数智倾力打造并运营，秉承服务于油气行业各方，让油田数智化简单易行。
              <span class="AnvisionMore" style="color: #fb6400;font-weight: bolder" v-if="!this.AnvisionMore"
                    @click="handleClick">更多 >>
                </span>
                <div  v-if="AnvisionMore">
              安迅数智作为安东集团旗下的行业领先的油田数智化技术服务公司，以客户为中心，通过数智化技术与油气技术完美结合，立足于油气田现场伴随式服务，以最简单方式、最低成本、最快周期、最易落地为特点，提供油气行业的全栈式解决方案。
                  <span class="AnvisionMore" style="color: #f96400;width: fit-content;font-weight: bold"
                        @click="()=>{this.AnvisionMore=false}"><i style="font-weight: bold"
                                                                  class="el-icon-arrow-up"></i>
                  </span></div>
            </div>
            <div class="foot">
              <div  v-sliden-in="{duration:1200}" key="anxun1" class="left1">
                <div @click="hidden3"  style="display: flex">
                  <span class="title1" :style="hid2?{ color: '#fd6400' } : { color: '#122277' }">安迅数智</span>
                  <span  v-if="hid2===0" style="width:fit-content;margin:15px 0 0 auto;float: right;color: black"><i class="el-icon-arrow-up"></i> </span>
                  <span  v-if="hid2===1" style="width:fit-content;margin:15px 0 0 auto;float: right;color: black"><i class="el-icon-arrow-down"></i> </span>
                </div>

                  <div v-if="hid2===0"   class="title2">
                    安迅数智立足油气田现场各类应用场景，以最可靠的项目落地、最快的项目周期、最低的成本，提供专业的伴随式服务，满足油气田数智化刚性需求。<br>服务涵盖智慧油田综合解决方案、油气田全场景数智服务。

                  </div>
                  <div v-if="hid2===0"    :style="{ WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', backgroundColor: bgColor, backgroundImage: textGradient }"
                       class="title3">
                    油气数智，简单易行
                  </div>
                </div>

              <div  v-sliden-in="{duration:1200}" key="anxun2" class="left1">
                <div @click="hidden" style="display: flex">
                  <span class="title1" :style="hid?{ color: '#fd6400' } : { color: '#122277' }">安迅油气通智能</span>
                  <span  v-if="hid===0" style="width:fit-content;margin:15px 0 0 auto;float: right;color: black"><i class="el-icon-arrow-up"></i> </span>
                  <span  v-if="hid===1" style="width:fit-content;margin:15px 0 0 auto;float: right;color: black"><i class="el-icon-arrow-down"></i> </span>
                </div>

                  <div v-if="hid===0" class="title2">
                    安迅油气通智能技术有限公司专注为石油行业提供人工智能技术解决方案，致力于将人工智能技术深度融入石油业务中。<br>我们的专业团队拥有丰富的行业经验和先进的人工智能技术，能够为石油企业提供高效、精准的解决方案，助力企业实现数字化转型和智能化升级。

                </div>
                <div v-if="hid===0"    :style="{ WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', backgroundColor: bgColor, backgroundImage: textGradient }"
                     class="title3"> 油气“通”智能</div>
              </div>
              <div   v-sliden-in="{duration:1200}" key="anxun3" class="left1">
                <div @click="hidden1"   style="display: flex">
                  <span class="title1" :style="hid1?{ color: '#fd6400' } : { color: '#122277' }">石同多媒体</span>
                  <span  v-if="hid1===0" style="width:fit-content;margin:15px 0 0 auto;float: right;color: black"><i class="el-icon-arrow-up"></i> </span>
                  <span v-if="hid1===1" style="width:fit-content;margin:15px 0 0 auto;float: right;color: black"><i class="el-icon-arrow-down"></i> </span>
                </div>

                  <div v-if="hid1===0" class="title2">
                    石同多媒体致力于服务油气行业多媒体需求，让油气世界更加有声有型。<br>提供科学可视化、营销推广及品牌优化服务，满足客户技术宣传、品牌推广、培训、竞赛和科研汇报等方面的多媒体需求。

                  </div>
                  <div v-if="hid1===0"   :style="{ WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', backgroundColor: bgColor, backgroundImage: textGradient }"
                       class="title3">
                    视通油气
                  </div>

                </div>





<!--              <div class="companyIntroduce">-->

<!--                &lt;!&ndash;        智能&ndash;&gt;-->
<!--                <div class="grand2">-->
<!--                  <div class="back">-->

<!--                    <div class="photoHidden" style="opacity: 0.1;left:50%;transform:translateX(-50%);height: 100%;">-->
<!--                      <img loading="lazy" src="@/assets/shuzhiback.png"/>-->
<!--                    </div>-->
<!--                    <div class="title">-->
<!--                      <div class="title1">-->
<!--                        <img loading="lazy" src="@/assets/anxunCompang.png"/></div>-->
<!--                      <div class="title2">-->
<!--                        安迅数智立足油气田现场各类应用场景，以最可靠的项目落地、最快的项目周期、最低的成本，提供专业的伴随式服务，满足油气田数智化刚性需求。服务涵盖智慧油田综合解决方案、油气田全场景数智服务。-->
<!--                      </div>-->
<!--                      <div class="title3">-->
<!--                        <img loading="lazy" src="@/assets/anxunSlogn.png"/></div>-->
<!--                    </div>-->
<!--                  </div>-->

<!--                </div>-->
<!--                <div class="grand3">-->
<!--                  <div class="back">-->

<!--                    <div class="photoHidden" style="opacity: 0.1;left:50%;transform:translateX(-50%);height: 25%">-->

<!--                      <img loading="lazy" src="@/assets/anxun.png"/>-->
<!--                    </div>-->
<!--                    <div class="title">-->
<!--                      <div class="title1">-->
<!--                        <img loading="lazy" src="@/assets/oilCompany.png"/></div>-->
<!--                      <div class="title2">-->
<!--                        安迅油气通智能技术有限公司专注为石油行业提供人工智能技术解决方案，致力于将人工智能技术深度融入石油业务中。我们的专业团队拥有丰富的行业经验和先进的人工智能技术，能够为石油企业提供高效、精准的解决方案，助力企业实现数字化转型和智能化升级。-->
<!--                      </div>-->
<!--                      <div class="title3">-->
<!--                        <img loading="lazy" src="@/assets/oilSlogin.png"/></div>-->
<!--                    </div>-->
<!--                  </div>-->

<!--                </div>-->
<!--                <div class="grand4" style="padding-bottom: 0">-->
<!--                  <div class="back">-->
<!--                    <div class="photoHidden" style="opacity: 0.1;left:50%;transform:translateX(-50%);height: 100%">-->
<!--                      <img loading="lazy" src="@/assets/mediaCompany.png"/>-->
<!--                    </div>-->
<!--                    <div class="title">-->
<!--                      <div class="title1">-->
<!--                        <img loading="lazy" src="@/assets/mediaCom.png"/></div>-->
<!--                      <div class="title2">-->
<!--                        石同多媒体致力于服务油气行业多媒体需求，让油气世界更加有声有型。提供科学可视化、营销推广及品牌优化服务，满足客户技术宣传、品牌推广、培训、竞赛和科研汇报等方面的多媒体需求。-->
<!--                      </div>-->
<!--                      <div class="title3">-->
<!--                        <img loading="lazy" src="@/assets/mediaSlogin.png"/></div>-->
<!--                    </div>-->
<!--                  </div>-->

<!--                </div>-->


<!--              </div>-->


              <!--            <div class="banner">-->
              <!--              <div class="back1">-->
              <!--                <div class="backTitle1"> 油气智能化全场景覆盖</div>-->
              <!--                <div class="span"></div>-->
              <!--                <div class="backTitle2">-->
              <!--                  我们拥有从地质勘探、钻井、完井等全流程智能化场景覆盖。-->
              <!--                </div>-->
              <!--              </div>-->

              <!--              <div class="back1">-->
              <!--                <div class="backTitle1">油田现场验证</div>-->
              <!--                <div class="span"></div>-->
              <!--                <div class="backTitle2">-->
              <!--                  已经实现在安东多个交付的工程项目中率先落地投入使用，率先通过智能化手段实现降本增效。-->
              <!--                </div>-->
              <!--              </div>-->

              <!--              <div class="back1">-->
              <!--                <div class="backTitle1">油气知识积累</div>-->
              <!--                <div class="span"></div>-->
              <!--                <div class="backTitle2">-->
              <!--                  我们基于安东多年积累的大量油田工程技术知识，用于智能化模型训练并持续优化。-->
              <!--                </div>-->
              <!--              </div>-->
              <!--              <div class="back1">-->
              <!--                <div class="backTitle1">技术生态伙伴</div>-->
              <!--                <div class="span"></div>-->
              <!--                <div class="backTitle2">-->
              <!--                  积累人工智能技术伙伴资源，涵盖数据治理、AI大模型、算力服务、应用开发等多领域技术伙伴。-->
              <!--                </div>-->
              <!--              </div>-->
              <!--            </div>-->
            </div>

        </div>

          <!--底部-->
          <div ref="bottom" class="screen7">
            <bottomTip></bottomTip>
          </div>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
        <partnerTip :dialogVisible="dialogconsult" @hideDialog="hide"></partnerTip>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import headtip from "@/views/headTip.vue";
import headOther from "@/views/headOther.vue";
import bottomTip from "@/views/bottomTip.vue";
import technology from "@/views/Anxun/technology.vue";
import network from "@/views/Anxun/network.vue";
import {shareUrl} from "@/utils/vxshare";
import AIAnswer from "@/views/AIAnswer.vue";
import {getAuthorize} from "@/api/user";
import AIAnswerMobile from "@/views/AIAnswerMobile.vue";
import kefu from "@/views/kefu.vue";
import Swiper from 'swiper'
import 'swiper/dist/css/swiper.css'
import {vSlidenIn} from '@/utils/vSlidenIn.js'; // 确保路径正确
import {vZoomIn} from '@/utils/vZoomIn.js'; // 确保路径正确

export default {
  name: "Home",
  components: {
    AIAnswer,
    bottomTip,
    PartnerTip,
    consultTip,
    headtip,
    headOther,
    AIAnswerMobile
  },
  directives: {
    slidenIn: vSlidenIn,
    zoomIn: vZoomIn
  },
  data() {
    return {
      hid:1,
      hid1:1,
      hid2:1,
      swiper:null,
      colors: ['red', 'blue', 'green', 'purple', 'orange'],
      gradients: [
        'linear-gradient(to right,yellow, green, blue, red, indigo, violet,orange,)',
        'linear-gradient(to right, green, blue, red, indigo, violet,orange, yellow)',
        'linear-gradient(to right, blue, red, indigo, violet,orange, yellow, green)',
        'linear-gradient(to right,yellow, green, blue, red, indigo, violet,orange,)',
        'linear-gradient(to right, green, blue, red, indigo, violet,orange, yellow)',
        'linear-gradient(to right, blue, red, indigo, violet,orange, yellow, green)',
      ],
      index: 0,
      bgColor: 'linear-gradient(to right,yellow, green, blue, red, indigo, violet,orange,)',
      textGradient: 'linear-gradient(to right,yellow, green, blue, red, indigo, violet,orange,)',
      showTooltip:false,
      dialogVisibleInfo: false,
      registerIn: false,
      forgetIn: false,
      loginIn: false,
      isMobile: false,
      titleEn: "",
      contentEn: "",
      languageSelect: "",
      isClicked: false,
      userInfo: "",
      isLogin: "",
      isNEWs: 0,
      isTextDisplayed: false,
      isSendingMessage: false,
      identification: 0,
      messages: [{
        ok: 0,
        id: '00000',
        sender: "ai",
        introduce: "您好，欢迎访问油气通，我们专注为油气行业提供高效精准的人工智能解决方案，助力企业实现数智化转型和智能化升级。\n" +
            "<br>请问您有什么需求我可以帮您？🤔",
        resource: "",
      },
        // {
        //   id: "",
        //   ok: 1,
        //   sender: "ai",
        //   answer: "解释类GPTs，基于油气开发目标解释，不仅有助于工程作业人员实时掌握油气藏地质地质条件以及工程作业情况，还为开发过程中的决策和调整提供了有力支持 解释类GPTs，基于油气开发目标解释，不仅有助于工程作业人员实时",
        //   title:"解释类GPTs",s
        //   url:"https://cn.oilgasgpts.com/gptTranslate",
        //   evaluationI: "0",
        //   copy: 0,
        //   question: this.question,
        // }
      ],
      newMessage: "",
      focus: 0,
      focusno: 0,
      Message: "",
      token: "",
      baseURL: this.$utils.baseURL,
      avatar: require("@/assets/user.jpg"),
      communicateHistory: [],
      denyformShow: false,
      denyForm: {
        id: "",
        question: "",
        answer: "",
      },
      primary: "",
      submitForm: {
        id: "",
        question: "",
        answer: "",
        primary: "",
      },
      textarea: "",
      deny: 0,
      count: 0,
      uniqueId: "",
      question: "",
      answer: "",
      options: [
        {
          value: "1",
          label: "新建对话",
        },
        {
          value: "2",
          label: "历史记录",
        },
      ],
      optionvalue: "1",
      showPopover: false,
      timer: null,
      articles: [],
      input: 0,
      screenInitialHeight: window.innerHeight,
      screenHeight: window.innerHeight,
      keyboardHeight:'',
      changeVisable: 1,
      scrollPosition: null,
      model: false,
      AnvisionMore: false,
      avator: require("../assets/user.jpg"),
      userName: "",
      userId: null,
      dialogVisible: false,
      title: "数智油田",
      dialogconsult: false,
      product: 1,
      infogpt: 1,
      currentIndex: 0,
      itemsPerPage: 4,
      letters: '0123456789ABCDEF',
      carouselItems: [
        {
          img: require("../assets/logo1.png"),
          title: "智能钻井",
          text:
              "着陆段地质层分类预警：基于六个维度数据特征，建立最小AI模型，准确率提升20%。" +
              "<br>" +
              "水平段上下穿层预警：水平段穿层实时判断、上下切角实时预警建立最小模型。",
        },
        {
          img: require("../assets/logo2.png"),
          title: "智能压裂",
          text:
              "影响压裂效果的指标权重分析：影响压裂效果（EUR）的多维地质工程参数的指标权重分析。\n" +
              "<br>" +
              "压裂产量EUR预测：基于给定的多维地质工程参数，对EUR的AI预测模型准确率78%。",
        },
        {
          img: require("../assets/logo3.png"),
          title: "智能采油气",
          text: "磕头机的生产冲次辅助决策：构建智能采油气机器人提供辅助决策。",
        },
        {
          img: require("../assets/logo4.png"),
          title: "地层监测",
          text: "基于光纤的井筒完整性监测：基于光纤DTS温度数据建立对井筒故障监测及位置预警的最小模型。",
        },
        {
          img: require("../assets/logo5.png"),
          title: "设备设施监测",
          text: "设备故障检测：基于开源的滚动轴承数据建立模型，实时判断轴承故障类型、故障大小、故障位置",
        },
      ],
      groupedItems: [],
      scrollY: 0,
      scrollHeight: 0,
      scrollTrue: true,
      scrollback: false,
      screendata:0,
    };
  },

  computed: {
    kefu() {
      return kefu
    },
    network() {
      return network
    },
    technology() {
      return technology
    },

  },
  created() {
    this.intervalId = setInterval(this.changeColors, 2000);
    localStorage.setItem("home", 1)
    // console.log("___________________",router.history.current.query)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    // console.log("用户分享")
    setTimeout(() => {
      // const imageUrl = 'https://i.postimg.cc/N0d3m2C9/GPTLOGO.png'; // Replace with the actual image URL
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        // imageUrl: encodeURIComponent(imageUrl), // Adding the image URL to the shareData object
        pageTitle: '油气通GPT云平台-全球油气行业专业、开放的大模型人工智能云平台',
        pageDescription: '安东油气通GPT云平台，发挥安东领先的、创新型一体化油田技术服务优势，为客户提供油气资讯GPTs、企业管理知识GPTs、油气专业类GPTs和通用类GPTs。',
        pageKeywords: '油气通GPT大模型，油气资讯GPTs，企业管理知识GPTs，油气专业类GPTs，通用类GPTs，备全生命周期管理，设备在线监测与预警，场站数字孪生，安迅数智，油气通GPT',
      };
      shareUrl(shareData);
    }, 1000);
    console.log("用户分享")
  },
  watch: {

  },

  beforeDestroy() {
    // this.$refs.total.removeEventListener('scroll', this.handleScroll);
    if (this.swiper) {
      this.swiper.destroy();
      this.swiper = null;
    }
    clearInterval(this.intervalId);
    this.$refs.total.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.updateModel);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);

    this.updateModel();
    this.initSwiper();

    // 通用GPT
    if (sessionStorage.getItem("skip") === '1') {
      this.$nextTick(() => {
        this.skipp1();
      });
    }
    // 油气资讯
    else if (sessionStorage.getItem("skip") === '2') {
      console.log("tiaozhuan")
      this.$nextTick(() => {
        this.skipp2();
      });
    }
    // 底部
    else if (sessionStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
    // 油气专业GPTs
    else if (sessionStorage.getItem("skip") === '4') {
      this.$nextTick(() => {
        this.skipp4();
      });
    }
    // 油气专业GPTs
    else if (sessionStorage.getItem("skip") === '5') {
      this.$nextTick(() => {
        this.skipp5();
      });
    }
    // 油气开发作业GPT应用服务
    else if (sessionStorage.getItem("skip") === '6') {
      this.$nextTick(() => {
        this.skipp6();
      });
    } else if (sessionStorage.getItem("skip") === '7') {
      this.$nextTick(() => {
        this.skipp7();
      });
    } else if (sessionStorage.getItem("skip") === '8') {
      this.$nextTick(() => {
        this.skipp8();
      });
    } else if (sessionStorage.getItem("skip") === '10') {
      this.$nextTick(() => {
        this.skipp10();
      });
    } else if (sessionStorage.getItem("skip") === '11') {
      this.$nextTick(() => {
        this.skipp11();
      });
    } else if (sessionStorage.getItem("skip") === '12') {
      this.$nextTick(() => {
        this.skipp12();
      });
    } else if (sessionStorage.getItem("skip") === '13') {
      this.$nextTick(() => {
        this.skipp13();
      });
    } else if (sessionStorage.getItem("skip") === '14') {
      this.$nextTick(() => {
        this.skipp14();
      });
    }
    // this.$nextTick(()=>{
    //   this.$refs.totalModel.addEventListener('scroll', this.handleScroll);
    //
    //     })


    // 添加滚动事件监听器
    this.updateGroupedItems();
  },
  // beforeUnmount() {
  //   window.removeEventListener('resize', this.handleResize);
  // },
  methods: {
    changeColors() {
      this.index = (this.index + 1) % this.colors.length;
      this.bgColor = this.gradients[this.index];
      this.textGradient = this.gradients[this.index];
    },
    handleClick() {
      this.AnvisionMore = true;
      setTimeout(() => {
        this.AnvisionMore = false;
      }, 50000);
    },
    hidden3(){
      // console.log("heii",this.hid2)
      if(this.hid2===1){
        this.hid2 = 0
      }
      else{
        this.hid2 = 1
      }
      // this.hid2=!this.hid2
      setTimeout(() => {
        // alert("出入")
        this.hid2 = 1
      }, 50000);
    },
    hidden23(){
      this.hid2=!this.hid2
      setTimeout(() => {
        // alert("出入")
        this.hid2 = 1
      }, 50000);
    },
    hidden(){
      if(this.hid===1){
        this.hid = 0
      }
      else{
        this.hid = 1
      }
      setTimeout(() => {
        // alert("出入")
        this.hid = 1
      }, 50000);
    },
    hidden2(){
      this.hid=0

    },
    hidden1(){
      if(this.hid1===1){
        this.hid1 = 0
      }
      else{
        this.hid1 = 1
      }
      setTimeout(() => {
        // alert("出入")
        this.hid1 = 1
      }, 50000);
    },
    hidden12(){
      this.hid1=0
      setTimeout(() => {
        // alert("出入")
        this.hid1 = 1
      }, 50000);
    },
    updateModel() {
      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      this.model = screenWidth <= 700;
    },
    initSwiper() {
      // 确保 swiper-container 已经被渲染
      this.$nextTick(() => {
        this.swiper = new Swiper(".swiper-container01", {
          slidesPerView: 1.1,
          spaceBetween: 10,
          loop: true,
          // autoplay: {
          //   delay:500000,
          //   stopOnLastSlide:false,
          //   disableOnInteraction:false,
          // },
          // 控制循环
          autoplay:5000,
          effect:'cube',
          pagination:".swiper-pagination",
          paginationType:"bullets",
          // paginationClickable:true,
          paginationClickable: true,
          //   点击分页器控制swiper切换
          preventClicks : false,
          // 当swiper在触摸时阻止默认事件（preventDefault），用于防止触摸时触发链接跳转
          preventLinksPropagation : false,  //默认true，阻止click冒泡。拖动Swiper时阻止click事件。
        });
        this.swiper = new Swiper(".swiper-container0", {
          direction:'vertical',
          height:100,
          slidesPerView: 1.1,
          spaceBetween: 10,
          loop: false,
          // autoplay: {
          //   delay:500000,
          //   stopOnLastSlide:false,
          //   disableOnInteraction:false,
          // },
          // 控制循环
          autoplay:5000,
          effect:'fade',
          pagination:".swiper-pagination",
          paginationType:"bullets",
          // paginationClickable:true,
          paginationClickable: true,
          //   点击分页器控制swiper切换
          preventClicks : false,
          // 当swiper在触摸时阻止默认事件（preventDefault），用于防止触摸时触发链接跳转
          preventLinksPropagation : false,  //默认true，阻止click冒泡。拖动Swiper时阻止click事件。
        });
        // eslint-disable-next-line no-undef
        this.swiper = new Swiper(".swiper-container1", {
          effect:'coverflow',
          slidesPerView: 1.1,
          spaceBetween: 10,

          loop: false,
          // 控制循环
          autoplay:5000,
          pagination:".swiper-pagination",
          paginationType:"bullets",
          // paginationClickable:true,
          paginationClickable: true,
          //   点击分页器控制swiper切换
          preventClicks : false,
          // 当swiper在触摸时阻止默认事件（preventDefault），用于防止触摸时触发链接跳转
          preventLinksPropagation : false,  //默认true，阻止click冒泡。拖动Swiper时阻止click事件。
        });
        this.swiper = new Swiper(".swiper-container2", {
          effect:'flip',
          slidesPerView: 1.1,
          spaceBetween: 10,
          loop: false,
          // 控制循环
          autoplay:5000,
          pagination:".swiper-pagination",
          paginationType:"bullets",
          paginationClickable: true,
          //   点击分页器控制swiper切换
          preventClicks : false,
          // 当swiper在触摸时阻止默认事件（preventDefault），用于防止触摸时触发链接跳转
          preventLinksPropagation : false,  //默认true，阻止click冒泡。拖动Swiper时阻止click事件。
        });
        this.swiper = new Swiper(".swiper-container-pc", {
          autoplay:5000,
          disableOnInteraction: true,
          slidesPerView: 3,
          spaceBetween: 5,
          loop: false,
          pagination:".swiper-pagination",
          paginationType:"bullets",
          paginationClickable:true,
          pauseOnMouseEnter: true, // 鼠标悬停时暂停自动播放
        });
        this.swiper = new Swiper(".swiper-container3", {
          slidesPerView: 1.1,
          spaceBetween: 10,
          loop: false,
          // 控制循环
          autoplay:5000,
          pagination:".swiper-pagination",
          paginationType:"bullets",
        });
        this.swiper = new Swiper(".swiper-container4", {
          slidesPerView: 1.1,
          spaceBetween: 10,
          loop: false,
          // 控制循环
          autoplay:5000,
          pagination:".swiper-pagination",
          paginationType:"bullets",
        });
        this.swiper = new Swiper(".swiper-container5", {
          slidesPerView: 1.1,
          spaceBetween: 10,
          loop: false,
          // 控制循环
          autoplay:5000,
          pagination:".swiper-pagination",
          paginationType:"bullets",
        });
        this.swiper = new Swiper(".swiper-container6", {
          slidesPerView: 1.1,
          spaceBetween: 10,
          loop: false,
          // 控制循环
          autoplay:5000,
          pagination:".swiper-pagination",
          paginationType:"bullets",
        });
        this.swiper = new Swiper(".swiper-container7", {
          slidesPerView: 1.1,
          spaceBetween: 10,
          loop: false,
          // 控制循环
          autoplay:5000,
          pagination:".swiper-pagination",
          paginationType:"bullets",
        });
// 鼠标悬停时停止自动播放
//         document.querySelector('.swiper-container-pc').addEventListener('mouseenter', function() {
//            console.log(document.querySelector('.swiper-container-pc').swiper.pauseOnMouseEnter)
//           document.querySelector('.swiper-container-pc').swiper.autoplayPaused=true;
//           // this.$set(document.querySelector('.swiper-container-pc').swiper.autoplayPaused,true)
//
//           console.log("-------yidu")
//           console.log(document.querySelector('.swiper-container-pc').swiper)
//
//         });
//
// // 鼠标移出时恢复自动播放
//         document.querySelector('.swiper-container-pc').addEventListener('mouseleave', function() {
//           document.querySelector('.swiper-container-pc').swiper.autoplayPaused=false;
//           // this.$set(document.querySelector('.swiper-container-pc').swiper.autoplayPaused,false)
//           console.log("-------yichu")
//           console.log(document.querySelector('.swiper-container-pc').swiper)
//         });

      });
    },
    // handleResize() {
    //   this.screenHeight = window.innerHeight;
    //   if (this.screenHeight < this.screenInitialHeight) {
    //     this.keyboardHeight = this.screenInitialHeight - this.screenHeight;
    //   } else {
    //     this.keyboardHeight = 0;
    //   }
    //   // console.log("键盘高度",  this.keyboardHeight)
    // },
    changeheadVisable() {
      if (this.changeVisable === 1) {
        this.changeVisable = 0
      } else {
        this.changeVisable = 1
      }
      console.log("父组件", this.changeVisable)
    },
    pushLogin() {
      let href = window.location.href.split("&code")[0];
      getAuthorize(href).then((res) => {
        window.location.replace(res.data.url);
      });
      // this.$router.push("/login")
      // this.$emit("pushLogin")
    },
    handleClose() {
      this.denyformShow = true;
    },
    showDeleteButton(historyItem) {
      // 在鼠标悬停时显示删除按钮
      this.$set(historyItem, "showDeleteButton", true);
    },
    hideDeleteButton(historyItem) {
      // 在鼠标移出时隐藏删除按钮
      this.$set(historyItem, "showDeleteButton", false);
    },
    showDialog(type) {
      this.dialogVisible = type;
    },
    optionnew(item) {
      console.log("Selected value:", item.value);
    },
    dateIfAddZero(time) {
      return time < 10 ? "0" + time : time;
    },

    changeWho() {
      this.showPopover = false;
    },

    handleScroll() {
      if(this.model===false){
        this.scrollHeight = this.$refs.total.scrollTop;
      }
      else{
        this.scrollHeight = this.$refs.totalModel.scrollTop;

      }
      // console.log("--------------------",this.scrollHeight)

      if( this.scrollHeight<100){
        this.scrollTrue=true
      }
      else{
        this.scrollTrue=false
      }
      if( this.scrollHeight<50){
        this.scrollback=false
      }
      else{
        this.scrollback=true
      }
      // console.log("gaodu",this.scrollHeight<100)
    },

    clickConsult(item) {
      this.title = item
      this.dialogVisible = true
      sessionStorage.setItem('title', item)
    },
    show() {
      localStorage.setItem("skip", 0)
      this.dialogconsult = true;
    },
    gpts() {
      localStorage.setItem("skip", 0)
      window.open("https://www.oilgascommunity.com/recently?id=39", "_blank");
    },
    // 油藏地质
    ground() {
      localStorage.setItem("skip", 0)
      window.open("/groundOil", "_blank");
    },
    oilgpt() {
      localStorage.setItem("skip", 0)
      window.open("/gptOil", "_blank");
    },
    // 钻井
    hole() {
      localStorage.setItem("skip", 0)
      window.open("/makeHole", "_blank");
    },
    oilDesign() {
      localStorage.setItem("skip", 0)
      window.open("/gptDesign", "_blank");
    },
    oilTranslate() {
      localStorage.setItem("skip", 0)
      window.open("/gptTranslate", "_blank");
    },
    oilTechnology() {
      localStorage.setItem("skip", 0)
      window.open("/gptTechnology", "_blank");
    },
    oilgpt2() {
      localStorage.setItem("skip", 0)
      window.open("/gptTranslate", "_blank");
    },
    // 压裂
    frack() {
      localStorage.setItem("skip", 0)
      window.open("/frackOil", "_blank");
    },
    oilgpt3() {
      localStorage.setItem("skip", 0)
      window.open("/gptHole", "_blank");
    },
    // 采油
    getOil() {
      localStorage.setItem("skip", 0)
      window.open("/getOil", "_blank");
    },

    // <!--设备检测-->
    equipment() {
      localStorage.setItem("skip", 0)
      window.open("/monitorEqu", "_blank");
    },

    // 地层监测
    monitor() {
      localStorage.setItem("skip", 0)
      window.open("/monitorOil", "_blank");
    },

    // 油田管理
    menage() {
      localStorage.setItem("skip", 0)
      window.open("/menageOil", "_blank");
    },

    station() {
      localStorage.setItem("skip", 0)
      window.open("/station", "_blank");
    },
    security() {
      localStorage.setItem("skip", 0)
      window.open("/security", "_blank");
    },
    productANxun() {
      localStorage.setItem("skip", 0)
      window.open("/product", "_blank");
    },
    equipmentAnxun() {
      localStorage.setItem("skip", 0)
      window.open("/equipmentAnxun", "_blank");
    },
    technologyAnxun() {
      localStorage.setItem("skip", 0)
      window.open("/technology", "_blank");
    },
    monitorAnxun() {
      localStorage.setItem("skip", 0)
      window.open("/monitorAnxun", "_blank");
    },
    networkAnxun() {
      localStorage.setItem("skip", 0)
      window.open("/pipeline", "_blank");
    },
    basic() {
      localStorage.setItem("skip", 0)
      window.open("/basic", "_blank");
    },
    setting() {
      localStorage.setItem("skip", 0)
      window.open("/digital", "_blank");
    },
    storage() {
      localStorage.setItem("skip", 0)
      window.open("/storage", "_blank");
    }, training() {
      localStorage.setItem("skip", 0)
      window.open("/training", "_blank");
    },
    build() {
      localStorage.setItem("skip", 0)
      window.open("/build", "_blank");
    },
    traning() {
      const element = this.$refs.aiPeixun;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    governance() {
      window.open("/governance", "_blank");
    },
    analysis() {
      window.open("/analysis", "_blank");
    },
    traningAi() {
      window.open("/traning", "_blank");
    },

    consult() {
      window.open("/consult", "_blank");
    },
    ERP() {
      window.open("/ERP", "_blank");
    },
    java() {
      window.open("/java", "_blank");
    },
    web() {
      window.open("/web", "_blank");
    },
    service() {
      const element = this.$refs.oilZixun;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    // meitiProduct() {
    // this.$confirm('您即将离开油气通平台，进入石同多媒体网站平台', '提示', {
    //       confirmButtonText: '继续访问',
    //       cancelButtonText: '取消',
    //       type: 'warning'
    //     }).then(() => {
    //     window.open("http://www.oilmates-omm.com/h-col-106.html", "_blank");
    // }).catch(() => {
    //
    //     });
    // },
    // meiti3D() {
    // this.$confirm('您即将离开油气通平台，进入石同多媒体网站平台', '提示', {
    //       confirmButtonText: '继续访问',
    //       cancelButtonText: '取消',
    //       type: 'warning'
    //     }).then(() => {
    //     window.open("http://www.oilmates-omm.com/h-col-102.html", "_blank");
    // }).catch(() => {
    //
    //     });
    // },
    // meitiDesign() {
    // this.$confirm('您即将离开油气通平台，进入石同多媒体网站平台', '提示', {
    //       confirmButtonText: '继续访问',
    //       cancelButtonText: '取消',
    //       type: 'warning'
    //     }).then(() => {
    //     window.open("http://www.oilmates-omm.com/h-col-104.html", "_blank");
    // }).catch(() => {
    //     });
    // },
    meitiProduct() {
      this.$confirm('<div><a style="color: white">您即将离开油气通平台，进入石同多媒体网站平台。</a><br>  <a style="color: orange">http://www.oilmates-omm.com/h-col-106.html</a></div>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        type: 'warning'
      }).then(() => {
        window.open("http://www.oilmates-omm.com/h-col-106.html", "_blank");
      }).catch(() => {

      });
    },
    meiti3D() {
      this.$confirm('<div><a style="color: white">您即将离开油气通平台，进入石同多媒体网站平台。</a><br>  <a style="color: orange">http://www.oilmates-omm.com/h-col-102.html</a></div>', ' ', {
        confirmButtonText: '继续访问',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        window.open("http://www.oilmates-omm.com/h-col-102.html", "_blank");
      }).catch(() => {
      });
    },
    meitiDesign() {
      this.$confirm('<div><a style="color: white">您即将离开油气通平台，进入石同多媒体网站平台。</a><br>  <a style="color: orange">http://www.oilmates-omm.com/h-col-104.html</a></div>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        type: 'warning'
      }).then(() => {
        window.open("http://www.oilmates-omm.com/h-col-104.html", "_blank");
      }).catch(() => {
      });
    },
    construct() {
      const element = this.$refs.companyZijian;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    constructPlat() {
      window.open("/construct", "_blank");
    },
    business() {
      window.open("/business", "_blank");
    },
    company() {
      window.open("/company", "_blank");
    },
    computingPower() {
      window.open("/computingPower", "_blank");
    },

    menageService() {
      const element = this.$refs.menageService;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    application() {
      window.open("/application", "_blank");
    },
    oilApplication() {
      window.open("/oilApplication", "_blank");
    },

    knowledge() {
      window.open("/gptKnowledge", "_blank");
    },
    data() {
      window.open("/gptData", "_blank");
    },
    wisdom() {
      window.open("/wisdom", "_blank");
    },
    plat() {
      window.open("/plat", "_blank");
    },
    design() {
      window.open("/design", "_blank");
    },
    automation() {
      window.open("/automation", "_blank");
    },

    traning1() {
      sessionStorage.setItem("skip", 11)
      this.$router.push("/traning")
    },
    service1() {
      sessionStorage.setItem("skip", 11)
      this.$router.push("/service")
    },
    construct1() {
      sessionStorage.setItem("skip", 11)
      this.$router.push("/construct")
    },
    menageService1() {
      const element = this.$refs.menageServices;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    application1() {
      sessionStorage.setItem("skip", 11)
      this.$router.push("/application")
    },
    oilApplication1() {
      sessionStorage.setItem("skip", 11)
      this.$router.push("/oilApplication")
    },

    knowledge1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/gptKnowledge")
    },
    data1() {
      this.$router.push("/gptData")
      sessionStorage.setItem("skip", 10)
    },
    wisdom1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/wisdom");
    },
    plat1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/plat")
    },
    design1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/design")
    },
    automation1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/automation")
    },

    gpts1() {
      window.open("https://www.oilgascommunity.com/recently?id=39", "_blank");
    },
    // 油藏地质
    ground1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/groundOil")
    },
    oilgpt1() {
      sessionStorage.setItem("skip", 5)
      this.$router.push("/gptOil")
    },
    // 钻井
    hole1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/makeHole")
    },

    oilgpt21() {
      sessionStorage.setItem("skip", 5)
      this.$router.push("/gptTranslate")
    },
    // 压裂
    frack1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/frackOil")
    },
    oilgpt31() {
      sessionStorage.setItem("skip", 5)
      this.$router.push("/gptHole")
    },
    // 采油
    getOil1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/getOil")
    },

    // <!--设备检测-->
    equipment1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/monitorEqu")
    },

    // 地层监测
    monitor1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/monitorOil")
    },

    // 油田管理
    menage1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/menageOil")
    },

    station1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/station")
    },
    security1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/security")
    },
    productANxun1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/product")
    },
    equipmentAnxun1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/equipmentAnxun")
    },
    technologyAnxun1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/technology")
    },
    monitorAnxun1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/monitorAnxun")
    },
    networkAnxun1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/pipeline")
    },
    basic1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/basic")
    },
    setting1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/digital")
    }, storage1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/storage")
    }, training1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/training")
    }, build1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/build")
    },
    showPartner() {
      localStorage.setItem("skip", 0)
      this.dialogconsult = true;
    },

    hideDialog() {
      localStorage.setItem("skip", 0)
      this.dialogVisible = false;
    },
    hide() {
      localStorage.setItem("skip", 0)
      this.dialogconsult = false;
    },
    expo() {
      this.$confirm('<div><a style="color: white">您即将离开油气通平台，进入油气展馆网站平台。</a><br><span style="color: orange"><a >https://cn.oilgasmall.com/</a></span></div>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        type: 'warning'
      }).then(() => {
        localStorage.setItem("skip", 0)
        window.open("https://cn.oilgasmall.com/", "_blank");
      }).catch(() => {

      });
    },
    job() {
      this.$confirm('<div><a style="color: white">您即将离开油气通平台，进入油气人才网站平台。</a><br>  <a style="color: orange">https://oilgasjobai.com/</a></div>', '', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        type: 'warning'
      }).then(() => {
        localStorage.setItem("skip", 0)
        window.open("https://oilgasjobai.com/", "_blank");
      }).catch(() => {

      });
    },
    info() {
      this.$confirm('<div><a style="color: white">您即将离开油气通平台，进入油气知识网站平台。</a><br>  <a style="color: orange">https://cn.oilgasinfoai.com/</a></div>', ' ', {
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        confirmButtonText: '继续访问',
        cancelButtonText: null,

        type: 'warning'
      }).then(() => {
        // 用户确认离开，继续路由跳转
        localStorage.setItem("skip", 0)
        window.open("https://cn.oilgasinfoai.com/", "_blank");
      }).catch(() => {

      });
    },
    wen() {
      this.$confirm('<div><a style="color: white">您即将离开油气通平台，进入文心一言网站平台。</a><br>  <a style="color: orange">https://yiyan.baidu.com/</a></div>', ' ', {
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        localStorage.setItem("skip", 0)
        window.open("https://yiyan.baidu.com/", "_blank");
      }).catch(() => {
      });
    },
    dou() {
      this.$confirm('<div><a style="color: white">您即将离开油气通平台，进入豆包网站平台。</a><br>  <a style="color: orange">https://www.doubao.com/</a></div>', ' ', {
        confirmButtonText: '继续访问',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        localStorage.setItem("skip", 0)
        window.open("https://www.doubao.com/", "_blank");
      }).catch(() => {

      });    },
    xun() {

      this.$confirm('<div><a style="color: white">您即将离开油气通平台，进入讯飞星火网站平台。</a><br>  <a style="color: orange">https://xinghuo.xfyun.cn/</a></div>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        type: 'warning'
      }).then(() => {
        localStorage.setItem("skip", 0)
        window.open("https://xinghuo.xfyun.cn/", "_blank");
      }).catch(() => {

      });    },
    tong() {
      this.$confirm('<div><a style="color: white">您即将离开油气通平台，进入通义千问网站平台。</a><br>  <a style="color: orange">https://tongyi.aliyun.com/qianwen/</a></div>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        type: 'warning'
      }).then(() => {
        localStorage.setItem("skip", 0)
        window.open("https://tongyi.aliyun.com/qianwen/", "_blank");
      }).catch(() => {

      });    },
    zhi() {
      this.$confirm('<div><a style="color: white">您即将离开油气通平台，进入智谱清言网站平台。</a><br>  <a style="color: orange">https://chatglm.cn/</a></div>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        type: 'warning'
      }).then(() => {
        localStorage.setItem("skip", 0)
        window.open("https://chatglm.cn/", "_blank");
      }).catch(() => {

      });
    },
    teng() {
      this.$confirm('<div><a style="color: white">您即将离开油气通平台，进入腾讯混元网站平台。</a><br>  <a style="color: orange">https://hunyuan.tencent.com/</a></div>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        type: 'warning'
      }).then(() => {
        localStorage.setItem("skip", 0)
        window.open("https://hunyuan.tencent.com/", "_blank");
      }).catch(() => {

      });
    },
    tian() {
      this.$confirm('<div><a style="color: white">您即将离开油气通平台，进入天工AI网站平台。</a><br>  <a style="color: orange">https://home.tiangong.cn/</a></div>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        type: 'warning'
      }).then(() => {
        localStorage.setItem("skip", 0)
        window.open("https://home.tiangong.cn/", "_blank");
      }).catch(() => {

      });
    },
    ge() {
      this.$confirm('<div><a style="color: white">您即将离开油气通平台，进入文心一格网站平台。</a><br>  <a style="color: orange">https://yige.baidu.com/</a></div>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        type: 'warning'
      }).then(() => {
        localStorage.setItem("skip", 0)
        window.open("https://yige.baidu.com/", "_blank");
      }).catch(() => {

      });
    },
    wan() {
      this.$confirm('<div><a style="color: white">您即将离开油气通平台，进入万彩AI网站平台。</a><br>  <a style="color: orange">https://ai.kezhan365.com/</a></div>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        type: 'warning'
      }).then(() => {
        localStorage.setItem("skip", 0)
        window.open("https://ai.kezhan365.com/", "_blank");
      }).catch(() => {

      });
    },
    yi() {
      this.$confirm('<div><a style="color: white">您即将离开油气通平台，进入一帧秒创网站平台。</a><br>  <a style="color: orange">https://aigc.yizhentv.com/</a></div>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        type: 'warning'
      }).then(() => {
        localStorage.setItem("skip", 0)
        window.open("https://aigc.yizhentv.com/", "_blank");
      }).catch(() => {

      });
    },


    expo1() {
      this.$confirm('<div><a style="color: white">您即将离开油气通平台，进入油气展馆网站平台。</a><br><a style="color: orange">https://cn.oilgasmall.com/</a></div>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        customClass: 'del-model',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,

        type: 'warning'
      }).then(() => {
        sessionStorage.setItem("skip", 2)
        // 在 Vue 组件或路由导航守卫中使用 router.push() 导航到外部链接
        const externalLink = 'https://cn.oilgasmall.com/';
        // 导航到外部链接处理路由，并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

      }).catch(() => {

      });

    },
    job1() {
      this.$confirm('<div><a style="color: white">您即将离开油气通平台，进入油气人才网站平台。</a><br>  <a style="color: orange">https://oilgasjobai.com/</a></div>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        customClass:'text',
        type: 'warning'
      }).then(() => {
        sessionStorage.setItem("skip", 2)
        const externalLink = 'https://oilgasjobai.com/';
        // 导航到外部链接处理路由，并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

      }).catch(() => {

      });
    },
    info1() {
      this.$confirm('<div><a style="color: white">您即将离开油气通平台，进入油气知识网站平台。</a><br>  <a style="color: orange">https://cn.oilgasinfoai.com/</a></div>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        type: 'warning'
      }).then(() => {
        sessionStorage.setItem("skip", 2)
        const externalLink = 'https://cn.oilgasinfoai.com/';
        // 导航到外部链接处理路由，并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

      }).catch(() => {

      });
    },
    wen1() {
      this.$confirm('<div><a style="color: white">您即将离开油气通平台，进入文心一言网站平台。</a><br>  <a style="color: orange">https://yiyan.baidu.com/</a></div>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        type: 'warning'
      }).then(() => {
        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://yiyan.baidu.com/';
        // 导航到外部链接处理路由，并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

      }).catch(() => {

      });
    },
    traningAi1() {
      sessionStorage.setItem("skip", 12)
      this.$router.push("/traning")
    },
    consult1() {
      sessionStorage.setItem("skip", 12)
      this.$router.push("/consult")
    },
    constructPlat1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/construct")

    },
    business1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/business")
    },
    company1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/company")
    },
    computingPower1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/computingPower")
    },
    position1() {
      const element = this.$refs.oilZixun;
      const rect = element.offsetTop - 150 + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      function scrollToElement(element, to, duration) {
        if (duration <= 0) return;
        const difference = to - element.scrollTop;
        const perTick = difference / duration * 10;
        setTimeout(() => {
          element.scrollTop = element.scrollTop + perTick;
          if (element.scrollTop === to) return;
          scrollToElement(element, to, duration - 10);
        }, 10);
      }

      scrollToElement(this.$refs.total, rect-50, 500); // 滚动持续时间为500毫秒
    },
    position2() {
      const element = this.$refs.generalMove;
      const rect = element.offsetTop - 20 + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      function scrollToElement(element, to, duration) {
        if (duration <= 0) return;
        const difference = to - element.scrollTop;
        const perTick = difference / duration * 10;
        setTimeout(() => {
          element.scrollTop = element.scrollTop + perTick;
          if (element.scrollTop === to) return;
          scrollToElement(element, to, duration - 10);
        }, 10);
      }

      scrollToElement(this.$refs.total, rect-50, 500); // 滚动持续时间为500毫秒
    },
    position3() {
      const element = this.$refs.aiPeixun;
      const rect = element.offsetTop - 20 + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      function scrollToElement(element, to, duration) {
        if (duration <= 0) return;
        const difference = to - element.scrollTop;
        const perTick = difference / duration * 10;
        setTimeout(() => {
          element.scrollTop = element.scrollTop + perTick;
          if (element.scrollTop === to) return;
          scrollToElement(element, to, duration - 10);
        }, 10);
      }

      scrollToElement(this.$refs.total, rect-50, 500); // 滚动持续时间为500毫秒
    },
    position4() {
      const element = this.$refs.companyZijian;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      function scrollToElement(element, to, duration) {
        if (duration <= 0) return;
        const difference = to - element.scrollTop;
        const perTick = difference / duration * 10;
        setTimeout(() => {
          element.scrollTop = element.scrollTop + perTick;
          if (element.scrollTop === to) return;
          scrollToElement(element, to, duration - 10);
        }, 10);
      }

      scrollToElement(this.$refs.total, rect-50, 500); // 滚动持续时间为500毫秒
    },
    position5() {
      const element = this.$refs.menageService;
      const rect = element.offsetTop - 20 + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      function scrollToElement(element, to, duration) {
        if (duration <= 0) return;
        const difference = to - element.scrollTop;
        const perTick = difference / duration * 10;
        setTimeout(() => {
          element.scrollTop = element.scrollTop + perTick;
          if (element.scrollTop === to) return;
          scrollToElement(element, to, duration - 10);
        }, 10);
      }

      scrollToElement(this.$refs.total, rect-50, 500); // 滚动持续时间为500毫秒
    },
    position6() {
      const element = this.$refs.consultMove;
      const rect = element.offsetTop - 20 + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      function scrollToElement(element, to, duration) {
        if (duration <= 0) return;
        const difference = to - element.scrollTop;
        const perTick = difference / duration * 10;
        setTimeout(() => {
          element.scrollTop = element.scrollTop + perTick;
          if (element.scrollTop === to) return;
          scrollToElement(element, to, duration - 10);
        }, 10);
      }

      scrollToElement(this.$refs.total, rect-50, 500); // 滚动持续时间为500毫秒
    },
    position7() {
      const element = this.$refs.professional;
      const rect = element.offsetTop - 50 + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      function scrollToElement(element, to, duration) {
        if (duration <= 0) return;
        const difference = to - element.scrollTop;
        const perTick = difference / duration * 10;
        setTimeout(() => {
          element.scrollTop = element.scrollTop + perTick;
          if (element.scrollTop === to) return;
          scrollToElement(element, to, duration - 10);
        }, 10);
      }

      scrollToElement(this.$refs.total, rect-50, 500); // 滚动持续时间为500毫秒
    },
    position8() {
      const element = this.$refs.moveProduct;
      const rect = element.offsetTop - 50 + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      function scrollToElement(element, to, duration) {
        if (duration <= 0) return;
        const difference = to - element.scrollTop;
        const perTick = difference / duration * 10;
        setTimeout(() => {
          element.scrollTop = element.scrollTop + perTick;
          if (element.scrollTop === to) return;
          scrollToElement(element, to, duration - 10);
        }, 10);
      }

      scrollToElement(this.$refs.total, rect-50, 500); // 滚动持续时间为500毫秒
    },
    position9() {
      const element = this.$refs.mediaMove;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      function scrollToElement(element, to, duration) {
        if (duration <= 0) return;
        const difference = to - element.scrollTop;
        const perTick = difference / duration * 10;
        setTimeout(() => {
          element.scrollTop = element.scrollTop + perTick;
          if (element.scrollTop === to) return;
          scrollToElement(element, to, duration - 10);
        }, 10);
      }

      scrollToElement(this.$refs.total, rect-50, 500); // 滚动持续时间为500毫秒
    },
    position10() {
      const element = this.$refs.companyZijian;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      function scrollToElement(element, to, duration) {
        if (duration <= 0) return;
        const difference = to - element.scrollTop;
        const perTick = difference / duration * 10;
        setTimeout(() => {
          element.scrollTop = element.scrollTop + perTick;
          if (element.scrollTop === to) return;
          scrollToElement(element, to, duration - 10);
        }, 10);
      }

      scrollToElement(this.$refs.total, rect-100, 500); // 滚动持续时间为500毫秒
    },
    skipp1() {
      const element = this.$refs.general;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      // window.scrollTo({
      //   top: rect,
      //   behavior: 'smooth'
      // });

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp2() {
      const element = this.$refs.zixun;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      console.log(rect)
      // window.scrollTo({
      //   top: rect,
      //   behavior: 'smooth'
      // });
      this.$nextTick(() => {
        // element.value.scrollIntoView({ behavior: 'smooth' });
        this.$refs.totalModel.scrollTop = rect-500
        // console.log( this.$refs.totalModel.scrollTop)
      });
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
      // window.scrollTo({
      //   top: rect,
      //   behavior: 'smooth'
      // });
    },
    skipp4() {
      const element = this.$refs.professional;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      // window.scrollTo({
      //   top: rect,
      //   behavior: 'smooth'
      // });
      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    skipp5() {
      const element = this.$refs.professional1;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      // window.scrollTo({
      //   top: rect,
      //   behavior: 'smooth'
      // });
      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp6() {
      const element = this.$refs.upload;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      // window.scrollTo({
      //   top: rect,
      //   behavior: 'smooth'
      // });
      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp7() {
      const element = this.$refs.station;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      // window.scrollTo({
      //   top: rect,
      //   behavior: 'smooth'
      // });
      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp8() {
      const element = this.$refs.technology;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      // window.scrollTo({
      //   top: rect,
      //   behavior: 'smooth'
      // });
      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp10() {
      const element = this.$refs.menageServices;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      // window.scrollTo({
      //   top: rect,
      //   behavior: 'smooth'
      // });
      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    uptop() {

      if (this.model === false) {
        const element = this.$refs.total;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        this.$nextTick(() => {
          this.$refs.total.scrollTop = rect
        });
      } else {
        console.log("shouji ")
        const element = this.$refs.totalModel;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        console.log(element)
        // window.scrollTo({
        //   top: 0,
        //   behavior: 'smooth'
        // });

        this.$nextTick(() => {
          // targetElement.value.scrollIntoView({ behavior: 'smooth' });
          this.$refs.totalModel.scrollTop = rect
        });
      }


    },
    uptop2() {
      this.focus=1
      this.focusno=0
      // console.log("实现2")
      // console.log("键盘watch-------------",this.keyboardHeight)
      // window.scrollTo({
      //   top: 0,
      //   behavior: 'smooth'
      // });
      const element = this.$refs.totalModel;
      const rect = element.offsetTop+ (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    uptopno() {
      this.focusno=1
    },

    upkefu() {
      window.open("https://channel-scrm.xiaoshouyi.com/embeded/im/iframe-h5.html?tenantToken=60cb1c79deb2cf2b3e2c53ad2ed91d09&siteId=3451460111799764&webPageTitle=在线咨询")
    },
    upkefu2() {
      window.open("https://channel-scrm.xiaoshouyi.com/embeded/im/iframe-h5.html?tenantToken=60cb1c79deb2cf2b3e2c53ad2ed91d09&siteId=3452726083343114&webPageTitle=在线咨询")
    },
    hezuo(){
      this.$emit("showDialog");
    },
    feedback() {
      window.open("/feedback")
      // window.open("https://cnmall.antonoil.com/gpts_feedback", "_blank");
    },
    feedback1() {
      this.$router.push("/feedback")
      // window.open("https://cnmall.antonoil.com/gpts_feedback", "_blank");
    },
    skipp11() {
      const element = this.$refs.company;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      // window.scrollTo({
      //   top: rect,
      //   behavior: 'smooth'
      // });
      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp12() {
      const element = this.$refs.traningConsult;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      // window.scrollTo({
      //   top: rect,
      //   behavior: 'smooth'
      // });
      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp13() {
      const element = this.$refs.companyConduct;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      // window.scrollTo({
      //   top: rect,
      //   behavior: 'smooth'
      // });
      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp14() {
      const element = this.$refs.media;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      // window.scrollTo({
      //   top: rect,
      //   behavior: 'smooth'
      // });
      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skippcom(value) {
      const data=value
      // console.log("---首页--------",data)
      if(data===1)
      {
        this.position1()
      }
      else  if(data===2)
      {
        this.position7()
      }
      else  if(data===3)
      {
        this.position5()
      }
      else  if(data===4)
      {
        this.position2()
      }
      else  if(data===5)
      {
        this.position8()
      }
      else  if(data===6)
      {
        this.position6()
      }
      else  if(data===7)
      {
        this.position9()
      }
      else  if(data===8)
      {
        this.position3()
      }
      else  if(data===9)
      {
        this.position10()
      }
      else  if(data===10)
      {
        const element = this.$refs.companyskip;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        this.$nextTick(() => {
          this.$refs.total.scrollTop = rect
        });
      }

    },
    screenmodel(value){
      if(value===0){
        this.screendata=0
      }
      else{
        this.screendata=1
      }

    },
    skippcom2(value) {
      const data=value
      // console.log("---首页--------",data)
      if(data===1)
      {
        this.skipp2()
      }
      else  if(data===2)
      {
        this.skipp5()
      }
      else  if(data===3)
      {
        this.skipp10()
      }
      else  if(data===4)
      {
        this.skipp1()
      }
      else  if(data===5)
      {
        this.skipp6()
      }
      else  if(data===6)
      {
        this.skipp7()
      }
      else  if(data===7)
      {
        this.skipp14()
      }
      else  if(data===8)
      {
        this.skipp12()
      }
      else  if(data===9)
      {
        this.skipp13()
      }
      else  if(data===10)
      {
        const element = this.$refs.companyskip;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        this.$nextTick(() => {
          this.$refs.totalModel.scrollTop = rect
        });
      }

    },
    dou1() {
      this.$confirm('<a style="color: white">您即将离开油气通平台，进入豆包网站平台。</a><br>  <a style="color: orange">https://www.doubao.com/</a>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        type: 'warning'
      }).then(() => {
        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://www.doubao.com/';
        this.$router.replace({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });
    },
    xun1() {
      this.$confirm('<div><a style="color: white">您即将离开油气通平台，进入讯飞星火网站平台。</a><br>  <a style="color: orange">https://xinghuo.xfyun.cn/</a></div>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        type: 'warning'
      }).then(() => {
        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://xinghuo.xfyun.cn/';
        // 导航到外部链接处理路由，并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });

    },
    tong1() {
      this.$confirm('<a style="color: white">您即将离开油气通平台，进入通义千问网站平台。</a><br>  <a style="color: orange">https://tongyi.aliyun.com/qianwen/</a>', ' ', {
        confirmButtonText: '继续访问',
        dangerouslyUseHTMLString: true,
        cancelButtonText: '取消',
        showCancelButton:false,
        type: 'warning'
      }).then(() => {
        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://tongyi.aliyun.com/qianwen/';
        // 导航到外部链接处理路由，并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

      }).catch(() => {

      });
    },
    zhi1() {
      this.$confirm('<a style="color: white">您即将离开油气通平台，进入智谱清言网站平台。</a><br>  <a style="color: orange">https://chatglm.cn/</a>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        showCancelButton:false,
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(() => {
        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://chatglm.cn/';
        // 导航到外部链接处理路由，并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });

    },
    teng1() {
      this.$confirm('<a style="color: white">您即将离开油气通平台，进入腾讯混元网站平台。</a><br>  <a style="color: orange">https://hunyuan.tencent.com/</a>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        showCancelButton:false,
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(() => {
        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://hunyuan.tencent.com/';
        // 导航到外部链接处理路由，并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });
    },
    tian1() {
      this.$confirm('<a style="color: white">您即将离开油气通平台，进入天工AI网站平台。</a><br>  <a style="color: orange">https://home.tiangong.cn/</a>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        showCancelButton:false,
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(() => {
        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://home.tiangong.cn/';
        // 导航到外部链接处理路由，并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });
    },
    ge1() {
      this.$confirm('<a style="color: white">您即将离开油气通平台，进入文心一格网站平台。</a><br>  <a style="color: orange">https://yige.baidu.com/</a>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        showCancelButton:false,
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(() => {
        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://yige.baidu.com/';
        // 导航到外部链接处理路由，并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });
    },
    wan1() {
      this.$confirm('<a style="color: white">您即将离开油气通平台，进入万彩AI网站平台。</a><br>  <a style="color: orange">https://ai.kezhan365.com/</a>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        showCancelButton:false,
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(() => {
        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://ai.kezhan365.com/';
        // 导航到外部链接处理路由，并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });
    },
    yi1() {
      this.$confirm('<a style="color: white">您即将离开油气通平台，进入一帧秒创网站平台。</a><br>  <a style="color: orange">https://aigc.yizhentv.com</a>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        showCancelButton:false,
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(() => {
        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://aigc.yizhentv.com/';
        // 导航到外部链接处理路由，并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });

    },
    meitiProduct1() {
      this.$confirm('<div><a style="color: white">您即将离开油气通平台，进入石同多媒体网站平台。</a><br>  <a style="color: orange">http://www.oilmates-omm.com/h-col-106.html</a></div>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        type: 'warning'
      }).then(() => {
        sessionStorage.setItem("skip", 14)
        const externalLink = 'http://www.oilmates-omm.com/h-col-106.html';
        // 导航到外部链接处理路由，并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });
    },
    meiti3D1() {
      this.$confirm('<div><a style="color: white">您即将离开油气通平台，进入石同多媒体网站平台。</a><br>  <a style="color: orange">http://www.oilmates-omm.com/h-col-102.html</a></div>', ' ', {
        confirmButtonText: '继续访问',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        sessionStorage.setItem("skip", 14)
        const externalLink = 'http://www.oilmates-omm.com/h-col-102.html';
        // 导航到外部链接处理路由，并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {
      });
    },
    meitiDesign1() {
      this.$confirm('<div><a style="color: white">您即将离开油气通平台，进入石同多媒体网站平台。</a><br>  <a style="color: orange">http://www.oilmates-omm.com/h-col-104.html</a></div>', '', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        type: 'warning'
      }).then(() => {
        sessionStorage.setItem("skip", 14)
        const externalLink = 'http://www.oilmates-omm.com/h-col-104.html';
        // 导航到外部链接处理路由，并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {
      });
    },
    updateGroupedItems() {
      const startIndex = this.currentIndex;
      this.groupedItems = [];

      for (let i = 0; i < this.itemsPerPage; i++) {
        const group = this.carouselItems.slice(
            startIndex + i,
            startIndex + i + this.itemsPerPage
        );
        this.groupedItems.push(group);
      }
    },
    leftnext() {
      console.log(this.currentIndex);
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.updateGroupedItems();
      }
    },
    rightnext() {
      if (this.currentIndex < 1) {
        this.currentIndex++;
        this.updateGroupedItems();
      }
    },
    onCarouselChange(index) {
      this.currentIndex = index;
      this.updateGroupedItems();
    },
  },
  beforeRouteLeave(to, from, next) {
    // 保存滚动位置
    if(this.model===true){
      this.scrollPosition = this.$refs.totalModel.scrollTop;
    }
    next();
  },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // 恢复滚动位置
      vm.$nextTick(() => {
        if(vm.model===true){
          vm.$refs.totalModel.scrollTop = vm.scrollPosition;
        }


      });
    });
  },
};
</script>

<style>

.image {
  width: 100%; /* 根据需要设置图片的宽度 */
  height: 100%; /* 高度自适应 */
  transition: transform 0.5s ease-out; /* 添加过渡效果 */
}

.image:hover {
  transform: scale(1.1); /* 鼠标悬浮时放大图片 */
}

@keyframes jiantou {
  0% {
    transform: scale(0.5);
    margin-top: -30px;
    opacity: 0.3;
  }
  50% {
    transform: scale(1);
    margin-top: -15px;
    opacity: 1;
  }
  100% {
    transform: scale(0.5);
    margin-top: -0px;
    opacity: 0.3;
  }
}

.animated-element {
  //height: 10vh; animation: jiantou 3s linear infinite 0S;
}

.animated-element2 {
  //height: 10vh; animation: jiantou 3s linear infinite 0.8s;
}

.animated-element3 {
  //height: 10vh; animation: jiantou 3s linear infinite 2s;
}

.animated-element4 {
  //height: 10vh; animation: jiantou 3s linear infinite 3s;
}

.el-message-box {
  width: 35% !important;
  background-color: #3a3b73 !important;
  border: none !important;
  color: white !important;
}

.el-message-box__status {
  top: -10% !important;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

.el-message-box__status + .el-message-box__message {
  padding: 8vh 0 2vh 0 !important;
  text-align: center;


}

.el-message-box__status.el-icon-warning {
  color: white !important;
  font-size: 6.5vh !important;
  top: 5% !important;
}

.el-message-box__message p {
  line-height: 6vh !important;
  font-size: 1.1vw !important;
  font-family: Times New Roman !Important;
}

.el-message-box__headerbtn .el-message-box__close {
  color: white !important;
}

.el-message-box__content {
  font-size: 1.1vw !important;
  line-height: 3vh !important;
}

.el-message-box__btns {
  text-align: center !important;

}
.el-message-box__btns{
  text-align: center !important;
  .el-button--primary {
    background-color: #f5f3f3 !important;
    border: none !important;
    font-size: 18px !important;
    font-family: Times New Roman !Important;
    color: #3a3b73 !important;
    width: 70% !important;
    padding: 1.5vh 0 !important;
    border-radius: 9px !important;
    margin-bottom: 5vh !important;
  }
}



@media screen and  (max-width: 757px) {
  .el-message-box {
    width: 95% !important;
    height: fit-content !important;
    margin: auto auto !important;
    background-color: #3a3b73 !important;
    border: none !important;
    color: white !important;
    border-radius: 9px !important;
  }

  .el-message-box__message p {
    line-height: 26px !important;
    font-size: 18px !important;
  }



  .el-message-box__status {
    font-size: 55px !important;
    top: 10% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;

  }

  .el-message-box__status.el-icon-warning {
    color: white !important;
    font-size: 55px !important;
  }

  .el-message-box__status + .el-message-box__message {
    padding: 65px 20px 25px 20px !important;
    font-size: 16px !important;
    line-height: 25px !important;
    text-align: center;

  }

  .el-message-box__headerbtn .el-message-box__close {
    color: white !important;
  }

  .el-message-box__content {
    font-size: 16px !important;
    line-height: 25px !important;
  }
  .el-button--primary {
    background-color: #f5f3f3 !important;
    border: none !important;
    font-size: 16px !important;
    font-family: Times New Roman !Important;
    color: #3a3b73 !important;
    width: 70% !important;
    padding: 10px 0 !important;
    border-radius: 9px !important;
    margin-bottom: 35px !important;
  }

  .el-message-box__btns {
    text-align: center !important;
    .el-button--primary {
      background-color: #f5f3f3 !important;
      border: none !important;
      font-size: 16px !important;
      font-family: Times New Roman !Important;
      color: #3a3b73 !important;
      width: 70% !important;
      padding: 10px 0 !important;
      border-radius: 9px !important;
      margin-bottom: 35px !important;
    }

  }

  .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0;
    margin-top: 0 !important;
  }

  .swiper-pagination {
    position: relative;
  }

  .swiper-pagination-bullet {
    width: 25px;
    height: 5px !important;
    border-radius: 9px;
  }

  .swiper-pagination-bullet-active {
    background-color: rgb(47, 110, 255);
  }
}
</style>
<style lang="scss" scoped>
@keyframes jiantou {
  0% {
    transform: scale(0.5);
    margin-top: -30px;
    opacity: 0.3;
  }
  50% {
    transform: scale(1);
    margin-top: -15px;
    opacity: 1;
  }
  100% {
    transform: scale(0.5);
    margin-top: -0px;
    opacity: 0.3;
  }
}

.animated-element {
  //height: 10vh; animation: jiantou 3s linear infinite 0S;
}

.animated-element2 {
  //height: 10vh; animation: jiantou 3s linear infinite 0.8s;
}

.animated-element3 {
  //height: 10vh; animation: jiantou 3s linear infinite 2s;
}

.animated-element4 {
  //height: 10vh; animation: jiantou 3s linear infinite 3s;
}
.run-enter-active {
  animation: fadeInDownBig 0.8s linear both;
}
.rundown-enter-active {
  animation: fadeInUpBig 0.8s linear both;
}

.avator {
  border: 2px solid #fff;
}

.userName {
  color: #2f6eff;
  font-size: 16px;
  line-height: 1.3;
  text-align: justify;
  margin-left: 10px;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.total {
  background: #bed8f9;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;

  .topback {
    position: fixed;
    z-index: 9;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }

  .screen1 {
    z-index: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    padding: 0 2.86077411900635vw 1.84301606922126vh 2.86077411900635vw;
    //background-image: url("../assets/back1.jpg");

    background: linear-gradient(180deg,#f5f4f6,#e6ebf7);
    /* Add any additional styling as needed */

    .up {
      z-index: 99;
      cursor: pointer;
      position: fixed;
      top: 40%;
      right: 0;
      padding: 5px;
      width: 3vw;
      height: fit-content;;
      border-top-left-radius: 9px;
      border-bottom-left-radius: 9px;
      background-color: #282d7e;

      //border-radius: 50%;
      //box-shadow: 5px 0 14px 0px #D4E4FF;

      .uptop {
        width: 2vw;
        height: 2vw;

        margin: 1vh auto;
        background-image: url("../assets/up.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;
      }

      .upkefu {
        margin: 0.5vh auto;
        width: 2vw;
        height: 2vw;
        background-image: url("../assets/kefu2.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;
      }

      .feedback {
        padding: 0.5vh 0;
        margin: 1vh auto;
        font-size: 0.8vw;
        text-align: center;
        color: white;
      }

    }

    .layer2 {
      padding: 8vh 5vw 2vh 5vw;
      text-align: center;
      height: fit-content;

      .title1 {
        height: fit-content;
        font-size: 2.5vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: bold;
        color: #122279;
      }

      .title2 {
        height: fit-content;
        font-size: 1.8vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: bold;
        //color: #2f6eff;
        color: #122279;
        margin-top: 2vh;
        line-height: 4vh;
      }

      .title3 {
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        row-gap: 1.5vh;
        column-gap: 1vw;
        text-align: center;
        margin: 3vh auto 0 auto;
        width: 60vw;
        font-size: 1.1vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: bold;
        //color: #2f6eff;
        color: #122279;
        background-color: white;
        padding: 2.5vh 1vw;
        border-radius: 9px;

        .hoverTitleup {
          border: white 1px solid;
          ////box-shadow: 5px 0 14px 0px #D4E4FF;
          //background-color: white;
          background: linear-gradient(180deg,#f5f4f6,#e6ebf7);
          padding: 1vh 0.5vw;
          border-radius: 9px;
          width: 100%;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: transform 0.3s ease-out;

        }

        .hoverTitleup:hover {
          transform: translateY(-5px);
        }
        .light{
          //display: -webkit-box;
          display:none;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: normal;
          font-size: 0.9vw;
          margin: 1vh 0;
          text-align: left;
        }

        .hoverTitle {
          display: flex;
          margin-right: 2vw;
          cursor: pointer;
          transition: transform 0.3s ease-out;

        }

        .hoverTitle:hover {
          transform: translateY(-5px);
        }
      }

    }

    .layer3 {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      padding: 0 12vw;
      width: 100%;
      text-align: center;
      margin: 0 auto;
      opacity: 1;
      //border: 5px solid blue;
    }
  }

  .dashedlayer {
    z-index: 8;
    position: absolute;
    top: 92.21260815822002vh;
    left: 0;
    width: 15vw;
    height: 8.52904820766378vh;
    background: linear-gradient(to right, #e9effb 30%, transparent 100%);
  }

  .dashedlayerblue {
    z-index: 9;
    position: absolute;
    top: 91.21260815822002vh;
    left: 0;
    width: 80vw;
    height: 8.52904820766378vh;
    background: linear-gradient(
            to bottom,
            rgba(202, 222, 251, 0.9) 0%,
            transparent 100%
    );
  }

  .layer4 {
    z-index: 2;
    position: absolute;
    top: 92.21260815822002vh;
    left: 4.44829578278452vw;
    width: 89.42807625649913vw;
    height: 8.52904820766378vh;
    background-image: url("../assets/hummer.png");
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
  }

  .dashed {
    z-index: 1;
    position: absolute;
    top: 67.36711990111248vh;
    left: 10.86077411900635vw;
    width: 80.22068168688619vw;
    height: 75.24474660074166vh;
    background: linear-gradient(to bottom, #ebf1fd 50%, transparent 100%);
  }

  .screen2 {

    width: 78.22068168688619vw;
    height: 80.54474660074166vh;
    padding: 2.78739184177998vh 1.09763142692085vw;
    background: linear-gradient(to bottom, #ebf1fd 50%, transparent 50%);
    //border-radius: 45px 45px 45px 45px;
    border: 2px dashed #53a3f7;

    .layer1 {
      height: 41.65636588380717vh;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:
        minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)
        minmax(0, 1fr);
      column-gap: 0;
    }
  }

  .screen3 {
    .internew {
      border-radius: 9px;
      height: fit-content;
      width: 70%;
      margin: 0 0 0 auto;;
      //z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;

      .grand2 {
        border-radius: 9px;
        cursor: pointer;
        width: 100%;
        min-height: 29.65636588380717vh;
        padding: 0.98887515451174vh 0.5vw 0.98887515451174vh 0.5vw;
        opacity: 1;
        position: relative;

        .back {
          transition: transform 0.5s ease;
          border-radius: 9px;
          width: 100%;
          height: 50vh;
          background-color: #015377;;
          ////box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
        }

        .photo { overflow: hidden;
          border-radius: 9px;
          opacity: 1.25;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 15vh;
          margin: 10vh auto 5vh auto;
          height: 15vh;

          .mengban {
            margin-top: 10vh;
            border-bottom-right-radius: 9px;
            border-bottom-left-radius: 9px;

            .title1 {
              height: 100%;
              color: white;
              margin-top: unset
            }
          }
        }

        .title1 {
          opacity: 4;
          margin: 2.22496909765142vh;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 1.8vw;
          font-family: Times New Roman;
          font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.5vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;;
          //text-align: justify;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Times New Roman;
          font-weight: 500;
          //color: #2168DB;
          height: 8.2vh;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Times New Roman;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          transform: translateY(-10px);

        }
      }
    }
    z-index: 10;
    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    padding: 5.78739184177998vh 8.09763142692085vw 5.78739184177998vh 8.09763142692085vw;
    background: transparent;

    .title {
      margin: 0 auto;
      text-align: center;
      height: 8.23733003708282vh;
      opacity: 1;
      font-size: 2.5vw;
      //color: #53A3F7;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 4.23733003708282vh;
    }

    .light {
      margin: 0 auto 4vh auto;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size: 1.3vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 4.94437577255871vh;

    }

    .light2 {
      margin: 0 auto 0 auto;
      text-align: justify;
      width: 66vw;
      font-size: 1.3vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #FF6400;
      line-height: 4.94437577255871vh;
    }


    .pro2 {

      border-radius: 9px;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;

      .grand2 {
        border-radius: 9px;
        cursor: pointer;
        width: 100%;
        min-height: 30.65636588380717vh;
        padding: 0.98887515451174vh 0.5vw 0.98887515451174vh 0.5vw;
        opacity: 1;
        position: relative;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          height: 100%;
          background: white;;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo { overflow: hidden;
          border-radius: 9px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 40vh;
          position: relative;
          .mengban{
            position: absolute;left: 0px;bottom: 0px;  width: 100%;height: 20%;background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);opacity: 0.9;border-bottom-right-radius: 9px;border-bottom-left-radius: 9px;
            .title1{
              position: absolute;height: 100%;color: white;margin-top:unset
            }
          }
        }

        .title1 {
          opacity: 4;
          margin-top: 2.22496909765142vh;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 1.6vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          transform: translateY(-10px);

        }
      }
    }


  }

  .screen4 {
    z-index: 10;
    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    padding: 5.78739184177998vh 8.09763142692085vw 5.78739184177998vh 8.09763142692085vw;
    background: white;

    .title {
      margin: 0 auto;
      text-align: center;
      height: 8.23733003708282vh;
      opacity: 1;
      font-size: 2.5vw;
      //color: #53A3F7;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 4.23733003708282vh;
    }

    .light2 {
      margin: 0 auto 0 auto;
      text-align: justify;
      width: 60.9693818602vw;
      font-size: 1.3vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #FF6400;
      line-height: 4.9443757726vh;

    }

    .light {
      margin: 0 auto 4vh auto;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size: 1.3vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 4.94437577255871vh;

    }

    .pro2 {
      border-radius: 9px;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;

      .grand2 {
        border-radius: 9px;
        cursor: pointer;
        width: 100%;
        min-height: 30.65636588380717vh;
        padding: 0.98887515451174vh 0.5vw 0.98887515451174vh 0.5vw;
        opacity: 1;
        position: relative;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          height: 100%;
          background: #ebf0fb;;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
        }

        .photo { overflow: hidden;
          border-radius: 9px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;

          .mengban {
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 100%;
            height: 20%;
            background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
            opacity: 0.9;
            border-bottom-right-radius: 9px;
            border-bottom-left-radius: 9px;

            .title1 {
              position: absolute;
              //height: 100%;
              color: white;
              margin-top: unset
            }
          }
        }

        .title1 {
          opacity: 4;
          margin: 2.22496909765142vh auto 2.22496909765142vh auto;
          padding: 3vh 1vw 1vh 1vw;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 1.6vw;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Roboto, sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto, sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          transform: translateY(-10px);

        }
      }
    }

    //.pro2 {
    //  border-radius: 9px;
    //  height: fit-content;
    //  width: 100%;
    //  z-index: 89156;
    //  display: grid;
    //  grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    //  column-gap: 0;
    //
    //  .grand2 {
    //    border-radius: 9px;
    //    cursor: pointer;
    //    width: 100%;
    //    min-height: 30.65636588380717vh;
    //    padding: 0.98887515451174vh 0.5vw 0.98887515451174vh 0.5vw;
    //    opacity: 1;
    //    position: relative;
    //
    //    .back {
    //      transition: transform 0.5s ease;
    //      width: 100%;
    //      height: 100%;
    //      background: #ebf0fb;;
    //      //box-shadow: 5px 0 14px 0px #D4E4FF;
    //      border: 2px solid #FFFFFF;
    //      border-radius: 9px;
    //    }
    //
    //    .photo { overflow: hidden;
    //      border-radius: 9px;
    //      background-size: contain;
    //      background-position: center;
    //      background-repeat: no-repeat;
    //      background-color: transparent; /* 这里设置背景颜色为透明 */
    //      opacity: 1.25;
    //      display: flex;
    //      justify-content: center;
    //      text-align: center;
    //      width: 100%;
    //      height: fit-content;
    //    }
    //
    //    .title1 {
    //      opacity: 4;
    //      margin: 2.22496909765142vh auto 2.22496909765142vh auto;
    //      padding: 3vh 1vw 1vh 1vw;
    //      text-align: center;
    //      display: flex;
    //      justify-content: center;
    //      align-items: center;
    //      height: 2.71940667490729vh;
    //      font-size: 1.6vw;
    //      font-family: Sarial, sans-serif, "Microsoft Yahei";
    //      font-weight: bold;
    //      //color: #2168DB;
    //      line-height: 2.71940667490729vh;
    //    }
    //
    //    .title2 {
    //      opacity: 4;
    //      margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
    //      text-align: justify;
    //      display: flex;
    //      justify-content: center;
    //      align-items: center;
    //      font-size: 0.80878105141537vw;
    //      font-family: Sarial, sans-serif, "Microsoft Yahei";
    //      font-weight: 500;
    //      //color: #2168DB;
    //      line-height: 2.71940667490729vh;
    //    }
    //
    //    .consult {
    //      position: absolute;
    //      display: none;
    //      font-family: Sarial, sans-serif, "Microsoft Yahei";
    //      font-weight: 500;
    //      font-size: 0.80878105141537vw;
    //      bottom: 3.94437577255871vh;
    //      left: 1.85540150202195vw;
    //    }
    //  }
    //
    //  .grand2:hover {
    //    .back {
    //      transform: translateY(-10px);
    //
    //    }
    //  }
    //}


  }

  .screenConsult {
    z-index: 10;

    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    padding: 5.78739184177998vh 8.09763142692085vw 5.78739184177998vh 8.09763142692085vw;
    background: white;

    .light2 {
      margin: 0 auto 0 auto;
      text-align: justify;
      width: 60.9693818602vw;
      font-size: 1.3vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #FF6400;
      line-height: 4.9443757726vh;

    }

    .title {

      height: 6.23733003708282vh;
      opacity: 1;
      font-size: 2.5vw;
      //color: #53A3F7;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 4.23733003708282vh;
    }

    .light {
      margin: 0 auto 2vh auto;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size: 1.3vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 4.94437577255871vh;

    }


    .pro1 {
      height: 37.45364647713226vh;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;

      .grand1 {
        cursor: pointer;
        width: 100%;
        height: 37.45364647713226vh;
        padding: 0.98887515451174vh 0.32432120161756vw 0.98887515451174vh 0.39324090121317vw;
        opacity: 1;

        .back {

          width: 100%;
          height: 100%;
          background: #EFF4FF;;
          border: 2px solid #FFFFFF;
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
        }

        .photo { overflow: hidden;
          border: 2px solid #FFFFFF;
          background-image: url("../assets/pro1.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          height: 21.73176761433869vh;
        }

        .title1 {
          opacity: 4;
          margin: 5.22496909765142vh 1.2vw auto 1.2vw;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 1.6vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 600;
          //color: #2168DB;
          line-height: 3.71940667490729vh;
        }

      }

      .grand1:hover {
        transform: scale(1.05);

        .title1 {
          color: #2168db;
          font-size: 1.6vw;
        }

        .title2 {
          font-size: 0.80878105141537vw;
        }
      }

    }

    .pro2 {
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;
      row-gap: 1.8vh;

      .grand1 {
        cursor: pointer;
        width: 25.30329289428076vw;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.12432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: 100%;
          background: #EFF4FF;;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 5px;
          display: flex;
        }

        .photo { overflow: hidden;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 3vh 0 3vh 0.8vw;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 19vw;
          height: 17.44004944375773vh;
        }

        .title1 {
          opacity: 4;
          margin: 3.22496909765142vh 0.85540150202195vw 1.22496909765142vh 0.85540150202195vw;
          text-align: left;
          width: 12vw;

          height: 3vh;
          font-size: 0.97094165222415vw;
          white-space: nowrap; //换行
          font-family: Roboto,sans-serif;
          font-weight: bold;
          overflow: hidden;

          text-overflow: ellipsis;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 0.94437577255871vh 0.85540150202195vw 0.94437577255871vh 1.54437577255871vh;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.51940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand1:hover {
        .back {
          box-shadow: 5px 0 14px 0px #9abaf3;
        }
      }

      .grand2 {
        cursor: pointer;
        width: 100%;
        min-height: 38.65636588380717vh;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: 100%;
          background: white;;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
        }

        .photo { overflow: hidden;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: fit-content;
          height: 40vh;
          //height: 4.73176761433869vh;
          .mengban{
            position: absolute;left: 0px;bottom: 0px;  width: 100%;height: 20%;background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);opacity: 0.9;border-bottom-right-radius: 9px;border-bottom-left-radius: 9px;
            .title1{
              position: absolute;height: 100%;color: white;margin-top:unset
            }
          }
        }

        .title1 {
          opacity: 4;
          margin-top: 2.22496909765142vh;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 1.6vw;
          font-family: Roboto,sans-serif;
          font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin:1.5vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          //word-break: break-all;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height:3.1940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      //.grand2:hover {
      //  .back {
      //    background-color: rgb(135, 103, 215);
      //  }
      //
      //  .photo { overflow: hidden;
      //    display: none;
      //  }
      //
      //  .title1 {
      //    position: absolute;
      //    left:1.8vw;
      //    top: 2.22496909765142vh;
      //    height: 2.71940667490729vh;
      //    color: white;
      //    font-size: 0.97094165222415vw;
      //  }
      //
      //  .title2 {
      //    word-break: normal;
      //    margin-top: 8.22496909765142vh;
      //    color: white;
      //    font-size: 0.80878105141537vw;
      //  }
      //
      //  .consult {
      //    position: absolute;
      //    left: 1.8vw;
      //    cursor: pointer;
      //    display: block;
      //    color: white;
      //  }
      //}
    }


    //.pro2 {
    //  height: fit-content;
    //  width: 100%;
    //  z-index: 89156;
    //  display: grid;
    //  grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    //  column-gap: 0;
    //  row-gap: 1.8vh;
    //
    //  .grand1 {
    //    cursor: pointer;
    //    width: 100%;
    //    min-height: fit-content;
    //    padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
    //    opacity: 1;
    //    position: relative;
    //
    //    .back {
    //      width: 100%;
    //      height: 100%;
    //      background: #EFF4FF;;
    //      //box-shadow: 5px 0 14px 0px #D4E4FF;
    //      border: 2px solid #FFFFFF;
    //      border-radius: 9px;
    //      display: flex;
    //    }
    //
    //    .photo { overflow: hidden;
    //      background-size: contain;
    //      background-position: center;
    //      background-repeat: no-repeat;
    //      background-color: transparent; /* 这里设置背景颜色为透明 */
    //      opacity: 1.25;
    //      margin: 3vh 0 3vh 0.8vw;
    //      display: flex;
    //      justify-content: center;
    //      text-align: center;
    //      width: fit-content;
    //      height: 17.44004944375773vh;
    //    }
    //
    //    .title1 {
    //      opacity: 4;
    //      margin: 3.22496909765142vh 0.85540150202195vw 1.22496909765142vh 0.85540150202195vw;
    //      text-align: justify;
    //      width: 12vw;
    //
    //      height: 3vh;
    //      font-size: 1.6vw;
    //      white-space: nowrap; //换行
    //      font-family: Sarial, sans-serif, "Microsoft Yahei";
    //      font-weight: bold;
    //      overflow: hidden;
    //
    //      text-overflow: ellipsis;
    //      //color: #2168DB;
    //      line-height: 2.71940667490729vh;
    //    }
    //
    //    .title2 {
    //      opacity: 4;
    //      margin: 0.94437577255871vh 0.85540150202195vw 0.94437577255871vh 1.54437577255871vh;
    //      text-align: justify;
    //      display: flex;
    //      justify-content: center;
    //      align-items: center;
    //      font-size: 0.80878105141537vw;
    //      font-family: Sarial, sans-serif, "Microsoft Yahei";
    //      font-weight: 500;
    //      //color: #2168DB;
    //      line-height: 2.51940667490729vh;
    //    }
    //
    //    .consult {
    //      position: absolute;
    //      display: none;
    //      font-family: Sarial, sans-serif, "Microsoft Yahei";
    //      font-weight: 500;
    //      font-size: 0.80878105141537vw;
    //      bottom: 3.94437577255871vh;
    //      left: 1.85540150202195vw;
    //    }
    //  }
    //
    //  .grand1:hover {
    //    .back {
    //      box-shadow: 5px 0 14px 0px #9abaf3;
    //    }
    //  }
    //
    //  .grand2 {
    //    cursor: pointer;
    //    width: 100%;
    //    min-height: 32.65636588380717vh;
    //    padding: 0.98887515451174vh 0.5vw 0.98887515451174vh 0.5vw;
    //    opacity: 1;
    //    position: relative;
    //
    //    .back {
    //      width: 100%;
    //      height: 100%;
    //      background: #EFF4FF;;
    //      //box-shadow: 5px 0 14px 0px #D4E4FF;
    //      border: 2px solid #FFFFFF;
    //      border-radius: 9px;
    //    }
    //
    //    .photo { overflow: hidden;
    //      background-size: contain;
    //      background-position: center;
    //      background-repeat: no-repeat;
    //      background-color: transparent; /* 这里设置背景颜色为透明 */
    //      opacity: 1.25;
    //      margin: 3vh auto auto auto;
    //      display: flex;
    //      justify-content: center;
    //      text-align: center;
    //      width: fit-content;
    //      height: 5.73176761433869vh;
    //    }
    //
    //    .title1 {
    //      opacity: 4;
    //      margin-top: 2.22496909765142vh;
    //      text-align: center;
    //      display: flex;
    //      justify-content: center;
    //      align-items: center;
    //      height: 2.71940667490729vh;
    //      font-size: 1.6vw;
    //      font-family: Sarial, sans-serif, "Microsoft Yahei";
    //      font-weight: bold;
    //      //color: #2168DB;
    //      line-height: 2.71940667490729vh;
    //    }
    //
    //    .title2 {
    //      opacity: 4;
    //      margin: 2.22496909765142vh 1.35540150202195vw;
    //      text-align: justify;
    //      display: flex;
    //      justify-content: center;
    //      align-items: center;
    //      font-size: 0.80878105141537vw;
    //      font-family: Sarial, sans-serif, "Microsoft Yahei";
    //      font-weight: 500;
    //      //color: #2168DB;
    //      line-height: 2.71940667490729vh;
    //    }
    //
    //    .consult {
    //      position: absolute;
    //      display: none;
    //      font-family: Sarial, sans-serif, "Microsoft Yahei";
    //      font-weight: 500;
    //      font-size: 0.80878105141537vw;
    //      bottom: 3.94437577255871vh;
    //      left: 1.85540150202195vw;
    //    }
    //  }
    //
    //  .grand2:hover {
    //    .back {
    //      background-color: rgb(135, 103, 215);
    //    }
    //
    //    .photo { overflow: hidden;
    //      display: none;
    //    }
    //
    //    .title1 {
    //      position: absolute;
    //      left: 2.05540150202195vw;
    //      top: 2.22496909765142vh;
    //      height: 2.71940667490729vh;
    //      color: white;
    //      font-size: 1.6vw;
    //    }
    //
    //    .title2 {
    //      margin-top: 8.22496909765142vh;
    //      text-align: justify;
    //      color: white;
    //      font-size: 0.80878105141537vw;
    //    }
    //
    //    .consult {
    //      position: absolute;
    //      left: 2.05540150202195vw;
    //      cursor: pointer;
    //      display: block;
    //      color: white;
    //    }
    //  }
    //}


  }

  .screenConsult1 {
    z-index: 10;
    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    padding: 5.78739184177998vh 8.09763142692085vw 5.78739184177998vh 8.09763142692085vw;
    background: white;

    .light2 {
      margin: 0 auto 0 auto;
      text-align: justify;
      width: 60.9693818602vw;
      font-size: 1.3vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #FF6400;


    }

    .title {

      height: 8.23733003708282vh;
      opacity: 1;
      font-size: 2.5vw;
      //color: #53A3F7;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 4.23733003708282vh;
    }

    .light {
      margin: 0 auto 4vh auto;
      text-align: justify;
      width: 66vw;
      height: fit-content;
      font-size: 1.3vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 4.94437577255871vh;

    }

    .pro12 {
      height: fit-content;
      width: 100%;
      overflow-x: hidden;
      //z-index: 89156;


      .grand1 {
        cursor: pointer;
        width: 98%;
        border-radius: 6px;
        padding: 0.98887515451174vh 0 0 0;
        opacity: 1;
        height: 250px;
        position: relative;
        background-color: transparent;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: white;
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          //border-radius: 9px;
          border: white 2px solid;
          border-radius: 9px;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: 20%;
          background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          // border-radius:6px;
          display: flex;
          border-bottom-left-radius: 9px;
          border-bottom-right-radius: 9px;
          padding: 10px 15px;
          justify-content: space-between;
        }

        .photo { overflow: hidden;

          border-radius: 9px;
          border: #dddddd 3px solid;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          //background-color: #d4e4ff; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          height: 40vh;
        }

        .title1 {
          opacity: 4;
          text-align: left;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.6vw;;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          color: white;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          // text-align: justify;
          margin: auto 0 0 auto;
          font-size: 1.21vw;
          font-family: Roboto, sans-serif;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }
    .pro1 {
      height: 37.45364647713226vh;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;

      .grand1 {
        transition: transform 0.5s ease;
        cursor: pointer;
        width: 100%;
        height: 37.45364647713226vh;
        padding: 0.98887515451174vh 0.32432120161756vw 0.98887515451174vh 0.39324090121317vw;
        opacity: 1;

        .back {
          width: 100%;
          height: 100%;
          background: #e7ecf7;;
          border: 2px solid #FFFFFF;
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
        }

        .photo { overflow: hidden;
          border: 2px solid #FFFFFF;
          background-image: url("../assets/pro1.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          height: 21.73176761433869vh;
        }

        .title1 {
          opacity: 4;
          margin: 5.22496909765142vh 1.2vw auto 1.2vw;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 1.6vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 600;
          //color: #2168DB;
          line-height: 3.71940667490729vh;
        }

      }

      .grand1:hover {
        transform: translateY(-10px);

        .title2 {
          font-size: 0.80878105141537vw;
        }
      }

    }

    //.pro3 {
    //  //margin-top: -4vh;
    //  height: fit-content;
    //  width: 100%;
    //  z-index: 89156;
    //  display: grid;
    //  grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    //  column-gap: 0;
    //  row-gap: 1.8vh;
    //
    //  .grand2 {
    //    cursor: pointer;
    //    width: 100%;
    //    height: 38.65636588380717vh;
    //    padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
    //    opacity: 1;
    //    position: relative;
    //
    //    .back {
    //      width: 100%;
    //      height: 100%;
    //      background: #edf2fd;;;
    //      //box-shadow: 5px 0 14px 0px #D4E4FF;
    //      border: 2px solid #FFFFFF;
    //      border-radius: 9px;
    //    }
    //
    //
    //    .photo { overflow: hidden;
    //      background-size: contain;
    //      background-position: center;
    //      background-repeat: no-repeat;
    //      background-color: transparent; /* 这里设置背景颜色为透明 */
    //      opacity: 1.25;
    //      margin: 3vh auto auto auto;
    //      display: flex;
    //      justify-content: center;
    //      text-align: center;
    //      width: fit-content;
    //      height: 4.73176761433869vh;
    //    }
    //
    //
    //    .title1 {
    //      opacity: 4;
    //      margin-top: 2.22496909765142vh;
    //      text-align: center;
    //      display: flex;
    //      justify-content: center;
    //      align-items: center;
    //      height: 2.71940667490729vh;
    //      font-size: 1.6vw;
    //      font-family: Sarial, sans-serif, "Microsoft Yahei";
    //      font-weight: bold;
    //      //color: #2168DB;
    //      line-height: 2.71940667490729vh;
    //    }
    //
    //    .title2 {
    //      opacity: 4;
    //      margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
    //      text-align: justify;
    //      display: flex;
    //      justify-content: center;
    //      align-items: center;
    //      font-size: 0.80878105141537vw;
    //      font-family: Sarial, sans-serif, "Microsoft Yahei";
    //      font-weight: 500;
    //      //color: #2168DB;
    //      line-height: 2.71940667490729vh;
    //    }
    //
    //    .consult {
    //      position: absolute;
    //      display: none;
    //      font-family: Sarial, sans-serif, "Microsoft Yahei";
    //      font-weight: 500;
    //      font-size: 0.80878105141537vw;
    //      bottom: 3.94437577255871vh;
    //      left: 1.85540150202195vw;
    //    }
    //  }
    //
    //  .grand2:hover {
    //    .back {
    //      background-color: rgb(135, 103, 215);
    //    }
    //
    //    .photo { overflow: hidden;
    //      display: none;
    //    }
    //
    //    .title1 {
    //      position: absolute;
    //      left: 1.85540150202195vw;
    //      top: 2.22496909765142vh;
    //      height: 2.71940667490729vh;
    //      color: white;
    //      font-size: 1.6vw;
    //    }
    //
    //    .title2 {
    //      margin-top: 8.22496909765142vh;
    //      text-align: justify;
    //      color: white;
    //      font-size: 0.80878105141537vw;
    //    }
    //
    //    .consult {
    //      cursor: pointer;
    //      display: block;
    //      color: white;
    //    }
    //
    //  }
    //}
    .pro3 {
      height: fit-content;
      width: 100%;
      overflow-x: hidden;
      //z-index: 89156;


      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 0.98887515451174vh 0 0 0;
        opacity: 1;
        height: 250px;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: white;
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          //border-radius: 9px;
          border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: 30%;
          background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          // border-radius:6px;
          display: flex;
          border-bottom-left-radius: 9px;
          border-bottom-right-radius: 9px;
          padding: 10px 15px;
          justify-content: space-between;
        }

        .photo { overflow: hidden;

          border-radius: 9px;
          border: #dddddd 3px solid;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          //background-color: #d4e4ff; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          height: 35vh;
        }

        .title1 {
          opacity: 4;
          text-align: left;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.6vw;;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          color: white;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          // text-align: justify;
          margin: auto 0 0 auto;
          font-size: 1.2vw;
          font-family: Roboto, sans-serif;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }
    .pro2 {
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;
      row-gap: 1vh;

      .grand1 {
        transition: transform 0.5s ease;
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        padding: 0.38887515451174vh 0.52432120161756vw 0.38887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          height: 100%;
          background: white;;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          display: flex;
        }

        .photo { overflow: hidden;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 1vh 0 1vh 0.8vw;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 25vw;
          border-radius: 9px;
          height: 17.44004944375773vh;
        }

        .title1 {
          opacity: 4;
          margin: 1.22496909765142vh 0.85540150202195vw 1.22496909765142vh 0.85540150202195vw;
          text-align: justify;
          width: 12vw;
          height: 3vh;
          font-size: 1.6vw;
          white-space: nowrap; //换行
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          overflow: hidden;

          text-overflow: ellipsis;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 0.94437577255871vh 0.85540150202195vw 0.94437577255871vh 1.54437577255871vh;
          text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.51940667490729vh;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand1:hover {
        .back {
          transform: translateY(-10px);
        }
      }

      .grand2 {
        cursor: pointer;
        width: 100%;
        min-height: 30.65636588380717vh;
        padding: 0.98887515451174vh 1.52432120161756vw 0.98887515451174vh 1.39324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: 100%;
          background: #EFF4FF;;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo { overflow: hidden;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 3vh auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: fit-content;
          height: 4.73176761433869vh;
        }

        .title1 {
          opacity: 4;
          margin-top: 2.22496909765142vh;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 1.6vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 2.94437577255871vh 1.35540150202195vw;
          text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          background-color: rgb(135, 103, 215);
        }

        .photo { overflow: hidden;
          display: none;
        }

        .title1 {
          position: absolute;
          left: 2.85540150202195vw;
          top: 2.22496909765142vh;
          height: 2.71940667490729vh;
          color: white;
          font-size: 1.6vw;
        }

        .title2 {
          margin-top: 8.22496909765142vh;
          text-align: justify;
          color: white;
          font-size: 0.80878105141537vw;
        }

        .consult {
          position: absolute;
          left: 2.85540150202195vw;
          cursor: pointer;
          display: block;
          color: white;
        }
      }
    }

    .pro4 {
      height: fit-content;
      width: 100%;
      margin: auto;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 1vw;
      row-gap: 1vh;

      .grand2 {
        cursor: pointer;
        width: 100%;
        margin: auto;
        height: 30vh;
        padding: 0.98887515451174vh 0 0.98887515451174vh 0;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: 100%;
          background: white;;;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #D4E4FF;
          border-radius: 9px;
        }

        .photo { overflow: hidden;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 5vh auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: fit-content;
          height: 8.73176761433869vh;
        }

        .title1 {
          opacity: 4;
          margin-top: 4.22496909765142vh;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 0.97094165222415vw;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.5vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          //text-align: justify;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          height: 14vh;
          text-overflow: ellipsis;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Roboto, sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto, sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          background-color: rgb(47, 110, 255);
        }

        .photo { overflow: hidden;

        }

        .title1 {
          color: white;
          margin-top: 3.22496909765142vh;
          //position: absolute;
          //left: 1.85540150202195vw;
          //top: 2.22496909765142vh;
          //height: 2.71940667490729vh;
          //color: white;
          //font-size: 0.97094165222415vw;
        }

        .title2 {
          margin-top: 8.22496909765142vh;
          //text-align: justify;
          color: white;
          font-size: 0.80878105141537vw;
        }

        .consult {
          cursor: pointer;
          display: block;
          left: 50%;
          transform: translateX(-50%);
          color: white;
        }

      }
    }


  }

  .screenConsultnew {
    z-index: 10;

    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    padding: 5.78739184177998vh 8.09763142692085vw 5.78739184177998vh 8.09763142692085vw;
    background: transparent;

    .light2 {
      margin: 0 auto 0 auto;
      text-align: justify;
      width: 60.9693818602vw;
      font-size: 1.3vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #FF6400;


    }

    .title {

      height: 8.23733003708282vh;
      opacity: 1;
      font-size: 2.5vw;
      //color: #53A3F7;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 4.23733003708282vh;
    }

    .light {
      margin: 0 auto 4vh auto;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size: 1.3vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 4.94437577255871vh;

    }

    .pro4 {
      height: fit-content;
      width: 100%;
      margin: auto;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 1vw;
      row-gap: 1vh;

      .grand2 {
        cursor: pointer;
        width: 100%;
        margin: auto;
        height: 30vh;
        padding: 0.98887515451174vh 0 0.98887515451174vh 0;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: 100%;
          background: white;;;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #D4E4FF;
          border-radius: 9px;
        }

        .photo { overflow: hidden;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 5vh auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width:8.73176761433869vh;
          height: 8.73176761433869vh;
        }

        .title1 {
          opacity: 4;
          margin-top: 4.22496909765142vh;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 1.5vw;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.5vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          //text-align: justify;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          height: 14vh;
          text-overflow: ellipsis;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Roboto, sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto, sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          background-color: rgb(247, 106, 35);
        }

        .photo { overflow: hidden;

        }

        .title1 {
          color: white;
          margin-top: 3.22496909765142vh;
          //position: absolute;
          //left: 1.85540150202195vw;
          //top: 2.22496909765142vh;
          //height: 2.71940667490729vh;
          //color: white;
          //font-size: 0.97094165222415vw;
        }

        .title2 {
          margin-top: 8.22496909765142vh;
          //text-align: justify;
          color: white;
          font-size: 0.80878105141537vw;
        }

        .consult {
          cursor: pointer;
          display: block;
          left: 50%;
          transform: translateX(-50%);
          color: white;
        }

      }
    }
    .pro3 {
      //margin-top: -4vh;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;
      row-gap: 2vh;

      .grand2 {
        cursor: pointer;
        width: 100%;
        height: 38.65636588380717vh;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: 100%;
          background: white;;;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }


        .photo { overflow: hidden;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 3vh auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: fit-content;
          height: 4.73176761433869vh;
        }


        .title1 {
          opacity: 4;
          margin-top: 2.22496909765142vh;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 1.6vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          background-color: rgb(135, 103, 215);
        }

        .photo { overflow: hidden;
          display: none;
        }

        .title1 {
          position: absolute;
          left: 1.85540150202195vw;
          top: 2.22496909765142vh;
          height: 2.71940667490729vh;
          color: white;
          font-size: 1.6vw;
        }

        .title2 {
          margin-top: 8.22496909765142vh;
          text-align: justify;
          color: white;
          font-size: 0.80878105141537vw;
        }

        .consult {
          cursor: pointer;
          display: block;
          color: white;
        }

      }
    }


  }

  .screenGeneral {
    z-index: 10;

    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    padding: 5.78739184177998vh 8.09763142692085vw 5.78739184177998vh 8.09763142692085vw;
    background: transparent;

    .title {
      margin: 0 auto;
      text-align: center;
      height: 8.23733003708282vh;
      opacity: 1;
      font-size: 2.5vw;
      //color: #53A3F7;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 4.23733003708282vh;
    }

    .light {
      margin: 0 auto 4vh auto;
      text-align: justify;
      width:fit-content;
      height: fit-content;
      font-size: 1.3vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 4.94437577255871vh;

    }


    .light2 {
      margin: 0 auto 0 auto;
      text-align: justify;
      width: 66vw;
      font-size: 1.3vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #FF6400;
      line-height: 4.94437577255871vh;
    }

    .pro1 {
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;
      row-gap: 2vh;

      .grand2 {
        cursor: pointer;
        width: 15.13575967648758vw;
        height: 42.65636588380717vh;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: 100%;
          background: white;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }


        .photo { overflow: hidden;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 3vh auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: fit-content;
          height: 4.73176761433869vh;
        }


        .title1 {
          opacity: 4;
          margin-top: 2.22496909765142vh;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 1.6vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 2.94437577255871vh 1.35540150202195vw;
          text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          background-color: rgb(135, 103, 215);
        }

        .photo { overflow: hidden;
          display: none;
        }

        .title1 {
          position: absolute;
          left: 1.85540150202195vw;
          top: 2.22496909765142vh;
          height: 2.71940667490729vh;
          color: white;
          font-size: 1.6vw;
        }

        .title2 {
          margin-top: 8.22496909765142vh;
          text-align: justify;
          color: white;
          font-size: 0.80878105141537vw;
        }

        .consult {
          cursor: pointer;
          display: block;
          color: white;
        }

      }
    }

    .pro3 {
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;
      row-gap: 2vh;

      .grand1 {
        cursor: pointer;
        width: 100%;
        //height: 46vh;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: fit-content;
          background: #e9eef9;;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .color {
          width: 100%;
          height: fit-content;
          background: linear-gradient(98.44deg, #faefdc 0%, #e5ebf8 50%, #faefdc 100%);

          text-align: center;
          justify-content: center;
          align-items: center;
          display: flex;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;

          .photo { overflow: hidden;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent; /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            display: flex;
            justify-content: center;
            text-align: center;
            width: fit-content;
            height: 15vh;
          }

        }


        .title1 {
          opacity: 4;
          margin-top: 3vh;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 1.6vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 2vh 1.35540150202195vw 4.94437577255871vh 1.35540150202195vw;
          text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand1:hover {
        .back {
          transform: translateY(-10px);

        }

      }


      .grand2 {
        cursor: pointer;
        width: 100%;
        min-height: 45vh;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          min-height: 43vh;
          background: #e9eef9;;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .color {
          width: 100%;
          height: fit-content;
          background: linear-gradient(98.44deg, #ADD8E6 0%, #e5ebf8 50%, #ADD8E6 100%);
          text-align: center;
          justify-content: center;
          align-items: center;
          display: flex;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;

          .photo { overflow: hidden;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent; /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            display: flex;
            justify-content: center;
            text-align: center;
            width: fit-content;
            height: 15vh;
          }

        }


        .title1 {
          opacity: 4;
          margin-top: 3vh;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 1.6vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 2vh 1.35540150202195vw 4.94437577255871vh 1.35540150202195vw;
          text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          transform: translateY(-10px);

        }

      }


    }
    .pro2 {
      height: fit-content;
      width: 100%;
      overflow-x: hidden;
      //z-index: 89156;

      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 0.98887515451174vh 0 0 0;
        opacity: 1;
        height: 250px;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: white;
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          //border-radius: 9px;
          border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: 30%;
          background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          // border-radius:6px;
          display: flex;
          border-bottom-left-radius: 9px;
          border-bottom-right-radius: 9px;
          padding: 10px 15px;
          justify-content: space-between;
        }

        .photo { overflow: hidden;

          border-radius: 9px;
          border: #dddddd 3px solid;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          //background-color: #d4e4ff; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          height: 40vh;
        }

        .title1 {
          opacity: 4;
          text-align: left;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.6vw;;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          color: white;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          // text-align: justify;
          margin: auto 0 0 auto;
          font-size: 1.21vw;
          font-family: Roboto, sans-serif;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }
  }

  .screenGeneral1 {
    z-index: 10;

    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    padding: 5.78739184177998vh 8.09763142692085vw 5.78739184177998vh 8.09763142692085vw;
    background: white;

    .title {
      margin: 0 auto;
      text-align: center;
      height: 8.23733003708282vh;
      opacity: 1;
      font-size: 2.5vw;
      //color: #53A3F7;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 4.23733003708282vh;
    }

    .light2 {
      margin: 0 auto 0 auto;
      text-align: justify;
      width: 60.9693818602vw;
      font-size: 1.3vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #FF6400;
      line-height: 4.9443757726vh;

    }

    .light {
      margin: 0 auto 4vh auto;
      text-align: justify;
      width:fit-content ;
      height: fit-content;
      font-size: 1.3vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 4.94437577255871vh;

    }


    .light2 {
      margin: 0 auto 0 auto;
      text-align: justify;
      width: 66vw;
      font-size: 1.3vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #FF6400;
      line-height: 4.94437577255871vh;
    }


  }

  .screen6 {
    width: 100%;
    height: fit-content;
    //padding-top: 10.8442521632vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    //background-color: #bfd9fd; /* 这里设置背景颜色为透明 */
    background-color: white; /* 这里设置背景颜色为透明 */
    z-index: 10;
    //left: 10.86077411900635vw;
    border-radius: 0;

    .title {
      margin: 0 auto;
      text-align: center;
      height: 8.23733003708282vh;
      opacity: 1;
      font-size: 2.25vw;
      //color: #53A3F7;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 4.23733003708282vh;
    }

    .light {
      margin: 0 auto 4vh auto;
      text-align: justify;
      width: 66vw;
      height: fit-content;
      font-size: 1.3vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 4.94437577255871vh;

    }

    .light2 {
      margin: 0 auto 0 auto;
      text-align: justify;
      width: 66vw;
      font-size: 1.3vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #FF6400;
      line-height: 4.94437577255871vh;
    }

    .pro2 {
      height: fit-content;
      width: 100%;
      z-index: 89156;
      padding: 5.78739184177998vh 8.09763142692085vw 0 8.09763142692085vw;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      //column-gap: 1vw;
      row-gap: 2vh;

      .grand1 {
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.52432120161756vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: 25vh;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          background-image: url("../assets/servicesBanner1.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .details {
            position: absolute;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            color: #2f6dfd;
            font-size: 0.80878105141537vw;
            bottom: 2.4437577255871vh;
            right: 1.05540150202195vw;
          }
        }

        .photo { overflow: hidden;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          //box-shadow: -1px 1px 14px 0 #a2baf1;
        }

        .title1 {
          opacity: 4;
          text-align: center;

          padding: 3vh 1vw;
          display: flex;
          justify-content: center;
          align-items: center;
          height: fit-content;
          font-size: 1.6vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          //color: #2168DB;
          line-height: 3.4vh
        }

        .title2 {
          opacity: 4;
          margin: 1.9443757726vh 1.355401502vw 2.9443757726vh 1.355401502vw;
          text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.8087810514vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          line-height: 2.7194066749vh;
        }


        .consult {
          position: absolute;
          display: none;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand1:hover {
        .back {
          transform: translateY(-10px);

        }
      }

      .grand2 {
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: fit-content;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          background-image: url("../assets/servicesBanner2.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .details {
            position: absolute;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            color: #2f6dfd;
            font-size: 0.80878105141537vw;
            bottom: 2.4437577255871vh;
            right: 1.05540150202195vw;
          }
        }

        .photo { overflow: hidden;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          //box-shadow: -1px 1px 14px 0 #a2baf1;
        }

        .title1 {
          opacity: 4;
          text-align: center;

          padding: 3vh 1vw;
          display: flex;
          justify-content: center;
          align-items: center;
          height: fit-content;
          font-size: 1.6vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          //color: #2168DB;
          line-height: 3.4vh
        }

        .title2 {
          opacity: 4;
          margin: 1.9443757726vh 1.355401502vw 2.9443757726vh 1.355401502vw;
          text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.8087810514vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          line-height: 2.7194066749vh;
        }


        .consult {
          position: absolute;
          display: none;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          transform: translateY(-10px);

        }
      }


      .grand3 {
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: fit-content;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          background-image: url("../assets/servicesBanner3.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .details {
            position: absolute;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            color: #2f6dfd;
            font-size: 0.80878105141537vw;
            bottom: 2.4437577255871vh;
            right: 1.05540150202195vw;
          }
        }

        .photo { overflow: hidden;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          //box-shadow: -1px 1px 14px 0 #a2baf1;
        }

        .title1 {
          opacity: 4;
          text-align: center;

          padding: 3vh 1vw;
          display: flex;
          justify-content: center;
          align-items: center;
          height: fit-content;
          font-size: 1.6vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          //color: #2168DB;
          line-height: 3.4vh
        }

        .title2 {
          opacity: 4;
          margin: 1.9443757726vh 1.355401502vw 2.9443757726vh 1.355401502vw;
          text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.8087810514vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          line-height: 2.7194066749vh;
        }


        .consult {
          position: absolute;
          display: none;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand3:hover {
        .back {
          transform: translateY(-10px);

        }
      }
    }


    .pro3 {
      border-radius: 9px;
      background-color: transparent;
      height: fit-content;
      width: 100%;
      padding: 4.8442521631644vh 8.09763142692085vw 0.1vh 8.09763142692085vw;

      .title {
        margin: 0 auto;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.5vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 4.23733003708282vh;
      }

      .title2 {
        margin: 0 auto 4vh auto;
        text-align: justify;
        //width: 66vw;
        height: fit-content;
        font-size: 1.3vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
      }

      .foot {
        margin-top: 3vh;
        text-align: center;

        .left1 {
          //border-top: 1px solid linear-gradient(to right,yellow, green, blue, red, indigo, violet,orange,);
        }

        .line {
          height: 0.2vh;
          width: 100%;
          background: linear-gradient(to right, orange, yellow, green, blue, red, indigo, violet);
        }

        .title1 {
          display: flex;
          text-align: left;
          align-items: center;
          font-size: 2vw;
          padding: 2vh 0;
          height: fit-content;
          color: green;
          font-weight: bold;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          font-family: Times New Roman;
        }

        .content {
          max-height: 0;
          min-height: 0;
          overflow: hidden;
          transition: max-height 2s ease, min-height 2s ease;
        }

        .content-visible {
          max-height: 40vh; /* 足够高的值以适应内容 */
          min-height: 0;
        }

        .content1 {
          max-height: 0;
          overflow: hidden;
          transition: max-height 2s ease;
        }

        .content-visible1 {
          max-height: 35vh; /* 足够高的值以适应内容 */
        }

        .content2 {
          max-height: 0;
          overflow: hidden;
          transition: max-height 2s ease;
        }

        .content-visible2 {
          max-height: 500px; /* 足够高的值以适应内容 */
        }

        .title2 {
          opacity: 4;
          color: white;
          width: 100%;
          text-align: left;
          height: fit-content;
          font-size: 1.3vw;
          font-family: Times New Roman;
          font-weight: 500;
          line-height: 5vh;
        }


        .title3 {
          margin: 2vh auto;
          justify-content: flex-end;
          display: flex;
          text-align: right;
          align-items: center;
          font-size: 1.8vw;
          padding: 0 2vw 2vh 2vw;
          height: fit-content;
          font-weight: bold;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          transition: background-color 1s ease, background-image 1s ease;
          color: transparent;
          background: linear-gradient(to right, orange, yellow, green, blue, red, indigo, violet);
          background-clip: text;
          animation: shine 3s infinite linear;
        }

        @keyframes shine {
          0% {
            background-position: 0 0;
          }
          100% {
            background-position: 100% 0;
          }
        }

        .flex {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        p {
          text-align: center;
          width: fit-content;
          color: #999999;
          margin: 0;
        }

        span {
          padding: 0 10px;
          color: #999999;
          font-size: 12px;
        }

        p:nth-child(1),
        p:nth-child(3) {
          font-size: 12px;
          font-family: Times New Roman;
          font-weight: 400;
          line-height: 30px;
        }

        p.red {
          color: #ef3e4f;
          /* font-weight: bold; */
          font-size: 12px;
          line-height: 30px;
        }

        p.red a {
          color: #999999;
        }
      }
      .companyIntroduce {
        height: fit-content;
        width: 100%;
        z-index: 89156;

        display: grid;
        grid-template-columns:minmax(0, 1fr);
        //column-gap: 1vw;
        row-gap: 3vh;

        .grand3 {
          cursor: pointer;
          width: 100%;
          height: 30vh;
          padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
          opacity: 1;
          position: relative;


          .back {
            width: 100%;
            display: flex;
            transition: transform 0.5s ease;
            height: 30vh;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            border: 2px solid #FFFFFF;
            border-radius: 9px;
            position: relative;
            //background-color: white;
            background-image: url("../assets/companyBackground1.png");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

            .photo { overflow: hidden;

              justify-content: center;
              text-align: center;
              align-items: center;
              width: 15vw;
              margin: auto auto auto auto;
              height: fit-content;
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              //box-shadow: -1px 1px 14px 0 #a2baf1;
            }

            .photoHidden {
              top: 5%;
              height: 90%;
              position: absolute;
              right: 0;
              opacity: 0.2;
            }

            .title {
              width: 100%;

              .title1 {
                display: flex;
                justify-content: center;
                text-align: center;
                align-items: center;
                width: 330px;
                margin: 4vh auto 0 1.355401502vw;
                height: 40px;
                border-top-right-radius: 9px;
                border-top-left-radius: 9px;
              }

              .title2 {
                opacity: 4;
                color: white;
                width: 100%;
                padding: 1.9443757726vh 1.355401502vw;
                text-align: justify;

                font-size: 1vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height: 4.5vh;
              }


              .title3 {
                position: absolute;
                display: flex;
                justify-content: center;
                text-align: right;
                bottom: 3vh;
                right: 1.355401502vw;
                align-items: center;
                width: 235px;
                height: 35px;
                border-top-right-radius: 9px;
                border-top-left-radius: 9px;
              }
            }
          ;


          }

        }

        .grand3:hover {
          .back {
            transform: translateY(-10px);

          }
        }

        .grand2 {
          cursor: pointer;
          width: 100%;
          height: 30vh;
          padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
          opacity: 1;
          position: relative;


          .back {
            width: 100%;
            display: flex;
            transition: transform 0.5s ease;
            height: 30vh;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            border: 2px solid #FFFFFF;
            border-radius: 9px;
            position: relative;
            //background-color: white;
            background-image: url("../assets/companyBackground.png");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

            .photo { overflow: hidden;

              justify-content: center;
              text-align: center;
              align-items: center;
              width: 15vw;
              margin: auto auto auto auto;
              height: fit-content;
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              //box-shadow: -1px 1px 14px 0 #a2baf1;
            }

            .photoHidden {
              top: 5%;
              height: 90%;
              position: absolute;
              right: 0;
              opacity: 0.2;
            }

            .title {
              width: 100%;

              .title1 {
                display: flex;
                justify-content: center;
                text-align: center;
                align-items: center;
                width: 163px;
                margin: 4vh auto 0 1.355401502vw;
                height: 40px;
                border-top-right-radius: 9px;
                border-top-left-radius: 9px;
              }

              .title2 {
                opacity: 4;
                color: white;
                width: 100%;
                padding: 1.9443757726vh 1.355401502vw;
                text-align: justify;

                font-size: 1vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height: 4.5vh;
              }


              .title3 {
                position: absolute;
                display: flex;
                justify-content: center;
                bottom: 3vh;
                right: 1.355401502vw;
                align-items: center;
                width: 300px;
                height: 35px;

              }
            }


          }

        }

        .grand2:hover {
          .back {
            transform: translateY(-10px);

          }
        }

        .grand4 {
          cursor: pointer;
          width: 100%;
          height: 30vh;
          padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
          opacity: 1;
          position: relative;


          .back {
            width: 100%;
            display: flex;
            transition: transform 0.5s ease;
            height: 30vh;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            border: 2px solid #FFFFFF;
            border-radius: 9px;
            position: relative;
            //background-color: white;
            background-image: url("../assets/companyBackground2.png");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

            .photo { overflow: hidden;

              justify-content: center;
              text-align: center;
              align-items: center;
              width: 15vw;
              margin: auto auto auto auto;
              height: fit-content;
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              //box-shadow: -1px 1px 14px 0 #a2baf1;
            }

            .photoHidden {
              top: 5%;
              height: 90%;
              position: absolute;
              right: 0;
              opacity: 0.2;
            }

            .title {
              width: 100%;

              .title1 {
                display: flex;
                justify-content: center;
                text-align: center;
                align-items: center;
                width: 198px;
                margin: 4vh auto 0 1.355401502vw;
                height: 40px;
                border-top-right-radius: 9px;
                border-top-left-radius: 9px;
              }

              .title2 {
                opacity: 4;
                color: white;
                width: 100%;
                padding: 1.9443757726vh 1.355401502vw;
                text-align: justify;
                font-size: 1vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height: 4.5vh;
              }


              .title3 {
                position: absolute;
                display: flex;
                justify-content: center;
                text-align: right;
                bottom: 3vh;
                right: 1.355401502vw;
                align-items: center;
                width: 135px;
                height: 35px;
                border-top-right-radius: 9px;
                border-top-left-radius: 9px;
              }
            }
          }

        }

        .grand4:hover {
          .back {
            transform: translateY(-10px);

          }
        }
      }

      .banner {

        border-radius: 9px;
        height: 30.53559950556242vh;
        //margin-top: 8.44993819530284vh;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 1vw;
        margin-top: 3vh;
        padding: 1.11248454882571vh 0.69324090121317vw;

        .back1 {
          border-radius: 9px;
          cursor: pointer;
          position: relative;
          width: 100%;
          height: 100%;

          border: 1px solid white;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          overflow: hidden;
          opacity: 1;

          .backTitle1 {
            position: absolute;
            top: 3.21384425216316vh;
            left: 1vw;
            height: 3.21384425216316vh;
            font-size: 1.0761821366vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            color: #2168db;
            line-height: 2.84301606922126vh;
          }

          .backTitle2 {
            position: absolute;
            top: 7.51384425216316vh;
            left: 1vw;
            width: 88%;
            font-size: 0.80878105141537vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 400;
            line-height: 2.71940667490729vh;
            opacity: 1;
            color: #2168db;
            text-align: justify;
          }

          .background {
            border-radius: 9px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../assets/adv1.png");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: transform 0.3s ease-out;
            overflow: hidden;
            padding: 3.21384425216316vh 1vw;
          }

          &:hover {
            .background {
              transform: scale(1.12);
            }
          }
        }

        .back4 {
          border-radius: 9px;
          cursor: pointer;
          position: relative;
          width: 100%;
          height: 100%;

          border: 1px solid white;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          overflow: hidden;
          opacity: 1;

          .backTitle1 {
            position: absolute;
            top: 3.21384425216316vh;
            left: 1vw;
            height: 3.21384425216316vh;
            font-size: 1.0761821366vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            color: #2168db;
            line-height: 2.84301606922126vh;
          }

          .backTitle2 {
            position: absolute;
            top: 7.51384425216316vh;
            left: 1vw;
            width: 88%;
            font-size: 0.80878105141537vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 400;
            line-height: 2.71940667490729vh;
            opacity: 1;
            color: #2168db;
            text-align: justify;
          }

          .background {
            border-radius: 9px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../assets/adv4.png");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: transform 0.3s ease-out;
            overflow: hidden;
            padding: 3.21384425216316vh 1vw;
          }

          &:hover {
            .background {
              transform: scale(1.12);
            }
          }
        }

        .back2 {
          border-radius: 9px;
          cursor: pointer;
          position: relative;
          width: 100%;
          height: 100%;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          overflow: hidden;
          opacity: 1;
          border: 1px solid white;

          .backTitle1 {
            position: absolute;
            top: 3.21384425216316vh;
            left: 1vw;
            height: 3.21384425216316vh;
            font-size: 1.0761821366vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            color: #2168db;
            line-height: 2.84301606922126vh;
          }

          .backTitle2 {
            position: absolute;
            top: 7.51384425216316vh;
            left: 1vw;
            width: 88%;
            font-size: 0.80878105141537vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 400;
            line-height: 2.71940667490729vh;
            opacity: 1;
            color: #2168db;
            text-align: justify;
          }

          .background {
            border-radius: 9px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../assets/adv2.png");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: transform 0.3s ease-out;
            overflow: hidden;
            padding: 3.21384425216316vh 1vw;
          }

          &:hover {
            .background {
              transform: scale(1.12);
            }
          }
        }

        .back3 {
          border: 1px solid white;
          border-radius: 9px;
          cursor: pointer;
          position: relative;
          width: 100%;
          height: 100%;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          overflow: hidden;
          opacity: 1;

          .backTitle1 {
            position: absolute;
            top: 3.21384425216316vh;
            left: 1vw;
            height: 3.21384425216316vh;
            font-size: 1.0761821366vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            color: #2168db;
            line-height: 2.84301606922126vh;
          }

          .backTitle2 {
            position: absolute;
            top: 7.51384425216316vh;
            left: 1vw;
            width: 88%;
            font-size: 0.80878105141537vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 400;
            line-height: 2.71940667490729vh;
            opacity: 1;
            color: #2168db;
            text-align: justify;
          }


          .background {
            border-radius: 9px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../assets/adv3.png");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: transform 0.3s ease-out;
            overflow: hidden;
          }

          &:hover {
            .background {
              transform: scale(1.12);
            }
          }
        }
      }
    }

    .pro4 {
      height: 87.72805933250927vh;
      width: 100%;
      padding: 13.8442521632vh 7.1854419410745vw 0 7.1854419410745vw;

      .title {
        text-align: center;
        height: 8.77626699629172vh;
        font-size: 3.5060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        //color: #2F6EFF;
        line-height: 2.84301606922126vh;
      }

      .banner {
        height: 48.83559950556242vh;
        margin-top: 4.44993819530284vh;
        width: 100%;
        column-gap: 1.61756210283073vw;
        padding: 1.11248454882571vh 1.69324090121317vw;
        position: relative;

        .carousel-group {
          padding: 0 2.76874638937031vw;
          display: flex;
        }

        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: #2168db;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .disabled-button {
          opacity: 0.5; /* Adjust the opacity as needed */
        }

        .disabled-buttonright {
          opacity: 0.5; /* Adjust the opacity as needed */
          background: gray;
          /* Add any other styles you want for the disabled state */
        }

        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: #2168db;
          color: #fff;
          position: absolute;
          top: 50%;
          right: 1.15540150202195vw;
          z-index: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .carousel-item {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 48.83559950556242vh;
          margin: 0 0.5108030040439vw;
          padding: 0.98887515451174vh 0.69324090121317vw;

          opacity: 1;

          .back {
            cursor: pointer;
            width: 100%;
            height: 100%;
            background: #2f6eff linear-gradient(to bottom, #cfe1ff 0%, #f0f6ff 100%);
            box-shadow: 3px 0px 8px 0px #d4e4ff;
            border-radius: 2px 2px 2px 2px;
            position: relative;

            .backlogo {
              margin: 3.11248454882571vh auto;
              text-align: center;
              height: 8.31520395550062vh;
              width: 8.3152039555vh;
            }

            .backtitle {
              margin-top: 2.81248454882571vh;
              text-align: center;
              height: 1.85414091470952vh;
              font-size: 1.5761821366vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 500;
              //color: #2168DB;
              line-height: 2.05414091470952vh;
            }

            .backtext {
              padding: 0 1.5248454882571vw;
              margin-top: 3.01248454882571vh;
              text-align: justify;
              font-size: 0.8087810514vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 500;
              //color: #2168DB;
              line-height: 2.7194066749vh;
            }

            .backconsult {
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 5.55414091470952vw;
            }

            .backconsultArrow {
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168db;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 6.55414091470952vw;
            }
          }
        }

        .carousel-item:hover {
          .backtitle {
            color: #2168db;
          }

          .backconsult {
            display: flex;
            position: absolute;
            bottom: 1.81248454882571vh;
            text-align: center;
            font-size: 0.9665511265vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 400;
            align-items: center;
            justify-content: center;
            height: 2.95414091470952vh;
            left: 5.55414091470952vw;

            color: transparent;
            /* 使用 @keyframes 定义颜色变化的动画 */
            animation: changeColor 0.2s ease-out 0.3s forwards;
          }

          /* 定义 @keyframes 动画，从透明色到蓝色 */
          @keyframes changeColor {
            from {
              color: rgba(33, 104, 219, 0); /* 透明色 */
            }
            to {
              color: rgba(33, 104, 219, 1); /* 蓝色 */
            }
          }

          .backconsultArrow {
            transform: translateX(2vw);
            transition: transform 0.3s ease-out;
          }
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .pro5 {
      height: 45.0061804697157vh;
      width: 100vw;
      padding: 8.8442521632vh 12.42056614673599vw;
      background-image: url("../assets/bottom.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      .highlight {
        height: 9.02348578491965vh;
        font-size: 2.1060658579vw;
        text-align: center;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 500;
        color: #2f6eff;
        line-height: 2.84301606922126vh;
      }

      .highlight1 {
        margin: 3.44993819530284vh auto auto auto;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 5.56242274412855vh;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #2168db;
        border-radius: 23px 23px 23px 23px;
        opacity: 1;
        font-size: 1.27vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #ffffff;
        line-height: 2.84301606922126vh;
        cursor: pointer;
      }

      .highlight1:hover {
        color: #2168db;
        background: rgba(255, 255, 255, 0.5);
        border: #2168db 1px solid;
      }
    }
  }

  .screen10 {

    width: 100%;
    height: fit-content;
    padding: 5.78739184177998vh 8.09763142692085vw 5.78739184177998vh 8.09763142692085vw;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    //background-color: transparent; /* 这里设置背景颜色为透明 */
    z-index: 10;
    //left: 10.86077411900635vw;
    border-radius: 0;
    background-color: white;

    .title {
      margin: 0 auto;
      text-align: center;
      height: 8.23733003708282vh;
      opacity: 1;
      font-size: 2.25vw;
      //color: #53A3F7;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 4.23733003708282vh;
    }

    .light {
      margin: 0 auto 4vh auto;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size: 1.3vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 4.94437577255871vh;
    }

    .light2 {
      margin: 0 auto 0 auto;
      text-align: justify;
      width: 66vw;
      font-size: 1.3vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #FF6400;
      line-height: 4.94437577255871vh;
    }

    .pro2 {
      height: fit-content;
      width: 100%;
      z-index: 89156;
      //padding: 0 8.09763142692085vw 0 8.09763142692085vw;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      //column-gap: 1vw;
      row-gap: 2vh;

      .grand1 {
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: 45vh;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          background-color: #e9eef9;
          //background-image: url("../assets/servicesBanner1.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .details {
            position: absolute;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            color: #2f6dfd;
            font-size: 0.80878105141537vw;
            bottom: 2.4437577255871vh;
            right: 1.05540150202195vw;
          }
        }

        .photo { overflow: hidden;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          //box-shadow: -1px 1px 14px 0 #a2baf1;
        }

        .title1 {
          opacity: 4;
          text-align: center;
          padding: 3vh 1vw;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.6vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          //color: #2168DB;
          line-height: 3.4vh;
          height: 12vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.9443757726vh 1.355401502vw 2.9443757726vh 1.355401502vw;
          text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.8087810514vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          line-height: 2.7194066749vh;
        }


        .consult {
          position: absolute;
          display: none;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand1:hover {
        .back {
          transform: translateY(-10px);

        }
      }

      .grand2 {
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: fit-content;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          background-color: #e9eef9;
          //background-image: url("../assets/servicesBanner2.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .details {
            position: absolute;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            color: #2f6dfd;
            font-size: 0.80878105141537vw;
            bottom: 2.4437577255871vh;
            right: 1.05540150202195vw;
          }
        }

        .photo { overflow: hidden;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          //box-shadow: -1px 1px 14px 0 #a2baf1;
        }

        .title1 {
          opacity: 4;
          text-align: center;

          padding: 3vh 1vw;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.6vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          //color: #2168DB;
          line-height: 3.4vh;
          height: 12vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.9443757726vh 1.355401502vw 2.9443757726vh 1.355401502vw;
          text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.8087810514vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          line-height: 2.7194066749vh;
        }


        .consult {
          position: absolute;
          display: none;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          transform: translateY(-10px);

        }
      }


      .grand3 {
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: fit-content;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          background-color: #e9eef9;
          border-radius: 9px;
          position: relative;
          //background-image: url("../assets/servicesBanner3.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .details {
            position: absolute;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            color: #2f6dfd;
            font-size: 0.80878105141537vw;
            bottom: 2.4437577255871vh;
            right: 1.05540150202195vw;
          }
        }

        .photo { overflow: hidden;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          //box-shadow: -1px 1px 14px 0 #a2baf1;
        }

        .title1 {
          opacity: 4;
          text-align: center;

          padding: 3vh 1vw;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 12vh;
          font-size: 1.6vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          //color: #2168DB;
          line-height: 3.4vh
        }

        .title2 {
          opacity: 4;
          margin: 1.9443757726vh 1.355401502vw 2.9443757726vh 1.355401502vw;
          text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.8087810514vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          line-height: 2.7194066749vh;
        }


        .consult {
          position: absolute;
          display: none;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand3:hover {
        .back {
          transform: translateY(-10px);

        }
      }
    }

    .pro1 {
      display: grid;
      width: 100%;
      height: fit-content;
      margin: auto;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

      column-gap: 1vw;

      .card {
        height: 100%;

        .back {
          width: 100%;
          height: 100%;
          border: #1c1e77 1px solid;
          border-radius: 9px;

        }
      }

      .card:hover {
        .back {
          cursor: pointer;
          background-color: #1c1e77;
          color: white;

          .self-build1 {
            content: url("../assets/bigmodel_white.png");
          }

          .self-build2 {
            content: url("../assets/business_white.png");
          }

          .self-build3 {
            content: url("../assets/knowledgeicon_white.png");
          }

          .self-build4 {
            content: url("../assets/CPU_white.png");
          }

          .self-build11 {
            content: url("../assets/more_btn_icon.png_white.png");
          }
        }
      }
    }

    .pro3 {
      border-radius: 9px;
      background-color: white;
      height: fit-content;
      width: 100%;
      padding: 4.8442521631644vh 8.09763142692085vw 8.8442521631644vh 8.09763142692085vw;

      .title {
        margin: 0 auto;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.25vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 4.23733003708282vh;
      }

      .title2 {
        margin: 0 auto;
        text-align: center;
        width: 66vw;
        font-size: 1.3vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        height: 8.7873918418vh;
        line-height: 4.94437577255871vh;
      }

      .banner {

        border-radius: 9px;
        height: 30.53559950556242vh;
        //margin-top: 8.44993819530284vh;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        //column-gap: 1vw;
        padding: 1.11248454882571vh 0.69324090121317vw;

        .back1 {
          border-radius: 9px;
          cursor: pointer;
          position: relative;
          width: 100%;
          height: 100%;

          border: 1px solid white;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          overflow: hidden;
          opacity: 1;

          .backTitle1 {
            position: absolute;
            top: 3.21384425216316vh;
            left: 1vw;
            height: 3.21384425216316vh;
            font-size: 1.0761821366vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            color: #2168db;
            line-height: 2.84301606922126vh;
          }

          .backTitle2 {
            position: absolute;
            top: 7.51384425216316vh;
            left: 1vw;
            width: 88%;
            font-size: 0.80878105141537vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 400;
            line-height: 2.71940667490729vh;
            opacity: 1;
            color: #2168db;
            text-align: justify;
          }

          .background {
            border-radius: 9px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../assets/adv1.jpg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: transform 0.3s ease-out;
            overflow: hidden;
            padding: 3.21384425216316vh 1vw;
          }

          &:hover {
            .background {
              transform: scale(1.12);
            }
          }
        }

        .back4 {
          border-radius: 9px;
          cursor: pointer;
          position: relative;
          width: 100%;
          height: 100%;

          border: 1px solid white;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          overflow: hidden;
          opacity: 1;

          .backTitle1 {
            position: absolute;
            top: 3.21384425216316vh;
            left: 1vw;
            height: 3.21384425216316vh;
            font-size: 1.0761821366vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            color: #2168db;
            line-height: 2.84301606922126vh;
          }

          .backTitle2 {
            position: absolute;
            top: 7.51384425216316vh;
            left: 1vw;
            width: 88%;
            font-size: 0.80878105141537vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 400;
            line-height: 2.71940667490729vh;
            opacity: 1;
            color: #2168db;
            text-align: justify;
          }

          .background {
            border-radius: 9px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../assets/environment.png");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: transform 0.3s ease-out;
            overflow: hidden;
            padding: 3.21384425216316vh 1vw;
          }

          &:hover {
            .background {
              transform: scale(1.12);
            }
          }
        }

        .back2 {
          border-radius: 9px;
          cursor: pointer;
          position: relative;
          width: 100%;
          height: 100%;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          overflow: hidden;
          opacity: 1;
          border: 1px solid white;

          .backTitle1 {
            position: absolute;
            top: 3.21384425216316vh;
            left: 1vw;
            height: 3.21384425216316vh;
            font-size: 1.0761821366vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            color: #2168db;
            line-height: 2.84301606922126vh;
          }

          .backTitle2 {
            position: absolute;
            top: 7.51384425216316vh;
            left: 1vw;
            width: 88%;
            font-size: 0.80878105141537vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 400;
            line-height: 2.71940667490729vh;
            opacity: 1;
            color: #2168db;
            text-align: justify;
          }

          .background {
            border-radius: 9px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../assets/adv2.jpg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: transform 0.3s ease-out;
            overflow: hidden;
            padding: 3.21384425216316vh 1vw;
          }

          &:hover {
            .background {
              transform: scale(1.12);
            }
          }
        }

        .back3 {
          border: 1px solid white;
          border-radius: 9px;
          cursor: pointer;
          position: relative;
          width: 100%;
          height: 100%;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          overflow: hidden;
          opacity: 1;

          .backTitle1 {
            position: absolute;
            top: 3.21384425216316vh;
            left: 1vw;
            height: 3.21384425216316vh;
            font-size: 1.0761821366vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            color: #2168db;
            line-height: 2.84301606922126vh;
          }

          .backTitle2 {
            position: absolute;
            top: 7.51384425216316vh;
            left: 1vw;
            width: 88%;
            font-size: 0.80878105141537vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 400;
            line-height: 2.71940667490729vh;
            opacity: 1;
            color: #2168db;
            text-align: justify;
          }


          .background {
            border-radius: 9px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../assets/adv3.jpg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: transform 0.3s ease-out;
            overflow: hidden;
          }

          &:hover {
            .background {
              transform: scale(1.12);
            }
          }
        }
      }
    }

    .pro4 {
      height: 87.72805933250927vh;
      width: 100%;
      padding: 13.8442521632vh 7.1854419410745vw 0 7.1854419410745vw;

      .title {
        text-align: center;
        height: 8.77626699629172vh;
        font-size: 3.5060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        //color: #2F6EFF;
        line-height: 2.84301606922126vh;
      }

      .banner {
        height: 48.83559950556242vh;
        margin-top: 4.44993819530284vh;
        width: 100%;
        column-gap: 1.61756210283073vw;
        padding: 1.11248454882571vh 1.69324090121317vw;
        position: relative;

        .carousel-group {
          padding: 0 2.76874638937031vw;
          display: flex;
        }

        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: #2168db;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .disabled-button {
          opacity: 0.5; /* Adjust the opacity as needed */
        }

        .disabled-buttonright {
          opacity: 0.5; /* Adjust the opacity as needed */
          background: gray;
          /* Add any other styles you want for the disabled state */
        }

        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: #2168db;
          color: #fff;
          position: absolute;
          top: 50%;
          right: 1.15540150202195vw;
          z-index: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .carousel-item {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 48.83559950556242vh;
          margin: 0 0.5108030040439vw;
          padding: 0.98887515451174vh 0.69324090121317vw;

          opacity: 1;

          .back {
            cursor: pointer;
            width: 100%;
            height: 100%;
            background: #2f6eff linear-gradient(to bottom, #cfe1ff 0%, #f0f6ff 100%);
            box-shadow: 3px 0px 8px 0px #d4e4ff;
            border-radius: 2px 2px 2px 2px;
            position: relative;

            .backlogo {
              margin: 3.11248454882571vh auto;
              text-align: center;
              height: 8.31520395550062vh;
              width: 8.3152039555vh;
            }

            .backtitle {
              margin-top: 2.81248454882571vh;
              text-align: center;
              height: 1.85414091470952vh;
              font-size: 1.5761821366vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 500;
              //color: #2168DB;
              line-height: 2.05414091470952vh;
            }

            .backtext {
              padding: 0 1.5248454882571vw;
              margin-top: 3.01248454882571vh;
              text-align: justify;
              font-size: 0.8087810514vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 500;
              //color: #2168DB;
              line-height: 2.7194066749vh;
            }

            .backconsult {
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 5.55414091470952vw;
            }

            .backconsultArrow {
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168db;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 6.55414091470952vw;
            }
          }
        }

        .carousel-item:hover {
          .backtitle {
            color: #2168db;
          }

          .backconsult {
            display: flex;
            position: absolute;
            bottom: 1.81248454882571vh;
            text-align: center;
            font-size: 0.9665511265vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 400;
            align-items: center;
            justify-content: center;
            height: 2.95414091470952vh;
            left: 5.55414091470952vw;

            color: transparent;
            /* 使用 @keyframes 定义颜色变化的动画 */
            animation: changeColor 0.2s ease-out 0.3s forwards;
          }

          /* 定义 @keyframes 动画，从透明色到蓝色 */
          @keyframes changeColor {
            from {
              color: rgba(33, 104, 219, 0); /* 透明色 */
            }
            to {
              color: rgba(33, 104, 219, 1); /* 蓝色 */
            }
          }

          .backconsultArrow {
            transform: translateX(2vw);
            transition: transform 0.3s ease-out;
          }
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .pro5 {
      height: 45.0061804697157vh;
      width: 100vw;
      padding: 8.8442521632vh 12.42056614673599vw;
      background-image: url("../assets/bottom.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      .highlight {
        height: 9.02348578491965vh;
        font-size: 2.1060658579vw;
        text-align: center;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 500;
        color: #2f6eff;
        line-height: 2.84301606922126vh;
      }

      .highlight1 {
        margin: 3.44993819530284vh auto auto auto;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 5.56242274412855vh;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #2168db;
        border-radius: 23px 23px 23px 23px;
        opacity: 1;
        font-size: 1.27vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #ffffff;
        line-height: 2.84301606922126vh;
        cursor: pointer;
      }

      .highlight1:hover {
        color: #2168db;
        background: rgba(255, 255, 255, 0.5);
        border: #2168db 1px solid;
      }
    }

    .pro12 {
      height: fit-content;
      width: 100%;
      overflow-x: hidden;
      //z-index: 89156;


      .grand1 {
        cursor: pointer;
        width: 98%;
        border-radius: 6px;
        padding: 0.98887515451174vh 0 0 0;
        opacity: 1;
        height: 250px;
        position: relative;
        background-color: transparent;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: white;
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          //border-radius: 9px;
          border: white 2px solid;
          border-radius: 9px;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: 20%;
          //background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
          background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          // border-radius:6px;
          display: flex;
          border-bottom-left-radius: 9px;
          border-bottom-right-radius: 9px;
          padding: 10px 15px;
          justify-content: space-between;
        }

        .photo { overflow: hidden;

          border-radius: 9px;
          border: #dddddd 3px solid;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          //background-color: #d4e4ff; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          height: 40vh;
        }

        .title1 {
          opacity: 4;
          text-align: left;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.6vw;;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          color: white;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          // text-align: justify;
          margin: auto 0 0 auto;
          font-size: 1.21vw;
          font-family: Roboto, sans-serif;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }
  }

  .screen8 {

    width: 100%;
    height: fit-content;
    padding: 5.78739184177998vh 8.09763142692085vw 5.78739184177998vh 8.09763142692085vw;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent; /* 这里设置背景颜色为透明 */
    z-index: 10;
    //left: 10.86077411900635vw;
    border-radius: 0;

    .poj {
      cursor: pointer;
      background-image: url("../assets/back1.png");
      background-size: contain;
      background-position: right;
      background-repeat: no-repeat;
      background-color: #e5edfc;
      width: 80%;
      height: 35%;
      border:  #e5edfc 1px solid;
      border-radius: 9px;
      margin: 5% 10%;
      position: relative
    }

    .poj:hover {
      background-color: #1d3b71;
      color:white;

      .pojimg {
        font-size: 1vw;
        font-weight: bold;
        position: absolute;
        bottom: 14%;
        right: 5%;
        color: white;

        .imgs {
          width: 1.2vw;
          object-fit: contain;
          content: url('../assets/more_btn_icon.png_white.png');
        }
      }
    }

    .title {
      margin: 0 auto;
      text-align: center;
      height: 8.23733003708282vh;
      opacity: 1;
      font-size: 2.25vw;
      //color: #53A3F7;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 4.23733003708282vh;
    }

    .light {
      margin: 0 auto 4vh auto;
      text-align: center;
      width: fit-content;
      height: fit-content;
      font-size: 1.3vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 4.94437577255871vh;
    }

    .light2 {
      margin: 0 auto 0 auto;
      text-align: center;
      width: 66vw;
      font-size: 1.3vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #FF6400;
      line-height: 4.94437577255871vh;
    }

    .pro1 {
      height: fit-content;
      width: 100%;
      z-index: 89156;
      padding: 0 8.09763142692085vw 0 8.09763142692085vw;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr);
      //column-gap: 1vw;
      row-gap: 2vh;


      .grand3 {
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          display: flex;
          transition: transform 0.5s ease;
          height: fit-content;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          background-image: url("../assets/servicesBanner3.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .details {
            position: absolute;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            color: #2f6dfd;
            font-size: 0.80878105141537vw;
            bottom: 2.4437577255871vh;
            right: 1.05540150202195vw;
          }
        }

        .photo { overflow: hidden;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          //box-shadow: -1px 1px 14px 0 #a2baf1;
        }

        .title1 {
          opacity: 4;
          text-align: justify;

          padding: 3vh 1.5vw 3vh 1.5vw;
          display: flex;
          height: fit-content;
          font-size: 1.6vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          //color: #2168DB;
          line-height: 1.5vh
        }

        .title2 {
          opacity: 4;
          margin: 0 1.355401502vw 0.5vh 1.355401502vw;
          text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.8087810514vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          line-height: 2.7194066749vh;
        }

        .title3 {
          opacity: 4;
          grid-template-columns:minmax(0, 1fr) minmax(0, 1fr);
          margin: 3vh 1.355401502vw 2.9443757726vh 1.355401502vw;
          text-align: justify;
          display: grid;
          justify-content: center;
          align-items: center;
          font-size: 0.8087810514vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          line-height: 2.7194066749vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand3:hover {
        .back {
          transform: translateY(-10px);

        }
      }
    }
    .pro2 {
      border-radius: 9px;
      height: fit-content;
      width: 100%;
      margin:auto;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;

      .grand2 {
        border-radius: 9px;
        cursor: pointer;
        width: 100%;
        min-height: 30.65636588380717vh;
        padding: 0.98887515451174vh 0.5vw 0.98887515451174vh 0.5vw;
        opacity: 1;
        position: relative;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          height: 100%;
          background: #ebf0fb;;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
        }

        .photo { overflow: hidden;
          border-radius: 9px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;

          .mengban {
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 100%;
            height: 20%;
            background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
            opacity: 0.9;
            border-bottom-right-radius: 9px;
            border-bottom-left-radius: 9px;

            .title1 {
              position: absolute;
              //height: 100%;
              color: white;
              margin-top: unset
            }
          }
        }

        .title1 {
          opacity: 4;
          margin: 2.22496909765142vh auto 2.22496909765142vh auto;
          padding: 3vh 1vw 1vh 1vw;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 1.6vw;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Roboto, sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto, sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          transform: translateY(-10px);

        }
      }
    }
    //.pro2 {
    //  height: fit-content;
    //  width: 100%;
    //  z-index: 89156;
    //  //padding: 0 8.09763142692085vw 0 8.09763142692085vw;
    //  display: grid;
    //  grid-template-columns:minmax(0, 1fr) minmax(0, 1fr);
    //  //column-gap: 1vw;
    //  row-gap: 2vh;
    //
    //
    //  .grand3 {
    //    cursor: pointer;
    //    width: 100%;
    //    height: 100%;
    //    padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
    //    opacity: 1;
    //    position: relative;
    //
    //
    //    .back {
    //      width: 100%;
    //      display: flex;
    //      transition: transform 0.5s ease;
    //      height: fit-content;
    //      //box-shadow: 5px 0 14px 0px #D4E4FF;
    //      border: 2px solid #FFFFFF;
    //      border-radius: 9px;
    //      position: relative;
    //      background-color: white;
    //      background-image: url("../assets/servicesBanner3.png");
    //      background-size: cover;
    //      background-position: center;
    //      background-repeat: no-repeat;
    //
    //      .details {
    //        position: absolute;
    //        font-family: Sarial, sans-serif, "Microsoft Yahei";
    //        font-weight: 500;
    //        color: #2f6dfd;
    //        font-size: 0.80878105141537vw;
    //        bottom: 2.4437577255871vh;
    //        right: 1.05540150202195vw;
    //      }
    //    }
    //
    //    .photo { overflow: hidden;
    //      display: flex;
    //      justify-content: center;
    //      text-align: center;
    //      width: 100%;
    //      height: fit-content;
    //      border-top-right-radius: 9px;
    //      border-top-left-radius: 9px;
    //      //box-shadow: -1px 1px 14px 0 #a2baf1;
    //    }
    //
    //    .title1 {
    //      opacity: 4;
    //      text-align: justify;
    //
    //      padding: 3vh 1.5vw;
    //      display: flex;
    //      height: fit-content;
    //      font-size: 1.6vw;
    //      font-family: Sarial, sans-serif, "Microsoft Yahei";
    //      font-weight: bold;
    //      //color: #2168DB;
    //      line-height: 3.4vh
    //    }
    //
    //    .title2 {
    //      opacity: 4;
    //      margin: 1.9443757726vh 1.355401502vw 2.9443757726vh 1.355401502vw;
    //      text-align: justify;
    //      display: flex;
    //      justify-content: center;
    //      align-items: center;
    //      font-size: 0.8087810514vw;
    //      font-family: Sarial, sans-serif, "Microsoft Yahei";
    //      font-weight: 500;
    //      line-height: 2.7194066749vh;
    //    }
    //
    //
    //    .consult {
    //      position: absolute;
    //      display: none;
    //      font-family: Sarial, sans-serif, "Microsoft Yahei";
    //      font-weight: 500;
    //      font-size: 0.80878105141537vw;
    //      bottom: 3.94437577255871vh;
    //      left: 1.85540150202195vw;
    //    }
    //  }
    //
    //  .grand3:hover {
    //    .back {
    //      transform: translateY(-10px);
    //
    //    }
    //  }
    //}

  }

  .screen9 {

    width: 100%;
    height: fit-content;
    padding-top: 7.78739184177998vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: white; /* 这里设置背景颜色为透明 */
    z-index: 10;
    //left: 10.86077411900635vw;
    border-radius: 0;

    .title {
      margin: 0 auto;
      text-align: center;
      height: 8.23733003708282vh;
      opacity: 1;
      font-size: 2.25vw;
      //color: #53A3F7;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 4.23733003708282vh;
    }

    .light {
      margin: 0 auto 4vh auto;
      text-align: center;
      width: 66vw;
      height: fit-content;
      font-size: 1.3vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 4.94437577255871vh;
    }

    .light2 {
      margin: 0 auto 0 auto;
      text-align: center;
      width: 66vw;
      font-size: 1.3vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #FF6400;
      line-height: 4.94437577255871vh;
    }

    .pro2 {
      height: fit-content;
      width: 100%;
      z-index: 89156;
      padding: 5.78739184177998vh 8.09763142692085vw 7.78739184177998vh 8.09763142692085vw;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      //column-gap: 1vw;
      row-gap: 2vh;

      .grand1 {
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: fit-content;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          background-image: url("../assets/servicesBanner1.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .details {
            position: absolute;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            color: #2f6dfd;
            font-size: 0.80878105141537vw;
            bottom: 2.4437577255871vh;
            right: 1.05540150202195vw;
          }
        }

        .photo { overflow: hidden;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          //box-shadow: -1px 1px 14px 0 #a2baf1;
        }

        .title1 {
          opacity: 4;
          text-align: center;

          padding: 3vh 1vw;
          display: flex;
          justify-content: center;
          align-items: center;
          height: fit-content;
          font-size: 1.6vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          //color: #2168DB;
          line-height: 3.4vh
        }

        .title2 {
          opacity: 4;
          margin: 1.9443757726vh 1.355401502vw 2.9443757726vh 1.355401502vw;
          text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.8087810514vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          line-height: 2.7194066749vh;
        }


        .consult {
          position: absolute;
          display: none;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand1:hover {
        .back {
          transform: translateY(-10px);

        }
      }

      .grand2 {
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: fit-content;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          background-image: url("../assets/servicesBanner2.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .details {
            position: absolute;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            color: #2f6dfd;
            font-size: 0.80878105141537vw;
            bottom: 2.4437577255871vh;
            right: 1.05540150202195vw;
          }
        }

        .photo { overflow: hidden;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          //box-shadow: -1px 1px 14px 0 #a2baf1;
        }

        .title1 {
          opacity: 4;
          text-align: center;

          padding: 3vh 1vw;
          display: flex;
          justify-content: center;
          align-items: center;
          height: fit-content;
          font-size: 1.6vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          //color: #2168DB;
          line-height: 3.4vh
        }

        .title2 {
          opacity: 4;
          margin: 1.9443757726vh 1.355401502vw 2.9443757726vh 1.355401502vw;
          text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.8087810514vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          line-height: 2.7194066749vh;
        }


        .consult {
          position: absolute;
          display: none;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          transform: translateY(-10px);

        }
      }


      .grand3 {
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: fit-content;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          background-image: url("../assets/servicesBanner3.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .details {
            position: absolute;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            color: #2f6dfd;
            font-size: 0.80878105141537vw;
            bottom: 2.4437577255871vh;
            right: 1.05540150202195vw;
          }
        }

        .photo { overflow: hidden;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          //box-shadow: -1px 1px 14px 0 #a2baf1;
        }

        .title1 {
          opacity: 4;
          text-align: center;
          padding: 3vh 1vw;
          display: flex;
          justify-content: center;
          align-items: center;
          height: fit-content;
          font-size: 1.6vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          //color: #2168DB;
          line-height: 3.4vh
        }

        .title2 {
          opacity: 4;
          margin: 1.9443757726vh 1.355401502vw 2.9443757726vh 1.355401502vw;
          text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.8087810514vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          line-height: 2.7194066749vh;
        }


        .consult {
          position: absolute;
          display: none;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand3:hover {
        .back {
          transform: translateY(-10px);

        }
      }
    }


    .pro3 {
      border-radius: 9px;
      background-color: white;
      height: fit-content;
      width: 100%;
      padding: 4.8442521631644vh 8.09763142692085vw 8.8442521631644vh 8.09763142692085vw;

      .title {
        margin: 0 auto;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.25vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 4.23733003708282vh;
      }

      .title2 {
        margin: 0 auto;
        text-align: center;
        width: 66vw;
        font-size: 1.3vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        height: 8.7873918418vh;
        line-height: 4.94437577255871vh;
      }

      .banner {

        border-radius: 9px;
        height: 30.53559950556242vh;
        //margin-top: 8.44993819530284vh;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        //column-gap: 1vw;
        padding: 1.11248454882571vh 0.69324090121317vw;

        .back1 {
          border-radius: 9px;
          cursor: pointer;
          position: relative;
          width: 100%;
          height: 100%;

          border: 1px solid white;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          overflow: hidden;
          opacity: 1;

          .backTitle1 {
            position: absolute;
            top: 3.21384425216316vh;
            left: 1vw;
            height: 3.21384425216316vh;
            font-size: 1.0761821366vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            color: #2168db;
            line-height: 2.84301606922126vh;
          }

          .backTitle2 {
            position: absolute;
            top: 7.51384425216316vh;
            left: 1vw;
            width: 88%;
            font-size: 0.80878105141537vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 400;
            line-height: 2.71940667490729vh;
            opacity: 1;
            color: #2168db;
            text-align: justify;
          }

          .background {
            border-radius: 9px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../assets/adv1.jpg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: transform 0.3s ease-out;
            overflow: hidden;
            padding: 3.21384425216316vh 1vw;
          }

          &:hover {
            .background {
              transform: scale(1.12);
            }
          }
        }

        .back4 {
          border-radius: 9px;
          cursor: pointer;
          position: relative;
          width: 100%;
          height: 100%;

          border: 1px solid white;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          overflow: hidden;
          opacity: 1;

          .backTitle1 {
            position: absolute;
            top: 3.21384425216316vh;
            left: 1vw;
            height: 3.21384425216316vh;
            font-size: 1.0761821366vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            color: #2168db;
            line-height: 2.84301606922126vh;
          }

          .backTitle2 {
            position: absolute;
            top: 7.51384425216316vh;
            left: 1vw;
            width: 88%;
            font-size: 0.80878105141537vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 400;
            line-height: 2.71940667490729vh;
            opacity: 1;
            color: #2168db;
            text-align: justify;
          }

          .background {
            border-radius: 9px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../assets/environment.png");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: transform 0.3s ease-out;
            overflow: hidden;
            padding: 3.21384425216316vh 1vw;
          }

          &:hover {
            .background {
              transform: scale(1.12);
            }
          }
        }

        .back2 {
          border-radius: 9px;
          cursor: pointer;
          position: relative;
          width: 100%;
          height: 100%;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          overflow: hidden;
          opacity: 1;
          border: 1px solid white;

          .backTitle1 {
            position: absolute;
            top: 3.21384425216316vh;
            left: 1vw;
            height: 3.21384425216316vh;
            font-size: 1.0761821366vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            color: #2168db;
            line-height: 2.84301606922126vh;
          }

          .backTitle2 {
            position: absolute;
            top: 7.51384425216316vh;
            left: 1vw;
            width: 88%;
            font-size: 0.80878105141537vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 400;
            line-height: 2.71940667490729vh;
            opacity: 1;
            color: #2168db;
            text-align: justify;
          }

          .background {
            border-radius: 9px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../assets/adv2.jpg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: transform 0.3s ease-out;
            overflow: hidden;
            padding: 3.21384425216316vh 1vw;
          }

          &:hover {
            .background {
              transform: scale(1.12);
            }
          }
        }

        .back3 {
          border: 1px solid white;
          border-radius: 9px;
          cursor: pointer;
          position: relative;
          width: 100%;
          height: 100%;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          overflow: hidden;
          opacity: 1;

          .backTitle1 {
            position: absolute;
            top: 3.21384425216316vh;
            left: 1vw;
            height: 3.21384425216316vh;
            font-size: 1.0761821366vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            color: #2168db;
            line-height: 2.84301606922126vh;
          }

          .backTitle2 {
            position: absolute;
            top: 7.51384425216316vh;
            left: 1vw;
            width: 88%;
            font-size: 0.80878105141537vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 400;
            line-height: 2.71940667490729vh;
            opacity: 1;
            color: #2168db;
            text-align: justify;
          }


          .background {
            border-radius: 9px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../assets/adv3.jpg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: transform 0.3s ease-out;
            overflow: hidden;
          }

          &:hover {
            .background {
              transform: scale(1.12);
            }
          }
        }
      }
    }

    .pro4 {
      height: 87.72805933250927vh;
      width: 100%;
      padding: 13.8442521632vh 7.1854419410745vw 0 7.1854419410745vw;

      .title {
        text-align: center;
        height: 8.77626699629172vh;
        font-size: 3.5060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        //color: #2F6EFF;
        line-height: 2.84301606922126vh;
      }

      .banner {
        height: 48.83559950556242vh;
        margin-top: 4.44993819530284vh;
        width: 100%;
        column-gap: 1.61756210283073vw;
        padding: 1.11248454882571vh 1.69324090121317vw;
        position: relative;

        .carousel-group {
          padding: 0 2.76874638937031vw;
          display: flex;
        }

        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: #2168db;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .disabled-button {
          opacity: 0.5; /* Adjust the opacity as needed */
        }

        .disabled-buttonright {
          opacity: 0.5; /* Adjust the opacity as needed */
          background: gray;
          /* Add any other styles you want for the disabled state */
        }

        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: #2168db;
          color: #fff;
          position: absolute;
          top: 50%;
          right: 1.15540150202195vw;
          z-index: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .carousel-item {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 48.83559950556242vh;
          margin: 0 0.5108030040439vw;
          padding: 0.98887515451174vh 0.69324090121317vw;

          opacity: 1;

          .back {
            cursor: pointer;
            width: 100%;
            height: 100%;
            background: #2f6eff linear-gradient(to bottom, #cfe1ff 0%, #f0f6ff 100%);
            box-shadow: 3px 0px 8px 0px #d4e4ff;
            border-radius: 2px 2px 2px 2px;
            position: relative;

            .backlogo {
              margin: 3.11248454882571vh auto;
              text-align: center;
              height: 8.31520395550062vh;
              width: 8.3152039555vh;
            }

            .backtitle {
              margin-top: 2.81248454882571vh;
              text-align: center;
              height: 1.85414091470952vh;
              font-size: 1.5761821366vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 500;
              //color: #2168DB;
              line-height: 2.05414091470952vh;
            }

            .backtext {
              padding: 0 1.5248454882571vw;
              margin-top: 3.01248454882571vh;
              text-align: justify;
              font-size: 0.8087810514vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 500;
              //color: #2168DB;
              line-height: 2.7194066749vh;
            }

            .backconsult {
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 5.55414091470952vw;
            }

            .backconsultArrow {
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168db;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 6.55414091470952vw;
            }
          }
        }

        .carousel-item:hover {
          .backtitle {
            color: #2168db;
          }

          .backconsult {
            display: flex;
            position: absolute;
            bottom: 1.81248454882571vh;
            text-align: center;
            font-size: 0.9665511265vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 400;
            align-items: center;
            justify-content: center;
            height: 2.95414091470952vh;
            left: 5.55414091470952vw;

            color: transparent;
            /* 使用 @keyframes 定义颜色变化的动画 */
            animation: changeColor 0.2s ease-out 0.3s forwards;
          }

          /* 定义 @keyframes 动画，从透明色到蓝色 */
          @keyframes changeColor {
            from {
              color: rgba(33, 104, 219, 0); /* 透明色 */
            }
            to {
              color: rgba(33, 104, 219, 1); /* 蓝色 */
            }
          }

          .backconsultArrow {
            transform: translateX(2vw);
            transition: transform 0.3s ease-out;
          }
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .pro5 {
      height: 45.0061804697157vh;
      width: 100vw;
      padding: 8.8442521632vh 12.42056614673599vw;
      background-image: url("../assets/bottom.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      .highlight {
        height: 9.02348578491965vh;
        font-size: 2.1060658579vw;
        text-align: center;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 500;
        color: #2f6eff;
        line-height: 2.84301606922126vh;
      }

      .highlight1 {
        margin: 3.44993819530284vh auto auto auto;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 5.56242274412855vh;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #2168db;
        border-radius: 23px 23px 23px 23px;
        opacity: 1;
        font-size: 1.27vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #ffffff;
        line-height: 2.84301606922126vh;
        cursor: pointer;
      }

      .highlight1:hover {
        color: #2168db;
        background: rgba(255, 255, 255, 0.5);
        border: #2168db 1px solid;
      }
    }
  }
}

.totalModel {
  background: #bed8f9;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;
  .up{
    cursor: pointer;
    position: fixed;
    top:40%;
    z-index: 2;
    right:0;
    background-color: #333f89;
    padding:5px;
    width: 30px;
    height:fit-content;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    //border-radius: 50%;
    //border: 5px solid #1295d9;
    .uptop{
      width:15px;
      height:15px;
      margin:5px auto;
      background-image: url("../assets/up.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
    }
    .upkefu{
      margin:0.5vh auto;
      width:15px;
      height:15px;
      background-image: url("../assets/kefu2.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
    }
    .wechaticon {
      width: 200px;
      height: 50px;
      background-color: #f0f0f0;
      transition: background-color 0.3s; /* 平滑过渡效果 */
    }
    .wechaticon.show-tooltip .tooltip {
      /* 当showTooltip为true时显示提示框 */
      opacity: 1;
    }

    .wechaticon:hover {
      background-color: #3498db; /* 鼠标悬停时的背景颜色 */
    }

    .feedback{
      cursor: pointer;
      width: fit-content;
      height:fit-content;
      color: white;
      font-size: 8px;
      margin:0 auto;
      text-align: center;
      //padding: 10px 0;
      display: flex;
      justify-content: center;
      border-top-left-radius: 9px;
      border-bottom-left-radius: 9px;
      //border: 5px solid #1295d9;
    }
  }
  .screen1 {
    //z-index: 3;
    position: relative;
    width: 100%;
    height: 80vh;
    background: linear-gradient(180deg,#f5f4f6,#e6ebf7);
    //background-position: center;
    //background-size: cover;
    //background-repeat: no-repeat;
    //background-color: transparent;
    /* Add any additional styling as needed */

    .layer2 {
      .title1 {
        margin: 0 auto;
        width: fit-content;
        height: 23px;
        font-size: 23px;
        font-family: Roboto,sans-serif;
        font-weight: bold;
        color: #111111;
        line-height: 8.23733003708282vh;
      }

      .title2 {
        margin: 35px auto 0 auto;
        width: fit-content;
        height: 17px;
        font-size: 16px;
        font-family: Roboto,sans-serif;
        font-weight: 400;
        color: #111111;
        line-height: 24px;
      }

      .title3 {
        display: flex;
        text-align: center;
        margin: 20px auto 0 auto;
        width: fit-content;
        height: fit-content;
        font-size: 8px;
        font-family: Roboto,sans-serif;
        font-weight: bold;
        color: #2f6eff;
        line-height: 22px;
      }
    }
    .title4img {
      position: absolute;
      bottom:5px;
      cursor: pointer;
      height: 5.5vh;
      width: 2vw;
      left: 48%;
      transform: translateX(-50%);
      animation: down 2s infinite;
      background-image: url("../assets/colordown.png");
      background-size: 100% auto;
      background-position: center;
      background-repeat: no-repeat;
      background-color: transparent;
      border: none;
    }

    .title4img:hover {
      background-color: transparent !important;
    }
    @keyframes down {
      0% {
        transform: translateY(-0.5vh); /* 原始大小 */
      }
      50% {
        transform: translateY(0.5vh); /* 原始大小 */
      }
      100% {
        transform: translateY(-0.5vh); /* 原始大小 */
      }
    }
    .title3img {
      position: absolute;
      top: 145px;
      cursor: pointer;
      height: 1.5vh;
      width: 1.5vw;
      left: 46%;
      //z-index: ;
      transform: translateX(-50%);
      animation: pulse 2s infinite;
      background-image: url("../assets/focusdown.png");
      background-size: 100% auto;
      background-position: center;
      background-repeat: no-repeat;
      background-color: transparent;
      border: none;
    }

    .title3img :hover {
      background-color: transparent;
    }
    @keyframes pulse {
      0% {
        transform: translateY(0.5vh); /* 原始大小 */
      }

      100% {
        transform: translateY(0); /* 原始大小 */
      }
    }
    .layer3 {
      //position: absolute;
      opacity: 1;
      z-index: 22;

      width: 100%;
      justify-content: center;

      .communicity {
        //z-index: 8;
        cursor: pointer;
        font-size: 16px;
        font-family: Roboto,sans-serif;
        font-weight: 400;
        color: #2f6eff;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center; /* Vertically center the content */
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 10px 15px;
        height: 35px;
        border: #2f6eff 1px solid;
        overflow: hidden; /* Add overflow property */
      }

      .communicityGpt {
        //z-index: 8;
        cursor: pointer;
        font-size: 16px;
        font-family: Roboto,sans-serif;
        font-weight: 400;
        color: white;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center; /* Vertically center the content */
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 10px 15px;
        height: 35px;
        background-color: #2f6eff;
        border: #2f6eff 1px solid;
        overflow: hidden; /* Add overflow property */
      }

    }
  }

  .screen3 {
    //z-index: 10;
    height: fit-content;
    padding: 20px 15px;
    background: rgba(255, 255, 255, 1);



    .title {
      margin: 0 auto;
      text-align: center;
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      //color: #53A3F7;
      font-family: Roboto,sans-serif;
      font-weight: bold;
      line-height: 26px;
    }

    .light {
      margin: 15px auto;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #2C3E50;
      line-height: 25px;
    }

    .light2 {
      margin: 20px auto 0 auto;
      text-align: justify;
      width: fit-content;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #FF6400;
      line-height: 25px;
    }
    .pro1 {
      height: fit-content;
      width: 100%;
      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 9px;
        padding:15px 0  0 0;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: white;
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
          border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }
        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          display: flex;
          padding: 10px 15px;
          justify-content: space-between;
        }
        .photo { overflow: hidden;
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          border-radius: 9px;
          height: 260px;
        }

        .title1 {
          text-align: left;
          font-size: 18px;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          color: white;
          text-overflow: ellipsis;
          width: 50vw;
          overflow: hidden;
          height: fit-content;
          //white-space: nowrap;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          // text-align: justify;
          font-size: 16px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }
    .pro11 {
      height: fit-content;
      width: 100%;
      //z-index: 89156;

      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 15px 0 0 0 ;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: white;
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
          border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }
        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          display: flex;
          padding: 10px 15px;
          justify-content: space-between;
        }
        .photo { overflow: hidden;
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          border-radius: 9px;
          height: 260px;
        }

        .title1 {
          opacity: 4;
          text-align: left;

          font-size: 18px;
          font-family: Roboto,sans-serif;
          font-weight: bold;
          color: white;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          margin:auto 0 0 auto;
          // text-align: justify;
          font-size: 16px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }
    .pro2 {
      height: fit-content;
      width: 100%;
      overflow-x: auto;
      //z-index: 89156;

      .swiper-container {
        width: 100%;
        height: fit-content;
      }
      .swiper-slide {
        text-align: center;
        //font-size: 18px;
        //background-color: #d4e4ff;
        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .swiper-pagination {
        position: relative;
        right: 20px;
        bottom: 20px;
        //z-index: 10;
      }

      .swiper-pagination-bullet {
        background: black; /* 设置分页器圆点的背景颜色 */
        opacity: 0.5; /* 设置透明度 */
      }

      .swiper-pagination-bullet-active {
        opacity: 1; /* 设置激活状态的透明度 */
      }
      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 0.98887515451174vh 0  0 0;
        opacity: 1;
        height: 250px;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: white;
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          //border-radius: 9px;
          border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }
        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          // border-radius:6px;
          display: flex;
          border-bottom-left-radius: 9px;
          border-bottom-right-radius: 9px;
          padding: 10px 15px;
          justify-content: space-between;
        }
        .photo { overflow: hidden;

          border-radius: 9px;
          border: #dddddd 3px solid;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          //background-color: #d4e4ff; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          height: 260px;
        }

        .title1 {
          opacity: 4;
          text-align: left;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          font-family: Roboto,sans-serif;
          font-weight: bold;
          color: white;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          // text-align: justify;
          margin:auto 0 0 auto;
          font-size: 16px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }
    .pro3 {
      .grand1 {
        display: grid;
        grid-template-columns:minmax(0, 1fr) minmax(0, 1fr);
        grid-gap: 10px;
        height: fit-content;

        .cards {

        }
      }
    }
  }

  .screen13 {
    //z-index: 10;
    height: fit-content;
    padding: 20px 15px;
    background: rgba(255, 255, 255, 1);



    .title {
      margin: 0 auto;
      text-align: center;
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      //color: #53A3F7;
      font-family: Roboto,sans-serif;
      font-weight: bold;
      line-height: 26px;
    }

    .light {
      margin: 15px 0;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #2C3E50;
      line-height: 25px;
    }

    .light2 {
      margin: 20px auto 0 auto;
      text-align: justify;
      width: fit-content;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #FF6400;
      line-height: 25px;
    }
    .pro1 {
      //height: fit-content;
      width: 100%;
      //z-index: 89156;
      .swiper-container0{
        position: relative;
      }
      ::v-deep .swiper-pagination {
        position: absolute;
      }
      ::v-deep .swiper-pagination-bullet {
        width: 5px !important;
        height: 25px !important;

        //position: absolute;
      }

      .swiper-container-vertical > .swiper-pagination-bullets{
        left:0px !important;
      }
      .swiper-pagination-bullet-active {
        background-color: rgb(47, 110, 255);
      }
      .grand1 {
        cursor: pointer;
        width: 100%;
        margin:0 0 0 auto;
        border-radius: 6px;
        padding: 15px  0  0 0;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: white;
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
          border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }
        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          display: flex;
          padding: 10px 15px;
          justify-content: space-between;
        }
        .photo { overflow: hidden;
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          border-radius: 9px;
          height: 260px;
        }

        .title1 {
          text-align: left;
          font-size: 18px;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          color: white;
          text-overflow: ellipsis;
          width: 50vw;
          overflow: hidden;
          height: fit-content;
          //white-space: nowrap;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          // text-align: justify;
          font-size: 16px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }
    .pro11 {
      height: fit-content;
      width: 100%;
      //z-index: 89156;

      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 0.98887515451174vh 0  0 0;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: white;
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
          border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }
        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          display: flex;
          padding: 10px 15px;
          justify-content: space-between;
        }
        .photo { overflow: hidden;
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          border-radius: 9px;
          height: 260px;
        }

        .title1 {
          opacity: 4;
          text-align: left;

          font-size: 18px;
          font-family: Roboto,sans-serif;
          font-weight: bold;
          color: white;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          margin:auto 0 0 auto;
          // text-align: justify;
          font-size: 16px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }
    .pro2 {
      height: fit-content;
      width: 100%;
      overflow-x: auto;
      //z-index: 89156;

      .swiper-container {
        width: 100%;
        height: fit-content;
      }
      .swiper-slide {
        text-align: center;
        //font-size: 18px;
        //background-color: #d4e4ff;
        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .swiper-pagination {
        position: relative;
        right: 20px;
        bottom: 20px;
        //z-index: 10;
      }

      .swiper-pagination-bullet {
        background: black; /* 设置分页器圆点的背景颜色 */
        opacity: 0.5; /* 设置透明度 */
      }

      .swiper-pagination-bullet-active {
        opacity: 1; /* 设置激活状态的透明度 */
      }
      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 0.98887515451174vh 0  0 0;
        opacity: 1;
        height: 250px;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: white;
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          //border-radius: 9px;
          border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }
        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          // border-radius:6px;
          display: flex;
          border-bottom-left-radius: 9px;
          border-bottom-right-radius: 9px;
          padding: 10px 15px;
          justify-content: space-between;
        }
        .photo { overflow: hidden;

          border-radius: 9px;
          border: #dddddd 3px solid;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          //background-color: #d4e4ff; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          height: 260px;
        }

        .title1 {
          opacity: 4;
          text-align: left;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          font-family: Roboto,sans-serif;
          font-weight: bold;
          color: white;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          // text-align: justify;
          margin:auto 0 0 auto;
          font-size: 16px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }
  }

  .screenConsult {
    //z-index: 10;
    height: fit-content;
    padding: 20px 15px;
    background: rgba(255, 255, 255, 1);

    .title {
      margin: 0 auto;
      text-align: center;
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      //color: #53A3F7;
      font-family: Roboto,sans-serif;
      font-weight: bold;
      line-height: 26px;
    }

    .light {
      margin: 15px auto;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #2C3E50;
      line-height: 25px;
    }

    .light2 {
      margin: 20px auto 0 auto;
      text-align: justify;
      width: fit-content;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #FF6400;
      line-height: 25px;
    }

    .pro1 {
      height: fit-content;
      width: 100%;
      overflow-x: auto;
      //z-index: 89156;

      .swiper-container {
        width: 100%;
        height: fit-content;
      }
      .swiper-slide {
        text-align: center;
        //font-size: 18px;
        //background-color: #d4e4ff;
        /* Center slide text vertically */
        display: flex;
        //margin-top: 50px;
        justify-content: center;
        align-items: center;
      }
      .swiper-pagination {
        position: relative;
        margin-top: 10px;
        background-color: black;
        //z-index: 10;
      }

      .swiper-pagination-bullet {
        background: black; /* 设置分页器圆点的背景颜色 */
        opacity: 0.5; /* 设置透明度 */
        width: 10px; /* 圆点宽度 */
        height: 10px; /* 圆点高度 */
        border: 1px solid #ccc; /* 圆点边框 */
        border-radius: 50%; /* 圆点边框圆角 */
        margin: 0 5px; /* 圆点间距 */

      }

      .swiper-pagination-bullet-active {
        opacity: 1; /* 设置激活状态的透明度 */
      }
      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 0.98887515451174vh 0  0 0;
        opacity: 1;
        height: 250px;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          //background-color: white;
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
          //border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }
        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          //background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
          ////box-shadow: 5px 0 14px 0 #d4e4ff;

          // border-radius:6px;
          display: flex;
          border-bottom-left-radius: 9px;
          border-bottom-right-radius: 9px;
          padding: 10px 15px;
          justify-content: space-between;
        }
        .photo { overflow: hidden;

          border-radius: 9px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          //background-color: #d4e4ff; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          height: 100%;
          ::v-deep .img{
            border-radius: 9px;
          }
        }

        .title1 {
          opacity: 4;
          text-align: left;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          font-family: Roboto,sans-serif;
          font-weight: bold;
          color: white;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          // text-align: justify;
          margin:auto 0 0 auto;
          font-size: 16px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }

    .pro2 {
      height: fit-content;
      width: 100%;
      //z-index: 89156;

      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 15px 0  0 0;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: white;
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
          border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }
        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          display: flex;
          padding: 10px 15px;
          justify-content: space-between;
        }
        .photo { overflow: hidden;
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          border-radius: 9px;
          height: 260px;
        }

        .title1 {
          text-align: left;
          font-size: 18px;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          color: white;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          //text-overflow: ellipsis;
          text-overflow: ellipsis;

          width: 40vw;
          overflow: hidden;
          height: fit-content;
          //white-space: nowrap;/
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          // text-align: justify;
          font-size: 16px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          margin:auto 0 0 auto;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }

    .pro3 {
      height: fit-content;
      width: 100%;
      //z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;
      row-gap: 1.8vh;

      .grand1 {
        cursor: pointer;
        width: fit-content;
        min-height: 180px;
        opacity: 1;
        position: relative;
        padding: 9px 6px;

        .back {
          width: 100%;
          height: 100%;
          background: white;
          box-shadow: 5px 0 2px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo { overflow: hidden;
          border-radius: 9px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 15px auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100px;
          height: 30px;
        }

        .title1 {
          opacity: 4;
          margin-top: 15px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 18px;
          font-size: 18px;
          font-family: Roboto,sans-serif;
          font-weight: bold;
          //color: #2168DB;
          line-height: 18px;
        }

        .title2 {
          opacity: 4;
          margin: 15px 8px 20px 8px;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height: 16px;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2 {
        cursor: pointer;
        width: fit-content;
        min-height: 180px;
        opacity: 1;
        position: relative;
        padding: 9px 6px;

        .back {
          width: 100%;
          height: 100%;
          background: white;
          box-shadow: 5px 0 2px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo { overflow: hidden;
          border-radius: 9px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 15px auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 110px;
          height: 30px;
        }

        .title1 {
          opacity: 4;
          margin-top: 15px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 18px;
          font-size: 18px;
          font-family: Roboto,sans-serif;
          font-weight: bold;
          //color: #2168DB;
          line-height: 18px;
        }

        .title2 {
          opacity: 4;
          margin: 15px 8px 20px 8px;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height: 16px;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

    }
  }

  .screenConsult1 {
    //z-index: 10;
    height: fit-content;
    padding: 20px 15px;
    background: rgba(255, 255, 255, 1);

    .title {
      margin: 0 auto;
      text-align: center;
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      //color: #53A3F7;
      font-family: Roboto,sans-serif;
      font-weight: bold;
      line-height: 26px;
    }

    .light {
      margin: 15px auto;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #2C3E50;
      line-height: 25px;
    }

    .light2 {
      margin: 20px auto 0 auto;
      text-align: justify;
      width: fit-content;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #FF6400;
      line-height: 25px;
    }

    .pro3 {
      height: fit-content;
      width: 100%;
      overflow-x: auto;
      //z-index: 89156;

      .swiper-container {
        width: 100%;
        height: fit-content;
      }
      .swiper-slide {
        text-align: center;
        //font-size: 18px;
        //background-color: #d4e4ff;
        /* Center slide text vertically */
        display: flex;
        //margin-top: 50px;
        justify-content: center;
        align-items: center;
      }
      .swiper-pagination {
        position: relative;
        margin-top: 10px;
        background-color: black;
        //z-index: 10;
      }

      .swiper-pagination-bullet {
        background: black; /* 设置分页器圆点的背景颜色 */
        opacity: 0.5; /* 设置透明度 */
        width: 10px; /* 圆点宽度 */
        height: 10px; /* 圆点高度 */
        border: 1px solid #ccc; /* 圆点边框 */
        border-radius: 50%; /* 圆点边框圆角 */
        margin: 0 5px; /* 圆点间距 */

      }

      .swiper-pagination-bullet-active {
        opacity: 1; /* 设置激活状态的透明度 */
      }
      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 0.98887515451174vh 0  0 0;
        opacity: 1;
        height: fit-content;
        position: relative;

        .back {
          width: 100%;
          height:fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          //background-color: white;
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
          //border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }
        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          //background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
          ////box-shadow: 5px 0 14px 0 #d4e4ff;

          // border-radius:6px;
          display: flex;
          border-bottom-left-radius: 9px;
          border-bottom-right-radius: 9px;
          padding: 10px 15px;
          justify-content: space-between;
        }
        .photo { overflow: hidden;

          border-radius: 9px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          //background-color: #d4e4ff; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          height: 250px;
          ::v-deep .img{
            border-radius: 9px;
          }
        }

        .title1 {
          opacity: 4;
          text-align: left;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          font-family: Roboto,sans-serif;
          font-weight: bold;
          color: white;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          // text-align: justify;
          margin:auto 0 0 auto;
          font-size: 16px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }


    .pro4 {
      height: fit-content;
      width: 100%;
      //z-index: 89156;

      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 15px 0 0 0 ;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: white;
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
          border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }
        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          display: flex;
          padding: 10px 15px;
          justify-content: space-between;
        }
        .photo { overflow: hidden;
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          border-radius: 9px;
          height: 260px;
        }

        .title1 {
          opacity: 4;
          //text-align: left;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          font-family: Roboto,sans-serif;
          font-weight: bold;
          color: white;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          // text-align: justify;
          font-size: 16px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          margin:auto 0 0 auto;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }
  }

  .screenGeneral {
    //z-index: 10;
    height: fit-content;
    padding: 20px 15px;
    background: rgba(255, 255, 255, 1);

    .title {
      margin: 0 auto;
      text-align: center;
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      //color: #53A3F7;
      font-family: Roboto,sans-serif;
      font-weight: bold;
      line-height: 26px;
    }

    .light {
      margin: 15px auto;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #2C3E50;
      line-height: 25px;
    }

    .light2 {
      margin: 20px auto 0 auto;
      text-align: justify;
      width: fit-content;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #FF6400;
      line-height: 25px;
    }
    .pro1 {
      height: fit-content;
      width: 100%;
      //z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 2px;
      row-gap: 0;

      .grand2 {
        cursor: pointer;
        width: 100%;
        height: fit-content;
        padding: 8px 4px 0 4px;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: 100%;
          background:  white;;
          padding: 0 5px;
          //box-shadow: 1px 0 2px 0px rgba(115, 115, 115, 0.5);
          border: 1px solid #C9C9C9;
          border-radius: 9px;
          //display: flex;
        }

        .photo { overflow: hidden;
          border-radius: 9px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 8px auto 6px auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 35px;
          height: 40px;
        }

        .title1 {
          opacity: 4;
          margin: 2px auto 8px auto;
          text-align: center;
          height: fit-content;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          font-family: Roboto,sans-serif;
          //font-weight: bold;
          color: #0a0a0a ;
          line-height: 25px;
        }

        .title2 {
          width: 110px;
          opacity: 4;
          margin: 5px 3px 5px 0;
          height: 14px;
          word-wrap: break-word; //换行
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 12px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height: 16px;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }
    }

  }

  .screen6 {

    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;
    background-color: #bbd6fd;
    padding: 20px 15px 10px 15px;

    .title {
      text-align: center;
      height: 21px;
      opacity: 1;
      font-size: 21px;
      //color: #53A3F7;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 26px;
    }

    .light {
      margin: 19px 2vw;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size: 16px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 25px;
    }

    .light2 {
      margin: 19px auto 0 auto;
      text-align: justify;
      width: fit-content;
      font-size: 16px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #FF6400;
      line-height: 25px;
    }
    .foot {
      margin-top: 15px;
      text-align: center;

      .left1 {
        border-top: 1px solid #e9e8e4;
      }

      .title1 {
        display: flex;
        text-align: left;
        align-items: center;
        font-size: 18px;
        padding: 15px 5px 10px 0px;
        height: fit-content;
        color: #2c3e4f;
        font-weight: bold;
        border-top-right-radius: 9px;
        border-top-left-radius: 9px;
        font-family: Times New Roman;
      }

      .content {
        max-height: 0;
        min-height: 0;
        overflow: hidden;
        transition: max-height 2s ease, min-height 2s ease;
      }

      .content-visible {
        max-height: 500px; /* 足够高的值以适应内容 */
        min-height: 0;
      }

      .content1 {
        max-height: 0;
        overflow: hidden;
        transition: max-height 2s ease;
      }

      .content-visible1 {
        max-height: 500px; /* 足够高的值以适应内容 */
      }

      .content2 {
        max-height: 0;
        overflow: hidden;
        transition: max-height 2s ease;
      }

      .content-visible2 {
        max-height: 500px; /* 足够高的值以适应内容 */
      }

      .title2 {
        opacity: 4;
        //color: black;
        width: 100%;
        text-align: left;
        height: fit-content;
        font-size: 16px;
        font-family: Times New Roman;
        //font-weight: 500;
        line-height: 22px;
      }


      .title3 {
        margin: 10px auto;
        justify-content: flex-end;
        display: flex;
        text-align: right;
        align-items: center;
        font-size: 16px;
        padding: 0 5px;
        height: fit-content;
        font-weight: bold;
        border-top-right-radius: 9px;
        border-top-left-radius: 9px;
        transition: background-color 1s ease, background-image 1s ease;
        color: transparent;
        background: linear-gradient(to right, orange, yellow, green, blue, red, indigo, violet);
        background-clip: text;
        animation: shine 3s infinite linear;
      }

      @keyframes shine {
        0% {
          background-position: 0 0;
        }
        100% {
          background-position: 100% 0;
        }
      }

      .flex {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      p {
        text-align: center;
        width: fit-content;
        color: #999999;
        margin: 0;
      }

      span {
        padding: 0 10px;
        color: #999999;
        font-size: 12px;
      }

      p:nth-child(1),
      p:nth-child(3) {
        font-size: 12px;
        font-family: Times New Roman;
        font-weight: 400;
        line-height: 30px;
      }

      p.red {
        color: #ef3e4f;
        /* font-weight: bold; */
        font-size: 12px;
        line-height: 30px;
      }

      p.red a {
        color: #999999;
      }
    }


    .companyIntroduce {
      height: fit-content;
      width: 100%;
      //z-index: 89156;

      display: grid;
      grid-template-columns:minmax(0, 1fr);
      //column-gap: 1vw;


      .grand3 {
        cursor: pointer;
        width: 100%;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;


        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: 260px;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          //background-color: white;
          background-image: url("../assets/companyBackground1.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .photo { overflow: hidden;

            justify-content: center;
            text-align: center;
            align-items: center;
            width: 59px;
            margin: 10px auto auto 20px;
            height: 10px;
            border-top-right-radius: 9px;
            border-top-left-radius: 9px;
            //box-shadow: -1px 1px 14px 0 #a2baf1;
          }

          .photoHidden {
            top: 30%;
            width: 100%;
            position: absolute;
            right: 0;
            opacity: 0.2;
          }

          .title {
            .title1 {
              display: flex;
              justify-content: center;
              text-align: center;
              align-items: center;
              width: 185px;
              margin: 25px auto 15px auto;
              height: 25px;
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
            }

            .title2 {
              opacity: 4;
              color: white;
              width: 100%;
              margin-top: -2px;
              padding: 0 20px 35px 10px;
              text-align: justify;
              height: fit-content;
              font-size: 12px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 500;
              line-height: 22px;
            }


            .title3 {
              position: absolute;
              display: flex;
              justify-content: center;
              text-align: right;
              bottom: 20px;
              right: 15px;
              align-items: center;
              width: 137px;
              height: 20px;
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
            }
          }
        ;


        }

      }

      .grand2 {
        cursor: pointer;
        width: 100%;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;


        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: 215px;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          //background-color: white;
          background-image: url("../assets/companyBackground.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .photo { overflow: hidden;

            justify-content: center;
            text-align: center;
            align-items: center;
            width: 59px;
            margin: 10px auto auto 20px;
            height: 10px;
            border-top-right-radius: 9px;
            border-top-left-radius: 9px;
            //box-shadow: -1px 1px 14px 0 #a2baf1;
          }

          .photoHidden {
            top: 8%;
            width: 100%;
            position: absolute;
            right: 0;
            opacity: 0.2;
          }

          .title {
            .title1 {
              display: flex;
              justify-content: center;
              text-align: center;
              align-items: center;
              width: 101px;
              margin: 25px auto 15px auto;
              height: 25px;
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
            }

            .title2 {
              opacity: 4;
              color: white;
              width: 100%;
              margin-top: -2px;
              padding: 0 20px 35px 10px;
              text-align: justify;
              height: fit-content;
              font-size: 12px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 500;
              line-height: 22px;
            }


            .title3 {
              position: absolute;
              display: flex;
              justify-content: center;
              text-align: right;
              bottom: 20px;
              right: 15px;
              align-items: center;
              width: 171px;
              height: 20px;
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
            }
          }
        ;


        }

      }


      .grand4 {
        cursor: pointer;
        width: 100%;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;


        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: 260px;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          //background-color: white;
          background-image: url("../assets/companyBackground2.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .photo { overflow: hidden;

            justify-content: center;
            text-align: center;
            align-items: center;
            width: 70px;
            margin: 10px auto auto 20px;
            height: 20px;
            border-top-right-radius: 9px;
            border-top-left-radius: 9px;
            //box-shadow: -1px 1px 14px 0 #a2baf1;
          }

          .photoHidden {
            width: 100%;
            position: absolute;
            right: 0;
            opacity: 0.2;
          }

          .title {
            .title1 {
              display: flex;
              justify-content: center;
              text-align: center;
              align-items: center;
              width: 122px;
              margin: 25px auto 15px auto;
              height: 25px;
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
            }

            .title2 {
              opacity: 4;
              color: white;
              width: 100%;
              margin-top: 10px;
              padding: 0 20px 35px 10px;
              text-align: justify;
              height: fit-content;
              font-size: 12px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 500;
              line-height: 22px;
            }


            .title3 {
              position: absolute;
              display: flex;
              justify-content: center;
              text-align: right;
              bottom: 20px;
              right: 15px;
              align-items: center;
              width: 76px;
              height: 20px;
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
            }
          }
        ;


        }

      }


    }

    .pro2 {
      height: fit-content;
      width: 100%;
      //z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 15px;

      .grand1 {
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: fit-content;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          background-image: url("../assets/servicesBanner1.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }

        .photo { overflow: hidden;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;
        }


        .title1 {
          opacity: 4;
          margin: 10px 10px 10px 10px;
          text-align: center;
          height: fit-content;
          font-size: 16px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          //font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 0 5px;
          padding: 0 5px 45px 5px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;

          text-align: justify;
          height: fit-content;
          font-size: 12px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          line-height: 25px;
        }


        .consult {
          position: absolute;
          bottom: 15px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size: 12px;
          color: #2F6EFF;
          right: 15px;
        }
      }

      .grand2 {
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: fit-content;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          background-image: url("../assets/servicesBanner1.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }

        .photo { overflow: hidden;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;
        }


        .title1 {
          opacity: 4;
          margin: 10px 10px 10px 10px;
          text-align: center;
          height: fit-content;
          font-size: 16px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          //font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 0 5px;
          padding: 0 5px 45px 5px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;

          text-align: justify;
          height: fit-content;
          font-size: 12px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          line-height: 25px;
        }


        .consult {
          position: absolute;
          bottom: 15px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size: 12px;
          color: #2F6EFF;
          right: 15px;
        }
      }


      .grand3 {
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: fit-content;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          background-image: url("../assets/servicesBanner1.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }

        .photo { overflow: hidden;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;
        }


        .title1 {
          opacity: 4;
          margin: 10px 10px 10px 10px;
          text-align: center;
          height: fit-content;
          font-size: 16px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          //font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 0 5px;
          padding: 0 5px 45px 5px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;

          text-align: justify;
          height: fit-content;
          font-size: 12px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          line-height: 25px;
        }


        .consult {
          position: absolute;
          bottom: 15px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size: 12px;
          color: #2F6EFF;
          right: 15px;
        }
      }


    }

    .pro3 {
      margin-left: -20px;
      background-color: transparent;
      height: fit-content;
      width: 100vw;
      padding: 0 15px;
      position: relative;
      //background-image: url("../assets/adv.jpg");
      //background-size: cover;
      //background-position: center;
      //background-repeat: no-repeat;
      .back {
        width: 100%;
        height: 500px;
        background-image: url("../assets/advantage.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .title {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }

      .title2 {
        margin:  0 2vw 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 16px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 25px;
      }

      .banner {
        height: fit-content;
        display: grid;
        grid-template-columns: minmax(0, 1fr);

        .back1 {
          cursor: pointer;
          position: relative;
          width: 100%;
          height: 100px;
          //box-shadow: 5px 0 2px 0px #D4E4FF;
          overflow: hidden;
          opacity: 1;
          //background: #D4E2FF;
          .backTitle1 {
            position: absolute;
            top: 5px;
            color: #2168db;
            left: 50%;
            transform: translateX(-50%);
            height: 32px;
            font-size: 18px;
            font-weight: 500;

            line-height: 30px;
          }

          .span {
            position: absolute;
            top: 32px;
            width: 65px;
            left: 50%;
            transform: translateX(-50%);
            background-color: #e5e0e0;
            height: 2px;
          }

          .backTitle2 {
            position: absolute;
            top: 40px;
            height: fit-content;
            left: 50%;
            transform: translateX(-50%);
            width: 90%;
            font-size: 16px;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 400;
            line-height: 2.71940667490729vh;
            opacity: 1;
            //color: #2168db;
            text-align: center;
          }
        }

      }
    }

    .pro4 {
      height: 87.72805933250927vh;
      width: 100%;
      padding: 13.8442521632vh 7.1854419410745vw 0 7.1854419410745vw;

      .title {
        text-align: center;
        height: 8.77626699629172vh;
        font-size: 3.5060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        //color: #2F6EFF;
        line-height: 2.84301606922126vh;
      }

      .banner {
        height: 48.83559950556242vh;
        margin-top: 4.44993819530284vh;
        width: 100%;
        column-gap: 1.61756210283073vw;
        padding: 1.11248454882571vh 1.69324090121317vw;
        position: relative;

        .carousel-group {
          padding: 0 2.76874638937031vw;
          display: flex;
        }

        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: #2168db;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          //font-weight: bold;
        }

        .disabled-button {
          opacity: 0.5; /* Adjust the opacity as needed */
        }

        .disabled-buttonright {
          opacity: 0.5; /* Adjust the opacity as needed */
          background: gray;
          /* Add any other styles you want for the disabled state */
        }

        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: #2168db;
          color: #fff;
          position: absolute;
          top: 50%;
          right: 1.15540150202195vw;
          //z-index: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .carousel-item {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 48.83559950556242vh;
          margin: 0 0.5108030040439vw;
          padding: 0.98887515451174vh 0.69324090121317vw;

          opacity: 1;

          .back {
            cursor: pointer;
            width: 100%;
            height: 100%;
            background: #2f6eff linear-gradient(to bottom, #cfe1ff 0%, #f0f6ff 100%);
            box-shadow: 3px 0px 8px 0px #d4e4ff;
            border-radius: 2px 2px 2px 2px;
            position: relative;

            .backlogo {
              margin: 3.11248454882571vh auto;
              text-align: center;
              height: 8.31520395550062vh;
              width: 8.3152039555vh;
            }

            .backtitle {
              margin-top: 2.81248454882571vh;
              text-align: center;
              height: 1.85414091470952vh;
              font-size: 1.5761821366vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 500;
              //color: #2168DB;
              line-height: 2.05414091470952vh;
            }

            .backtext {
              padding: 0 1.5248454882571vw;
              margin-top: 3.01248454882571vh;
              text-align: justify;
              font-size: 0.8087810514vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 500;
              //color: #2168DB;
              line-height: 2.7194066749vh;
            }

            .backconsult {
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 5.55414091470952vw;
            }

            .backconsultArrow {
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168db;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 6.55414091470952vw;
            }
          }
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .pro5 {
      height: fit-content;
      width: 100vw;
      margin-left: -20px;
      padding: 20px 15px;
      background-image: url("../assets/bottom.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      .highlight {
        text-align: justify;
        height: 21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }

      .highlight1 {
        margin: 35px auto auto auto;
        cursor: pointer;
        font-size: 17px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: white;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        padding: 10px 15px;
        height: 40px;
        background-color: #2f6eff;
        border: #2f6eff 1px solid;
        overflow: hidden;
      }

    }
  }


  .screen7 {

    width: 100%;
    bottom: 0;
    height: fit-content;
    background-color: #333333;

    .foot {
      padding-top: 10px;
      text-align: center;
      border-top: 1px solid #373b4f;

      .flex {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      p {
        text-align: center;
        width: fit-content;
        color: #999999;
        margin: 0;
      }

      span {
        padding: 0 10px;
        color: #999999;
        font-size: 12px;
      }

      p:nth-child(1),
      p:nth-child(3) {
        font-size: 12px;
        font-family: Roboto,sans-serif;
        font-weight: 400;
        line-height: 30px;
      }

      p.red {
        color: #ef3e4f;
        /* font-weight: bold; */
        font-size: 12px;
        line-height: 30px;
      }

      p.red a {
        color: #999999;
      }
    }

    .yuming {
      color: white;
    }

    .left {
      display: flex;
      color: #ffffff;

      .left1 {
        width: 9.51415366839977vw;
        font-size: 0.8709416522vw;
        font-family: Roboto,sans-serif;
        font-weight: 400;
        color: #ffffff;
        line-height: 4.33745364647713vh;
      }

      .left2 {
        margin-left: 4.15944540727903vw;
        width: 20.51415366839977vw;
        font-size: 0.8709416522vw;
        font-family: Roboto,sans-serif;
        font-weight: 400;
        color: #ffffff;
        line-height: 4.33745364647713vh;
      }
    }

    .right {
      margin-top: -1vh;
      display: flex;
      color: #ffffff;

      .left1 {
        width: 7.23916811091854vw;
        font-size: 0.8709416522vw;
        font-family: Roboto,sans-serif;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        line-height: 3.33745364647713vh;

        .up {
          width: 100%;
          height: auto;
          background-image: url("../assets/attention.png");
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }

      .left2 {
        margin-left: 4.15944540727903vw;
        width: 7.23916811091854vw;
        font-size: 0.8709416522vw;
        font-family: Roboto,sans-serif;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        line-height: 3.33745364647713vh;

        .up {
          width: 100%;
          height: auto;
          background-image: url("../assets/attention.png");
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }
  }

  //.screen4Plus{
  //  left: 10.86077411900635vw;
  //  position: absolute;
  //  top: 176.9962917181706vh;
  //  margin: 0 auto;
  //  width: 78.22068168688619vw;
  //  background: rgba(184, 223, 246, 0.4);
  //  box-shadow: 5px 0 2px 0px #D4E4FF;
  //  height: fit-content;
  //
  //  border-radius: 9px;
  //  padding: 4.0242274412855vh 1.09763142692085vw;
  //  .title{
  //    margin: 0 0.8vw;
  //    height: 6.05686032138443vh;
  //    opacity: 1;
  //    font-size: 1.67533217793183vw;
  //    //color: #53A3F7;
  //    font-family: Roboto,sans-serif;
  //    font-weight: 700;
  //    line-height: 2.71940667490729vh;
  //  }
  //  .screen4 {
  //    display: grid;
  //    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  //    column-gap: 1.44425187752744vw;
  //    .card {
  //      width: 24.09012131715771vw;
  //      height: 100%;
  //      padding: 1.11248454882571vh 0.51993067590988vw;
  //      border-radius: 9px 9px 9px 9px;
  //      opacity: 1;
  //      cursor: pointer;
  //      //border: 2px solid #FFFFFF;
  //      .top {
  //        height: 26.946847960445vh;
  //        width: 100%;
  //        padding: 2.4610630407911vh 1.79087232813403vw;
  //        position: relative;
  //        .logo {
  //          position: absolute;
  //          right: 2.00008464681845vw;
  //          top: 4.07354758961681vh;
  //          width: 6.54592720970537vw;
  //          height: 4.3263288009888vh;
  //          background-image: url("../assets/expoAi.png");
  //          background-size: 100% auto;
  //          background-position: center;
  //          background-repeat: no-repeat;
  //          background-color: transparent; /* 这里设置背景颜色为透明 */
  //          opacity: 1;
  //          //border: black 1px solid;
  //        }
  //        .title1 {
  //          height: 2.71940667490729vh;
  //          font-size: 0.97094165222415vw;
  //          font-family: Roboto,sans-serif;
  //          font-weight: bold;
  //          color: #339999;
  //          line-height: 5.31520395550062ch;
  //        }
  //        .title2 {
  //          //text-align: justify;
  //          margin-top: 4.71940667490729vh;
  //          height: fit-content;
  //          font-size: 0.80878105141537vw;
  //          font-family: Roboto,sans-serif;
  //          font-weight: 400;
  //          color: #339999;
  //          line-height: 2.96662546353523vh;
  //        }
  //      }
  //      .back {
  //        margin: auto 0 0 0;
  //        width: 100%;
  //        height: 100%;
  //        background: #eeeeee linear-gradient(to right, #ecfbe9 0%, #f0f6ff 100%);
  //        border-radius: 9px 9px 9px 9px;
  //        opacity: 1;
  //        border: 2px solid #ffffff;
  //        .bottom {
  //          width: 100%;
  //          height: 5.06798516687268vh;
  //          background: white;
  //          border-radius: 0px 0px 9px 9px;
  //          opacity: 1;
  //          padding-left: 1.32871172732525vw;
  //          padding-right: 1.32871172732525vw;
  //          display: flex;
  //          justify-content: space-between;
  //          align-items: center;
  //          color: #339999;
  //          font-size: 0.92432120161756vw;
  //        }
  //        .bottom:hover {
  //          background: #2f6eff;
  //          color: white;
  //        }
  //      }
  //    }
  //    .card:hover {
  //      transform: scale(1.05);
  //      transition-duration: 0.3s;
  //      .bottom {
  //        background: #2f6eff;
  //        color: white;
  //      }
  //    }
  //    .card2 {
  //      width: 24.09012131715771vw;
  //      height: 100%;
  //      padding: 1.11248454882571vh 0.51993067590988vw;
  //      border-radius: 9px 9px 9px 9px;
  //      opacity: 1;
  //      cursor: pointer;
  //      //border: 2px solid #FFFFFF;
  //      .top {
  //        height: 26.946847960445vh;
  //        width: 100%;
  //        padding: 2.4610630407911vh 1.79087232813403vw;
  //        position: relative;
  //        .logo {
  //          position: absolute;
  //          right: 2.00008464681845vw;
  //          top: 4.07354758961681vh;
  //          width: 5.54592720970537vw;
  //          height: 4.3263288009888vh;
  //          background-image: url("../assets/infoAi.png");
  //          background-size: 100% auto;
  //          background-position: center;
  //          background-repeat: no-repeat;
  //          background-color: transparent; /* 这里设置背景颜色为透明 */
  //          opacity: 1;
  //          //border: black 1px solid;
  //        }
  //        .title1 {
  //          height: 2.71940667490729vh;
  //          font-size: 0.97094165222415vw;
  //          font-family: Roboto,sans-serif;
  //          font-weight: bold;
  //          color: #2168db;
  //          line-height: 5.31520395550062ch;
  //        }
  //        .title2 {
  //          //text-align: justify;
  //          margin-top: 4.71940667490729vh;
  //          height: fit-content;
  //          font-size: 0.80878105141537vw;
  //          font-family: Roboto,sans-serif;
  //          font-weight: 400;
  //          color: #2168db;
  //          line-height: 2.96662546353523vh;
  //        }
  //      }
  //      .back {
  //        margin: auto 0 0 0;
  //        width: 100%;
  //        height: 100%;
  //        background: #eeeeee linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
  //        border-radius: 9px 9px 9px 9px;
  //        opacity: 1;
  //        border: 2px solid #ffffff;
  //        .bottom {
  //          width: 100%;
  //          height: 5.06798516687268vh;
  //          background: white;
  //          border-radius: 0px 0px 9px 9px;
  //          opacity: 1;
  //          padding-left: 1.32871172732525vw;
  //          padding-right: 1.32871172732525vw;
  //          display: flex;
  //          justify-content: space-between;
  //          align-items: center;
  //          color: #2168db;
  //          font-size: 0.92432120161756vw;
  //        }
  //        .bottom:hover {
  //          background: #2f6eff;
  //          color: white;
  //        }
  //      }
  //    }
  //    .card2:hover {
  //      transform: scale(1.05);
  //      transition-duration: 0.3s;
  //      .bottom {
  //        background: #2f6eff;
  //        color: white;
  //      }
  //    }
  //
  //    .card3 {
  //      width: 24.09012131715771vw;
  //      height: 100%;
  //      padding: 1.11248454882571vh 0.51993067590988vw;
  //      border-radius: 9px 9px 9px 9px;
  //      opacity: 1;
  //      cursor: pointer;
  //      //border: 2px solid #FFFFFF;
  //      .top {
  //        height: 26.946847960445vh;
  //        width: 100%;
  //        padding: 2.4610630407911vh 1.79087232813403vw;
  //        position: relative;
  //        .logo {
  //          position: absolute;
  //
  //          right: 2.00008464681845vw;
  //          top: 4.07354758961681vh;
  //          width: 6.54592720970537vw;
  //          height: 4.3263288009888vh;
  //          background-image: url("../assets/jobAi.png");
  //          background-size: 100% auto;
  //          background-position: center;
  //          background-repeat: no-repeat;
  //          background-color: transparent; /* 这里设置背景颜色为透明 */
  //          opacity: 1;
  //          //border: black 1px solid;
  //        }
  //        .title1 {
  //          height: 2.71940667490729vh;
  //          font-size: 0.97094165222415vw;
  //          font-family: Roboto,sans-serif;
  //          font-weight: bold;
  //          color: #18a6ab;
  //          line-height: 5.31520395550062ch;
  //        }
  //        .title2 {
  //          //text-align: justify;
  //          margin-top: 4.71940667490729vh;
  //          height: fit-content;
  //          font-size: 0.80878105141537vw;
  //          font-family: Roboto,sans-serif;
  //          font-weight: 400;
  //          color: #18a6ab;
  //          line-height: 2.96662546353523vh;
  //        }
  //      }
  //      .back {
  //        margin: auto 0 0 0;
  //        width: 100%;
  //        height: 100%;
  //        background: #eeeeee linear-gradient(to right, #d7f4fa 0%, #f0f6ff 100%);
  //        border-radius: 9px 9px 9px 9px;
  //        opacity: 1;
  //        border: 2px solid #ffffff;
  //        .bottom {
  //          width: 100%;
  //          height: 5.06798516687268vh;
  //          background: white;
  //          border-radius: 0px 0px 9px 9px;
  //          opacity: 1;
  //          padding-left: 1.32871172732525vw;
  //          padding-right: 1.32871172732525vw;
  //          display: flex;
  //          justify-content: space-between;
  //          align-items: center;
  //          color: #18a6ab;
  //          font-size: 0.92432120161756vw;
  //        }
  //      }
  //    }
  //    .card3:hover {
  //      transform: scale(1.05);
  //      transition-duration: 0.3s;
  //      .bottom {
  //        background: #2f6eff;
  //        color: white;
  //      }
  //    }
  //  }
  //}
  //.screen5Plus{
  //  left: 10.86077411900635vw;
  //  position: absolute;
  //  top: 223.9962917181706vh;
  //  margin: 0 auto;
  //  width: 78.22068168688619vw;
  //  background: rgba(184, 223, 246, 0.4);
  //  box-shadow: 5px 0 2px 0px #D4E4FF;
  //  height: fit-content;
  //  border-radius: 9px;
  //  padding: 4.0242274412855vh 1.09763142692085vw;
  //  .title{
  //    margin: 0 0.8vw;
  //    height: 6.05686032138443vh;
  //    opacity: 1;
  //    font-size: 1.67533217793183vw;
  //    //color: #53A3F7;
  //    font-family: Roboto,sans-serif;
  //    font-weight: 700;
  //    line-height: 2.71940667490729vh;
  //  }
  //  .screen4 {
  //    display: grid;
  //    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  //    column-gap: 1.44425187752744vw;
  //    .card {
  //      width: 37.55054881571346vw;
  //      height: 100%;
  //      padding: 1.11248454882571vh 0.51993067590988vw;
  //      border-radius: 9px 9px 9px 9px;
  //      opacity: 1;
  //      cursor: pointer;
  //      //border: 2px solid #FFFFFF;
  //      .top {
  //        height: 35.946847960445vh;
  //        width: 100%;
  //        position: relative;
  //        .logo {
  //          border-radius: 9px 9px 9px 9px;
  //          width: 100%;
  //          height: 17.42892459826947vh;
  //          background-image: url("../assets/mogu.jpg");
  //          background-size: 100% auto;
  //          background-position: center;
  //          background-repeat: no-repeat;
  //          background-color: transparent; /* 这里设置背景颜色为透明 */
  //          opacity: 1;
  //          //border: black 1px solid;
  //        }
  //        .title1 {
  //          height: 2.71940667490729vh;
  //          font-size: 0.97094165222415vw;
  //          font-family: Roboto,sans-serif;
  //          font-weight: bold;
  //          text-align: center;
  //          //color: #2168DB;
  //          line-height: 5.31520395550062ch;
  //        }
  //        .title2 {
  //          //text-align: justify;
  //          margin: 4.71940667490729vh  1.32871172732525vw;
  //          height: fit-content;
  //          font-size: 0.80878105141537vw;
  //          font-family: Roboto,sans-serif;
  //          font-weight: 400;
  //          //color: #339999;
  //          line-height: 2.96662546353523vh;
  //        }
  //      }
  //      .back {
  //        margin: auto 0 0 0;
  //        width: 100%;
  //        height: 100%;
  //        background: #EEEEEE linear-gradient(to bottom, #CFE1FF 0%, #E5EFFF 100%);
  //        border-radius: 9px 9px 9px 9px;
  //        opacity: 1;
  //        border: 2px solid #ffffff;
  //        .bottom {
  //          width: 100%;
  //          height: 5.06798516687268vh;
  //          background: white;
  //          border-radius: 0px 0px 9px 9px;
  //          opacity: 1;
  //          padding-left: 1.32871172732525vw;
  //          padding-right: 1.32871172732525vw;
  //          display: flex;
  //          justify-content: space-between;
  //          align-items: center;
  //          color: #339999;
  //          font-size: 0.92432120161756vw;
  //        }
  //        .bottom:hover {
  //          background: #2f6eff;
  //          color: white;
  //        }
  //      }
  //    }
  //    .card:hover {
  //      transform: scale(1.05);
  //      transition-duration: 0.3s;
  //      .bottom {
  //        background: #2f6eff;
  //        color: white;
  //      }
  //    }
  //    .card2 {
  //      width: 37.55054881571346vw;
  //      height: 100%;
  //      padding: 1.11248454882571vh 0.51993067590988vw;
  //      border-radius: 9px 9px 9px 9px;
  //      opacity: 1;
  //      cursor: pointer;
  //      //border: 2px solid #FFFFFF;
  //      .top {
  //        height: 26.946847960445vh;
  //        width: 100%;
  //        padding: 2.4610630407911vh 1.79087232813403vw;
  //        position: relative;
  //        .logo {
  //          position: absolute;
  //          right: 2.00008464681845vw;
  //          top: 4.07354758961681vh;
  //          width: 5.54592720970537vw;
  //          height: 4.3263288009888vh;
  //          background-image: url("../assets/mogu.jpg");
  //          background-size: 100% auto;
  //          background-position: center;
  //          background-repeat: no-repeat;
  //          background-color: transparent; /* 这里设置背景颜色为透明 */
  //          opacity: 1;
  //          //border: black 1px solid;
  //        }
  //        .title1 {
  //          height: 2.71940667490729vh;
  //          font-size: 0.97094165222415vw;
  //          font-family: Roboto,sans-serif;
  //          font-weight: bold;
  //          color: #2168db;
  //          line-height: 5.31520395550062ch;
  //        }
  //        .title2 {
  //          //text-align: justify;
  //          margin-top: 4.71940667490729vh;
  //          height: fit-content;
  //          font-size: 0.80878105141537vw;
  //          font-family: Roboto,sans-serif;
  //          font-weight: 400;
  //          color: #2168db;
  //          line-height: 2.96662546353523vh;
  //        }
  //      }
  //      .back {
  //        margin: auto 0 0 0;
  //        width: 100%;
  //        height: 100%;
  //        background: #EEEEEE linear-gradient(to bottom, #CFE1FF 0%, #E5EFFF 100%);
  //        border-radius: 9px 9px 9px 9px;
  //        opacity: 1;
  //        border: 2px solid #ffffff;
  //        .bottom {
  //          width: 100%;
  //          height: 5.06798516687268vh;
  //          background: white;
  //          border-radius: 0px 0px 9px 9px;
  //          opacity: 1;
  //          padding-left: 1.32871172732525vw;
  //          padding-right: 1.32871172732525vw;
  //          display: flex;
  //          justify-content: space-between;
  //          align-items: center;
  //          color: #2168db;
  //          font-size: 0.92432120161756vw;
  //        }
  //        .bottom:hover {
  //          background: #2f6eff;
  //          color: white;
  //        }
  //      }
  //    }
  //    .card2:hover {
  //      transform: scale(1.05);
  //      transition-duration: 0.3s;
  //      .bottom {
  //        background: #2f6eff;
  //        color: white;
  //      }
  //    }
  //
  //    .card3 {
  //      width: 24.09012131715771vw;
  //      height: 100%;
  //      padding: 1.11248454882571vh 0.51993067590988vw;
  //      border-radius: 9px 9px 9px 9px;
  //      opacity: 1;
  //      cursor: pointer;
  //      //border: 2px solid #FFFFFF;
  //      .top {
  //        height: 26.946847960445vh;
  //        width: 100%;
  //        padding: 2.4610630407911vh 1.79087232813403vw;
  //        position: relative;
  //        .logo {
  //          position: absolute;
  //
  //          right: 2.00008464681845vw;
  //          top: 4.07354758961681vh;
  //          width: 6.54592720970537vw;
  //          height: 4.3263288009888vh;
  //          background-image: url("../assets/jobAi.png");
  //          background-size: 100% auto;
  //          background-position: center;
  //          background-repeat: no-repeat;
  //          background-color: transparent; /* 这里设置背景颜色为透明 */
  //          opacity: 1;
  //          //border: black 1px solid;
  //        }
  //        .title1 {
  //          height: 2.71940667490729vh;
  //          font-size: 0.97094165222415vw;
  //          font-family: Roboto,sans-serif;
  //          font-weight: bold;
  //          color: #18a6ab;
  //          line-height: 5.31520395550062ch;
  //        }
  //        .title2 {
  //          //text-align: justify;
  //          margin-top: 4.71940667490729vh;
  //          height: fit-content;
  //          font-size: 0.80878105141537vw;
  //          font-family: Roboto,sans-serif;
  //          font-weight: 400;
  //          color: #18a6ab;
  //          line-height: 2.96662546353523vh;
  //        }
  //      }
  //      .back {
  //        margin: auto 0 0 0;
  //        width: 100%;
  //        height: 100%;
  //        background: #eeeeee linear-gradient(to right, #d7f4fa 0%, #f0f6ff 100%);
  //        border-radius: 9px 9px 9px 9px;
  //        opacity: 1;
  //        border: 2px solid #ffffff;
  //        .bottom {
  //          width: 100%;
  //          height: 5.06798516687268vh;
  //          background: white;
  //          border-radius: 0px 0px 9px 9px;
  //          opacity: 1;
  //          padding-left: 1.32871172732525vw;
  //          padding-right: 1.32871172732525vw;
  //          display: flex;
  //          justify-content: space-between;
  //          align-items: center;
  //          color: #18a6ab;
  //          font-size: 0.92432120161756vw;
  //        }
  //      }
  //    }
  //    .card3:hover {
  //      transform: scale(1.05);
  //      transition-duration: 0.3s;
  //      .bottom {
  //        background: #2f6eff;
  //        color: white;
  //      }
  //    }
  //  }
  //}
  //.screen5 {
  //  position: absolute;
  //  top: 286.2138442521632vh;
  //  width: 100%;
  //  height:fit-content;
  //  background-image: url("../assets/AIScreen.jpg");
  //  background-size: cover;
  //  background-position: center;
  //  background-repeat: no-repeat;
  //  background-color: transparent; /* 这里设置背景颜色为透明 */
  //  .title {
  //    margin-top: 12.84301606922126vh;
  //    text-align: center;
  //    height: 8.89987639060569vh;
  //    font-size: 3.5060658579vw;
  //    font-family: Roboto,sans-serif;
  //    font-weight: 400;
  //    //color: #2F6EFF;
  //    line-height: 2.84301606922126vh;
  //  }
  //}


}
</style>

