<template>
  <div>
    <div class="news1">
      <div
          ref="talkCon"
          class="talk_con"
      >
        <div class="content" >
          <div class="communicate"  :style="focusone ? { height: '90%' } : { height: '60%',margin:'15px 0 0 0'}" >
            <div class="talk-show"  ref="messageContainer" :style="(focusone&&changeinner) ? { height: '50vh'} : { height: '100%'}">

            <div
                    v-for="message in messages"
                    :key="message.id"
                    class="message"
                    :class="message.sender === 'user' ? 'user' : 'ai'"
                >
                  <div v-if="message.sender === 'user'">
                    <div class="btalk">
                      <div style="display: flex;justify-content: flex-end">
                        <div>
                          <span><div class="arrow-content">{{ message.text }}</div>
                          </span>
                        </div>
                        <div>
                          <img
                              :src=" avatar
                                "
                              alt="User Avatar"
                              class="userPhoto"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="atalk">
                      <div v-if="message.ok===0" class="AIphotoMove"></div>
                      <div v-if="message.ok===1||message.ok===2" class="AIphoto"></div>
                      <div id="asay" class="answer">

                        <div id="whose1" class="talk">
                          <div v-if="message.title!=='nan'" style="font-weight: bold;font-size:14px;line-height: 20px" v-html="message.title"></div>
                          <div v-html="message.introduce"></div>
                          <div v-if="(message.ok===1||message.ok===2)&&(JSON.stringify(message.url)!=='null'&&message.url!=='nan')" class="talkurl"
                               @click="urlChange(message)"
                          >
                            <div style="color: black">详细请查看链接：</div>
                            <div  v-html="message.url"></div>
                          </div>
                          <div v-if="message.isSendingMessage===2" class="stop" style="color: gray">已停止生成</div>
                          <div v-if="message.isSendingMessage===1" class="stopMove"   @click="stopPrinting=true">停止生成</div>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          <div v-if="screenChange===0" class="talk_input">
            <div class="input_container">
              <div>
                        <textarea
                            ref="textarea"
                            id="text"
                            v-model="newMessage"
                            :rows="1"
                            class="talk_word"
                            maxlength="3000"
                            placeholder="请输入您要查找的GPT或内容"
                            @input="resizeTextarea"
                            @focus="focusinput"
                            @keydown.enter.prevent
                            @keyup.enter="sendMessage(event)"
                        ></textarea>
              </div>
              <div class="talk_button">
                <el-button
                    class="talk_sub"
                    :disabled="!isSendingMessage"
                    type="primary"
                    @click="sendMessage()"
                ></el-button>
              </div>

            </div>
          </div>
          <div class="talk_input" v-else style="position: fixed;">
            <div class="input_container">
              <div>
                       <textarea
                           id="text"
                           ref="textarea"
                           v-model="newMessage"
                           :rows="1"
                           class="talk_word"
                           maxlength="3000"
                           placeholder="请输入您要查找的GPT或内容"
                           @focus="focusinput1"
                           @blur="blurInput"
                           @input="resizeTextarea"
                           @keydown.enter.prevent
                           @keyup.enter="sendMessage(event)"
                       ></textarea>
              </div>
              <div class="talk_button">
                <el-button
                    class="talk_sub"
                    :disabled="!isSendingMessage"
                    type="primary"
                    @click="sendMessage()"
                ></el-button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {getAuthorize} from "@/api/user";
import {shareUrl} from "@/utils/vxshare.js";
// import VConsole from 'vconsole';
export default {
  name: "AIAnswer",
  props: {
    input: {
      type: Number,
      required: true
    },
    focusone:{
      type: Number,
      required: true
    }
  },
  components: {},
  data() {
    return {
      changeVisable: 1,
      dialogVisibleInfo: false,
      registerIn: false,
      forgetIn: false,
      loginIn: false,
      dialogVisible: false,
      isMobile: false,
      titleEn: "",
      contentEn: "",
      languageSelect: "",
      isClicked: false,
      userInfo: "",
      isLogin: "",
      isNEWs: 0,
      isTextDisplayed: false,
      isSendingMessage: true,
      stopPrinting:false,
      identification: 0,
      messages: [{
        ok: 0,
        id: '00000',
        sender: "ai",
        introduce: "您好，我们专注为油气行业提供人工智能解决方案，助力企业实现数智化转型。<br>" +
            "请描述您的需求!",
        url:'nan',
        resource: "",
        isSendingMessage: 0,
      },
        // {
        //   id: "",
        //   ok: 1,
        //   sender: "ai",
        //   answer: "解释类GPTs，基于油气开发目标解释，不仅有助于工程作业人员实时掌握油气藏地质地质条件以及工程作业情况，还为开发过程中的决策和调整提供了有力支持 解释类GPTs，基于油气开发目标解释，不仅有助于工程作业人员实时",
        //   title:"解释类GPTs",s
        //   url:"https://cn.oilgasgpts.com/gptTranslate",
        //   evaluationI: "0",
        //   copy: 0,
        //   question: this.question,
        // }
      ],
      newMessage: "",
      focus: 0,
      Message: "",
      token: "",
      baseURL: this.$utils.baseURL,
      avatar: require("@/assets/user.jpg"),
      userId: "",
      communicateHistory: [],
      denyformShow: false,
      denyForm: {
        id: "",
        question: "",
        answer: "",
      },
      primary: "",
      submitForm: {
        id: "",
        question: "",
        answer: "",
        primary: "",
      },
      textarea: "",
      deny: 0,
      count: 0,
      uniqueId: "",
      question: "",
      answer: "",
      options: [
        {
          value: "1",
          label: "新建对话",
        },
        {
          value: "2",
          label: "历史记录",
        },
      ],
      optionvalue: "1",
      screenInitialHeight: window.innerHeight,
      screenHeight: window.innerHeight,
      keyboardHeight:'',
      screenChange:0,
      changeinner:0,
      showPopover: false,
      timer: null,
      articles: [],
    };
  },
  methods: {
    handleResize() {
      this.screenHeight = window.innerHeight;
      if (this.screenHeight < this.screenInitialHeight) {
        this.keyboardHeight = this.screenInitialHeight - this.screenHeight;
      } else {
        this.keyboardHeight = 0;
      }
    },
    urlChange(msg) {
      if (msg.url === "https://cn.oilgasgpts.com/") {
        switch (msg.title) {
          case "油气资讯GPTs":
            this.$emit("skipping", 1);
            break;
          case "油气专业GPTs":
            this.$emit("skipping", 2);
            break;
          case "企业管理GPTs":
            this.$emit("skipping", 3);
            console.log("tiaozhuan");
            break;
          case "通用类GPTs":
            this.$emit("skipping", 4);
            console.log("tiaozhuan");
            break;
          case "油气开发作业GPT应用服务":
            this.$emit("skipping", 5);
            break;
          case "数智油田解决方案GPT应用服务":
            this.$emit("skipping", 6);
            break;
          case "智能多媒体服务":
            this.$emit("skipping", 7);
            break;
          case "人工智能全员普及培训及转型咨询服务":
            this.$emit("skipping", 8);
            break;
          case "油气企业自建GPT云平台服务":
            this.$emit("skipping", 9);
            break;
          case "安迅数智服务":
            this.$emit("skipping", 10);
            break;
          case "安迅数智":
            this.$emit("skipping", 10);
            break;
          case "石同多媒体":
            this.$emit("skipping", 10);
            break;
          default:
            console.log("未找到匹配的标题");
            break;
        }
      } else {
        window.open(msg.url);
      }

    },
    changeheadVisable() {
      if (this.changeVisable === 1) {
        this.changeVisable = 0
      } else {
        this.changeVisable = 1
      }
      console.log("父组件", this.changeVisable)
    },
    pushLogin() {
      let href = window.location.href.split("&code")[0];
      getAuthorize(href).then((res) => {
        window.location.replace(res.data.url);
      });
      // this.$router.push("/login")
      // this.$emit("pushLogin")
    },
    handleClose() {
      this.denyformShow = true;
    },
    showDeleteButton(historyItem) {
      // 在鼠标悬停时显示删除按钮
      this.$set(historyItem, "showDeleteButton", true);
    },
    hideDeleteButton(historyItem) {
      // 在鼠标移出时隐藏删除按钮
      this.$set(historyItem, "showDeleteButton", false);
    },
    showDialog(type) {
      this.dialogVisible = type;
    },
    optionnew(item) {
      console.log("Selected value:", item.value);
    },
    dateIfAddZero(time) {
      return time < 10 ? "0" + time : time;
    },
    // 文章索引
    clickRobot(item) {
      this.isNEWs = 1;
      this.$router.push({
        path: "/articalDetail",
        query: {
          Typeid: item.primaryTypeid,
          newId: item.id,
        },
      });
      this.$axios
          .get("/api/news/" + item.id, {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          })
          .then((res) => {
            this.articles = res.data.data;
            const currentDate = new Date(this.articles.createTime);
            const year = currentDate.getFullYear();
            let month = this.dateIfAddZero(currentDate.getMonth() + 1);
            let day = this.dateIfAddZero(currentDate.getDate());
            const formattedDate = `${year}-${month}-${day}`;
            this.articles.dateTime = formattedDate;
          })
          .catch((error) => {
            console.log(error);
          });

      var vm = this;
      vm.$axios
          .post("/systemenes/trans", {
            // params: {
            newsId: item.id,
            // },
            headers: {
              Authorization: vm.token,
            },
          })
          .then((res) => {
            // console.log(res.data.data.enTitle)
            vm.titleEn = res.data.data.enTitle;
            vm.contentEn = res.data.data.enContent;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    // 新建对话
    // newContent() {
    //   this.uniqueId = "id-" + Math.random().toString(36).substr(2, 9);
    //   this.messages = [];
    //   this.count = 0;
    //   this.isSendingMessage = false;
    //   if (this.languageSelect === "zh") {
    //     this.$message.warning({
    //       title: "提示",
    //       message: "新建对话成功，我们将重新开始新一轮对话。",
    //       showClose: false,
    //       customClass: "notify-success",
    //     });
    //   } else {
    //     this.$message.warning({
    //       title: "Tips",
    //       message:
    //           "If the new dialogue is successful, we will start a new round of dialogue",
    //       showClose: false,
    //       customClass: "notify-success",
    //     });
    //   }
    // },
    // // 历史记录轮次
    // historylist() {
    //   // console.log("ddd")
    //   // console.log(parseInt(this.userId))
    //   this.$axios
    //     .get("api/qa/selectAll", {
    //       params: {
    //         userId: this.userId,
    //       },
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: parseInt(this.userId),
    //       },
    //     })
    //     .then((res) => {
    //       this.communicateHistory = res.data;
    //       for (var i = 0; i < this.communicateHistory.length; i++) {
    //         const currentDate = new Date(this.communicateHistory[i].createTime);
    //         const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
    //         const day = ("0" + currentDate.getDate()).slice(-2);
    //         const hours = ("0" + currentDate.getHours()).slice(-2);
    //         const min = ("0" + currentDate.getMinutes()).slice(-2);
    //         const formattedDate = `${month}-${day} ${hours}:${min}`;
    //         this.communicateHistory[i].createTime = formattedDate;
    //         this.communicateHistory[i].isClicked = false;
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    // 删除历史
    deleteHistory(item) {
      console.log(item.roundId);

      console.log("删除");
    },
    // 详细的历史记录
    HisCommunicate(communicateHistory) {
      for (var i = 0; i < this.communicateHistory.length; i++) {
        this.communicateHistory[i].isClicked = false;
      }

      communicateHistory.isClicked = true;
      console.log(communicateHistory);
      this.$axios
          .get("/api/qa/selectByRoundId/" + communicateHistory.roundId, {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          })
          .then((res) => {
            this.isTextDisplayed = true;
            this.messages = [];
            for (var i = 0; i < res.data.length; i++) {
              this.uniqueId = communicateHistory.roundId;
              this.messages.push({
                id: res.data[i].id,
                sender: "user",
                text: res.data[i].qaQuery,
              });
              if (res.data[i].qaEvaluate) {
                res.data[i].qaEvaluate = 1;
              } else {
                res.data[i].qaEvaluate = 0;
              }
              this.messages.push({
                // 使用保存的vm
                id: res.data[i].id,
                sender: "ai",
                introduce: res.data[i].qaAnswers,
                deny: res.data[i].qaEvaluate,
                question: res.data[i].qaQuery,
              });
            }
          });
    },
    // 点否提交
    submitDeny() {
      this.submitForm.answer = this.denyForm.answer;
      this.denyformShow = false;
      console.log(this.denyForm);
      console.log(this.submitForm);
      // 假设您有一个要更新的 message 对象
      this.messages = this.messages.map((message) => {
        if (message.id === this.submitForm.id) {
          message.deny = 1; // 将匹配条件下的 message 的 deny 属性设置为 1
        }
        return message; // 返回更新后的 message
      });

      console.log(this.messages); // 打印更新后的 messages 数组

      this.$axios
          .put(
              "/api/qa/update",
              {
                id: this.submitForm.id,
                qaEvaluationContent: this.denyForm.answer,
                qaAnswers: this.denyForm.answer,
                qaQuery: this.submitForm.primary,
                qaEvaluate: 1,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: this.token,
                },
              }
          )
          .then((res) => {
            console.log(res);
          });
    },
    // 点否重置
    resetDeny() {
      this.denyForm.question = this.submitForm.question;
      this.denyForm.answer = this.submitForm.primary;
    },
    changeWho() {
      this.showPopover = false;
    },
    resizeTextarea() {

      const textarea = document.getElementById('text');
      textarea.style.height = 'auto'; // 重置高度，使其自动调整
      if(this.isMobile===false){
        textarea.style.height = (textarea.scrollHeight - 20) + 'px'; // 设置为内容高度
        if (this.newMessage.trim() === '') {

          textarea.style.height = '4vh'; // 当内容为空时，设置为最小高度

        }
      }
      else{
        textarea.style.height = (textarea.scrollHeight-16) + 'px'; // 设置为内容高度
        if (this.newMessage.trim() === '') {

          console.log("kongde ")
          textarea.style.height = '5vh'; // 当内容为空时，设置为最小高度

        }
      }


      textarea.scrollTop = 0; // 将滚动条置顶
      if (this.newMessage.length >= 3000) {
        this.$emit('input', this.newMessage.slice(0, 3000));
        this.$message.warning(`最多可以输入3000个字符`);
      }
    },
    sendMessage($event) {
      if(this.isSendingMessage===true) {
        this.stopPrinting=false
        this.$emit("setTop", 1)
        const textarea = document.getElementById('text');
        textarea.style.height = 'auto'; // 重置高度，使其自动调整
        textarea.style.height = textarea.scrollHeight + 'px'; // 设置为内容高度
        textarea.style.height = '12px'; // 当内容为空时，设置为最小高度
        // 找到所有满足条件的消息
        const messagesToUpdate = this.messages.filter(
            (message) => message.id === "00000" && message.sender === "ai" && message.ok === 0
        );

// 遍历所有找到的消息并更新它们的ok属性
        messagesToUpdate.forEach((message) => {
          this.$set(message, "ok", 2);
        });

        // 找到所有满足条件的消息
        const mes = this.messages.filter(
            (message) => message.id === "11111" && message.sender === "ai" && message.ok === 0
        );

// 遍历所有找到的消息并更新它们的ok属性
        mes.forEach((message) => {
          this.$set(message, "ok", 2);
        });


        // 清除之前的定时器
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          // this.uniqueId = "id-" + Math.random().toString(36).substr(2, 9);
          this.messages = [{
            ok: 0,
            id: '00000',
            sender: "ai",
            introduce: "您好，我们专注为油气行业提供人工智能解决方案，助力企业实现数智化转型。<br>" +
                "请描述您的需求!",
            resource: "",
            url:'nan',
            isSendingMessage: 0,
          },];
          this.count = 0;
          this.isSendingMessage = false;
          // this.historylist();
          this.$message.warning({
            title: "提示",
            message: "当前超出10min未进行对话，请刷新重试",
            showClose: true,
          });
        }, 600000); // 等于 60,000*30 毫秒

        // 禁用发送按钮

        if (this.uniqueId === "") {
          this.uniqueId = "id-" + Math.random().toString(36).substr(2, 9);
        }

        console.log("输入", this.newMessage)
        if (this.newMessage.trim() !== "") {
          // 问题

          this.isSendingMessage = false;
          this.count++;
          this.question = this.newMessage;
          this.messages.push({
            id: this.uniqueId,
            sender: "user",
            text: this.newMessage,
          });

          // 先添加“稍后再试”消息
          this.messages.push({
            id: "",
            ok: 0,
            sender: "ai",
            introduce: "正在生成，请稍候...",
            isSendingMessage: 1,
            title: "",
            url: "",
            evaluationI: "0",
            copy: 0,
            question: this.question,
          });

          if (this.count === 21) {
            this.uniqueId = "id-" + Math.random().toString(36).substr(2, 9);
            this.messages = [];
            this.count = 0;
            this.isSendingMessage = true;
            // this.historylist();
            this.$message.warning({
              title: "提示",
              message:
                  "当前对话轮次超出小安的能力限制啦，我们将重新开始新一轮对话。",
              showClose: false,
            });
          } else {
            this.newMessage = "";
            this.$refs.textarea.blur()
            var vm = this; // 保存this
            this.$axios
                .get(
                    "https://cn.oilgasgpts.com/search/askQuestion?question=" + this.question,
                    // {
                    //   question: this.question,
                    //   round_id: this.uniqueId,
                    //   userId: this.userId,
                    // },
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: this.token,
                      },
                    }
                )
                .then(async function (response) {

                  // console.log(response.data.data)
                  var resindex = [];
                  for (var i = 0; i < response.data.data.introduce.length; i++) {
                    resindex.push({
                      index: i + 1,
                      title: response.data.data.introduce[i],
                    });
                  }
                  vm.isSendingMessage = false;
                  // 查找索引以替换“稍后再试”消息
                  const index = vm.messages.findIndex(
                      (message) => message.id === "" && message.sender === "ai"
                  );
                  // 若存在这样的信息
                  if (index !== -1) {
                    // 逐字显示回答

                    vm.$set(vm.messages[index], "id", response.data.id); // 更改ID
                    // 主子输出
                    vm.$set(vm.messages[index], "title", response.data.data.title);
                    vm.$set(vm.messages[index], 'url', response.data.data.url);
                    console.log(response.data.data.url !== "nan")
                    await vm.printAnswerMessage(
                        response.data.data.introduce,
                        vm,
                        index,
                        resindex
                    );

                    this.$nextTick(() => {
                      // 在下一个tick时滚动到底部
                      const container = this.$refs.messageContainer;
                      container.scrollTop = container.scrollHeight;
                    });
                    // console.log("huidu ")
                    // console.log(vm.messages)
                  }
                });
            // setTimeout(() => {
            //
            // }, 2000); // 2000毫秒（2秒）
          }
        } else {
          this.$message.warning({
            title: "提示",
            message: "请输入你的问题",
            showClose: false,
          });
        }

        this.$nextTick(() => {
          const container = this.$refs.messageContainer;
          container.scrollTop = container.scrollHeight;
        });
      }else{
        this.$message.warning({
          title: "提示",
          message: "正在生成中，请稍候",
          showClose: false,
        });
        $event.preventDefault();
        console.log("禁止发送")
      }

    },
    focusinput() {
      this.focus = 1
      this.focusone = 1
      this.screenChange=1
      this.$emit("setTop", 1)
      this.$emit("screenmodel", 1)
      this.changeinner=1

      // alert("焦点")

      console.log("获取到焦点")
    },
    // 失去焦点
    blurInput(){
      // 键盘位置
      this.screenChange=0
      // alert("失去焦点")
      // 首页屏幕高度恢复100vh
      this.$emit("screenmodel", 0)
      this.changeinner=0
    },
    focusinput1() {
      this.focus = 1
      this.$emit("setTop", 1)
      console.log("???-----------------------------------------------")
      console.log(this.$refs.textarea.style.zIndex)
    },
    async printAnswerMessage(message, vm, index, resindex) {
      let currentIndex = 0;
      let printedMessage = "";

      const printFunction = () => {
        // 输出
        if (!this.stopPrinting &&currentIndex < message.length) {

          printedMessage += message[currentIndex];
          vm.messages[index].introduce = printedMessage;
          currentIndex++;
          setTimeout(() => {
            if (!this.stopPrinting) {
              printFunction();
            }
            else{
              console.log("stoooooooooooooop")
              this.stopPrinting=false
              vm.$set(vm.messages[index], "recourse", resindex); // 更改
              vm.$set(vm.messages[index], "ok", 1); // 更改图表样式、
              this.isSendingMessage=true
              vm.$set(vm.messages[index], "isSendingMessage", 2);
              console.log(vm.messages)
            }
            this.$nextTick(() => {
              const container = this.$refs.messageContainer;
              container.scrollTop = container.scrollHeight;
            });
          }, 25);

        }
        // 输出结束
        else {
          vm.$set(vm.messages[index], "recourse", resindex); // 更改ID
          vm.$set(vm.messages[index], "ok", 1); // 更改图表样式
          // 当消息逐字显示完成后，设置 isTextDisplayed 为 true
          this.isTextDisplayed = true;
          this.isSendingMessage=true
          if(this.stopPrinting===true){
            vm.$set(vm.messages[index], "isSendingMessage", 2);
          }
          else{
            vm.$set(vm.messages[index], "isSendingMessage", 0);
          }
          this.$nextTick(() => {
            const container = this.$refs.messageContainer;
            container.scrollTop = container.scrollHeight;
          });
        }
      };
      printFunction();
    },
    // 复制
    copy(message) {
      if (message.copy === 0) {
        message.copy = 1
      }
      // console.log(message)
      const textToCopy = message.introduce;
      const el = document.createElement('textarea');
      el.value = textToCopy;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$message({
        message: '复制成功',
        type: 'success'
      });
    },
    // // 点赞
    // gray(message) {
    //   console.log(message.evaluationI)
    //   console.log("message")
    //   console.log(message)
    //   if (message.evaluationI === "1") {
    //
    //     this.$axios.post(
    //         // "http://11.89.147.1:8080/system/dialogue/external_evaluation",
    //         "http://localhost:8080/system/dialogue/internal_evaluation",
    //         {
    //           id: message.id,
    //           evaluationI: "0",
    //         },
    //         {
    //           headers: {
    //             "Content-Type": "application/json",
    //             // Authorization: this.token,
    //           },
    //         }
    //     ).then((res) => {
    //       this.getNo()
    //       this.getAppreciate()
    //       this.$set(message, "evaluationI", "0"); // 更改ID
    //       console.log(res);
    //     });
    //
    //
    //   } else {
    //
    //     // console.log("end")
    //     // console.log(message.evaluationI)
    //     this.$axios
    //         .post(
    //             // "http://11.89.147.1:8080/system/dialogue/external_evaluation",
    //             "http://localhost:8080/system/dialogue/internal_evaluation",
    //             {
    //               id: message.id,
    //               evaluationI: "1",
    //             },
    //             {
    //               headers: {
    //                 "Content-Type": "application/json",
    //                 // Authorization: this.token,
    //               },
    //             }
    //         ).then((res) => {
    //       this.$set(message, "evaluationI", "1"); // 更改ID
    //       this.getNo()
    //       this.getAppreciate()
    //       console.log(res);
    //     });
    //   }
    // },
    // // 点否点击
    // denyChange(event) {
    //   // 获取按钮自带的 data-info 参数
    //   if (event.deny === 0) {
    //     this.denyformShow = true;
    //     this.submitForm.question = event.question;
    //     this.submitForm.answer = event.answer;
    //     this.submitForm.primary = event.answer;
    //     this.submitForm.id = event.id;
    //     this.denyForm.question = this.submitForm.question;
    //     this.denyForm.answer = this.submitForm.primary;
    //   } else {
    //     this.messages = this.messages.map((message) => {
    //       if (message.id === this.submitForm.id) {
    //         message.deny = 0; // 将匹配条件下的 message 的 deny 属性设置为 1
    //       }
    //       return message; // 返回更新后的 message
    //     });
    //
    //     console.log(this.messages); // 打印更新后的 messages 数组
    //   }
    // },
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    console.log(this.userInfo.avatar !== null);
    this.token = localStorage.getItem("access_token");
    this.userId = localStorage.getItem("userId");
    // this.token = "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6ImI0ODQ4ZGRlLTg4ODEtNGFhNy1iNzRmLTgwZTM5NmMxZmU2YyJ9.LMY_3ane52i9K_vJ3NaBeF5rkMx-jCSLPZI2rFClTkqOVAT3amV1O-FJkZD4BHJyo1wOv-J6K-eDdKzdCyF6UQ",

    //  this.userId =1

    // this.historylist();
    let url = window.location.href;
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        title: "油气人才",
        text: "全球油气行业专业人才求职招募平台，AI辅助简历优化，在线雇主及时沟通，更多油气行业岗位，快来油气人才吧！",
      };
      shareUrl(shareData);
    }, 1000);
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);

    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  watch: {
    optionvalue(newValue) {
      // console.log("Selected value:", newValue);
      if (newValue === "1") {
        this.newContent();
      }
      // 在这里可以处理选项变化后的逻辑
    },
    focusone(newValue) {
      console.log("focusone", newValue=='0');
      this.$nextTick(() => {
        const container = this.$refs.messageContainer;
        container.scrollTop = container.scrollHeight;
      });
      if (newValue == '0') {
        // this.messages=[{
        //   ok: 0,
        //   id: '00000',
        //   sender: "ai",
        //   introduce: "您好，我们专注为油气行业提供人工智能解决方案，助力企业实现数智化转型，\n" +
        //       "请描述您的需求!",
        //   resource: "",
        // }];
      }
      // 在这里可以处理选项变化后的逻辑
    },
    screenHeight(newValue, oldValue) {
      console.log("shuj",newValue)
      console.log("获取到焦点")
      if (newValue < oldValue) {
        // 有键盘
        this.screenChange = 1;
        this.$emit("setTop", 1)
        this.$emit("screenmodel", 1)
        this.changeinner=1
      }
      else {
        // 无键盘
        this.screenChange = 0;
        this.$emit("screenmodel", 0)
        this.$emit("setTopno", 0)
        this.changeinner=0
      }
      console.log("---------------",this.screenChange===0)
      this.$nextTick(() => {
        const container = this.$refs.messageContainer;
        container.scrollTop = container.scrollHeight;
      });
    },
    input(newValue) {
      console.log("Selected value:", newValue === 0);

      if (newValue === 0) {
        this.focus = 0
      }

    },
  },
};
</script>

<style lang='scss' scoped>
.expand-enter-active {
  animation: bounceIn 5s linear both;
}

.expand-height-enter-active {
  transition: height 3s ease;
}

.expand-height-enter-from,
.expand-height-leave-to {
  height: 58vh;
}

.expanded {
  height: 75vh;
  overflow: hidden;
}


.news1 {
  //background-color: white;
//transform:scaleX(1) !important;

 ::v-deep .el-button--primary {

    width: 12% !important;
   background-color: transparent !important;

  }

  .communicate::-webkit-scrollbar-track-piece {
    background: linear-gradient(180deg, #f5f4f6, #e6ebf7);
  }
  .communicate::-webkit-scrollbar {
    width: 6px;
    transition: all 2s;
  }
  .communicate::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #f5f4f6, #e6ebf7);
    border-radius: 100px;
  }
  .communicate::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(180deg, #f5f4f6, #e6ebf7);
  }
  .communicate::-webkit-scrollbar-corner {
    background: linear-gradient(180deg, #f5f4f6, #e6ebf7);
  }



  .talk-show::-webkit-scrollbar-track-piece {
    background: linear-gradient(180deg, #f5f4f6, #e6ebf7);
  }
  .talk-show::-webkit-scrollbar {
    width: 6px;
    transition: all 2s;
  }
  .talk-show::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #f5f4f6, #e6ebf7);
    border-radius: 100px;
  }
  .talk-show::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(180deg, #f5f4f6, #e6ebf7);
  }
  .talk-show::-webkit-scrollbar-corner {
    background: linear-gradient(180deg, #f5f4f6, #e6ebf7);
  }
  .talk-show{
    margin-top:-15px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .talk_con {
    //background-image: url("../assets/aiBg.png");
    background: transparent;
    border-radius:9px;
    //border: #000000 2px solid;
    //height: 88vh;
    width: 100%;
    height: 75vh;
    margin: 0 auto;
    //border: #1c0101 5px solid;
  }

  .content {
    background-color: transparent;
    width: 100%;
    margin: 0 auto 0 auto;
    height:75vh ;
    border-radius: 15px;
    //border: 5px solid black;
    .tip{
      margin-top: 15px;
      display: grid;
      grid-template-columns: minmax(0,1fr) minmax(0,1fr);
      column-gap: 10px;
    }
    .tips{
      cursor: pointer;
      width: 100%;
      border-radius: 5px;
      background:linear-gradient(to right, rgb(230, 250, 250) 0%,  #f1e4d3 100%);
      height: fit-content;
      padding: 10px;
      .circle{
        width: 15px;
        height: 15px;
        background-image:url("../assets/Circle.png");
        background-size:contain;
        background-position:center;
        background-repeat:no-repeat;
        background-color: transparent; /* 这里设置背景颜色为透明 */
      }
      .highlight{
        font-size: 14px;
        color: #333333;
        font-weight: bold;
        margin:-2px 10px;
      }
      .title{
        font-size: 12px;
        font-weight: bold;
        color: #666666;
        margin:10px 0 0 0;
        text-align: justify;
      }
    }
  }

  .communicate {
    height: 90%;
    padding:0 5% 5% 5%;
    //margin-top: 15px;
    border-top: transparent 2px solid;
    width: 95%;
    background: transparent;
    font-size: 14px;
    overflow-y: auto;
    overflow-x: hidden;
    //border: 5px solid black;
  }

  //.arrow-content {
  //  padding: 10px;
  //  margin:5px 1% auto auto;
  //  position: relative;
  //  display: inline-block;
  //  text-align: left;
  //  color: #000;
  //  border-radius: 5px;
  //  background-color: rgba(46, 128, 5, 0.6);
  //}
  .triangle1 {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 8px 8px; /* 调整边框宽度，0表示无顶部边框 */
    border-color: transparent transparent transparent rgba(46, 128, 5, 0.6); /* 调整边框颜色 */
    top: 15px; /* 控制三角形位置 */
    right: -14px; /* 控制三角形位置 */
    transform: translateY(-50%); /* 让三角形垂直居中 */
  }
  .right {
    text-align: left;
  }

  .talk_sub {
    background-color: transparent;
    position: absolute;
    cursor: pointer; /* 鼠标变成手型 */
    width: 6vh;
    height: 4vh;
    float: left;
    //margin-top: -4vh;
    //bottom:15px;
    right:0;
    top: 50%;
    transform: translateY(-50%);
    padding: 8px 10px;
    //margin-left: 88%;
    //margin-right: 5px;
    border: none;
    border-radius: 5px;
    background-image: url("../assets/send.png");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }

  @keyframes pulseno { 0% { transform: scale(1); /* 原始大小 */ } 50% { transform: scale(1.2); /* 放大到120% */ } 100% { transform: scale(1); /* 恢复到原始大小 */ } }
  .el-button--primary.is-disabled, .el-button--primary.is-disabled:active, .el-button--primary.is-disabled:focus, .el-button--primary.is-disabled:hover {
    background-color: transparent;
    position: absolute;
    cursor: pointer;
    width: 6vh;
    height: 4vh;
    float: left;
    top: 50%;
    transform: translateY(-50%);
    right:0;
    padding: 8px 10px;
    //margin-left: 58%;
    //margin-right: 2vw;
    border: none;
    border-radius: 5px;
    background-image: url("../assets/sendno.png");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    //animation: pulseno 2s infinite;
  }
  @keyframes pulseno { 0% { transform: scale(1); /* 原始大小 */ } 50% { transform: scale(1.2); /* 放大到120% */ } 100% { transform: scale(1); /* 恢复到原始大小 */ } }
  .el-button--primary {
    color: #fff;
  }
  .el-button--primary:focus, .el-button--primary:hover{
    background-color: transparent;
    position: absolute;
    cursor: pointer; /* 鼠标变成手型 */
    width: 6vh;
    height: 4vh;
    float: left;
    //margin-top: -4vh;
    top: 50%;
    transform: translateY(-50%);
    right:0;
    padding: 8px 10px;
    //margin-left: 58%;
    //margin-right: 2vw;
    border: none;
    border-radius: 5px;
    background-image: url("../assets/send.png");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    //background-color: transparent;
  }
  .talk {
    display: inline-block;
    background-color: white;
    border-radius: 5px;
    color: #000;
    margin: 8px 10% auto 5px;
    width: 100%;
    line-height: 20px;
    padding: 10px 10px;
    word-wrap: break-word;
    position: relative; /* Add this */
  }
  /* 添加尖嘴（三角形） */
  .triangle {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 8px 8px; /* 调整边框宽度，0表示无顶部边框 */
    border-color: transparent #fffcfc transparent transparent; /* 调整边框颜色 */
    top: 15px; /* 控制三角形位置 */
    left: -15px; /* 控制三角形位置 */
    transform: translateY(-50%); /* 让三角形垂直居中 */
  }

  .select {
    background-image: url("../assets/button.png");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    width: 45px;
    height: 45px;
    margin-top: auto;
    bottom: 5px;
    border: none;
  }

  .select1 {
    width: 25px;
    height: 20px;
    margin-top: auto;
    bottom: 0px;
    border: none;
  }

  ::v-deep .talkcontent .el-input__inner {
    width: 100%;
    background-color: #e8e8e8;
    text-align: center;
    border-color: #c0c4cc;
    color: #000;
  }

  ::v-deep .el-textarea .el-input__count {
    color: #909399;
    background: #fff;
    position: absolute;
    font-size: 18px;
    bottom: 5px;
    left: 10px;
  }

  ::v-deep .el-textarea__inner {
    border: none !important;
    font-size: 18px !important;
    height: 86px;
    width: 98%;
  }

  .talkcontent {
    margin-left: 1%;
    min-height: 82px;
    white-space: pre-wrap; /* 使文本根据内容自动换行 */
    outline: none;
    width: 98%;
    max-height: 82px;
    padding: 3px;
    float: left;
    text-indent: 10px;
    border-radius: 8px;
    overflow: auto;
    //border: black 5px solid; background-color: white; /* 半透明白色，alpha值为0.5 */
  }

  .atalk {
    margin: 5px;
    padding: 5px;
    display: flex;
    //background-color: white;
    position: relative;
    align-items: flex-start; /* Align items at the top */
  }

  .btalk {
    margin: 2px;
    right: 0 !important;
    display: flex;
    flex-direction: column;
    flex-grow: 8;
  }

  .AIphotoMove {
    min-width: 25px;
    background-image: url("../assets/AIphoto.png");
    width: 25px;
    height: 25px;
    margin-right: 5px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border-radius: 20%;
    margin-top: 5px;
    font-size: 8px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: pulse 2s infinite;
  }
  .talkurl {
    color: #FF6400;
    cursor: pointer;
    //font-weight: bold;
    line-height: 20px;
    width: fit-content;
    //display: flex;

  }

  .stopMove{
    text-align: left;
    cursor: pointer;
    margin-top: 10px;
    border-top: rgba(210, 208, 208, 0.5) 1px solid;
    padding:10px 0 0 0;
    font-size: 12px;
    color: #7b88f2;
  }
  .stop{
    text-align: left;
    cursor: pointer;
    margin:5px 0 0 0;
    font-size: 12px;
    color: gray;

  }
  @keyframes pulse {
    0% {
      transform: scale(1); /* 原始大小 */
    }
    50% {
      transform: scale(1.1); /* 放大到120% */
    }
    100% {
      transform: scale(1); /* 恢复到原始大小 */
    }
  }

  .AIphoto {
    min-width: 25px;
    background-image: url("../assets/AIphotoNo.png");
    width:25px;
    height:25px;
    margin-right: 5px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border-radius: 20%;
    margin-top: 5px;
    font-size: 8px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;

  }


  .userPhoto {
    //background-image: url("../assets/userPhoto.png"); min-width: 30px; margin-top: 3px; position: relative;
    width: 25px;
    min-width: 25px;
    height: 25px;
    margin-left: 8px;
    font-size: 14px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
    margin-top: 5px;
  }

  .arrow-content {
    background-color: #0267ff;
    border-radius: 5px;
    color: white;
    margin: 8px 5px auto 10px;
    padding: 5px 10px;
    display: inline-block;
    min-height: 30px;
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    line-height: 22px;
    letter-spacing: 0.5px;
  }

  .title {
    color: black;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .whotalk {
    min-height: 88px;
    outline: none;
    max-height: 25px;
    padding: 5px;
    width: 80% !important;
    border-radius: 8px;
    border: none;
    //border-right: black 1px solid; width: 10%;
  }

  .talk_input {
    overflow: visible;
    position: absolute;
    display: flex; /* 启用Flexbox布局 */
    flex-direction: column; /* 子元素垂直排列 */
    align-items: center; /* 拉伸子元素以填充容器 */
    justify-content: center;;
    left: 50%;
    transform: translateX(-50%);
    height: fit-content;
    border-radius: 5px;
    background-color: transparent;
    bottom: 25px;
    width:85%;
  }

  .input_container {
    height: fit-content;
    //border: black 1px solid;
    background-color: transparent;
    display: flex; /* 使用 Flexbox 布局 */
    align-items: center; /* 垂直居中 */
    width: 100%;
  }

  .talk_word {
    border: gray 1px solid !important;
    border-radius: 9px !important;
    resize: vertical; /* 允许垂直调整大小 */
    min-height: 25px;
    height: auto;
    overflow-y: hidden;
    max-height: 200px;
    outline: none;
    width: 80vw;
    padding: 10px;
    text-indent: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.5px;

  }

  .talk_button {
    display: flex;
    justify-content: space-between; /* 子元素之间的间隔平均分布 */
    cursor: pointer; /* 鼠标变成手型 */
    width: fit-content;

    padding: 10px;
    margin: auto 0 0 0;
  }
}
</style>

