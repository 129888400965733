<template>
  <div>
    <div v-if="isMobile===false">
      <el-dialog
          :visible.sync="dialogVisible"
          width="350px"
          :before-close="handleClose"
          top="35vh"
      >
        <div>
          <div class="title1"><span style="color:#2F6EFF">提交成功</span></div>
          <div class="title2">我们将安排专人尽快与您取得联系，预祝我们合作愉快！</div>
          <div style="text-align: center;margin-top: 30px">  <el-button type="primary" @click="handleClose">确定</el-button></div>

        </div>
      </el-dialog>
    </div>
    <div v-if="isMobile===true" style="font-size: 14px" class="phone">
      <el-dialog
          :visible.sync="dialogVisible"
          width="350px"
          :before-close="handleClose"
      >
        <div>
          <div class="title1"><span style="color:#2F6EFF">提交成功</span></div>
          <div class="title2" >我们将安排专人尽快与您取得联系，预祝我们合作愉快！</div>
          <div style="text-align: center;">  <el-button style="width: 100px" type="primary" @click="handleClose">确定</el-button></div>

        </div>
      </el-dialog>
    </div>
  </div>

</template>
<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
  }, data(){
    return{
      token:localStorage.getItem("access_token"),
      isMobile:false,
      languageSelect:'',
      ruleForm: {
        companyName: '',
        userName: '',
        contact: '',
        content: '',
        consultType:1,
        consultUrl:'',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
      },
      rules: {
        companyName: [
          { required: true, message: '请输入企业名称', trigger: 'blur' },
        ],
        userName: [
          { required: true, message: '请输入您的姓名', trigger: 'blur' }
        ],
        contact: [
          { required: true, message: '请输入您的联系方式', trigger: 'blur' }
        ],

      }
    }
  },
  mounted() {
    // Check if the device width is less than 768px (example for mobile)
    this.isMobile = window.matchMedia('(max-width: 767px)').matches;
  },
  created() {
    if(localStorage.getItem('language')){
      this.languageSelect=localStorage.getItem('language')
    }else{
      this.languageSelect='zh'
    }

  },
  methods: {
    handleClose() {
      this.ruleForm.companyName=""
      this.ruleForm.userName=""
      this.ruleForm.contact=""
      this.ruleForm.content=""
      this.$emit('hideDialog')
      location.reload()
    },
    submitForm(formName) {
      formName.consultUrl=window.location.href
      console.log(formName)
      if(formName.companyName===""){
        this.$message('请输入您的企业名称');
      }
      else if(formName.userName===""){
        this.$message('请输入您的联系姓名');
      }
      else if(formName.contact===""){
        this.$message('请输入您的联系方式');
      }

      else{
        this.$axios.post("/api/consult/add" ,{
          companyName: formName.companyName,
          userName: formName.userName,
          contact: formName.contact,
          content: formName.content,
          consultType:1,
          consultUrl:formName.consultUrl,
        }, {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token   },
        })
            .then((res) => {
              if(res.data.code===200){
                alert('提交成功，我们会及时与您沟通!');
                this.handleClose()
                this.resetForm(formName)
              }
              else{
                alert('提交失败，请重新提交试试!');
              }

            }).catch(error=>{
          console.log(error)
        })

      }

      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      //     alert('submit!');
      //   } else {
      //     console.log('error submit!!');
      //     return false;
      //   }
      // });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  },
};
</script>
<style scoped lang="scss">

.title1{
  text-align: center;
  width: 100%;
  font-size: 25px;
  margin-top: -45px;
}
.title2{
  color:#111111;
  text-align: center;
  width: 100%;
  line-height: 32px;
  margin-top: 25px;
}
.phone{
  position: fixed;
  z-index: 9999999999;
  .title1{
    text-align: center;
    width: 100%;
    font-size:16px;
    margin-top: -45px;
  }
  .title2{
    color:#111111;
    text-align: center;
    width: 80%;
    margin:35px auto 0 auto;
    line-height: 32px;
  }
  ::v-deep .el-form-item__error{
    left: 15px !important;
    top:80%;
  }
  ::v-deep .el-textarea__inner{
    border: none !important;
    margin-top: 5px;
    height: 35px;
  }
  .el-form-item {
    margin-bottom: 10px !important;
  }
  .span{
    height: 1px;
    background-color: #e1d7d7;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  ::v-deep {
    .el-dialog__body {
      padding: 30px 20px 30px 20px;
      color: #606266;
      font-size: 14px;
      word-break: break-all;
    }
    .el-dialog__header {
      .el-dialog__title {
        font-weight: bold;
        font-size: 14px;
      }
    }

    .el-dialog__headerbtn .el-dialog__close {
      color:#2F6EFF;;
      border: #2F6EFF 1px solid;
      border-radius: 50%;
    }
    .el-input__inner {
      border:none !important;

    }
    .el-textarea {
      border-radius: 4px;
      line-height: 20px;
      border: none !important;
      position: relative;
      font-size: 14px;
    }
    .el-form-item__label {
      font-size: 14px;
    }
    .el-button {
      width: fit-content;
      height: fit-content;
      padding: 15px 25px;
      margin-top: 25px;
      line-height: 35px;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
      color: #2F6EFF;
      a {
        display: block;
      }
    }
    .el-button--primary {
      border: 1px solid #2F6EFF;
      background-color: #2F6EFF !important;
      color: #fff !important;
      margin-bottom: 10px !important;
    }

  }
  ::v-deep {
    .el-dialog{
      margin:auto !important;
      font-size: 14px;

      top: 250px;
    }
    .el-dialog__header {
      padding:5px;
      .el-dialog__title {
        font-weight: bold;
        padding:15px;
        font-size: 14px;

      }

    }


    .el-button {
      width: fit-content;
      height: fit-content;
      font-size: 14px;
      padding:10px  15px;
      line-height:25px;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
      a {
        display: block;
      }
    }

  }

}
::v-deep {
  .el-dialog__body {
    padding: 30px 50px 30px 40px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
  }
  .el-dialog__header {
    .el-dialog__title {
      font-weight: bold;
      font-size: 16px;
    }
  }

  .el-dialog__headerbtn .el-dialog__close {
    color:#2F6EFF;;
    border: #2F6EFF 1px solid;
    border-radius: 50%;
  }
  .el-input__inner {
    border-radius: 5px !important;
    border: 1px solid #9b9a9a !important;

  }
  .el-textarea {
    border-radius: 5px !important;
    line-height: 40px;
    border: 1px solid #9b9a9a !important;
    position: relative;
    font-size: 14px;
  }
  .el-form-item__label {
    font-size: 16px;
  }
  .el-button {
    width: 150px;
    height: 45px;
    line-height: 35px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    color: #2F6EFF;
    padding: 0;
    a {
      display: block;
    }
  }
  .el-button--primary {
    border: 1px solid #2F6EFF;
    background: #2F6EFF;
    color: #fff;
  }

}
</style>
