import { render, staticRenderFns } from "./headOther.vue?vue&type=template&id=8313e280&scoped=true"
import script from "./headOther.vue?vue&type=script&lang=js"
export * from "./headOther.vue?vue&type=script&lang=js"
import style0 from "./headOther.vue?vue&type=style&index=0&id=8313e280&prod&lang=css"
import style1 from "./headOther.vue?vue&type=style&index=1&id=8313e280&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_420b40f8fe8ddaf3248c7dea96ec9571/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8313e280",
  null
  
)

export default component.exports